import React from 'react'
import { Route, Routes } from "react-router-dom";

import Usuarios from '../../Components/Customer/Usuarios/Usuarios';

/**
 * 
 * @export
 * @function RouterUsuarios
 * @description Router for dashboard routes 
 */
function RouterUsuarios(props) {
  return (
    <Routes>
      <Route path='/' element={<Usuarios {...props}/>}/>
    </Routes>
  )
}

export default RouterUsuarios