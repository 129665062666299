import React from 'react'
import { Layout, Col, Row, Typography, List, Button, Popconfirm, Card, Space, message, Modal } from "antd";
import { EditOutlined, DeleteOutlined, MailOutlined } from '@ant-design/icons';
import axios from 'axios';

import ModalUsuarios from '../../Customer/Usuarios/ModalUsuarios';
import CustomAvatar from '../../Widgets/Avatar';
import FloatingButton from '../../Widgets/FloatingButton/FloatingButton';
import { TipoUsuario,getResponseError } from '../../Utils';

import { User } from '../../../Hooks/Logged';

import usePermissions from '../../../Hooks/usePermissions';


const { Content } = Layout;
const {  Text } = Typography

class Usuarios extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			usuarios: {
				page: 1,
				limit: 10,
				total: 0,
				pages: 0,
				// filters: filters ?? [],
				// sort: sort ?? {},
				data: [],
			}
		}
	}

	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.get()
	}

	get = ({
		page = this.state.usuarios.page,
		limit = this.state.usuarios.limit
	} = this.state.usuarios) => {
		this.setState({ loading: true })
		axios.get('/usuarios', {
			params: {
				page,
				limit,
				parent: this.props.usuario_id
			}
		}).then(({ data }) => {
			this.setState({
				usuarios: data
			})
		}).catch(error => {
			message.error(getResponseError(error.response,"Error al obtener los usuarios"))
		}).finally(() => {
			this.setState({ loading: false })
		})
	}

	/**
	 * @methodOf  Usuarios
	 * @method sendEmail
	 *
	 * @description Envia un correo para recuperar la contraseña
	 *
	 * */
	sendEmail = (email) => {
		this.setState({ loading: true })
		axios.put("/password/recovery", { email: email })
			.then(res => {
				Modal.success({
					title: 'Restablecer contraseña.',
					content: '¡El correo ha sido enviado!'
				})
			})
			.catch(res => {
				Modal.warning({
					title: 'Error',
					content: 'El correo no se ha podido enviar. Verifica que el correo sea correcto'
				});
			})
			.finally(() => {
				this.setState({
					loading: false
				})
			})

	}



	render() {
		return (
			<>
				<Content className="p-1">
					<Row>
						<Col span={24} className="">
							<Row className='main'>
								<Col xs={24}>
									<List
										loading={this.state.loading}
										className="component-list"
										itemLayout="horizontal"
										locale={{ emptyText: "Sin Usuarios" }}
										dataSource={this.state.usuarios?.data}
										pagination={{
											current: this.state.usuarios.page,
											pageSize: this.state.usuarios.limit,
											total: this.state.usuarios.total,
											position: 'bottom',
											className: "flex-left",
											showSizeChanger: true,
											pageSizeOptions: [20, 50, 100, 500],
											showQuickJumper: true,
											locale: { jump_to: "Ir a la página", page: '' },
											onChange: (page, limit) => {
												this.getUsuarios({ page, limit })
											},
											onShowSizeChange: (page, limit) => {
												this.getUsuarios({ page, limit })
											}
										}}
										header={<Row className="header-list width-100 ">
											<Col xs={2} className="center">
												<Text></Text>
											</Col>
											<Col xs={6} className="center">
												<Text>Nombre</Text>
											</Col>
											<Col xs={6} className="center">
												<Text>Email</Text>
											</Col>
											<Col xs={3} className="center">
												<Text>Tipo</Text>
											</Col>
											<Col xs={4} className="center">
												<Text>Rol</Text>
											</Col>
											<Col xs={3} className="center">
												<Text>Acciones</Text>
											</Col>
										</Row>
										}

										renderItem={item => (
											<List.Item className="component-list-item" key={item._id}>
												<Card className="card-list width-100" bordered={false}>
													<Row className="width-100" align={"middle"}>
														<Col xs={2} className="center">
															<CustomAvatar
																name={item.nombre_completo}
																url={item?.avatar?.name ? {
																	url: axios.defaults.baseURL + "/usuarios/" + item._id,
																	avatar: item.avatar.file,
																} : null}
															/>
														</Col>
														<Col xs={6} className="center">
															<Text>{item.nombre_completo}</Text>
														</Col>
														<Col xs={6} className="center">
															<Text>{item.email}</Text>
														</Col>
														<Col xs={3} className="center">
															<Text>{TipoUsuario(item.tipo)}</Text>
														</Col>
														<Col xs={4} className="center">
															<Text>{item.rol}</Text>
														</Col>
														<Col xs={3} className="center">
															<Space>
																<Popconfirm
																	placement="topRight"
																	disabled={!this.props.recoveryUsuarios}
																	title="¿Deseas enviar un correo de recuperación de contraseña?" okText="Sí" cancelText="No"
																	onConfirm={() => this.sendEmail(item.email)}

																>
																	<Button
																		type="primary"
																		icon={<MailOutlined />}
																		disabled={!this.props.recoveryUsuarios}
																	/>
																</Popconfirm>
																<Button
																	type="primary"
																	icon={<EditOutlined />}
																	onClick={() => {
																		this.setState({ modal_visible: true, usuario_id: item._id, cliente_id: item.cliente_id })
																	}}
																	disabled={!this.props.editUsuarios}
																/>

																<Popconfirm
																	placement="topRight"

																	title="¿Deseas eliminar este usuario?"
																	onConfirm={() => axios.delete('/usuarios/', {
																		params: {
																			usuario_id: item._id
																		}
																	}).then((response) => {
																		message.success(response?.data?.message)
																		this.get()
																	})
																		.catch((error) => {
																			message.error(getResponseError(error.response, "No se pudo eliminar"))
																			this.get();
																		})
																	}
																	okText="Sí"
																	cancelText="No"
																	disabled={!this.props.deleteUsuarios}
																>
																	<Button
																		type="danger"
																		icon={<DeleteOutlined />}
																		disabled={!this.props.deleteUsuarios}
																	/>
																</Popconfirm>

															</Space>
														</Col>
													</Row>
												</Card>
											</List.Item>
										)}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
				</Content>
				<FloatingButton onClick={() => this.setState({ modal_visible: true })} disabled={!this.props.createUsuarios} />
				<ModalUsuarios
					visible={this.state.modal_visible}
					cliente_id={this.props.cliente_id ?? this.state.cliente_id}
					tipo={2}
					onClose={(flag) => {
						this.setState({ modal_visible: false, usuario_id: undefined })
						if (flag === true)
							this.get()
					}}
					usuario_id={this.state.usuario_id}
				/>
			</>
		)
	}
}

export default function (props) {
	let user = React.useContext(User)

	let permissions = usePermissions(user?.rol_id?.permisos, {
		editUsuarios: ["usuarios", "edit"],
		createUsuarios: ["usuarios", "create"],
		deleteUsuarios: ["usuarios", "delete"],
		recoveryUsuarios: ["usuarios", "recovery_password"]

	})

	return (<Usuarios {...props} {...permissions} />)
}