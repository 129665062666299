import React, { Component } from 'react';
import { Card, Typography, message, Row, Col, Switch, Button, Space } from 'antd'
import axios from 'axios';
import moment from 'moment';

//Componentes
import User from '../../../../Hooks/Logged';
import { tipoActividadEconomica } from '../../../Utils';
import usePermissions from '../../../../Hooks/usePermissions';
import { getResponseError, tipoClienteCuenta } from '../../../Utils';

//Modales
import ModalConfiguracionExt from '../ModalConfiguracionExt';
import ModalKey from '../ModalKey';

import 'moment/locale/es-mx';
moment.locale('es-mx')

const { Title, Paragraph } = Typography;

class CardInfoCliente extends Component {



    constructor(props) {
        super(props);
        this.state = {
            cliente_id: this.props.cliente_id,
            cliente: {},
            updating: false,
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getCliente()
    }

    /**
     * @memberOf CardInfoCliente
     * @method getCliente
     * @descripcion Obtiene la informacion del cliente
     * */
    getCliente = () => {

        this.setState({ loading: true })
        axios.get('/cliente/' + this.state.cliente_id, {
            params:{
                balance_total: true
            }
        }).then(({ data }) => {
            this.setState({
                cliente: data
            })
        }).catch(error => {
            message.error(getResponseError(error.response, "Error al obtener la información del cliente"))
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

     /**
     * @memberOf CardInfoCliente
     * @method update
     * @descripcion Actualiza la autentificacion de 2 factores , para todos los usuarios del cliente
     * */
    update = (checked) => {
        this.setState({ updating: true })
        axios.put('/cliente', {
            cliente_id: this.state.cliente_id,
            auth_2FA: checked,
        })
            .then(({ data }) => {
                this.setState({ cliente: data })
                message.success(`Todos los usuarios del cliente tienen ${checked ? "activado" : "desactivado"} el 2FA`)
            }).catch(error => {
                message.error(getResponseError(error.response, 'Error al actualizar'))
            }).finally(() => {
                this.setState({ updating: false })
            })
    }




    render() {
        let { cliente } = this.state;
        return (
            <Card bordered loading={this.state.loading} style={{ minHeight: '340px' }}>
                <Row align="middle" className='mb-1' gutter={[12, 12]}>
                    <Col>
                        <Title level={4} >Información Personal</Title>
                    </Col>
                </Row>
                <Row>

                    {
                        cliente.nombre ?
                            <Col xs={24} lg={12} xl={6}>
                                <Paragraph>Nombre</Paragraph>
                                <Paragraph strong style={{ fontSize: 16 }}>{cliente?.nombre} {cliente?.apellido_paterno || ""} {cliente?.apellido_materno || ""}</Paragraph>
                            </Col> : null
                    }

                    {
                        cliente.rfc ?
                            <Col xs={24} lg={12} xl={6}>
                                <Paragraph>RFC</Paragraph>
                                <Paragraph strong style={{ fontSize: 16 }}>{cliente?.rfc}</Paragraph>
                            </Col> : null
                    }
                    {
                        cliente.curp ?
                            <Col xs={24} lg={12} xl={6}>
                                <Paragraph>CURP</Paragraph>
                                <Paragraph strong style={{ fontSize: 16 }}>{cliente?.curp}</Paragraph>
                            </Col> : null
                    }
                    {
                        cliente.id_identificacion ?
                            <Col xs={24} lg={12} xl={6}>
                                <Paragraph>Número de Identificación</Paragraph>
                                <Paragraph strong style={{ fontSize: 16 }}>{cliente?.id_identificacion}</Paragraph>
                            </Col> : null
                    }
                    {
                        cliente.email ?
                            <Col xs={24} lg={12} xl={6}>
                                <Paragraph>Email</Paragraph>
                                <Paragraph strong style={{ fontSize: 16 }}>{cliente?.email}</Paragraph>
                            </Col> : null
                    }
                    {
                        cliente.alias_local ?
                            <Col xs={24} lg={12} xl={6}>
                                <Paragraph>Alias</Paragraph>
                                <Paragraph strong style={{ fontSize: 16 }}>{cliente?.alias_local}</Paragraph>
                            </Col> : null
                    }
                    {
                        cliente.actividad_economica ?
                            <Col xs={24} lg={12} xl={6}>
                                <Paragraph>Actividad Económica</Paragraph>
                                <Paragraph strong style={{ fontSize: 16 }}>{tipoActividadEconomica(cliente?.actividad_economica)}</Paragraph>
                            </Col> : null
                    }
                    {
                        cliente.fecha_nacimiento ?
                            <Col xs={24} lg={12} xl={6}>
                                <Paragraph>Fecha de Nacimiento</Paragraph>
                                <Paragraph strong style={{ fontSize: 16 }}>{moment(cliente?.fecha_nacimiento).format('LL')}</Paragraph>
                            </Col> : null
                    }
                    {
                        cliente.genero ?
                            <Col xs={24} lg={12} xl={6}>
                                <Paragraph>Género</Paragraph>
                                <Paragraph strong style={{ fontSize: 16, textTransform: 'capitalize' }}>{cliente?.genero}</Paragraph>
                            </Col> : null
                    }
                    {
                        cliente.pais_nacimiento?.nombre ?
                            <Col xs={24} lg={12} xl={6}>
                                <Paragraph>País de nacimiento</Paragraph>
                                <Paragraph strong style={{ fontSize: 16 }}>{cliente?.pais_nacimiento?.nombre}</Paragraph>
                            </Col> : null
                    }
                    {
                        cliente.pais?.nombre ?
                            <Col xs={24} lg={12} xl={6}>
                                <Paragraph>País de residencia</Paragraph>
                                <Paragraph strong style={{ fontSize: 16 }}>{cliente?.pais?.nombre}</Paragraph>
                            </Col> : null
                    }

                    {
                        cliente.direccion_id?.calle ?
                            <Col xs={24} lg={12} xl={6}>
                                <Paragraph>Domicilio</Paragraph>
                                <Paragraph strong style={{ fontSize: 16 }}>{cliente?.direccion_id?.calle} {cliente?.direccion_id?.num_exterior}</Paragraph>
                            </Col> : null
                    }
                    {
                        cliente.direccion_id?.codigo_postal ?
                            <Col xs={24} lg={12} xl={6}>
                                <Paragraph>Código Postal</Paragraph>
                                <Paragraph strong style={{ fontSize: 16 }}>{cliente?.direccion_id?.codigo_postal}</Paragraph>
                            </Col> : null
                    }
                    {
                        cliente.direccion_id?.alcaldia_municipio ?
                            <Col xs={24} lg={12} xl={6}>
                                <Paragraph>Municipio / Ciudad</Paragraph>
                                <Paragraph strong style={{ fontSize: 16 }}>{cliente?.direccion_id?.alcaldia_municipio}</Paragraph>
                            </Col> : null
                    }
                    {
                        cliente.direccion_id?.entidad_federativa_id ?
                            <Col xs={24} lg={12} xl={6}>
                                <Paragraph>Entidad Federativa</Paragraph>
                                <Paragraph strong style={{ fontSize: 16 }}>{cliente?.direccion_id?.entidad_federativa_id?.nombre}</Paragraph>
                            </Col> : null
                    }
                    {
                        cliente.tipo_cliente !== undefined && cliente.tipo_cliente !== null ?
                            <Col xs={24} lg={12} xl={6}>
                                <Paragraph>Tipo Cliente</Paragraph>
                                <Paragraph strong style={{ fontSize: 16 }}>{tipoClienteCuenta(cliente.tipo_cliente)}</Paragraph>
                            </Col> : null
                    }
                    <Col xs={24} lg={12} xl={6}>
                        <Paragraph>Verificación de 2 pasos</Paragraph>
                        <Paragraph strong style={{ fontSize: 16 }}><Switch checked={cliente.auth_2FA} disabled={!this.props.editCliente} onChange={this.update} loading={this.state.updating} /></Paragraph>
                    </Col>
                    <Col xs={24} lg={12} xl={6}>
                        <Paragraph style={{color: "transparent"}}>x</Paragraph>
                        <Space>
                            <Button onClick={()=>this.setState({modal_visible: true})}>Configuración enlace externo</Button>
                            <Button onClick={()=>this.setState({modal_visible_key: true})}>Key de Seguridad</Button>
                        </Space>
                    </Col>
                    <Col xs={24}>
                        <Paragraph className="font-24">Balance Total del cliente</Paragraph>
                        <Paragraph strong style={{ fontSize: 22 }}> $ {cliente.balance_total ? cliente.balance_total.toMoney(true) : 0} mxn </Paragraph>
                    </Col>
                </Row>
                <ModalConfiguracionExt
                    visible={this.state.modal_visible}
                    onClose={()=>this.setState({modal_visible: false})}
                    cliente_id={this.props.cliente_id}
                />
                <ModalKey
                    visible={this.state.modal_visible_key}
                    onClose={()=>this.setState({modal_visible_key: false})}
                    cliente_id={this.props.cliente_id}
                />
            </Card>
        )
    }
}

export default (props) => {
    let user = React.useContext(User)
    let permissions = usePermissions(user?.rol_id?.permisos, {
        editCliente: ["clientes", "edit"]
    })

    return <CardInfoCliente {...props} {...permissions} />
}