import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'
import axios from 'axios';

import { IconCuenta } from '../Icons'
import { getResponseError } from '../../Utils';
const { Option } = Select 

/**
 * @const SelectRol
 * @description Select para los roles registrados en el sistema
 */
const SelectRol = (props) => {

    const { 
        value, 
        onChange, 
        placeholder = "Seleccione el Rol", 
        onSelect = () => {}, 
        onTipoSelect = () => {},
        disabled = false, 
        className = "", 
        params = {}, 
        bordered, 
        allowClear = true, 
        onClear = () => {},
        size="default"
    } = props

    const [ roles, setRol ] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
    })


    /**
     * @const getRol
     * @description Obitiene los roles
     */
    const getRol = ({page, limit, search} = roles) => {

        axios.get('/roles', {
            params: {
                page,
                limit,
                search,
                ...params,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            setRol(data)
        }).catch(error => {
            console.log("ERR, error",error)
            message.error(getResponseError(error.response, 'Error al obtener las roles'))
        })
    }

    //componentDidMount
    useEffect(() => {
        getRol()
    }, [])

    useEffect(() => {
        if(value)
            onChange(value?.value ?? value)
    }, [value])

    return (
        <Select
            size={size}
            bordered={bordered}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            allowClear={allowClear}
            showSearch
            filterOption={false}
            labelInValue
            onSearch={(search)=> getRol({search})}
            onSelect={(rol, ...args)=> {
                onChange(rol.value)
                onSelect(rol.value, ...args)

                const r = roles.data.find(r => r._id === rol.value)
                onTipoSelect(r.tipo)
            }}
            value={value}
            onClear={()=>{
                onClear()
                onChange(undefined)
                onSelect(undefined)
                onTipoSelect(1) // Administrador
            }}
        >
            {
                roles?.data?.map(({ _id, nombre, color, logo, tipo }) => <Option value={_id} key={_id} tipo={tipo}>
                    {nombre ?? ""} 
                </Option>)
            }
        </Select>
    );
}



export default SelectRol;