import React from 'react'
import { Route, Routes } from "react-router-dom";
import Reglas from '../../Components/Admin/Reglas/Reglas';

/**
 * 
 * @export
 * @function RouterReglas
 * @description Router para modulo de Reglas del admin 
 */
function RouterReglas(props) {
  return (
    <Routes>
      <Route path='/' element={<Reglas {...props} />}/>
    </Routes>
  )
}

export default RouterReglas