import React from 'react'
import { Layout, Col, Row, Typography, List, Card, Space, message, Button, Popconfirm } from "antd";
import axios from 'axios';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';

//Componentes
import User from '../../../Hooks/Logged';
import useSearch from '../../../Hooks/Search';
import CustomAvatar from '../../Widgets/Avatar';
import usePermissions from '../../../Hooks/usePermissions';
import { getResponseError, tipoClienteCuenta } from '../../Utils';
import FloatingButton from '../../Widgets/FloatingButton/FloatingButton';

//Modales
import ModalClientes from './ModalClientes'
import ModalRevendedores from '../../Admin/Revendedores/ModalRevendedores'

const { Content } = Layout;
const { Title, Text } = Typography

class Clientes extends React.Component {

	constructor(props) {
		super(props)
		let { page, limit, search } = this.props.params
		this.state = {
			loading: false,

			searching: true,
			filtroSearch: '',
			clientes: {
				page: page ?? 1,
				limit: limit ?? 20,
				total: 0,
				pages: 0,
				search: search ?? undefined,
				data: [],
			},
			isOpen: false
		}
	}

	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.get()
	}


	/**
	* @method componentDidUpdate
	* @description Actualiza la vista si se buscan datos
	*/
	componentDidUpdate() {

		const filtro = this.state.filtroSearch;
		const propFiltro = this.props.search;
		if (filtro !== propFiltro && this.state.searching === true) {
			this.get();
			this.state.searching = false;
		}
	}
	/**
	 * @memberOf Clientes
	 * @method get
	 * @descripcion Ontiene los clientes registrados en el sistema
	 * */
	get = ({
		page = this.state.clientes.page,
		limit = this.state.clientes.limit,
		search = this.props.search
	} = this.state.clientes) => {

		this.setState({ loading: true })
		axios.get('/clientes', {
			params: {
				page,
				limit,
				search
			}
		}).then(({ data }) => {
			this.setState({
				clientes: {
					...data,
				},
				loading: false,
				searching: true,
				filtroSearch: search
			});
		}).catch(error => {
			message.error(getResponseError(error.response, "Error al obtener los clientes"))
		}).finally(() => {
			this.setState({ loading: false })
		})
	}


	/**
	 * @memberOf Clientes
	 * @method deleteCliente
	 * @descripcion Elimina a un cliente
	 * */
	deleteCliente = (cliente_id) => {
		this.setState({ loading: true })

		axios.delete('/cliente', {
			params: {
				cliente_id: cliente_id
			}
		}).then((response) => {
			message.success("Cliente dado de baja")
			this.get()
		})
			.catch((error) => {
				message.error(error?.response?.data?.message ?? "Error al eliminar el cliente");
				//this.get();
			}).finally(() => {
				this.setState({ loading: false })
			})
	}

	render() {
		return (
			<>
				<Content className="p-1">
					<Row>
						<Col span={24} className="" style={{ paddingRight: '0.5rem' }}>
							<Row className='main'>
								<Col xs={24}>
									<Title level={5}> Clientes </Title>
								</Col>
								<Col xs={24}>
									<List
										loading={this.state.loading}
										className="component-list mb-3"
										itemLayout="horizontal"
										locale={{ emptyText: "Sin Clientes" }}
										dataSource={this.state.clientes?.data}
										pagination={{
											current: this.state.clientes.page,
											pageSize: this.state.clientes.limit,
											total: this.state.clientes.total,
											position: 'bottom',
											className: "flex-left",
											showSizeChanger: true,
											pageSizeOptions: [10 ,20, 50, 100, 500],
											showQuickJumper: true,
											locale: { jump_to: "Ir a la página", page: '' },
											onChange: (page, limit) => {
												this.get({ page, limit })
											},
											onShowSizeChange: (page, limit) => {
												this.get({ page, limit })
											}
										}}
										header={<Row className="header-list width-100 ">
											<Col xs={0} md={7}>
												<Text>Nombre</Text>
											</Col>
											<Col xs={0} md={4} className="center">
												<Text>Alias</Text>
											</Col>
											<Col xs={0} md={6} className="center">
												<Text>Email</Text>
											</Col>
											<Col xs={0} md={4} className="center">
												<Text>Tipo cliente</Text>
											</Col>
											<Col xs={0} md={3} className="center">
												<Text>Acciones</Text>
											</Col>
										</Row>
										}

										renderItem={item => (
											<List.Item className="component-list-item" key={item._id}>
												<Card className="card-list width-100" bordered={false}>
													<Row gutter={[2,8]} className="width-100" align={"middle"}>
														<Col xs={24} md={7} className="flex-left">
															<CustomAvatar
																name={item.nombre + " " + item.apellido_paterno + " " + item.apellido_materno}
															/>
															<Text ellipsis>{item.nombre} {item.apellido_paterno} {item.apellido_materno}</Text>
														</Col>
														<Col xs={24} md={4} className="center">
															<Text>{item.alias_local ?? "-"}</Text>
														</Col>
														<Col xs={24} md={6} className="center">
															<Text>{item.email}</Text>
														</Col>
														<Col xs={24} md={4} className="center">
															{tipoClienteCuenta(item.tipo_cliente)}
														</Col>
														<Col xs={24} md={3} className="flex-right">
															<Space>

																<Button
																	disabled={!this.props.accessClienteDetalle}
																	type='primary'
																	title="Ver detalle"
																	icon={<EyeOutlined />}
																	onClick={() => this.props.navigate("/admin/clientes/" + item._id,)}
																/>

																<Button
																	disabled={!this.props.editClientes}
																	type="primary"
																	icon={<EditOutlined />}
																	onClick={() => this.setState({ modal_visible: true, cliente_id: item._id })}
																/>

																{this.props.user?.rol_id?.super_admin ? <Popconfirm
																	disabled={!this.props.deleteClientes}
																	placement="topRight"
																	title="¿Deseas eliminar este cliente?, los usuarios tambien se eliminaran"
																	onConfirm={() => this.deleteCliente(item._id)}
																	okText="Sí"
																	cancelText="No"
																>
																	<Button
																		disabled={!this.props.deleteClientes}
																		type="danger"
																		icon={<DeleteOutlined />}
																	/>
																</Popconfirm> : null}

															</Space>
														</Col>
													</Row>
												</Card>
											</List.Item>
										)}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
				</Content>
				{this.props.createClientes && <FloatingButton onClick={() => this.setState({ modal_visible_step: true })} />}


				<ModalClientes
					visible={this.state.modal_visible}
					onClose={(flag) => {
						this.setState({ modal_visible: false, cliente_id: undefined })
						if (flag === true)
							this.get()
					}}
					cliente_id={this.state.cliente_id}
				/>
				<ModalRevendedores
					visible={this.state.modal_visible_step}
					title="Creación del Cliente"
					tipo="cliente"
					onClose={(flag) => {
						this.setState({ modal_visible_step: false, modal_visible: false, cliente_id: undefined })
						if (flag === true)
							this.get()
					}}
				/>
			</>
		)
	}
}

export default function (props) {
	//let user = React.useContext(Logged)
	const [params, setParams] = useSearchParams();
	const [search, setSearch] = useSearch();

	let user = React.useContext(User)
	let permissions = usePermissions(user?.rol_id?.permisos, {
		editClientes: ["clientes", "edit"],
		createClientes: ["clientes", "create"],
		deleteClientes: ["clientes", "delete"],
		accessClienteDetalle: ["clientes", "access"]
	})

	return (<Clientes
		{...props}
		search={search}
		navigate={useNavigate()}
		setParams={setParams}
		params={params}
		{...permissions}
		user={user}
	/>)
}