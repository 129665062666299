import React, { Component } from "react";
import { Row, Col, Modal, Space, Typography, Form, Input, message, Spin, Button, Select, Divider, Switch } from 'antd';
import axios from "axios"
import { getResponseError } from "../../Utils";

const { Option } = Select;
const { Title, Text } = Typography;

/**
 *
 * @class FormCobranza
 * @extends {Component}
 * @description Modal que muestra y edita las fechas de corte de cada cuenta de un cliente,
 * las fechas de corte se registran el dia que se hace la primea transaccion con la cuenta o la que se pone aqui
 * esto para hacer el cobro de uso de cuenta si hay reglas que lo aplican
 */
class FormCobranza extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            cuentas: []
        }
    }

    formRef = React.createRef();

    componentDidMount() {
       if(this.props.cliente_id)
        this.getCuentas()
    }

    /**
     * @memberof FormCobranza
     * @method getCuentas
     * @description Obtiene las cuentas del cliente
     */
    getCuentas = () => {
        this.setState({loading: true})
        axios.get('/cuentas',{
            params:{
                cliente_id: this.props.cliente_id,
                limit: 50
            }
        }).then(({data}) => {
            this.setState({
                cuentas: data.data
            })

            let datos = {}
            for(let cuenta of data.data){
                datos[cuenta._id] = cuenta.dia_corte
            }

            this.formRef.current.setFieldsValue({...datos})

        }).catch(error => {
            message.error(error?.response?.data?.message ?? "Error al obtener las cuentas del cliente")
        }).finally(()=>this.setState({loading: false}))
    }

    /**
     * @memberof FormCobranza
     * @method onFinish
     * @description Se ejecuta al hacer submit en el formulario, actualiza las fechas de corte de las cuentas
     */
    onFinish = (values) => {

        console.log("values", values);

        let cuentas = Object.entries(values).map(([cuenta_id, dia_corte]) => ({ cuenta_id, dia_corte }));
        
        console.log("cuentas", cuentas);

        this.setState({loading: true})
        axios.post('/cobranza/dia-corte',{
            cliente_id: this.props.cliente_id,
            cuentas,
        }).then(response => {
            message.success("Cuentas Actualizadas correctamente")
            this.props.onClose(true)
        }).catch(error => {
            message.error("Error al actualizar las cuentas")
        }).finally(()=>this.setState({loading: false}))
    }


    render() {
        return (
            <>
                <Spin spinning={this.state.loading}>
                    <Form
                        layout="vertical"
                        ref={this.formRef}
                        onFinish={this.onFinish}
                    >
                        <Row>
                            <Col span={16}>
                                <Text> Cuenta </Text>
                            </Col>
                            <Col span={8} className="flex-right">
                                <Text> Dia de corte mensual </Text>
                            </Col>
                        </Row>
                       {
                            this.state.cuentas.map(cuenta => {
                                return <Row key={cuenta._id}>
                                    <Col span={18} className="flex-left-column">
                                        <Text strong>{cuenta.nombre}</Text>
                                        <Text>{cuenta.cuenta}</Text>
                                    </Col>
                                    <Col span={6} className="pt-2">
                                        <Form.Item
                                            name={cuenta._id}
                                        >
                                            <Select
                                                placeholder="Dia del mes"
                                                allowClear
                                            >
                                                <Option value="1">1</Option>
                                                <Option value="2">2</Option>
                                                <Option value="3">3</Option>
                                                <Option value="4">4</Option>
                                                <Option value="5">5</Option>
                                                <Option value="6">6</Option>
                                                <Option value="7">7</Option>
                                                <Option value="8">8</Option>
                                                <Option value="9">9</Option>
                                                <Option value="10">10</Option>
                                                <Option value="11">11</Option>
                                                <Option value="12">12</Option>
                                                <Option value="13">13</Option>
                                                <Option value="14">14</Option>
                                                <Option value="15">15</Option>
                                                <Option value="16">16</Option>
                                                <Option value="17">17</Option>
                                                <Option value="18">18</Option>
                                                <Option value="19">19</Option>
                                                <Option value="20">20</Option>
                                                <Option value="21">21</Option>
                                                <Option value="22">22</Option>
                                                <Option value="23">23</Option>
                                                <Option value="24">24</Option>
                                                <Option value="25">25</Option>
                                                <Option value="26">26</Option>
                                                <Option value="27">27</Option>
                                                <Option value="28">28</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            })
                       }
                       <Row align="center">
                            <Col xs={22} lg={10} >
                                <Form.Item >
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        block
                                        size="large"
                                        className="mt-2">
                                        Aceptar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </>
        )
    }
}



export default function ModalCobranza(props) {
    const { visible = false, onClose = () => { }, cuenta_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        destroyOnClose={true}
        zIndex={1000}
        footer={false}
        closable={false}
        width={600}
    >
        <Row>
            <Col span={24} className="center">
                <img src={"/img/BXNKLogo.png"} width="100" />
            </Col>
        </Row>
        <Title level={3} className="text-center mb-2 mt-1">Editar Cuentas</Title>
        <FormCobranza {...props} />
    </Modal>

}