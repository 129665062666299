import React from 'react'
import { Layout, Col, Row, Typography, List, Card, Space, message, Button, Popconfirm, Tooltip, Tag } from "antd";
import axios from 'axios';
import { useSearchParams, useNavigate } from 'react-router-dom'
import { EditOutlined, DeleteOutlined, ArrowUpOutlined, QuestionCircleOutlined, ArrowDownOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { AiOutlineFilePdf } from 'react-icons/ai'

//components
import { User } from '../../../Hooks/Logged';
import useSearch from '../../../Hooks/Search';
import usePermissions from '../../../Hooks/usePermissions';
import FloatingButton from '../../Widgets/FloatingButton/FloatingButton';
import { TipoCuentaText, CodificarCuenta, EstadoCuenta, getResponseError, tipoClienteCuenta, RenderRecibeComision } from '../../Utils';

//Modal
import ModalCuenta from './ModalCuenta';
import ModalCobroComision from './ModalCobroComision';
import ModalEstadoCuenta from './ModalEstadoCuenta';

const { Content } = Layout;
const { Title, Text } = Typography

class Cuentas extends React.Component {

    constructor(props) {
        super(props)
        let { page, limit, filters, search, sort } = this.props.params
        this.state = {
            loading: false,
            searching: true,
            filtroSearch: '',
            cuentas: {
                page: page ?? 1,
                limit: limit ?? 20,
                total: 0,
                pages: 0,
                search: search ?? undefined,
                data: [],
            },
            cuenta: ""
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.get()
    }


    /**
    * @method componentDidUpdate
    * @description Actualiza la vista si se buscan datos
    */
    componentDidUpdate() {

        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;
        if (filtro !== propFiltro && this.state.searching == true) {
            this.get();
            this.state.searching = false;
        }
    }
    /**
     * @memberOf cuentas
     * @method get
     * @descripcion Obtiene los cuentas registrados en el sistema
     * */
    get = ({
        page = this.state.cuentas.page,
        limit = this.state.cuentas.limit,
        search = this.props.search
    } = this.state.cuentas) => {

        this.setState({ loading: true })
        axios.get('/cuentas', {
            params: {
                page,
                limit,
                search,
                all: true
            }
        }).then(({ data }) => {
            this.setState({
                cuentas: {
                    ...data,
                },
                loading: false,
                searching: true,
                filtroSearch: search
            });
        }).catch(error => {
            message.error(getResponseError(error.response, "Error al obtener las cuentas"))
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    /**
     *
     * @memberof Cuentas
     * 
     * @method bloquearCuenta
     * @description Generamos el PDF del estado de cuenta
     */
    bloquearCuenta = (cuenta) => {
        this.setState({loading: true})
        axios.put('/cuenta', {
            cuenta_id: cuenta._id,
            cuenta_bloqueada: true        
        }).then((response) => {
            message.success("Cuenta desbloqueada")
            this.get()
        }).catch((error) => {
            message.error(getResponseError(error.response, 'No se pudo hacer la accion'))
            this.setState({loading: false})
        })
    } 

    /**
     *
     * @memberof Cuentas
     * 
     * @method desbloquearcuenta
     * @description Generamos el PDF del estado de cuenta
     */
    desbloquearcuenta = (cuenta) => {

        if(cuenta.cuenta_bloqueada && cuenta.saldo_bloqueado === 0){
            this.bloquearCuenta(cuenta)
            return
        }

        this.setState({modal_visible_comision: true, cuenta_id: cuenta._id})
        
    } 


    /**
     *
     * @memberof Cuentas
     * 
     * @method renderButton
     * @description Renderiza el boton para acitvar o descativar la cuenta
     */
    renderButton = (cuenta) => {

        let { cuenta_bloqueada } = cuenta;

        let title = cuenta_bloqueada ? "¿Deseas desbloquear la cuenta?" : "¿Deseas bloquear esta cuenta?"
        let icon = cuenta_bloqueada ?  <CheckCircleOutlined /> : <CloseCircleOutlined />
        let text_btn = cuenta_bloqueada ? "Desbloquear cuenta" : "Bloquear cuenta" 
        let onConfirm = cuenta_bloqueada ? this.desbloquearcuenta : this.bloquearCuenta
        
        return <Popconfirm
            placement="topRight"
            title={title}
            onConfirm={() => onConfirm(cuenta)}
            okText="Sí"
            cancelText="No"
            disabled={cuenta.estado === "B"}
        >
            <Button
                type="primary"
                icon={icon}
                title={text_btn}
                disabled={cuenta.estado === "B"}
            />
        </Popconfirm>
    }

    /**
     *
     * @memberof Cuentas
     * 
     * @method renderEstado
     * @description Renderiza el boton para acitvar o descativar la cuenta
     */
    renderEstado = ({estado, cuenta_bloqueada}) => {

        if(estado === "B")
            return <Tag color={"red"}>Cuenta dada de Baja </Tag>

        if(cuenta_bloqueada)
            return <Tooltip 
                placement="topLeft" 
                title={"Cuenta temporalmente bloqueada por exceder límites; egresos bloqueados."}
            > <Tag color={"red"}>Cuenta Bloqueda <QuestionCircleOutlined /> </Tag> </Tooltip>

        return <Tag color={"lime"}>Cuenta Disponible </Tag>
    }

    /**
     *
     * @memberof Cuentas
     * 
     * @method renderButtonBaja
     * @description Renderiza el boton para dar de baja  o alta la cuenta
     */
    renderButtonBaja = (cuenta) => {

        let { estado, es_concentradora, _id } = cuenta;

        let title = estado === "B" ? "¿Deseas dar de alta esta cuenta?" : "¿Deseas dar de baja esta cuenta?"
        let icon = estado === "B" ?  <ArrowUpOutlined /> : <ArrowDownOutlined />
        let text_message = estado === "B" ? 'Cuenta dada de Alta' : 'Baja Exitosa'
        let alta = estado === "B" ? true : undefined

        let disabled = false

        if((this.props.user.rol_id.tipo === 5 && es_concentradora) || !this.props.deleteCuentas){
            disabled = true
        }
        
        return <Popconfirm
            placement="topRight"
            title={title}
            onConfirm={() => axios.delete('/cuenta', {
                params: {
                    cuenta_id: _id,
                    alta: alta
                }
            }).then((response) => {
                message.success(text_message)
                this.get()
            }).catch((error) => {
                message.error(getResponseError(error.response, 'No se pudo eliminar'))
                this.get()
            })
            }
            okText="Sí"
            cancelText="No"
            disabled={disabled}
        >
            <Button
                type="danger"
                icon={icon}
                disabled={disabled}
            />
        </Popconfirm>
    }

    disableCuenta = (cuenta) => {

        if(this.props.user.rol_id.tipo === 5 && cuenta.es_concentradora)
            return true

        if(!this.props.editCuentas || cuenta.estado === "B")
            return true
    }

    render() {
        return (
            <>
                <Content className="p-1">
                    <Row>
                        <Col span={24} className="" style={{ paddingRight: '0.5rem' }}>
                            <Row className='main'>
                                <Col xs={24}>
                                    <Title level={5}> Cuentas </Title>
                                </Col>
                                <Col xs={24}>
                                    <List
                                        loading={this.state.loading}
                                        className="component-list mb-3"
                                        itemLayout="horizontal"
                                        locale={{ emptyText: "Sin Cuentas" }}
                                        dataSource={this.state.cuentas?.data}
                                        pagination={{
                                            current: this.state.cuentas.page,
                                            pageSize: this.state.cuentas.limit,
                                            total: this.state.cuentas.total,
                                            position: 'bottom',
                                            className: "flex-left",
                                            showSizeChanger: true,
                                            pageSizeOptions: [10, 20, 50, 100, 500],
                                            showQuickJumper: true,
                                            locale: { jump_to: "Ir a la página", page: '' },
                                            onChange: (page, limit) => {
                                                this.get({ page, limit })
                                            },
                                            onShowSizeChange: (page, limit) => {
                                                this.get({ page, limit })
                                            }
                                        }}
                                        header={<Row className="header-list width-100 ">
                                            <Col xs={0} sm={0} md={4}>
                                                <Text>Nombre / Cuenta</Text>
                                            </Col>
                                            <Col xs={0} sm={0} md={4} className="center">
                                                <Text>Alias</Text>
                                            </Col>
                                            <Col xs={0} sm={0} md={4} className="center">
                                                <Text>Saldo</Text>
                                            </Col>
                                            <Col xs={0} sm={0} md={2} className="center">
                                                <Text>Tipo Cuenta</Text>
                                            </Col>
                                            <Col xs={0} sm={0} md={3} className="center">
                                                <Text>Asesor</Text>
                                            </Col>
                                            <Col xs={0} sm={0} md={3} className="center">
                                                <Text></Text>
                                            </Col>
                                            <Col xs={0} sm={0} md={3} className="center">
                                                <Text>Acciones</Text>
                                            </Col>
                                        </Row>
                                        }

                                        renderItem={item => (
                                            <List.Item className="component-list-item" key={item._id}>
                                                <Card className="card-list width-100" bordered={false}>
                                                    <Row className="width-100" align={"middle"} gutter={[0,12]}>
                                                        <Col xs={24} sm={12} lg={4} className="flex-left-column">
                                                            <Tooltip placement="topLeft"  title={item.nombre}>
                                                                <Text ellipsis>{item.nombre}</Text>
                                                            </Tooltip>
                                                            <Tooltip placement="topLeft"  title={item.cuenta}>
                                                                <Space align="start" className="space-cuenta-comision">
                                                                    <small ellipsis>{item.cuenta}</small>{item.recibe_comision ? RenderRecibeComision({cuenta: item}) : null}
                                                                </Space>
                                                            </Tooltip>
                                                        </Col>
                                                        
                                                        <Col xs={24} sm={12} lg={4} className="center ">
                                                            <Tooltip placement="topLeft"  title={item.alias}>
                                                                <Text ellipsis>{item.alias}</Text>
                                                            </Tooltip>
                                                        </Col>
                                                        <Col xs={24} sm={12} lg={4} className="center ">
                                                            <Tooltip 
                                                                placement="topLeft" 
                                                                title={<div>
                                                                    Balance disponible: ${item.balance.toMoney(true)} mxn <br/>
                                                                    Balance bloqueado: $ {(item.saldo_bloqueado ?? 0).toMoney(true)} mxn
                                                                </div>}
                                                            >
                                                                <Text>$ {(item.balance + (item.saldo_bloqueado ?? 0)).toMoney(true)} mxn</Text>
                                                            </Tooltip>
                                                        </Col>
                                                        <Col xs={24} sm={12} lg={2} className="center ">
                                                            {tipoClienteCuenta(item.tipo_cuenta)}
                                                        </Col>
                                                        <Col xs={24} sm={12} lg={3} className="center ">
                                                            {item?.usuario_id?.nombre_completo ? <Tag color={"purple"}>{item?.usuario_id?.nombre_completo}</Tag> : "-"}
                                                        </Col>
                                                        <Col xs={24} sm={12} lg={3} className="center ">
                                                            {this.renderEstado(item)}
                                                        </Col>
                                                        <Col xs={24} sm={24} lg={4} className="flex-right">
                                                            <Space>
                                                                {this.props.user?.rol_id?.super_admin ? this.renderButton(item) : null}
                                                                <Button
                                                                    type="primary"
                                                                    icon={<EditOutlined />}
                                                                    onClick={() => this.setState({ modal_visible: true, cuenta_id: item._id })}
                                                                    disabled={this.disableCuenta(item)}
                                                                />

                                                                
                                                                {
                                                                    this.props.estadoCuenta ? (
                                                                            <Button
                                                                                title="Ver estado de cuenta"
                                                                                type='primary'
                                                                                className='btn-primary center'
                                                                                icon={<AiOutlineFilePdf />}
                                                                                disabled={item.estado === "B"}
                                                                                onClick={()=>this.setState({modal_visible_estado: true, cuenta_id: item._id})}
                                                                            />
                                                                    ) : (
                                                                        <Tooltip title="No estas autorizado" placement='topLeft'>
                                                                            <Button
                                                                                title="Ver estado de cuenta"
                                                                                type='primary'
                                                                                className='btn-primary center'
                                                                                icon={<AiOutlineFilePdf />}
                                                                                disabled={item.estado === "B"}
                                                                            />
                                                                        </Tooltip>
                                                                    )
                                                                }

                                                                {this.renderButtonBaja(item)}

                                                            
                                                            </Space> 
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </List.Item>
                                        )}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {this.props.createCuentas && <FloatingButton onClick={() => this.setState({ modal_visible: true })} />}
                </Content >
                <ModalCuenta
                    cuenta_id={this.state.cuenta_id}
                    visible={this.state.modal_visible}
                    onClose={
                        () => {
                            this.setState({ modal_visible: false, cuenta_id: null })
                            this.get(this.state.cuentas.page)
                        }
                    }
                    revendedor={this.props.user?.rol_id?.tipo === 5}
                />

                <ModalCobroComision
                    visible={this.state.modal_visible_comision}
                    onClose={(flag) => {
                        this.setState({ modal_visible_comision: false, cuenta_id: null })
                        if(flag === true)
                            this.get()
                    }}
                    cuenta_id={this.state.cuenta_id}
                />

                <ModalEstadoCuenta
                    visible={this.state.modal_visible_estado}
                    onClose={() => {
                        this.setState({ modal_visible_estado: false, cuenta_id: null })
                    }}
                    cuenta_id={this.state.cuenta_id}
                />

            </>
        )
    }
}

export default function (props) {
    const [params, setParams] = useSearchParams();
    const [search, setSearch] = useSearch();

    let user = React.useContext(User)

    let permissions = usePermissions(user?.rol_id?.permisos, {
        editCuentas: ["cuentas", "edit"],
        createCuentas: ["cuentas", "create"],
        deleteCuentas: ["cuentas", "delete"],
        estadoCuenta: ["cuentas", "estado_cuenta"]
    })

    return (<Cuentas {...props} navigate={useNavigate()} search={search} setParams={setParams} params={params} {...permissions} user={user}/>)
}