import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { User, SetUser } from './Hooks/Logged';
import axios from 'axios'
//componentes
import Routes from './Routes'


axios.defaults.baseURL = process.env.REACT_APP_WEB_SERVICE
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.withCredentials = true

axios.interceptors.request.use(function (config) {
	config.headers.Authorization = sessionStorage.getItem("token")
	return config;
});

// Tratar con el status 401 (Jwt Expired)
axios.interceptors.response.use(res => res, error => {
	const errname = error.response.data?.name
	if(
		error.response.status === 401 && 
		(errname === "JsonWebTokenError" || errname === "TokenExpiredError")
	){
		const redirect = /^\/(customer|admin)/.test(window.location.pathname)
		if(redirect) return window.location.href = "/"
	}
		
	return Promise.reject(error)
})
class App extends Component {

	constructor(props) {
		super(props);

		this.state = {
			user: 0,
		}
	}

	componentDidMount() {
		axios.get('/user/logged', {
			headers: { Authorization: sessionStorage.getItem('token') }
		}).then(({ data }) => {
			this.setUser(data)
		}).catch((error) => {
			console.log('error', error.response)
			this.setUser(undefined)
		})
	}

	setUser = (user) => this.setState({user}) 

	render() {
		const { setUser } = this;
		const { user } = this.state;
		return (
			<BrowserRouter>
				<User.Provider value={user}>
					<SetUser.Provider value={setUser}>
						<Routes />
					</SetUser.Provider>
				</User.Provider>
			</BrowserRouter>
		)
	}
}

export default App;
