import React from 'react'
import { Route, Routes } from "react-router-dom";

import Roles from '../../Components/Admin/Roles/Roles';

/**
 * 
 * @export
 * @function RouterRoles
 * @description Router for dashboard routes 
 */
function RouterRoles(props) {
  return (
    <Routes>
      <Route path='/' element={<Roles {...props}/>}/>
    </Routes>
  )
}

export default RouterRoles