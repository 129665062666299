import React, { Component, useContext } from 'react'
import { Row, Col, Form, Input, Button, Card, Spin, Divider, Typography, Checkbox, Alert, Layout, Modal } from 'antd';
import { Link, Navigate, useNavigate  } from 'react-router-dom';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import { SetUser } from '../../Hooks/Logged'

//modales
import Modal2FA from './Modal2FA'

//css
import axios from 'axios'

import '../../Styles/Modules/Auth/auth.scss';

const { Title, Text } = Typography

/**
 * 
 * @class Login
 * @extends {Component}
 * @description Componente Login page
 */
class Login extends Component {
	
	

	constructor(props) {
		super(props);
		this.state = {
			log: false
		}
	}


	/**
	 * @method onFinish
	 * @desription Verifica si las claves ingresadas son correctas, para abrirla verificacion de 2 pasos
	 * */
	onFinish = (values) => {
        this.setState({ loading: true })
        axios.post('/login', {
            email: values.email,
            password: values.password
        })
        .then(response => {
        	if(response.data.type){
	        	this.setState({
	        		modal_visible: true,
	            	qrcode: response.data.qrcode,
	            	values
	        	})            
        	}else{
        		this.loginUser(response)
        	}
        })
        .catch((error) => {
			console.log(error)
			Modal.error({title: "Error al iniciar sesión", content: error?.response?.data?.message ?? "Credenciales incorrectas o usuario no registrado."})
            this.setState({ loading: false });
        })
        .finally(()=>{
        	this.setState({loading: false})
        })
    };


    /**
	 * @method loginUser
	 * @desription Una vez iniciada la sesion correctamente y validada, se establecen los tokens
	 * */
    loginUser = ({ data, headers }) => {
    	const { setUser, navigate } = this.props;
		sessionStorage.setItem('token', headers.authorization);
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        setUser(data.user);
       	navigate(`/${(data.user.rol_id?.tipo == 1 || data.user.rol_id?.tipo == 4|| data.user.rol_id?.tipo == 5) ? "admin" : "customer"}/dashboard`);
    }

	render() {
		
		return(
			<Layout className="auth" style={{ backgroundImage: "url(/img/background/background-main.png)"}}>
				<Card className="card">
					<Row align="center">
						<Col span={24} className="center mb-1">
							<img src={"/img/BXNKLogo.png"} width="164"/>
						</Col>
						<Col span={24} className="text-center mb-2">
							<Title>Iniciar Sesión</Title>
						</Col>
						<Col span={20}>
							<Form
								onFinish={this.onFinish}
							>
								<Form.Item
							      	name="email"
							      	rules={[{ required: true, message: 'Ingrese su correo electrónico' }]}
							    >
							      	<Input
							      		prefix={<UserOutlined />}
							      		placeholder="Correo Electrónico"
							      		size="large"
							      	/>
							    </Form.Item>

							    <Form.Item
							      name="password"
							      rules={[
									{
										required: true,
										message: 'Por favor ingresa tu  contraseña'
								  }
								]}
								hasFeedback
							    >
							     	<Input.Password
							     		prefix={<LockOutlined />}
							     		placeholder="Contraseña"
							     		size="large"
							     	/>
							    </Form.Item>

							    <Form.Item >
							     	<Button type="primary" loading={this.state.loading} htmlType="submit" block size="large">
							        	Acceder
							      	</Button>
							    </Form.Item>
							</Form>
						</Col>
						<Col span={24} className="text-center">
							<Text>Olvidaste tu constraseña? <Link to='/password/recovery'>Recuperala aquí</Link></Text>
						</Col>
					</Row>
				</Card>
				<Modal2FA
					visible={this.state.modal_visible}
					qrcode={this.state.qrcode}
					values={this.state.values}
					onClose={()=>{
						this.setState({modal_visible: false})
					}}
					login={this.loginUser}
				/>
			</Layout>
		)
	}
}

export default function (props) {

	return <Login {...props} 
		navigate={useNavigate()}
		setUser={useContext(SetUser)}
	/>
}