import React, { Component } from "react";
import { Row, Col, Modal, Typography, Form, Input, message, Spin, Button, Select, DatePicker, Divider, Switch } from 'antd';
import { SelectCuenta, SelectEntidadFederativa, SelectPais } from "../Widgets/Inputs/Selects";
import { getResponseError } from '../Utils'
import axios from "axios"
import moment from "moment";

const { Title } = Typography;


class ModalCliente extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            cliente: {
            },
        }
    }

    modalRef = React.createRef();

    componentDidMount() {
        if (this.props.cliente_id) {
            this.get()
        }
    }

    /**
     * @memberof ModalCliente
     * @method get
     * @description Obtiene informacion del cliente
     */
    get = () => {

        this.setState({ loading: true })
        axios.get('/dashboard/cuenta-cliente/' + this.props.cliente_id, {
            params: {
                usuario: true, //obtiene si el usuario tiene asignado el 2FA
                cliente_id: this.props.cliente_id
            }
        })
            .then(({ data }) => {

                let cliente = data;
                this.setState({
                    cliente: cliente
                })
                let direccion = cliente.direccion_id

                this.modalRef.current.setFieldsValue({
                    ...cliente,
                    ...direccion,
                    fecha_nacimiento: moment(cliente.fecha_nacimiento),
                    cuenta_id: cliente.cuenta_id ? cliente.cuenta_id?.map(cuenta => ({
                        value: cuenta._id,
                        label: cuenta.nombre + " " + (cuenta.cuenta || "")
                    })) : undefined,
                    entidad_federativa_id: direccion?.entidad_federativa_id ? ({
                        value: direccion.entidad_federativa_id?._id,
                        label: direccion.entidad_federativa_id?.nombre ?? ""
                    }) : undefined,
                    pais: cliente.pais ? ({
                        value: cliente.pais?._id,
                        label: cliente.pais?.nombre ?? ""
                    }) : undefined,
                    pais_nacimiento: cliente.pais_nacimiento ? ({
                        value: cliente.pais_nacimiento?._id,
                        label: cliente.pais_nacimiento?.nombre ?? ""
                    }) : undefined,
                    entidad: (cliente.apellido_paterno) ? "fisica" : "moral"
                })
            }).catch(error => {
                console.log(error)
                message.error(getResponseError(error.response, "Error al obtener el cliente"))
            }).finally(() => {
                this.setState({ loading: false })
            })

    }

    onFinish = (values) => {

        this.setState({ loading: true })
        axios.put('/customer/usuario', values)
            .then(response => {
                message.success("Actualizado con éxito")
                this.props.onClose()
                if (response.data.type === "TEMP_SECRET")
                    this.props.openQR(response.data.qrcode)
            }).catch(error => {
                message.error(getResponseError(error.response, "Error al activar Autentización"))
            }).finally(() => this.setState({ loading: false }))
    }


    render() {
        const { entidad } = this.state
        return (
            <Form
                layout="vertical"
                onFinish={this.onFinish}
                ref={this.modalRef}
                onValuesChange={(value) => {
                    if (value.auth_2FA != undefined) {
                        this.setState({ auth_2FA_new: value.auth_2FA })
                    }
                }}

            >
                <Spin spinning={this.state.loading}>

                    <Row justify="center">
                        <Col xs={22} lg={20} >
                            <Row gutter={[16, 16]} align="middle">
                                <Col xs={24} >
                                    <Form.Item
                                        name="entidad"
                                    >
                                        <Select
                                            disabled
                                            placeholder="Entidad"
                                            size="large"
                                            onSelect={(entidad) => this.setState({ entidad })}
                                            options={[
                                                { value: "fisica", label: 'Física' },
                                                { value: "moral", label: 'Moral' },
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="nombre"

                                    >
                                        <Input
                                            size="large"
                                            disabled
                                            placeholder="Nombre Completo"
                                        />
                                    </Form.Item>
                                </Col>
                                {
                                    entidad === "fisica" && (
                                        <>
                                            <Col xs={24} md={12}>
                                                <Form.Item
                                                    name="apellido_paterno"

                                                >
                                                    <Input
                                                        size="large"
                                                        disabled
                                                        placeholder="Apellido Paterno"
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={12}>
                                                <Form.Item
                                                    name="apellido_materno"

                                                >
                                                    <Input
                                                        size="large"
                                                        disabled
                                                        placeholder="Apellido Materno"
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </>
                                    )
                                }
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="email"

                                    >
                                        <Input
                                            size="large"
                                            disabled
                                            placeholder="Email"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="telefono"

                                    >
                                        <Input
                                            size="large"
                                            disabled
                                            placeholder="Teléfono"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="rfc"

                                    >
                                        <Input
                                            size="large"
                                            disabled
                                            placeholder="RFC"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="curp"

                                    >
                                        <Input
                                            size="large"
                                            disabled
                                            placeholder="CURP"
                                        />
                                    </Form.Item>
                                </Col>
                                {
                                    entidad === "fisica" && (
                                        <>
                                            <Col xs={24} md={12}>
                                                <Form.Item
                                                    name="genero"

                                                >
                                                    <Select
                                                        disabled
                                                        placeholder="Género"
                                                        size="large"
                                                        options={[
                                                            { value: "masculino", label: 'Masculino' },
                                                            { value: "femenino", label: 'Femenino' },
                                                        ]}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={12}>
                                                <Form.Item
                                                    name="fecha_nacimiento"
                                                >
                                                    <DatePicker
                                                        disabled
                                                        placeholder="Selecciona Fecha Nacimiento"
                                                        className="w-100"
                                                        size="large"
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </>
                                    )
                                }
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="pais_nacimiento"

                                    >
                                        <SelectPais
                                            size="large"
                                            disabled
                                            placeholder="País de nacimiento"
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="pais"

                                    >
                                        <SelectPais
                                            size="large"
                                            disabled
                                            placeholder="País donde reside"
                                            onClaveSelect={pais_clave => this.setState({ pais_clave })}
                                        />
                                    </Form.Item>
                                </Col>
                                {
                                    entidad === "fisica" && (
                                        <>
                                            <Col xs={24} md={12}>
                                                <Form.Item
                                                    name="actividad_economica"
                                                >
                                                    <Input
                                                        size="large"
                                                        disabled
                                                        placeholder="Actividad Ecónomica"
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={12}>
                                                <Form.Item
                                                    name="id_identificacion"
                                                >
                                                    <Input
                                                        size="large"
                                                        disabled
                                                        placeholder="Id de la Identificación"
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </>
                                    )
                                }
                                <Col xs={24}>
                                    <Form.Item
                                        name="cuenta_id"

                                    >
                                        <SelectCuenta
                                            size="large"
                                            mode="multiple"
                                            disabled
                                            placeholder="Seleccionar Cuentas"
                                            params={{
                                                sin_cliente: true
                                            }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xs={24}>
                                    <Divider> Dirección </Divider>
                                </Col>

                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="calle"
                                    >
                                        <Input
                                            size="large"
                                            disabled
                                            placeholder="Calle"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="num_exterior"
                                    >
                                        <Input
                                            size="large"
                                            disabled
                                            placeholder="Número Exterior"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="colonia"
                                    >
                                        <Input
                                            size="large"
                                            disabled
                                            placeholder="Colonia"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="codigo_postal"
                                    >
                                        <Input
                                            size="large"
                                            disabled
                                            placeholder="Codigo Postal"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="alcaldia_municipio"
                                    >
                                        <Input
                                            size="large"
                                            disabled
                                            placeholder="Alcaldia / Municipio"
                                        />
                                    </Form.Item>
                                </Col>

                                {
                                    (this.state.pais_clave && this.state.pais_clave !== this.state.mexico_clave) ? (
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                name="entidad_federativa"

                                            >
                                                <Input
                                                    size="large"
                                                    disabled
                                                    placeholder="Entidad Federativa"
                                                />
                                            </Form.Item>
                                        </Col>
                                    ) : (
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                name="entidad_federativa_id"

                                            >
                                                <SelectEntidadFederativa
                                                    size="large"
                                                    disabled
                                                />
                                            </Form.Item>
                                        </Col>
                                    )
                                }
                                <Col xs={12}>
                                    <Form.Item
                                        label="Verificación de 2 pasos"
                                        name="auth_2FA"
                                        valuePropName="checked"
                                    >
                                        <Switch />
                                    </Form.Item>
                                </Col>
                                {this.state.auth_2FA === true && this.state.secret === true && this.state.auth_2FA_new === false ? <Col xs={12}>
                                    <Form.Item
                                        name="token"
                                        rules={[{
                                            required: true,
                                            message: "Por favor, ingrese el token"
                                        }]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder="Ingrese código de verificación"
                                        />
                                    </Form.Item>
                                </Col> : null}

                                <Col xs={24}>
                                    <Form.Item >
                                        <Button htmlType="submit" type="primary" block size="large" className="mt-2">
                                            Aceptar
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function Modals(props) {
    const { visible = false, onClose = () => { }, cliente_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        destroyOnClose={true}
        width={750}
        zIndex={1000}
        footer={false}
        closable={false}
    >
        <Row>
            <Col span={24} className="center">
                <img src={"/img/BXNKLogo.png"} width="164" />
            </Col>
        </Row>
        <Title level={3} className="text-center mb-2 mt-1">Información de la Cuenta</Title>
        <ModalCliente {...props} />
    </Modal>

}