import React from 'react'
import { Layout, Col, Row, Typography, List, Card, Form, Select, Statistic, Space, message, Button } from "antd";
import axios from 'axios';
import moment from 'moment';
import { EyeOutlined, CheckOutlined } from '@ant-design/icons';

//Componentes
import { User } from '../../../../Hooks/Logged';
import { getResponseError } from '../../../Utils';
import CustomAvatar from '../../../Widgets/Avatar';
import { IconCuenta } from "../../../Widgets/Icons";
import usePermissions from '../../../../Hooks/usePermissions';
import FloatingButton from '../../../Widgets/FloatingButton/FloatingButton';

//Modales
import ModalDetalle from '../../../Admin/Transacciones//ModalDetalle';
import ModalTransferencia from '../../../Admin/Transacciones/ModalTransferencia';

const { Content } = Layout;
const { Text } = Typography
const { Option } = Select;
class Transacciones extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			transacciones: {
				page: 1,
				limit: 20,
				total: 0,
				pages: 0,
				data: [],
			},
			cuenta_id: undefined
		}
	}

	refCuentas = React.createRef();

	componentDidMount() {
		this.get()
	}

	get = ({
		page = this.state.transacciones.page,
		limit = this.state.transacciones.limit,
		cuenta = this.state.cuenta
	} = this.state.transacciones) => {
		this.setState({ loading: true })
		axios.get('/cobranza/transacciones', {
			params: {
				page,
				limit,
			}
		}).then(({ data }) => {
			this.setState({
				transacciones: data,
			})
		}).catch(error => {
			console.log('error', error)
			message.error(error.response?.data?.message ?? "Error al obtener las transacciones")
		}).finally(() => {
			this.setState({ loading: false })
		})
	}


	render() {
		return (
			<>
				<Content className="pl-1" style={{marginBottom: "6rem"}}>
					<Row>
						<Col span={24} className="" style={{ paddingRight: '0.5rem' }}>
							<Row className='main'>
								<Col xs={24}>
									<List
										loading={this.state.loading}
										className="component-list"
										itemLayout="horizontal"
										locale={{ emptyText: "Sin Transacciones" }}
										dataSource={this.state.transacciones?.data}
										pagination={{
											current: this.state.transacciones.page,
											pageSize: this.state.transacciones.limit,
											total: this.state.transacciones.total,
											position: 'bottom',
											className: "flex-left",
											showSizeChanger: true,
											pageSizeOptions: [20, 50, 100, 500],
											showQuickJumper: true,
											locale: { jump_to: "Ir a la página", page: '' },
											onChange: (page, limit) => {
												this.get({ page, limit })
											},
											onShowSizeChange: (page, limit) => {
												this.get({ page, limit })
											}
										}}
										header={<Row className="header-list width-100 ">
											<Col xs={0} sm={0} md={4} className=" center">
												<Text>Concepto</Text>
											</Col>
											<Col xs={0} sm={0} md={3} className="center">
												<Text>Fecha</Text>
											</Col>
											<Col xs={0} sm={0} md={5} className="center">
												<Text>Cuenta Ordenante</Text>
											</Col>
											<Col xs={0} sm={0} md={4} className="center">
												<Text>Cuenta Beneficiario</Text>
											</Col>
											<Col xs={0} sm={0} md={3} className="center">
												<Text ellipsis>Estado</Text>
											</Col>
											<Col xs={0} sm={0} md={3} className="center">
												<Text>Monto</Text>
											</Col>
										</Row>
										}

										renderItem={item => (
											<List.Item className="component-list-item">
												<Card className="card-list width-100" bordered={false}>
													<Row className="width-100" align={"middle"}>
														<Col xs={24} sm={12} md={4} className="">
															<Text ellipsis strong>{item.concepto} </Text>
														</Col>
														<Col xs={24} sm={12} md={3} className="center">
															<Text ellipsis className="text-gray">{moment(item.fecha_operacion).format("DD/MM/YYYY HH:mm")}</Text>
														</Col>
														<Col xs={24} sm={12} md={5} className="center">
															<Text ellipsis className="text-gray">{item.ordenante?.nombre}</Text>
														</Col>
														<Col xs={24} sm={12} md={4} className="center">
															<Text ellipsis className="text-gray">{item.beneficiarios[0]?.nombre ?? "ND"}</Text>
														</Col>
														<Col xs={24} sm={12} md={3} className="center">
															<Text ellipsis className="text-gray">{item.estado ?? "No Enviado a STP"}</Text>
														</Col>
														<Col xs={24} sm={12} md={3} className="center">
															<Statistic
																value={item.monto?.toMoney(true)}
																precision={2}
																prefix={item.tipo === 2 ? "-$" : "$"}
																valueStyle={{ 
																	fontWeight: 'bold', 
																	fontSize: 16 ,
																	color: item.tipo === 1 ? "#4caa21" : "#bb2a2a"
																}}
															/>
														</Col>
														<Col xs={24} sm={12} md={2} className="flex-right">
															<Button
																type="primary"
																icon={<EyeOutlined />}
																onClick={() => {
																	this.setState({ modal_detalle: true, transaccion_id: item._id })	
																}}
															/>
														</Col>


													</Row>
												</Card>
											</List.Item>
										)}
									/>
								</Col>
							</Row>
						</Col>

					</Row>
				</Content>

				{this.props.user?.rol_id.super_admin ? <FloatingButton
					title="Crear Transacción"
					onClick={()=>{
						this.setState({
							modalVisible: true
						})
					}}
				/>
					: null
				}

				<ModalTransferencia
					visible={this.state.modalVisible}
					onClose={() => {
						this.get()
						this.setState({ modalVisible: false, transaccion_id: undefined })
					}}
					cliente_id={this.props.cliente_id}
					transaccion_id={this.state.transaccion_id}
					cuenta_id={this.state.cuenta_id}
					transferir={true}
				/>

				<ModalDetalle
					visible={this.state.modal_detalle}
					onClose={() => {
						this.setState({ modal_detalle: false, transaccion_id: null })
					}}
					transaccion_id={this.state.transaccion_id}
					sin_hijos={true}
				/>

			</>
		)
	}
}

export default function (props) {
	let user = React.useContext(User)

	let permissions = usePermissions(user?.rol_id?.permisos, {})
	return (<Transacciones {...props} {...permissions} user={user}/>)
}