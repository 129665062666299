import React, { Component } from "react";
import { Row, Col, Modal, Typography, Form, Input, message, Spin, Button, DatePicker, Select, Tag, Radio, Checkbox, Tooltip } from 'antd';
import moment from "moment";


import { CloseOutlined } from '@ant-design/icons';

import { EmailInput } from "../../Widgets/Inputs/Inputs"
import { SelectCuenta, SelectEntidadFederativa, SelectPais, SelectActividadEconomica, SelectRol } from "../../Widgets/Inputs/Selects";
import { AvatarLoader } from "../../Widgets/Uploaders";
import { getResponseError } from '../../Utils'
import axios from "axios"

const { Title, Text } = Typography;
const { Option } = Select;

class ModalUsuario extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            tipo: 1,


            comision_cuenta: null
        }
    }

    modalUsuario = React.createRef();

    componentDidMount() {
        if (this.props.usuario_id) {
            this.getUsuario()
        }
    }

    /**
     * @memberof ModalUsuario
     * @method getUsuario
     * @description Obtiene informacion del usuario
     */
    getUsuario = () => {

        this.setState({ loading: true })
        axios.get('/vendedor/' + this.props.usuario_id)
            .then(response => {

                let usuario = response.data
                console.log("usuario", usuario);

                this.modalUsuario.current.setFieldsValue({
                    ...usuario.cliente_id,
                    fecha_nacimiento: moment(usuario.cliente_id.fecha_nacimiento),
                    rol_id: usuario.rol_id?._id ? {
                        label: usuario.rol_id?.nombre,
                        value: usuario.rol_id?._id,
                    } : null,
                    avatar: usuario.avatar ? {
                        uid: usuario.avatar.file,
                        name: usuario.avatar.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/usuarios/" + this.props.usuario_id + "?avatar=" + usuario.avatar.file + "&Authorization=" + sessionStorage.getItem("token")
                    } : null,
                    cliente_id: usuario.cliente_id ? {
                        label: `${usuario.cliente_id?.nombre} ${usuario.cliente_id?.apellido_paterno ?? ""} ${usuario.cliente_id?.apellido_materno ?? ""}`,
                        value: usuario.cliente_id?._id,
                    } : null,
                    cuenta_id: usuario?.cliente_id?.cuenta_id?.map(e => ({ value: e._id, label: <Text> {e.nombre} / <small>{e.cuenta}</small> </Text> }))
                })

                this.setState({ comision_cuenta:  response.data?.recibe_comision })

            }).catch(error => {
                message.error(getResponseError(error.response, "Error al obtener el usuario"))
            }).finally(() => {
                this.setState({ loading: false })
            })

    }

    /**
    * @memberof ModalUsuario
    * @method onFinish
    * @description Se ejecuta al dar enter al formulario
    */
    onFinish = (values) => {

        if (values.cuenta_id?.length > 0 && !this.state.comision_cuenta)
            return message.error("Debe de indicar la cuenta comisión por defecto")

        values.comision_cuenta = this.state.comision_cuenta

        const formData = new FormData()
        if (values.tipo_cliente === 0) values.tipo_cliente = "0"

        formData.appendMultiple({
            ...values,
            avatar: undefined,
            usuario_id: this.props.usuario_id,
            cuenta_id: null,
        })

        if (values.avatar && !values.avatar.fromDB) {
            formData.append("avatar", values.avatar, values.avatar.name)
        }

        if (Array.isArray(values.cuenta_id) && values.cuenta_id?.length > 0) {
            for (let cuenta of values.cuenta_id) {
                formData.append("cuenta_id", cuenta.value)
            }
        } else {
            formData.append("cuenta_id", "none")
        }

        if (this.props.usuario_id) {
            this.updateVendedor(formData)
        } else {
            this.addVendedor(formData)
        }

    }

    /**
     * @memberof ModalUsuario
     * @method addVendedor
     * @description Crea un nuevo usuario en el sistema
     */
    addVendedor = (values) => {
        this.setState({ loading: true })
        axios.post('/vendedor', values, {
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(response => {
                message.success('Usuario Añadido')
                this.props.onClose(true)
            }).catch(error => {
                console.log(error)
                message.error(getResponseError(error.response, "Error al añadir el usuario"))
            }).finally(() => this.setState({ loading: false }))

    }

    /**
     * @memberof ModalUsuario
     * @method updateVendedor
     * @description Crea un nuevo usuario en el sistema
     */
    updateVendedor = (values) => {
        this.setState({ loading: true })

        axios.put('/vendedor', values)
            .then(response => {
                message.success('Usuario Editado')
                this.props.onClose(true)
            }).catch(error => {
                message.error(getResponseError(error.response, "Error al editar el usuario"))
            }).finally(() => this.setState({ loading: false }))

    }



    /**
     *
     *
     * @memberof ModalCliente
     * @description Verifica que el cliente sea mayor de edad 
     */
    validateEdad = (_, fecha) => {
        const minAge = moment().subtract(18, 'years');
        if (fecha && fecha.isAfter(minAge)) {
            return Promise.reject('Debe tener mas de 18 años');
        }
        return Promise.resolve();
    }

    render() {
        return (
            <Form
                layout="vertical"
                ref={this.modalUsuario}
                onFinish={this.onFinish}
                onValuesChange={this.onValuesChange}
                initialValues={{
                    tipo_cliente: 2
                }}
            >
                <Spin spinning={this.state.loading}>

                    <Row align="center">
                        <Col xs={22} lg={20} >
                            <Form.Item
                                name="avatar"
                                className="center"
                            >
                                <AvatarLoader
                                    imageCrop={true}
                                    onRemove={() => {
                                        axios.put('/usuarios', {
                                            usuario_id: this.props.usuario_id,
                                            delete_avatar: true
                                        })
                                    }}

                                />
                            </Form.Item>
                            <Row gutter={[16, 0]}>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="nombre"
                                        requiredMark={true}
                                        label={this.props.cliente_id ? "Nombre" : <>Obligatorio</>}

                                        rules={[{
                                            required: true,
                                            message: "Por favor, Ingrese el nombre."
                                        }]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder="Nombre Completo"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="apellido_paterno"
                                        requiredMark={true}
                                        label={this.props.cliente_id ? "Apellido Paterno" : <>Obligatorio</>}

                                        rules={[{
                                            required: true,
                                            message: "Por favor, Ingrese el apellido paterno."
                                        }]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder="Apellido Paterno"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="apellido_materno"
                                        requiredMark={true}
                                        label={this.props.cliente_id ? "Apellido Materno" : <>Obligatorio</>}

                                        rules={[{
                                            required: true,
                                            message: "Por favor, Ingrese el apellido materno."
                                        }]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder="Apellido Materno"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="alias_local"
                                        label="Alias"
                                    >
                                        <Input
                                            size="large"
                                            placeholder="Alias"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="email"
                                        requiredMark={true}
                                        label={this.props.cliente_id ? "Email" : <>Obligatorio</>}

                                        rules={[{
                                            required: true,
                                            message: "Por favor, Ingrese el email."
                                        }]}
                                    >
                                        <EmailInput
                                            size="large"
                                            placeholder="Email"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        label={this.props.cliente_id ? "Fecha Nacimiento" : <>Obligatorio</>}
                                        name="fecha_nacimiento"

                                        rules={[
                                            {
                                                required: true,
                                                message: "Por favor, seleccione ingrese la fecha de nacimiento."
                                            },
                                            {
                                                validator: this.validateEdad
                                            }
                                        ]}

                                    >
                                        <DatePicker
                                            showToday={false}
                                            placeholder="Selecciona Fecha Nacimiento"
                                            className="w-100"
                                            size="large"
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="pais_nacimiento"
                                        requiredMark={true}
                                        label={this.props.cliente_id ? "País de Nacimiento" : <>Obligatorio</>}

                                        rules={[{
                                            required: true,
                                            message: "Por favor, selecciona el país."
                                        }]}
                                    >
                                        <SelectPais
                                            size="large"
                                            placeholder="País de nacimiento"
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="pais"
                                        requiredMark={true}
                                        label={this.props.cliente_id ? "País de Residencia" : <>Obligatorio</>}
                                        rules={[{
                                            required: true,
                                            message: "Por favor, selecciona el país."
                                        }]}
                                    >
                                        <SelectPais
                                            size="large"
                                            placeholder="País donde reside"
                                            onClaveSelect={pais_clave => this.setState({ pais_clave })}
                                        />
                                    </Form.Item>
                                </Col>


                                <Col xs={24}>
                                    <Form.Item
                                        name="cuenta_id"
                                        label={this.props.cliente_id ? "Cuentas" : <></>}

                                    >
                                        <SelectCuenta
                                            size="large"
                                            mode="multiple"
                                            placeholder="Seleccionar Cuentas del cliente"
                                            params={{
                                                sin_cliente: true,
                                                nin_usuario_id: this.props.usuario_id
                                            }}
                                            tagRender={({ label, value, onClose, ...args }) => {
                                                // console.log('args', args)
                                                return <Tag style={{ fontSize: 14 }}>
                                                    <Tooltip title='¿Esta cuenta es la que recibirá la comisión?'>
                                                        <Checkbox
                                                            checked={value == this.state.comision_cuenta}
                                                            onClick={e => this.setState({ comision_cuenta: value })}
                                                        />
                                                    </Tooltip> {label} <CloseOutlined  onClick={() => onClose()} />
                                                </Tag>
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                {/* <Col xs={24}>
                                    <Form.Item
                                        name="recibe_comision"
                                        label={this.props.cliente_id ? "Cuentas" : <></>}
                                    >
                                        <SelectCuenta
                                            size="large"
                                            // mode=""
                                            placeholder="Seleccionar Cuentas del cliente"
                                            params={{
                                                sin_cliente: true,
                                                nin_usuario_id: this.props.usuario_id
                                            }}
                                        />
                                    </Form.Item>
                                </Col> */}
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="tipo_cliente"
                                        label={this.props.cliente_id ? "Tipo de Cliente" : <>Obligatorio</>}
                                        requiredMark={true}
                                        rules={[{
                                            required: true,
                                            message: "Por favor, selecciona el país."
                                        }]}
                                    >
                                        <Select
                                            placeholder="Tipo de Cliente"
                                            size="large"
                                        >
                                            <Option value={0}>Personal</Option>
                                            <Option value={1}>Cliente</Option>
                                            <Option value={2}>Vendedor</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    {/*<Form.Item
                                        name="rol_id"
                                        requiredMark={true}
                                        label={this.props.usuario_id ? "Rol" : <>Obligatorio</>}
                                        rules={[{
                                            required: true,
                                            message: "Por favor, seleccione el rol"
                                        }]}
                                    >
                                        <SelectRol
                                            size="large"
                                            params={{ tipos: [4] }}
                                            placeholder={`Rol`}
                                        />
                                    </Form.Item>*/}
                                </Col>
                            </Row>

                            <Form.Item >
                                <Button htmlType="submit" type="primary" block size="large" className="mt-2">
                                    Aceptar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function Modals(props) {
    const { visible = false, onClose = () => { }, usuario_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        destroyOnClose={true}
        zIndex={1000}
        footer={false}
        closable={false}
        width={800}
    >
        <Row>
            <Col span={24} className="center">
                <img src={"/img/BXNKLogo.png"} width="164" />
            </Col>
        </Row>
        <Title level={1} className="text-center mb-2 mt-1">{usuario_id ? "Editar" : "Crear"} Perfil Vendedor</Title>
        <ModalUsuario {...props} />
    </Modal>

}