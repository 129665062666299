import React, { Component } from "react";
import { Row, Col, Modal, Typography, Form, Input, message, Spin, Button, DatePicker, Select, Space, Switch } from 'antd';
import moment from "moment";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { SelectCuenta, SelectEntidadFederativa, SelectPais, SelectActividadEconomica, SelectRol } from "../../Widgets/Inputs/Selects";
import { AvatarLoader } from "../../Widgets/Uploaders";
import { getResponseError } from '../../Utils'
import axios from "axios"

const { Title, Text } = Typography;
const { Option } = Select;

class ModalUsuario extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            tipo: 1
        }
    }

    modalUsuario = React.createRef();

    componentDidMount() {
        if (this.props.usuario_id) {
            this.getUsuario()
        }
    }

    /**
     * @memberof ModalUsuario
     * @method getUsuario
     * @description Obtiene informacion del usuario
     */
    getUsuario = () => {

        this.setState({ loading: true })
        axios.get('/vendedor/' + this.props.usuario_id)
            .then(response => {

                let usuario = response.data
                this.modalUsuario.current.setFieldsValue({
                    cuentas_id: usuario.cuentas_id.map((e) => {
                        return {
                            cuenta_id:{
                                value: e.cuenta_id._id,
                                label: e.cuenta_id.nombre
                            },
                            ver_cuenta: e.ver_cuenta
                        }
                    })
                })

                

            }).catch(error => {
                message.error(getResponseError(error.response, "Error al obtener el usuario"))
            }).finally(() => {
                this.setState({ loading: false })
            })

    }

    /**
    * @memberof ModalUsuario
    * @method onFinish
    * @description Se ejecuta al dar enter al formulario
    */
    onFinish = (values) => {
        if (this.props.usuario_id) {
            this.updateVendedor(values)
        } 
    }


    /**
     * @memberof ModalUsuario
     * @method updateVendedor
     * @description Crea un nuevo usuario en el sistema
     */
    updateVendedor = (values) => {
        this.setState({ loading: true })
        axios.post('/vendedor/cuentas', {
            usuario_id: this.props.usuario_id,
            ...values
        }).then(response => {
            message.success('Usuario Editado')
            this.props.onClose(true)
        }).catch(error => {
            message.error(getResponseError(error.response, "Error al editar el usuario"))
        }).finally(() => this.setState({ loading: false }))

    }


    render() {
        return (
            <Form
                layout="vertical"
                ref={this.modalUsuario}
                onFinish={this.onFinish}
                onValuesChange={this.onValuesChange}
            >
                <Spin spinning={this.state.loading}>

                    <Row align="center">
                        <Col xs={22} lg={20} >
                            <Row gutter={[28,16]}>
                                <Col span={18} className="center">
                                    <Text>Cuentas de los clientes</Text>
                                </Col>
                                <Col span={5} className="center">
                                    <Text>Ver Cuenta</Text>
                                </Col>
                                <Form.List name="cuentas_id">
                                    {(fields, { add, remove }) => (
                                        <>
                                        {fields.map(({ key, name, ...restField }) => (
                                                <><Col span={18}>
                                                    <Form.Item
                                                        className="m-0"
                                                        {...restField}
                                                        name={[name, 'cuenta_id']}
                                                        rules={[{ required: true, message: 'Seleccione la cuenta' }]}
                                                    >
                                                        <SelectCuenta/>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={3}>
                                                    <Form.Item
                                                        className="m-0"
                                                        {...restField}
                                                        name={[name, 'ver_cuenta']}
                                                        valuePropName="checked"
                                                    >
                                                        <Switch/>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={3} className="center">
                                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                                </Col>
                                                </>
                                                
                                        ))}
                                        <Col span={24}>
                                            <Form.Item>
                                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                   Añadir Cuenta
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </>
                                  )}
                                </Form.List>
                            </Row>
                             <Form.Item >
                                <Button htmlType="submit" type="primary" block size="large" className="mt-2">
                                    Aceptar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function Modals(props) {
    const { visible = false, onClose = () => { }, usuario_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        destroyOnClose={true}
        zIndex={1000}
        footer={false}
        closable={false}
        width={800}
    >
        <Row>
            <Col span={24} className="center">
                <img src={"/img/BXNKLogo.png"} width="164" />
            </Col>
        </Row>
        <Title level={3} className="text-center mb-2 mt-1">{usuario_id ? "Editar" : "Añadir"} Cuentas</Title>
        <ModalUsuario {...props} />
    </Modal>

}