import React from 'react'
import { Layout, Col, Row, Typography, List, Card, Space, message, Button, Popconfirm, Modal, Tooltip } from "antd";
import axios from 'axios';
import { useSearchParams, useNavigate } from 'react-router-dom'
import { EditOutlined, DeleteOutlined, MailOutlined } from '@ant-design/icons';

import { User } from '../../../Hooks/Logged'
import useSearch from '../../../Hooks/Search';
import usePermissions from '../../../Hooks/usePermissions';
import { getResponseError } from '../../Utils';
import CustomAvatar from '../../Widgets/Avatar';
import FloatingButton from '../../Widgets/FloatingButton/FloatingButton';

import ModalUsuarios from './ModalUsuarios'

const { Content } = Layout;
const { Title, Text } = Typography

class Usuarios extends React.Component {

	constructor(props) {
		super(props)
		let { page, limit, filters, search, sort } = this.props.params
		this.state = {
			loading: false,
			usuarios: {
				page: page ?? 1,
				limit: limit ?? 20,
				total: 0,
				pages: 0,
				search: search ?? undefined,
				data: [],
			},
			searching: true,
			filtroSearch: '',
		}
	}

	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.getUsuarios()
	}

	/**
     * @method componentDidUpdate
     * @description Actualiza la vista si se buscan datos
     */
    componentDidUpdate() {

        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;
        if (filtro !== propFiltro && this.state.searching === true) {
            this.getUsuarios();
            this.state.searching = false;
        }
    }

	/**
	 * @memberOf Usuarios
	 * @method getUsuarios
	 * @descripcion Ontiene los usuarios registrados en el sistema
	 * */
	getUsuarios = ({
		page = this.state.usuarios.page,
		limit = this.state.usuarios.limit,
		search = this.props.search
	} = this.state.usuarios) => {

		this.setState({ loading: true })
		axios.get('/usuarios', {
			params: {
				page,
				limit,
				search: search
			}
		}).then(({ data }) => {
			//console.clear()
			console.log("data", data);
			this.setState({
				usuarios: {
					...data,
				},
				loading: false,
				searching: true,
				filtroSearch: search
			});
		}).catch(error => {
			console.log("error", error);
			message.error(getResponseError(error.response, "Error al obtener los usuarios"))
		}).finally(() => {
			this.setState({ loading: false })
		})
	}

	/**
	 * @methodOf  Usuarios
	 * @method sendEmail
	 *
	 * @description Envia un correo para recuperar la contraseña
	 *
	 * */
	sendEmail = (email) => {
		this.setState({ loading: true })
		axios.put("/password/recovery", { email: email })
			.then(res => {
				Modal.success({
					title: 'Restablecer contraseña.',
					content: '¡El correo ha sido enviado!'
				})
			})
			.catch(res => {
				Modal.warning({
					title: 'Error',
					content: 'El correo no se ha podido enviar. Verifica que el correo sea correcto'
				});
			})
			.finally(() => {
				this.setState({
					loading: false
				})
			})

	}


	render() {
		return (
			<>
				<Content className="p-1">
					<Row className="mb-3">
						<Col span={24} className="">
							<Row className='main'>
								<Col xs={24}>
									<Title level={5}> Usuarios </Title>
								</Col>
								<Col xs={24}>
									<List
										loading={this.state.loading}
										className="component-list"
										itemLayout="horizontal"
										locale={{ emptyText: "Sin Usuarios" }}
										dataSource={this.state.usuarios?.data}
										pagination={{
											current: this.state.usuarios.page,
											pageSize: this.state.usuarios.limit,
											total: this.state.usuarios.total,
											position: 'bottom',
											className: "flex-left",
											showSizeChanger: true,
											pageSizeOptions: [20, 50, 100, 500],
											showQuickJumper: true,
											locale: { jump_to: "Ir a la página", page: '' },
											onChange: (page, limit) => {
												this.getUsuarios({ page, limit })
											},
											onShowSizeChange: (page, limit) => {
												this.getUsuarios({ page, limit })
											}
										}}
										header={<Row className="header-list width-100 ">
											<Col xs={2} className="center">
												<Text></Text>
											</Col>
											<Col xs={6} className="center">
												<Text>Nombre</Text>
											</Col>
											<Col xs={6} className="center">
												<Text>Email</Text>
											</Col>
											<Col xs={3} className="center">
												<Text>Tipo</Text>
											</Col>
											<Col xs={4} className="center">
												<Text>Rol</Text>
											</Col>
											<Col xs={3} className="center">
												<Text>Acciones</Text>
											</Col>
										</Row>
										}

										renderItem={item => (
											<List.Item className="component-list-item" key={item._id}>
												<Card className="card-list width-100" bordered={false}>
													<Row className="width-100" align={"middle"}>
														<Col xs={2} className="center">
															<CustomAvatar
																name={item.nombre_completo}
																url={item?.avatar?.name ? {
																	url: axios.defaults.baseURL + "/usuarios/" + item._id,
																	avatar: item.avatar.file,
																} : null}
															/>
														</Col>
														<Col xs={6} className="center">
															<Text>{item.nombre_completo}</Text>
														</Col>
														<Col xs={6} className="center">
															<Text>{item.email}</Text>
														</Col>
														<Col xs={3} className="center">
															<Text>{item.parent_user ? "Usuario Hijo" : "Usuario Padre / Admin"}</Text>
														</Col>
														<Col xs={4} className="center">
															<Text>{item.rol}</Text>
														</Col>
														<Col xs={3} className="center">
															<Space>
																<Popconfirm
																	placement="topRight"
																	disabled={!this.props.recoveryUsuarios}
																	title="¿Deseas enviar un correo de recuperación de contraseña?" okText="Sí" cancelText="No"
																	onConfirm={() => this.sendEmail(item.email)}
																>
																	<Button
																		type="primary"
																		icon={<MailOutlined />}
																		disabled={!this.props.recoveryUsuarios}
																	/>
																</Popconfirm>
																<Button
																	type="primary"
																	icon={<EditOutlined />}
																	onClick={() => this.setState({ modal_visible: true, usuario_id: item._id })}
																	disabled={!this.props.editUsuarios || !item.parent_user}
																/>

																<Popconfirm
																	placement="topRight"

																	title="¿Deseas eliminar este usuario?"
																	onConfirm={() => axios.delete('/usuarios/', {
																		params: {
																			usuario_id: item._id
																		}
																	}).then((response) => {
																		message.success(response?.data?.message)
																		this.getUsuarios()
																	})
																		.catch(error => {
																			message.error(getResponseError(error.response, "No se pudo eliminar"))
																			this.getUsuarios();
																		})
																	}
																	okText="Sí"
																	cancelText="No"
																	disabled={!this.props.deleteUsuarios || !item.parent_user}
																>
																	{
																		item.parent_user ? (
																			<Button
																				type="danger"
																				icon={<DeleteOutlined />}
																				disabled={!this.props.deleteUsuarios || !item.parent_user}
																			/>
																		) : (
																			<Tooltip title="No puedes borrar al usuario principal" placement='topLeft'>
																				<Button
																					type="danger"
																					icon={<DeleteOutlined />}
																					disabled={!this.props.deleteUsuarios || !item.parent_user}
																				/>
																			</Tooltip>
																		)
																	}
																</Popconfirm>

															</Space>
														</Col>
													</Row>
												</Card>
											</List.Item>
										)}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
				</Content>
				<FloatingButton onClick={() => this.setState({ modal_visible: true })} disabled={!this.props.createUsuarios} />
				<ModalUsuarios
					visible={this.state.modal_visible}
					onClose={(flag) => {
						this.setState({ modal_visible: false, usuario_id: undefined })
						if (flag === true)
							this.getUsuarios()
					}}
					usuario_id={this.state.usuario_id}
				/>
			</>
		)
	}
}

export default function (props) {

	let user = React.useContext(User)

	let permissions = usePermissions(user?.rol_id?.permisos, {
		editUsuarios: ["usuarios", "edit"],
		createUsuarios: ["usuarios", "create"],
		deleteUsuarios: ["usuarios", "delete"],
		recoveryUsuarios: ["usuarios", "recovery_password"]

	})
	const [search, setSearch] = useSearch()
	const [params, setParams] = useSearchParams();
	return (<Usuarios
		{...props}
		navigate={useNavigate()}
		setParams={setParams}
		search={search}
		setSearch={setSearch}
		params={params}
		{...permissions} />)
}