import React from 'react'
import { Layout, Col, Row, Typography, List, Button, Popconfirm, Card, Space, message } from "antd";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';

import ModalRoles from '../../Roles/ModalRoles';
import FloatingButton from '../../../Widgets/FloatingButton/FloatingButton';

import { User } from '../../../../Hooks/Logged';
import usePermissions from '../../../../Hooks/usePermissions';
import { getResponseError } from '../../../Utils';
const { Content } = Layout;
const { Text } = Typography

class Roles extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            roles: {
                page: 1,
                limit: 10,
                total: 0,
                pages: 0,
                // filters: filters ?? [],
                // sort: sort ?? {},
                data: [],
            }
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getRoles()
    }

    /**
     * @method getRoles
     * @desription Obtiene los roles que pertencen al cliente
     * */
    getRoles = ({
        page = this.state.roles.page,
        limit = this.state.roles.limit
    } = this.state.roles) => {
        this.setState({ loading: true })
        axios.get('/roles', {
            params: {
                page,
                limit,
                cliente_id: this.props.cliente_id
            }
        }).then(({ data }) => {
            this.setState({
                roles: data
            })
        }).catch(error => {
            message.error(getResponseError(error.response, "Error al obtener los roles"))
        }).finally(() => {
            this.setState({ loading: false })
        })
    }





    render() {
        return (
            <>
                <Content className="p-1">
                    <Row>
                        <Col span={24} className="" style={{ paddingRight: '0.5rem' }}>
                            <Row className='main'>
                                <Col xs={24}>
                                    <List
                                        loading={this.state.loading}
                                        className="component-list"
                                        itemLayout="horizontal"
                                        locale={{ emptyText: "Sin Roles" }}
                                        dataSource={this.state.roles?.data}
                                        pagination={{
                                            current: this.state.roles.page,
                                            pageSize: this.state.roles.limit,
                                            total: this.state.roles.total,
                                            position: 'bottom',
                                            className: "flex-left",
                                            showSizeChanger: true,
                                            pageSizeOptions: [20, 50, 100, 500],
                                            showQuickJumper: true,
                                            locale: { jump_to: "Ir a la página", page: '' },
                                            onChange: (page, limit) => {
                                                this.get({ page, limit })
                                            },
                                            onShowSizeChange: (page, limit) => {
                                                this.get({ page, limit })
                                            }
                                        }}
                                        header={<Row className="header-list width-100 pr-1 pl-1" gutter={[16, 16]}>
                                            <Col xs={24} lg={10} className="center">
                                                <Text strong>Nombre</Text>
                                            </Col>
                                            <Col xs={24} lg={10} className="center">
                                                <Text strong>Descripción</Text>
                                            </Col>
                                        </Row>}

                                        renderItem={item => (
                                            <List.Item className="component-list-item" key={item._id}>
                                                <Card className="card-list width-100" bordered={false}>
                                                    <Row className="width-100" align={"middle"}>
                                                        <Col xs={24} lg={10} className="center">
                                                            <Text>{item.nombre}</Text>
                                                        </Col>
                                                        <Col xs={24} lg={10} className="center">
                                                            <Text>{item.descripcion}</Text>
                                                        </Col>
                                                        <Col xs={4} className="center">
                                                            <Space size="small" direction="horizontal">
                                                                <Button
                                                                    type="primary"
                                                                    disabled={!item.editable || !this.props.editRoles}
                                                                    icon={<EditOutlined />}
                                                                    title="Editar"
                                                                    onClick={() => this.setState({ modal_visible: true, rol_id: item._id })}
                                                                />
                                                                <Popconfirm
                                                                    placement="topRight"
                                                                    disabled={!item.editable || !this.props.deleteRoles}
                                                                    title="¿Deseas eliminar este rol?"
                                                                    onConfirm={() => axios.delete('/roles',
                                                                        { params: { rol_id: item._id } }).then(() => {
                                                                            this.getRoles()
                                                                            message.success('Rol eliminado')
                                                                        }).catch(error => {
                                                                            message.error(getResponseError(error.response, 'Error al eliminar el rol'))
                                                                        })}
                                                                    okText="Sí"
                                                                    cancelText="No"
                                                                >
                                                                    <Button
                                                                        disabled={!item.editable || !this.props.deleteRoles}
                                                                        type="primary"
                                                                        icon={<DeleteOutlined />}
                                                                        title="Eliminar"
                                                                        danger
                                                                    />
                                                                </Popconfirm>
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </List.Item>
                                        )}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <FloatingButton onClick={() => this.setState({ modal_visible: true })} disabled={!this.props.createRoles} />
                    <ModalRoles
                        visible={this.state.modal_visible}
                        onClose={() => {
                            this.setState({ modal_visible: false, rol_id: undefined })
                            this.getRoles()
                        }}
                        rol_id={this.state.rol_id}
                        cliente_id={this.props.cliente_id}
                        tipo={3}
                    />
                </Content >

            </>
        )
    }
}

export default function (props) {
    let user = React.useContext(User)

    let permissions = usePermissions(user?.rol_id?.permisos, {
        editRoles: ["clientes", "roles", "edit"],
        createRoles: ["clientes", "roles", "create"],
        deleteRoles: ["clientes", "roles", "delete"]
    })

    return (<Roles {...props} {...permissions} />)
}