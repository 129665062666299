import React from 'react'
import { Layout, Col, Row, Radio, Collapse, Typography, Modal, List, Card, Statistic, Space, message, Button, Tooltip } from "antd";
import axios from 'axios';
import moment from 'moment';
import { useSearchParams, useNavigate } from 'react-router-dom'
import { EyeOutlined, FilePdfOutlined, CheckOutlined, CheckCircleOutlined, WarningOutlined, CloseCircleOutlined, FileDoneOutlined } from '@ant-design/icons';

//component
import { User } from '../../../Hooks/Logged';
import useSearch from '../../../Hooks/Search';
import { getResponseError } from '../../Utils';
import CustomAvatar from '../../Widgets/Avatar';
import usePermissions from '../../../Hooks/usePermissions';
import FiltrosDrawer from '../../Widgets/DrawerFiltros/DrawerFiltros';
import FloatingButton from '../../Widgets/FloatingButton/FloatingButton';

//Modal
import ModalDetalle from './ModalDetalle';
import ModalComprobante from './ModalComprobante';
import ModalTransferencia from './ModalTransferencia';
import Cuenta from '../../../Hooks/Cuentas';


const { Content } = Layout;
const { Title, Text } = Typography
const { Panel } = Collapse

class Transacciones extends React.Component {

	constructor(props) {
		super(props)
		let { page, limit, filters, search, sort } = this.props.params
		this.state = {
			loading: false,
			modal_visible: false,
			modal_detalle: false,
			transferir: false,
			capturar: false,
			liberar: false,
			searching: true,
			transaccion_id: null,
			filtroSearch: '',
			tipo_dato: 'local',
			transacciones: {
				page: page ?? 1,
				limit: limit ?? 20,
				total: 0,
				pages: 0,
				search: search ?? undefined,
				filters: filters ?? [],
				data: [],
			},
		}
	}

	drawerFiltros = React.createRef()

	componentDidMount() {
		this.getTransacciones()
	}

	/**
	* @method componentDidUpdate
	* @description Actualiza la vista si se buscan datos
	*/
	componentDidUpdate(prevProps, prevState) {

		const filtro = this.state.filtroSearch;
		const propFiltro = this.props.search;
		if ((filtro !== propFiltro && this.state.searching == true) || (this.props.cuenta !== prevProps.cuenta)) {
			this.getTransacciones();
			this.state.searching = false;
		}
	}

	/**
	* @method getTransacciones
	* @description Actualiza la vista si se buscan datos
	*/
	getTransacciones = ({
		page = this.state.transacciones.page,
		limit = this.state.transacciones.limit,
		filters = this.state.transacciones.filters,
		search = this.props.search,
		tipo_dato = this.state.tipo_dato,

	} = this.state.transacciones) => {
		this.setState({ loading: true })
		axios.get('/transacciones', {
			params: {
				page,
				limit,
				search,
				filters,
				tipo_dato,
				cuenta_id: this.props.cuenta?._id
			}
		}).then(({ data }) => {

			this.setState({
				transacciones: {
					...data,
				},
				loading: false,
				searching: true,
				filters: filters,
				filtroSearch: search,
				tipo_dato
			});

			this.getTipoActualSTP(filters)

		}).catch(error => {
			console.log("ERR, error", error.message)
			let messages = error.message != null ? error.message : "Ocurrio un error al cargar las transacciones"
			message.error(messages)
		}).finally(() => {
			this.setState({ loading: false })
		})
	}



	/**
	* @method viewCEP
	* @description Actualiza la vista si se buscan datos
	*/
	viewCEP = (url_cep) => {
		let url = new URL(url_cep)
		window.open(url, '_blank')
	}

	getPDF = (transaccion_id) => {
		let urlPDF = new URL(axios.defaults.baseURL + '/transaccion/comprobante');
		urlPDF.searchParams.append('Authorization', sessionStorage.getItem('token'))
		urlPDF.searchParams.append('id', transaccion_id)
		return urlPDF.href
	}


	/**
	 *
	 *
	 * @memberof Transacciones
	 * @description Envia la peticion al servidor para liberar transacciones
	 */
	solicitarLiberacion = () => {
		// this.setState({ loading: true })
		axios.put('/transacciones/liberar', {
		}).then(res => {
			if (res.data.data.length > 0)
				Modal.info({
					title: 'Resumen de transacciones ejecutadas.',
					width: 800,
					content: (
						<List
							footer={null}
							dataSource={res.data.data}
							size="small"
							className='width-100'
							header={
								<Row className='width-100' justify="space-around">
									<Col> </Col>
									<Col span={6}>Concepto</Col>
									<Col span={8}>Descripción</Col>
									<Col span={8}>Estado</Col>
								</Row>
							}
							renderItem={item => (
								<List.Item>
									<Row className='width-100' justify="space-around">
										<Col>
											{(item.estado == 'LIQUIDADO') ? <CheckCircleOutlined style={{ color: '#51B56D' }} /> : null}
											{(item.estado == 'CANCELADO') ? <CloseCircleOutlined style={{ color: '#FF0000' }} /> : null}
											{(item.estado == 'PENDIENTE') ? <WarningOutlined style={{ color: '#d99816' }} /> : null}

										</Col>
										<Col span={6}>
											<Tooltip placement="top" title={item.transaccion_id}>
												{item.concepto}
											</Tooltip>
										</Col>
										<Col span={8}>{item.message}</Col>
										<Col span={8}>{item.estado}</Col>
									</Row>
								</List.Item>
							)}
						/>
					),
					onOk() { },
					okText: "Cerrar"
				});
			else
				message.info('No hay transacciones pendientes de ejecutar')
		}).catch(error => {
			console.log('error', error.response)
			message.error(getResponseError(error.response, "Ocurrio un error"))
		}).finally(() => {
			this.setState({ loading: false })
			this.getTransacciones()
		})

	}

	/**
	 * @memberof Transacciones
	 * @description Cuando buscamos transacciones con stp, no nos dice si son ingresos o egresos 
	 * asi que analizamos los filtros para saber, y poner el color correspondiente
	 *
	 */
	getTipoActualSTP = (filters) => {

		if (!Array.isArray(filters) || filters.length === 0) {
			this.setState({ tipo_orden_actual: null })
			return
		}

		let tipo = null

		for (const obj of filters) {
			if (obj.tipo_orden) {
				tipo = obj.tipo_orden;
			}
		}

		this.setState({ tipo_orden_actual: tipo })
	}

	generarPdf = (id, index) => {

		this.setState({loading: true})

		axios.get(this.getPDF(id), { responseType: 'blob', })
			.then(response => window.open(URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))))
			.catch(e => {
				message.error(e?.message || "Error al generar el ODF")
			})
			.finally(e => this.setState({loading: false}))
	}


	render() {

		const contentMenuPlus = (
			<div className="flex-column">
				{this.props.transferirTransacciones ?
					<Button type="primary" className="width-80 mb-05" onClick={() => this.setState({ modal_visible: true, capturar: false, transferir: true })}>
						Realizar Transacción
					</Button>
					: null}
				{this.props.capturarTransacciones ?
					<Button type="primary" className="width-100" onClick={() => this.setState({ modal_visible: true, capturar: true, transferir: false })}>
						Programar Transacción
					</Button> : null}
			</div>
		);
		return (
			<>
				<Content className="p-1">
					<Row className='main' style={{ marginBottom: "6rem" }}>
						<Col xs={24}>
							<Title level={5}> Transacciones </Title>
							<Space direction='horizontal' size={2}>
								<Button onClick={() => { this.setState({ visibleFilters: true }) }}> Filtros </Button>
								<Radio.Group defaultValue="local" buttonStyle="solid" onChange={(e) => {
									this.getTransacciones({ tipo_dato: e.target.value });
									this.setState({ tipo_dato: e.target.value })
									this.drawerFiltros.current.clearForm()

								}}>
									<Radio.Button value="local">Local</Radio.Button>
									<Radio.Button value="stp" >STP</Radio.Button>
								</Radio.Group>
							</Space>
						</Col>
						<Col xs={24}>
							<List
								loading={this.state.loading}
								className="component-list mb-3"
								itemLayout="horizontal"
								locale={{ emptyText: "Sin Transacciones" }}
								dataSource={this.state.transacciones?.data}
								pagination={{
									current: this.state.transacciones.page,
									pageSize: this.state.transacciones.limit,
									total: this.state.transacciones.total,
									position: 'bottom',
									className: "flex-left",
									showSizeChanger: true,
									pageSizeOptions: [10, 20, 50, 100, 500],
									showQuickJumper: true,
									locale: { jump_to: "Ir a la página", page: '' },
									onChange: (page, limit) => {
										this.getTransacciones({ page, limit })
									},
									onShowSizeChange: (page, limit) => {
										this.getTransacciones({ page, limit })
									}
								}}
								header={<Row className="header-list width-100 ">
									<Col xs={0} sm={0} md={0} lg={3} className=" center">
										<Text>Concepto</Text>
									</Col>
									<Col xs={0} sm={0} md={0} lg={3} className="center">
										<Text>Fecha</Text>
									</Col>
									<Col xs={0} sm={0} md={0} lg={3} className="center">
										<Text>Referencia</Text>
									</Col>
									<Col xs={0} sm={0} md={0} lg={3} className="center">
										<Text>Ordenante</Text>
									</Col>
									<Col xs={0} sm={0} md={0} lg={3} className="center">
										<Text>Beneficiario</Text>
									</Col>
									<Col xs={0} sm={0} md={0} lg={3} className="center">
										<Text ellipsis>Estado</Text>
									</Col>
									<Col xs={0} sm={0} md={0} lg={3} className="center">
										<Text>Monto</Text>
									</Col>
								</Row>
								}

								renderItem={(item, index) => {

									// console.log('item', item);


									let elem = <List.Item className="component-list-item">
										<Card className="card-list width-100" bordered={false}>
											<Row className="width-100" align={"middle"} gutter={[0, 8]}>
												<Col xs={24} md={12} lg={3} className="flex-left">

													<CustomAvatar
														size="small"
														style={{minWidth: "10px", marginLeft: "6px"}}
														color={item.tipo === 1 || this.state?.tipo_orden_actual === "R" ? "#4caa21" : "#bb2a2a"}
														name={item.ordenante?.nombre}
													/>
													<Text ellipsis strong>{item.concepto} </Text>

												</Col>
												<Col xs={24} md={12} lg={3} className="center">
													<Text ellipsis className="text-gray">{item.fecha_operacion ? moment(item.fecha_operacion).format("DD/MM/YYYY HH:mm") : 'No se ha liberado'}</Text>
												</Col>
												<Col xs={24} md={12} lg={3} className="center">
													<Text ellipsis className="text-gray">{item.referencia_numerica ?? "-"}</Text>
												</Col>
												<Col xs={24} md={12} lg={3} className="center">
													<Text ellipsis className="text-gray">{item.ordenante?.nombre}</Text>
												</Col>
												<Col xs={24} md={12} lg={3} className="center">
													<Text ellipsis className="text-gray">{item.beneficiarios[0]?.nombre ?? "ND"}</Text>
												</Col>
												<Col xs={24} md={12} lg={3} className="center">
													<Text ellipsis className="text-gray">{item.estado ?? "No Enviado a STP"}</Text>
												</Col>
												<Col xs={24} md={12} lg={3} className="center">
													<Statistic
														value={Math.abs(item.monto)}
														precision={2}
														prefix={item.tipo === 2 ? "-$" : "$"}
														suffix={"MXN"}
														valueStyle={{
															fontWeight: 'bold',
															fontSize: 16,
															marginRigth: "5px",
															color: item.tipo === 1 || this.state?.tipo_orden_actual === "R" ? "#4caa21" : "#bb2a2a"
														}}
													/>
												</Col>

												<Col xs={24} md={12} lg={3} className="flex-right">
													<Space>
														<Tooltip title="Ver detalle">
															<Button
																type="primary"
																icon={<EyeOutlined />}
																disabled={!this.props.verTransacciones}
																onClick={(e) => {
																	e.stopPropagation()
																	if (this.state.tipo_dato == 'local')
																		this.setState({ modal_detalle: true, transaccion_id: item._id, transaccion: null })
																	else
																		this.setState({ modal_detalle: true, transaccion_id: item._id, transaccion: item })
																}

																}
															/>
														</Tooltip>
														{this.state.tipo_dato === "local" ? <Tooltip title="Descargar comprobante de transacción">
															<Button
																onClick={() => this.generarPdf(item._id, index)}
																type="primary"
																icon={<FilePdfOutlined />}
															//disabled={!this.props.verTransacciones}
															/>
														</Tooltip> : null}

														<Tooltip title="El comprobante no esta disponible todavía">
															<Button
																type="link"
																icon={<FilePdfOutlined />}
																href={item.cep?.url_cep}
																disabled={!item.cep?.url_cep}
															/>
														</Tooltip>
													</Space>
												</Col>
											</Row>
										</Card>
									</List.Item>
									if (Array.isArray(item.comi) && item.comi.length > 0) {
										elem = <Collapse bordered={false} className="collapse-tra" >
											<Panel
												className='panel-tra'
												style={{ padding: 0 }}
												showArrow={true}
												header={elem}
												key="1">
												<List
													className="component-list mt-1"
													itemLayout="horizontal"
													locale={{ emptyText: "Sin Transacciones" }}
													dataSource={item.comi}
													renderItem={(item, index) => <List.Item className="component-list-item">
														<Row className="width-100" align={"middle"} gutter={[0, 8]}>
															<Col xs={24} md={12} lg={3} className="flex-left">

																<CustomAvatar
																	color={item.tipo === 1 || this.state?.tipo_orden_actual === "R" ? "#4caa21" : "#bb2a2a"}
																	name={item.ordenante?.nombre}
																/>
																<Text ellipsis strong>{item.concepto} </Text>

															</Col>
															<Col xs={24} md={12} lg={3} className="center">
																<Text ellipsis className="text-gray">{item.fecha_operacion ? moment(item.fecha_operacion).format("DD/MM/YYYY HH:mm") : 'No se ha liberado'}</Text>
															</Col>
															<Col xs={24} md={12} lg={3} className="center">
																<Text ellipsis className="text-gray">{item.referencia_numerica ?? "-"}</Text>
															</Col>
															<Col xs={24} md={12} lg={3} className="center">
																<Text ellipsis className="text-gray">{item.ordenante?.nombre}</Text>
															</Col>
															<Col xs={24} md={12} lg={3} className="center">
																<Text ellipsis className="text-gray">{item.beneficiarios[0]?.nombre ?? "ND"}</Text>
															</Col>
															<Col xs={24} md={12} lg={3} className="center">
																<Text ellipsis className="text-gray">{item.estado ?? "No Enviado a STP"}</Text>
															</Col>
															<Col xs={24} md={12} lg={3} className="center">
																<Statistic
																	value={Math.abs(item.monto)}
																	precision={2}
																	prefix={item.tipo === 2 ? "-$" : "$"}
																	suffix={"MXN"}
																	valueStyle={{
																		fontWeight: 'bold',
																		fontSize: 16,
																		marginRigth: "5px",
																		color: "#bb2a2a" //"#4caa21" : "#bb2a2a"
																	}}
																/>
															</Col>

															<Col xs={24} md={12} lg={3} className="flex-right">
																<Space>
																	<Tooltip title="Ver detalle">
																		<Button
																			type="primary"
																			icon={<EyeOutlined />}
																			disabled={!this.props.verTransacciones}
																			onClick={() => {
																				if (this.state.tipo_dato == 'local')
																					this.setState({ modal_detalle: true, transaccion_id: item._id, transaccion: null })
																				else
																					this.setState({ modal_detalle: true, transaccion_id: item._id, transaccion: item })
																			}

																			}
																		/>
																	</Tooltip>
																	{this.state.tipo_dato === "local" ? <Tooltip title="Descargar comprobante de transacción">
																		<Button
																			onClick={() => this.generarPdf(item._id, index)}
																			type="primary"
																			icon={<FilePdfOutlined />}
																		/>
																	</Tooltip> : null}

																	{/*<Tooltip title="El comprobante no esta disponible todavía">
																		<Button
																			type="link"
																			icon={<FilePdfOutlined />}
																			href={item.cep?.url_cep}
																			disabled={!item.cep?.url_cep}
																		/>
																	</Tooltip>*/}
																</Space>
															</Col>
														</Row>
													</List.Item>}
												/>
											</Panel>
										</Collapse>

										// <Panel  header="This is panel header 3" key="3">
										// 	<p>{}</p>
										// </Panel>
										//  <div>
										// 	
										// </div>
									}

									return elem
								}}
							/>
						</Col>
					</Row>
				</Content>




				{this.props.liberarTransacciones ? <FloatingButton
					icon={<CheckOutlined />}
					background="#3EA741"
					content={"Liberar Transacciones"}
					style={{ marginRight: '120px' }}

					onClick={this.solicitarLiberacion}
				/>
					: null
				}


				{(this.props.capturarTransacciones || this.props.transferirTransacciones) ?
					<FloatingButton
						content={contentMenuPlus}
					/> : null}
				<FloatingButton
					content={contentMenuPlus}
				/>

				<ModalComprobante
					visible={this.state.modal_visible_comprobante}
					onClose={() => {
						this.setState({ modal_visible_comprobante: false })
					}}
					transaccion_id={this.state.transaccion_id}
				/>

				<ModalTransferencia
					visible={this.state.modal_visible}
					onClose={(transaccion) => {
						this.setState({ modal_visible: false, transaccion_id: null })
						this.getTransacciones()
					}}
					transaccion_id={this.state.transaccion_id}
					cliente_id={this.props.cliente_id}
					user_rol={this.props.user_rol}
					capturar={this.state.capturar}
					transferir={this.state.transferir}
				/>

				<ModalDetalle
					visible={this.state.modal_detalle}
					onClose={() => {
						this.setState({ modal_detalle: false, transaccion_id: null })
					}}
					transaccion_id={this.state.transaccion_id}
					transaccion={this.state.transaccion}
					isAdmin={true}
				/>

				<FiltrosDrawer
					title="Filtrar Transacciones"
					ref={this.drawerFiltros}
					updateFilters={filters => this.getTransacciones({
						page: 1,
						filters
					})}
					initialValues={{
						fecha: moment(),
						tipo_orden: "E"
					}}
					onClose={() => this.setState({ visibleFilters: false })}
					visible={this.state.visibleFilters}
					beneficiarios={false}

					cuentas={(this.state.tipo_dato === 'local')}
					monto={(this.state.tipo_dato === 'local')}
					fecha={(this.state.tipo_dato === 'stp')}
					rango_fecha={(this.state.tipo_dato === 'local')}
					tipo_operacion={(this.state.tipo_dato === 'stp')}
					clientes={(this.state.tipo_dato === 'local')}

				/>

			</>
		)
	}
}

export default function (props) {
	const [params, setParams] = useSearchParams();

	let user = React.useContext(User)

	let cuenta = React.useContext(Cuenta)

	let permissions = usePermissions(user?.rol_id?.permisos, {
		capturarTransacciones: ["transacciones", "capturar"],
		transferirTransacciones: ["transacciones", "transferir"],
		liberarTransacciones: ["transacciones", "liberar"],
		verTransacciones: ["transacciones", "read"]
	})

	const [search, setSearch] = useSearch();

	return (<Transacciones {...props} cuenta={cuenta} {...permissions} search={search} user_rol={user?.rol_id?.tipo} cliente_id={user.cliente_id} navigate={useNavigate()} setParams={setParams} params={params} />)
}