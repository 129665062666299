import React from 'react'
import { Layout, Col, Row, Typography, List, Card, Form, Select, Statistic, Space, message, Button, Collapse, Tooltip } from "antd";
import axios from 'axios';
import moment from 'moment';
import { EyeOutlined, CheckOutlined, FilePdfOutlined, FileExcelOutlined } from '@ant-design/icons';

//Componentes
import { User } from '../../../../Hooks/Logged';
import { getResponseError } from '../../../Utils';
import CustomAvatar from '../../../Widgets/Avatar';
import { IconCuenta } from "../../../Widgets/Icons";
import usePermissions from '../../../../Hooks/usePermissions';
import FloatingButton from '../../../Widgets/FloatingButton/FloatingButton';

//Modales
import ModalDetalle from '../../../Admin/Transacciones//ModalDetalle';
import ModalTransferencia from '../../../Admin/Transacciones/ModalTransferencia';

const { Panel } = Collapse
const { Content } = Layout
const { Text } = Typography
const { Option } = Select
class Transacciones extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			transacciones: {
				page: 1,
				limit: 10,
				total: 0,
				pages: 0,
				data: [],
			},
			cuentas: {
				page: 1,
				limit: 10,
				total: 0,
				pages: 0,
				data: [],
			},
			cuenta_id: undefined,
			tipo_dato: "local",
			loadingButton: false
		}
	}

	refCuentas = React.createRef();

	componentDidMount() {
		this.getCuentas()
		if(this.props.cuenta){
			if(this.refCuentas.current){
				this.refCuentas.current.setFieldsValue({cuenta: this.props.cuenta})
				this.get({cuenta: this.props.cuenta, page: 1})
			}
		}
	}


	componentDidUpdate(prevProps){
		if(this.props.cuenta != prevProps.cuenta){
			if(this.refCuentas.current){
				this.refCuentas.current.setFieldsValue({cuenta: this.props.cuenta})
				this.get({cuenta: this.props.cuenta, page: 1})
			}
		}
	}

	get = ({
		page = this.state.transacciones.page,
		limit = this.state.transacciones.limit,
		cuenta = this.state.cuenta
	} = this.state.transacciones) => {
		console.log("cuenta", cuenta);
		this.setState({ loading: true })
		axios.get('/transacciones', {
			params: {
				page,
				limit,
				cuenta,
				tipo_dato: "local",
				all_transacciones: "true",
			}
		}).then(({ data }) => {
			console.log("data", data);
			this.setState({
				transacciones: data,
				cuenta: cuenta
			})
		}).catch(error => {
			console.log('error', error)
			message.error(error.response?.data?.message ?? "Error al obtener las transacciones")
		}).finally(() => {
			this.setState({ loading: false })
		})
	}


	/**
	 *
	 *
	 * @memberof Transacciones
	 */
	getCuentas = ({ page, limit, search } = this.state.cuentas) => {

		axios.get('/cuentas', {
			params: {
				page,
				limit,
				search,
				cliente_id: this.props.cliente_id
			}
		}).then(({ data }) => {
			this.refCuentas.current.setFieldsValue({ cuenta: data.data[0].cuenta })
			this.setState({
				cuentas: data,
				cuenta_id: data.data[0].cuenta
			})
			this.get({ cuenta: data.data[0].cuenta })
		}).catch(error => {
			console.log("ERR, error", error)
			message.error(getResponseError(error.response, 'Error al obtener las cuentas'))
		})
	}

	changeData = (x, values) => {
		let { cuenta } = this.refCuentas?.current?.getFieldsValue();
		this.get({ page: 1, limit: 10, cuenta: cuenta.value })
		this.props.setCuenta(cuenta.value)
	}

	getPDF = (transaccion_id) => {
		let urlPDF = new URL(axios.defaults.baseURL + '/transaccion/comprobante');
		urlPDF.searchParams.append('Authorization', sessionStorage.getItem('token'))
		urlPDF.searchParams.append('id', transaccion_id)
		return urlPDF.href
	}

	generarPdf = (id, index) => {

		this.setState({loading: true})

		axios.get(this.getPDF(id), { responseType: 'blob', })
			.then(response => window.open(URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))))
			.catch(e => {
				message.error(e?.message || "Error al generar el ODF")
			})
			.finally(e => this.setState({loading: false}))
	}

	/**
	 * @memberof Transacciones
	 * 
	 * @method getTransacciones
     * @desription Obtiene las transacciones en formato csv del cliente
	 */
	getTransacciones =  (tipo) => {

        let url = new URL(axios.defaults.baseURL + '/transacciones/csv')
        url.searchParams.set('Authorization', sessionStorage.getItem('token'))
        url.searchParams.set("cuenta", this.state.cuenta)
        //window.open(url, '_blank');

        this.setState({loadingButton: true})
        axios.get(url.href, { responseType: 'blob', })
        .then(response =>{
        	// Crea un objeto URL a partir de la respuesta blob
	        const url = window.URL.createObjectURL(new Blob([response.data]));

	        // Crea un enlace temporal para descargar el archivo
	        const link = document.createElement('a');
	        link.href = url;

	        // Obtiene el nombre de archivo del encabezado Content-Disposition
	        const fileName = `transacciones-${this.state.cuenta}.csv`;

	        link.setAttribute('download', fileName);

	        // Agrega el enlace al documento y simula un clic para descargar el archivo
	        document.body.appendChild(link);
	        link.click();

	        // Elimina el enlace del documento después de la descarga
	        document.body.removeChild(link);
        })
        .catch(error => {
        	console.log("error", error);
            message.error("Error al obtener el Archivo")
        })
        .finally(e => this.setState({loadingButton: false}))

    }

	render() {
		return (
			<>
				<Content className="pl-1" style={{marginBottom: "6rem"}}>
					<Row justify={"end"} gutter={[16,16]}>
						<Col xs={24} md={12} lg={16} className="flex-right">
							{ this.props.user.rol_id?.tipo === 1 ? <Tooltip placement="topRight" title={"Descargar transacciones"}>
								<Button 
			                        type="primary"
			                        onClick={()=> this.getTransacciones()}
			                        icon={<FileExcelOutlined style={{color: "white"}}/>}
			                        style={{marginRight: "4px"}}
			                        loading={this.state.loadingButton}
			                    />
		                    </Tooltip> : null }
						</Col>
						<Col xs={24} md={12} lg={8}>
							<Form ref={this.refCuentas} onValuesChange={(x, values) => this.changeData(x, values)} >
								<Form.Item noStyle name="cuenta">
									<Select
										placeholder="Seleccione la Cuenta"
										showSearch
										filterOption={false}
										className='width-100'
										labelInValue
										onSearch={(search) => this.getCuentas({ search })}
										suffixIcon={<IconCuenta />}
									>
										{this.state.cuentas?.data?.map(({ _id, nombre, cuenta }) => <Option value={cuenta} key={cuenta}>
											{nombre ?? ""} <small className='text-gray'>{cuenta ?? ""} </small>
										</Option>)}
									</Select>
								</Form.Item>
							</Form>
						</Col>
					</Row>
					<Row>
						<Col span={24} className="" style={{ paddingRight: '0.5rem' }}>
							<Row className='main'>
								<Col xs={24}>
									<List
										loading={this.state.loading}
										className="component-list"
										itemLayout="horizontal"
										locale={{ emptyText: "Sin Transacciones" }}
										dataSource={this.state.transacciones?.data}
										pagination={{
											current: this.state.transacciones.page,
											pageSize: this.state.transacciones.limit,
											total: this.state.transacciones.total,
											position: 'bottom',
											className: "flex-left",
											showSizeChanger: true,
											pageSizeOptions: [20, 50, 100, 500],
											showQuickJumper: true,
											locale: { jump_to: "Ir a la página", page: '' },
											onChange: (page, limit) => {
												this.get({ page, limit })
											},
											onShowSizeChange: (page, limit) => {
												this.get({ page, limit })
											}
										}}
										header={<Row className="header-list width-100 ">
											<Col xs={0} sm={0} md={3} className=" center">
												<Text>Concepto</Text>
											</Col>
											<Col xs={0} sm={0} md={3} className="center">
												<Text>Fecha</Text>
											</Col>
											<Col xs={0} sm={0} md={3} className="center">
												<Text>Referencia</Text>
											</Col>
											<Col xs={0} sm={0} md={4} className="center">
												<Text>Ordenante</Text>
											</Col>
											<Col xs={0} sm={0} md={3} className="center">
												<Text>Beneficiario</Text>
											</Col>
											<Col xs={0} sm={0} md={3} className="center">
												<Text ellipsis>Estado</Text>
											</Col>
											<Col xs={0} sm={0} md={3} className="center">
												<Text>Monto</Text>
											</Col>
										</Row>
										}

										renderItem={(item, index) => {

											// console.log('item', item);
		
		
											let elem = <List.Item className="component-list-item">
												<Card className="card-list width-100" bordered={false}>
													<Row className="width-100" align={"middle"} gutter={[0, 8]}>
														<Col xs={24} sm={12} md={3} className="flex-left">
		
															<CustomAvatar
																color={item.tipo === 1 || this.state?.tipo_orden_actual === "R" ? "#4caa21" : "#bb2a2a"}
																name={item.ordenante?.nombre}
															/>
															<Text ellipsis strong>{item.concepto} </Text>
		
														</Col>
														<Col xs={24} sm={12} md={3} className="center">
															<Text ellipsis className="text-gray">{item.fecha_operacion ? moment(item.fecha_operacion).format("DD/MM/YYYY HH:mm") : 'No se ha liberado'}</Text>
														</Col>
														<Col xs={24} sm={12} md={3} className="center">
															<Text ellipsis className="text-gray">{item.referencia_numerica ?? "-"}</Text>
														</Col>
														<Col xs={24} sm={12} md={4} className="center">
															<Text ellipsis className="text-gray">{item.ordenante?.nombre}</Text>
														</Col>
														<Col xs={24} sm={12} md={3} className="center">
															<Text ellipsis className="text-gray">{item.beneficiarios[0]?.nombre ?? "ND"}</Text>
														</Col>
														<Col xs={24} sm={12} md={3} className="center">
															<Text ellipsis className="text-gray">{item.estado ?? "No Enviado a STP"}</Text>
														</Col>
														<Col xs={24} sm={12} md={3} className="center">
															<Statistic
																value={Math.abs(item.monto)}
																precision={2}
																prefix={item.tipo === 2 ? "-$" : "$"}
																suffix={"MXN"}
																valueStyle={{
																	fontWeight: 'bold',
																	fontSize: 16,
																	marginRigth: "5px",
																	color: item.tipo === 1 || this.state?.tipo_orden_actual === "R" ? "#4caa21" : "#bb2a2a"
																}}
															/>
														</Col>
		
														<Col xs={24} md={2} className="flex-right">
															<Space>
																<Tooltip title="Ver detalle">
																	<Button
																		type="primary"
																		icon={<EyeOutlined />}
																		disabled={!this.props.verTransacciones}
																		onClick={(e) => {
																			e.stopPropagation()
																			this.setState({ modal_detalle: true, transaccion_id: item._id, transaccion: null })
																		}}
																	/>
																</Tooltip>
																{this.state.tipo_dato === "local" ? <Tooltip title="Descargar comprobante de transacción">
																	<Button
																		onClick={() => this.generarPdf(item._id, index)}
																		type="primary"
																		icon={<FilePdfOutlined />}
																	/>
																</Tooltip> : null}
		
																<Tooltip title="El comprobante no esta disponible todavía">
																	<Button
																		type="link"
																		icon={<FilePdfOutlined />}
																		href={item.cep?.url_cep}
																		disabled={!item.cep?.url_cep}
																	/>
																</Tooltip>
															</Space>
														</Col>
													</Row>
												</Card>
											</List.Item>
											if (Array.isArray(item.comi) && item.comi.length > 0) {
												elem = <Collapse bordered={false} expandIcon={() => null} >
													<Panel
														className='panel-tra'
														style={{ padding: 0 }}
														showArrow={false}
														header={elem}
														key="1">
														<List
															className="component-list mt-1"
															itemLayout="horizontal"
															locale={{ emptyText: "Sin Transacciones" }}
															dataSource={item.comi}
															renderItem={(item, index) => <List.Item className="component-list-item">
																<Row className="width-100" align={"middle"} gutter={[0, 8]}>
																	<Col xs={24} md={3} className="flex-left">
		
																		<CustomAvatar
																			color={item.tipo === 1 || this.state?.tipo_orden_actual === "R" ? "#4caa21" : "#bb2a2a"}
																			name={item.ordenante?.nombre}
																		/>
																		<Text ellipsis strong>{item.concepto} </Text>
		
																	</Col>
																	<Col xs={24} md={3} className="center">
																		<Text ellipsis className="text-gray">{item.fecha_operacion ? moment(item.fecha_operacion).format("DD/MM/YYYY HH:mm") : 'No se ha liberado'}</Text>
																	</Col>
																	<Col xs={24} md={3} className="center">
																		<Text ellipsis className="text-gray">{item.referencia_numerica ?? "-"}</Text>
																	</Col>
																	<Col xs={24} md={3} className="center">
																		<Text ellipsis className="text-gray">{item.ordenante?.nombre}</Text>
																	</Col>
																	<Col xs={24} md={3} className="center">
																		<Text ellipsis className="text-gray">{item.beneficiarios[0]?.nombre ?? "ND"}</Text>
																	</Col>
																	<Col xs={24} md={3} className="center">
																		<Text ellipsis className="text-gray">{item.estado ?? "No Enviado a STP"}</Text>
																	</Col>
																	<Col xs={24} md={3} className="center">
																		<Statistic
																			value={Math.abs(item.monto)}
																			precision={2}
																			prefix={item.tipo === 2 ? "-$" : "$"}
																			suffix={"MXN"}
																			valueStyle={{
																				fontWeight: 'bold',
																				fontSize: 16,
																				marginRigth: "5px",
																				color: "#bb2a2a" //"#4caa21" : "#bb2a2a"
																			}}
																		/>
																	</Col>
		
																	<Col xs={24} md={3} className="center">
																		<Space>
																			<Tooltip title="Ver detalle">
																				<Button
																					type="primary"
																					icon={<EyeOutlined />}
																					disabled={!this.props.verTransacciones}
																					onClick={() => {
																						if (this.state.tipo_dato == 'local')
																							this.setState({ modal_detalle: true, transaccion_id: item._id, transaccion: null })
																						else
																							this.setState({ modal_detalle: true, transaccion_id: item._id, transaccion: item })
																					}
		
																					}
																				/>
																			</Tooltip>
																			{this.state.tipo_dato === "local" ? <Tooltip title="Descargar comprobante de transacción">
																				<Button
																					onClick={() => this.generarPdf(item._id, index)}
																					type="primary"
																					icon={<FilePdfOutlined />}
																				//disabled={!this.props.verTransacciones}
																				/>
																			</Tooltip> : null}
		
																			{/*<Tooltip title="El comprobante no esta disponible todavía">
																				<Button
																					type="link"
																					icon={<FilePdfOutlined />}
																					href={item.cep?.url_cep}
																					disabled={!item.cep?.url_cep}
																				/>
																			</Tooltip>*/}
																		</Space>
																	</Col>
																</Row>
															</List.Item>}
														/>
													</Panel>
												</Collapse>
											}		
											return elem
										}}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
				</Content>
				{this.props.user?.rol_id.super_admin ? <FloatingButton
					title="Crear Transacción"
					onClick={()=>{
						this.setState({
							modalVisible: true
						})
					}}
				/> : null }
				<ModalTransferencia
					visible={this.state.modalVisible}
					onClose={() => {
						this.get()
						this.setState({ modalVisible: false, transaccion_id: undefined })
					}}
					cliente_id={this.props.cliente_id}
					transaccion_id={this.state.transaccion_id}
					cuenta_id={this.state.cuenta_id}
					transferir={true}
				/>
				<ModalDetalle
					visible={this.state.modal_detalle}
					onClose={() => {
						this.setState({ modal_detalle: false, transaccion_id: null })
					}}
					transaccion_id={this.state.transaccion_id}
				/>
			</>
		)
	}
}

export default function (props) {
	let user = React.useContext(User)

	let permissions = usePermissions(user?.rol_id?.permisos, {
		capturarTransacciones: ["transacciones", "capturar"],
		transferirTransacciones: ["transacciones", "transferir"],
		liberarTransacciones: ["transacciones", "liberar"],
		verTransacciones: ["transacciones", "read"]
	})
	return (<Transacciones {...props} {...permissions} user={user}/>)
}