import React, { Component } from "react";
import { Row, Col, Modal, Typography, Form, Input, message, Spin, Button } from 'antd';

import { EmailInput } from "../../Widgets/Inputs/Inputs"
import { SelectRol, SelectCliente } from "../../Widgets/Inputs/Selects";
import { AvatarLoader } from "../../Widgets/Uploaders";
import { getResponseError } from '../../Utils'
import axios from "axios"

const { Title } = Typography;


class ModalUsuario extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            tipo: 1
        }
    }

    modalUsuario = React.createRef();

    componentDidMount() {
        if (this.props.usuario_id) {
            this.getUsuario()
        }
        if (this.props.cliente_id) {
            this.modalUsuario.current.setFieldsValue({
                cliente_id: {
                    value: this.props.cliente_id,
                }
            })
        }
    }

    /**
     * @memberof ModalUsuario
     * @method getUsuario
     * @description Obtiene informacion del usuario
     */
    getUsuario = () => {

        this.setState({ loading: true })
        axios.get('/usuarios/' + this.props.usuario_id)
            .then(response => {

                let usuario = response.data
                console.log("usuario", usuario);
                this.setState({ tipo: usuario.cliente_id ? 2 : 1 })
                this.modalUsuario.current.setFieldsValue({
                    ...response.data,
                    rol_id: usuario.rol_id?._id ? {
                        label: usuario.rol_id?.nombre,
                        value: usuario.rol_id?._id,
                    } : null,
                    avatar: usuario.avatar ? {
                        uid: usuario.avatar.file,
                        name: usuario.avatar.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/usuarios/" + this.props.usuario_id + "?avatar=" + usuario.avatar.file + "&Authorization=" + sessionStorage.getItem("token")
                    } : null,
                    cliente_id: usuario.cliente_id ? {
                        label: `${usuario.cliente_id?.nombre} ${usuario.cliente_id?.apellido_paterno ?? ""} ${usuario.cliente_id?.apellido_materno ?? ""}`,
                        value: usuario.cliente_id?._id,
                    } : null,
                    cuentas_id: usuario?.cuentas_id?.map(e => e.cuenta_id)
                })
                    console.log("usuario?.cuentas_id?.map(e => e.cuenta_id)", usuario?.cuentas_id?.map(e => e.cuenta_id));
            }).catch(error => {
                message.error(getResponseError(error.response, "Error al obtener el usuario"))
            }).finally(() => {
                this.setState({ loading: false })
            })

    }

    /**
     * @memberof ModalUsuario
     * @method addUsuario
     * @description Crea un nuevo usuario en el sistema
     */
    addUsuario = (values) => {
        this.setState({ loading: true })

        axios.post('/usuarios', values, {
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(response => {
                message.success('Usuario Añadido')
                this.props.onClose(true)
            }).catch(error => {
                console.log(error)
                message.error(getResponseError(error.response, "Error al añadir el usuario"))
            }).finally(() => this.setState({ loading: false }))

    }

    /**
     * @memberof ModalUsuario
     * @method updateUsuario
     * @description Crea un nuevo usuario en el sistema
     */
    updateUsuario = (values) => {
        this.setState({ loading: true })

        axios.put('/usuarios', values)
            .then(response => {
                message.success('Usuario Editado')
                this.props.onClose(true)
            }).catch(error => {
                message.error(getResponseError(error.response, "Error al editar el usuario"))
            }).finally(() => this.setState({ loading: false }))

    }

    /**
     * @memberof ModalUsuario
     * @method getCliente
     * @description Obtiene informacion del usuario
     */
    getCliente = (cliente_id) => {

        this.setState({ loading: true })
        axios.get('/cliente/' + cliente_id, {
        })
            .then(response => {
                let cliente = response.data;
                this.setState({ tipo: this.state.tipo === 2 || this.state.tipo === 4 ? this.state.tipo : 2 }) // Usuario tipo cliente
                this.modalUsuario.current.setFieldsValue({
                    nombre_completo: `${cliente.nombre ?? ""} ${cliente.apellido_paterno ?? ""} ${cliente.apellido_materno ?? ""}`,
                    email: cliente.email
                })

            }).catch(error => {
                message.error(getResponseError(error.response, "Error al obtner el usuario"))
            }).finally(() => {
                this.setState({ loading: false })
            })

    }

    /**
    * @memberof ModalUsuario
    * @method onFinish
    * @description Se ejecuta al dar enter al formulario
    */
    onFinish = (values) => {

        const formData = new FormData()
        formData.appendMultiple({
            ...values,
            avatar: undefined,
            usuario_id: this.props.usuario_id,
        })

        if (values.avatar && !values.avatar.fromDB) {
            formData.append("avatar", values.avatar, values.avatar.name)
        }

        if (this.props.usuario_id) {
            this.updateUsuario(formData)
        } else {
            this.addUsuario(formData)
        }

    }

    render() {
        return (
            <Form
                layout="vertical"
                ref={this.modalUsuario}
                onFinish={this.onFinish}
                onValuesChange={this.onValuesChange}
            >
                <Spin spinning={this.state.loading}>

                    <Row align="center">
                        <Col xs={22} lg={20} >
                            <Form.Item
                                name="avatar"
                                className="center"
                            >
                                <AvatarLoader
                                    imageCrop={true}
                                    onRemove={() => {
                                        axios.put('/usuarios', {
                                            usuario_id: this.props.usuario_id,
                                            delete_avatar: true
                                        })
                                    }}

                                />
                            </Form.Item>
                            <Form.Item
                                name="nombre_completo"
                                requiredMark={true}
                                label={this.props.usuario_id ? "Nombre" : <>Obligatorio</>}
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el nombre"
                                }]}
                            >
                                <Input
                                    size="large"
                                    placeholder="Nombre Completo"
                                />
                            </Form.Item>
                            <Form.Item
                                name="email"
                                requiredMark={true}
                                label={this.props.usuario_id ? "Email" : <>Obligatorio</>}
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el email"
                                }]}
                            >
                                <EmailInput
                                    size="large"
                                    placeholder="Email"
                                />
                            </Form.Item>
                            <Form.Item
                                name="rol_id"
                                requiredMark={true}
                                label={this.props.usuario_id ? "Rol" : <>Obligatorio</>}
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el rol"
                                }]}
                            >
                                <SelectRol
                                    disabled={this.state.tiene_hijo}
                                    size="large"
                                    params={{ tipos: [1, 2, 4] }}
                                    placeholder={`Rol del ${this.state.tipo === 2 ? "Cliente" : "Admin"}`}
                                    onTipoSelect={(tipo) => this.setState({ tipo })}
                                />
                            </Form.Item>
                            {
                                this.state.tipo === 2 || this.state.tipo === 4 && (
                                    <Form.Item
                                        name="cliente_id"
                                        requiredMark={true}
                                        label={this.props.usuario_id ? "Cliente" : <>Obligatorio</>}
                                        rules={[{
                                            required: true,
                                            message: "Por favor, seleccione el cliente"
                                        }]}
                                    >
                                        <SelectCliente
                                            size="large"
                                            params={{
                                                //sin_usuario: true,
                                            }}
                                            //disabled={this.props.usuario_id}
                                            allowClear={false}
                                            onSelect={(cliente) => {
                                                if(!this.props.usuario_id){
                                                    if (cliente) {
                                                        this.getCliente(cliente)
                                                    } else {
                                                        this.modalUsuario.current.setFieldsValue({ nombre: '', email: '' })
                                                    }
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                )
                            }
                            <Form.Item >
                                <Button htmlType="submit" type="primary" block size="large" className="mt-2">
                                    Aceptar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function Modals(props) {
    const { visible = false, onClose = () => { }, usuario_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        destroyOnClose={true}
        zIndex={1000}
        footer={false}
        closable={false}
    >
        <Row>
            <Col span={24} className="center">
                <img src={"/img/BXNKLogo.png"} width="164" />
            </Col>
        </Row>
        <Title level={1} className="text-center mb-2 mt-1">{usuario_id ? "Editar" : "Crear"} Usuario</Title>
        <ModalUsuario {...props} />
    </Modal>

}