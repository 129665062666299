import React, { useEffect, useState } from 'react'
import { Button, Tooltip } from "antd"

import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'


export default function ViewButton({
    value = false,
    onChange,
    tooltip = '¿El comisionista tiene permiso sobre este cliente / vendedor?',

    open = <EyeOutlined />,
    close = <EyeInvisibleOutlined />,
    ...props
}) {

    const [active, setActive] = useState(value)

    useEffect(() => {
        if (active != value)
            setActive(value)
    }, [value])

    const triggerChange = (value) => {
        setActive(value)
        if (onChange)
            onChange(value)
    }

    return <Tooltip title={<>
        {tooltip}
        <br />
        <strong>{value ? "Si" : "No"}</strong>
    </>}>
        <Button
            type={value ? 'primary' : 'ghost'}
            icon={value ? open : close}
            onClick={() => triggerChange(!active)}
            {...props}
            className="btn-comision"
        />
    </Tooltip>
}

