import React from 'react'
import { Route, Routes } from "react-router-dom";
import Roles from '../../Components/Customer/Roles/Roles';

/**
 * 
 * @export
 * @function RouterRoles
 * @description Router for Roles routes 
 */
function RouterRoles(props) {
  return (
    <Routes>
      <Route path='/' element={<Roles {...props}/>}/>
    </Routes>
  )
}

export default RouterRoles