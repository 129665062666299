import React from 'react';
// import FilterProyecto from '../../../Widgets/Filtros/FilterProyecto';
// import FilterCategoria from '../../../Widgets/Filtros/FilterCategoria';
// import FilterRubro from '../../../Widgets/Filtros/FilterRubro';
import PreviewWrapper from '../PreviewWrapper';


const FilterPreview = ({ size }) => (
    <PreviewWrapper id="Filtros" title="Filtros" >
        <div className="components buttons" style={{ background: '#2D348C', padding: 10 }}>
            <div className="component-row">
                <div className="component-col">
                    {/*<FilterProyecto size={size} />*/}
                </div>

                <div className="component-col">
                    {/*<FilterCategoria size={size} />*/}
                </div>
                <div className="component-col">
                    {/*<FilterRubro size={size} />*/}
                </div>
            </div>
        </div>
    </PreviewWrapper>
);

export default FilterPreview;
