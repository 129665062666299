import React from 'react'

let Cuenta = React.createContext(null);
let SetCuenta = React.createContext(() => {});

export {
    Cuenta,
    SetCuenta
}

export default Cuenta;
