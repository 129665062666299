import React, { Component } from "react";
import { Row, Col, Modal, Space, Typography, Form, Input, message, Spin, Button, Select, Divider, Switch, Tabs } from 'antd';
import { SelectCliente, SelectInstituciones, SelectUsuario } from "../../../Widgets/Inputs/Selects";
import axios from "axios"
import moment from "moment";
import { getResponseError } from "../../../Utils";
import { User } from '../../../../Hooks/Logged';
import Decimal from 'decimal.js';

const { Title, Text } = Typography;
const { Option } = Select;

/**
 *
 *
 * @class FormCuenta
 * @extends {Component}
 */
export default class FormCuenta extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            errores_spei: [],
            errores_uso_cuenta: [],
            tipo_cuenta_default: null,
            tipo_cuenta_options: [],
            esClabe: true,


        }
        window.x = message
    }

    formRef = React.createRef();

    componentDidMount() {

        
console.log("this.props.user?.rol_id?.tipo", this.props.user?.rol_id?.tipo);
        
        if(this.props.data.cuenta){
            this.formRef.current.setFieldsValue({
                ...this.props.data.cuenta
            })
        }

        this.setTipoCuentaOptions()
    }


    /**
     * @memberof FormCuenta
     * @method submit
     * @description Se ejecuta al dar enter al formulario
     */
    submit = (values) => {
        this.props.nextStep({ cuenta: {...values}})
    }

    /**
     * @memberof FormCuenta
     * @method generar
     * @description Genera una CLABE aleatoria
     */
    generar = () => {
        this.setState({ loading: true })
        axios.get('/cuenta/generar', {

        }).then(({ data }) => {
            this.formRef.current.setFieldsValue({ cuenta: data })
        }).catch(error => {
            console.log("error", error)
            message.error(getResponseError(error.response, "Error al generar CLABE aleatoria"))
        }).finally(() => {
            this.setState({ loading: false })
        })

    }

    setTipoCuentaOptions = () => {
        let tipo_cuenta_options = [], tipo_cuenta_default = null

        if(this.props.tipo === "cliente"){
            tipo_cuenta_options.push({ value: 0, label: 'Personal' },{ value: 1, label: 'Cliente' })
        }

        if(this.props.tipo === "vendedor"){
            tipo_cuenta_options.push({ value: 2, label: 'Vendedor' })
        }

        if(this.props.tipo === "revendedor"){
            tipo_cuenta_options.push({ value: 3, label: 'Revendedor' })
        }


        this.setState({tipo_cuenta_options })

    }


    render() {

        let { tipo } = this.props;

        return (
            <>
                <Form
                    layout="vertical"
                    ref={this.formRef}
                    onFinish={this.submit}
                    initialValues={{
                        ...this.props.initialValues,
                        tipo: 40,
                        cuenta_id: this.props.cuenta_id,
                        dia_corte: moment().format("D") > 28 ? 1 : moment().format("D"),
                        tipo_cuenta: tipo === "cliente" ? 1 : tipo === "vendedor" ? 2 : tipo === "revendedor" ? 3 : null
                    }}
                    validateTrigger={["onChange", "onBlur"]}
                    onValuesChange={this.verificarReglas}
                    preserve={false}
                >
                    <Form.Item
                        name="cuenta_id"
                        noStyle
                    >
                        <Input type="hidden" />
                    </Form.Item>
                    <Spin spinning={this.state.loading}>

                        <Row align="center">
                            <Col xs={22} lg={20} >
                                <Form.Item
                                    name="tipo"
                                    hidden={true}
                                >
                                </Form.Item>
                                {this.state.esClabe && !this.props.cuenta_id ? <a onClick={e => this.generar()}>Generar CLABE</a> : null}
                                <Form.Item
                                    name="cuenta"
                                    requiredMark={true}
                                    label={this.props.cuenta_id ? "Cuenta" : <>Obligatorio</>}
                                    rules={[{
                                        required: true,
                                        message: "Por favor, seleccione la cuenta"
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Cuenta"
                                        disabled={this.props.cuenta_id}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="nombre"
                                    requiredMark={true}
                                    label={this.props.cuenta_id ? "Nombre" : <>Obligatorio</>}
                                    rules={[{
                                        required: true,
                                        message: "Por favor, Ingrese el nombre de la cuenta"
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Nombre"
                                    />
                                </Form.Item>



                                <Form.Item
                                    name="alias"
                                    requiredMark={true}
                                    label={this.props.cuenta_id ? "Alias" : <>Obligatorio</>}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Alias"
                                    />
                                </Form.Item>

                                <Row gutter={[16, 0]}>

                                    <Col xs={24} md={12}>
                                        <Form.Item
                                            name="tipo_cuenta"
                                            size="large"
                                            requiredMark={true}
                                            label={this.props.cuenta_id ? "Tipo Cuenta" : <>Obligatorio</>}
                                            rules={[{
                                                required: true,
                                                message: "Por favor, Seleccione el tipo de cuenta"
                                            }]}
                                        >
                                            <Select
                                                placeholder="Tipo de Cuenta"
                                                size="large"
                                                options={this.state.tipo_cuenta_options}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item
                                            name="dia_corte"
                                            size="large"
                                            requiredMark={true}
                                            label={"Dia de corte"}
                                            rules={[{
                                                required: true,
                                                message: "Por favor, Seleccione el origen del estado de cuenta"
                                            }]}
                                        >
                                            <Select
                                                placeholder="Dia del mes"
                                                allowClear
                                                size="large"
                                                //disabled={this.props.cuenta_id}
                                            >
                                                <Option value="1">1</Option>
                                                <Option value="2">2</Option>
                                                <Option value="3">3</Option>
                                                <Option value="4">4</Option>
                                                <Option value="5">5</Option>
                                                <Option value="6">6</Option>
                                                <Option value="7">7</Option>
                                                <Option value="8">8</Option>
                                                <Option value="9">9</Option>
                                                <Option value="10">10</Option>
                                                <Option value="11">11</Option>
                                                <Option value="12">12</Option>
                                                <Option value="13">13</Option>
                                                <Option value="14">14</Option>
                                                <Option value="15">15</Option>
                                                <Option value="16">16</Option>
                                                <Option value="17">17</Option>
                                                <Option value="18">18</Option>
                                                <Option value="19">19</Option>
                                                <Option value="20">20</Option>
                                                <Option value="21">21</Option>
                                                <Option value="22">22</Option>
                                                <Option value="23">23</Option>
                                                <Option value="24">24</Option>
                                                <Option value="25">25</Option>
                                                <Option value="26">26</Option>
                                                <Option value="27">27</Option>
                                                <Option value="28">28</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={24}>
                                        { this.props.user?.rol_id?.tipo === 5 ? null : <Form.Item
                                            name="dias_prueba"
                                            size="large"
                                            requiredMark={true}
                                            label={this.props.cuenta_id ? "Dias de prueba" : <>Obligatorio</>}
                                            rules={[{
                                                required: true,
                                                message: "Por favor, Seleccione los dias de prueba de la cuenta"
                                            }]}
                                        >
                                            <Select
                                                placeholder="Dias de prueba"
                                                allowClear
                                                size="large"
                                                //disabled={this.props.cuenta_id}
                                            >
                                                <Option value={0}>0</Option>
                                                { this.props.tipo === "cliente" ? <> 
                                                <Option value={10}>10</Option>
                                                <Option value={20}>20</Option>
                                                <Option value={30}>30</Option>
                                                <Option value={40}>40</Option>
                                                <Option value={50}>50</Option>
                                                <Option value={60}>60</Option>
                                                <Option value={70}>70</Option>
                                                <Option value={80}>80</Option>
                                                <Option value={90}>90</Option> </> : <>
                                                    
                                                    <Option value={1}>1</Option>
                                                    <Option value={2}>2</Option>
                                                    <Option value={3}>3</Option>
                                                    <Option value={4}>4</Option>
                                                    <Option value={5}>5</Option>
                                                    <Option value={6}>6</Option>
                                                    <Option value={7}>7</Option>
                                                    <Option value={8}>8</Option>
                                                    <Option value={9}>9</Option>
                                                    <Option value={10}>10</Option>
                                                </> }

                                            </Select>
                                        </Form.Item> }
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                        <Row align="center">
                            <Col xs={22} lg={20} >
                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                    <Button
                                        style={{width: "250px"}}
                                        size="large"
                                        className="mt-2"
                                        onClick={()=>this.props.backStep()}
                                    >
                                        Atras
                                    </Button>
                                    <Form.Item >
                                        <Button
                                            htmlType="submit"
                                            type="primary"
                                            style={{width: "250px"}}
                                            size="large"
                                            className="mt-2"
                                            disabled={!this.state.errores_spei.length == 0 || !this.state.errores_uso_cuenta.length == 0}>
                                            Aceptar
                                        </Button>
                                    </Form.Item>

                                </div>
                            </Col>
                        </Row>
                    </Spin>
                </Form>
            </>
        )
    }
}
