import React, { useState, useEffect } from 'react';
import { Select, message, Typography } from 'antd'
import { UserOutlined } from '@ant-design/icons';
import axios from 'axios'
import { getResponseError } from '../../Utils';


const { Option } = Select
const { Text } = Typography

/**
 * @const SelectBeneficiario
 * @description Select para los beneficiarios registrados en el sistema
 */
const SelectBeneficiario = (props) => {

    const {
        value,
        onChange,
        placeholder = "Seleccione el Beneficiario",
        onSelect = () => { },
        params = {},
        onClear = () => { },
        suffixIcon = null,
    } = props

    const [beneficiarios, setBeneficiarios] = useState({
        data: [],
        page: 1,
        limit: 50,
        total: 0,
        search: null,
    })

    console.log('beneficiarios', beneficiarios)
    const [cliente_id, setCliente] = useState(null)

    const [bandera, setBandera] = useState(false)
    /**
     * @const get
     * @description Obitiene las beneficiarios
     */
    const get = ({ page, limit, search } = beneficiarios) => {
    
        axios.get('/beneficiarios', {
            params: {
                page,
                limit,
                search,
                ...params,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            setBeneficiarios({...data})
            
        }).catch(error => {
            console.log("ERR, error", error)
            message.error(getResponseError(error.response, 'Error al obtener los beneficiarios'))
        })
    }

    useEffect(() => {
        get()
    }, [])

    useEffect(() => {
        if (value)
            onChange(value?.value ?? value)
    }, [value])

    useEffect(() => {
        if (params.cliente_id != null || params.cliente_id != cliente_id) {
            get()
            setCliente(params.cliente_id)
        }
    }, [params.cliente_id])
    return (
        <Select
            placeholder={placeholder}
            {...props}
            onSearch={(search) => get({ search })}
            onSelect={(b) => {
                onChange(b)
                onSelect(b)
            }}

            onClear={() => {
                onClear()
                onChange(undefined)
                onSelect(undefined)
            }}
            
            suffixIcon={suffixIcon ? <UserOutlined style={{ fontSize: '18px' }} /> : null}
            filterOption={false}
        >
            {   
                beneficiarios?.data?.map(({ _id, nombre, cuenta, alias }) => <Option value={_id} key={_id}>
                    <Text strong>{nombre ?? ""} </Text>
                    &nbsp;   <small>{cuenta ?? ""}</small>
                    {console.log('rendering..', nombre)}
                </Option>)
            }
        </Select>
    );
}



export default SelectBeneficiario;