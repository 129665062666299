import React, { Component } from "react";
import { Row, Col, Modal, Typography, Form, Input, message, Spin, Button } from 'antd';

import { EmailInput } from "../../Widgets/Inputs/Inputs"
import { SelectRol, SelectCuenta } from "../../Widgets/Inputs/Selects";
import { AvatarLoader } from "../../Widgets/Uploaders";
import { getResponseError } from "../../Utils";
import axios from "axios"

const { Title } = Typography;


class ModalUsuario extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            tiene_hijo: false
        }
    }

    modalUsuario = React.createRef();

    componentDidMount() {
        if (this.props.usuario_id) {
            this.getUsuario()
        }
            console.log("this.props.cliente_id", this.props.cliente_id);
        if (this.props.cliente_id) {
            this.modalUsuario.current.setFieldsValue({
                cliente_id: {
                    value: this.props.cliente_id,

                }
            })
        }
    }

    /**
     * @memberof ModalUsuario
     * @method getUsuario
     * @description Obtiene informacion del usuario
     */
    getUsuario = () => {

        this.setState({ loading: true })
        axios.get('/usuarios/' + this.props.usuario_id, {})
            .then(response => {
                console.log('usuario get', response.data)
                let usuario = response.data
                this.setState({ tiene_hijo: usuario.tiene_hijo })
                this.modalUsuario.current.setFieldsValue({
                    ...response.data,
                    rol_id: usuario.rol_id?._id ? {
                        label: usuario.rol_id?.nombre,
                        value: usuario.rol_id?._id,
                    } : null,
                    avatar: usuario.avatar ? {
                        uid: usuario.avatar.file,
                        name: usuario.avatar.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/usuarios/" + this.props.usuario_id + "?avatar=" + usuario.avatar.file + "&Authorization=" + sessionStorage.getItem("token")
                    } : null,
                    cuentas_id: usuario?.cuentas_id?.map( e => ({
                        value: e.cuenta_id?._id,
                        label: <>{e?.cuenta_id?.nombre ?? ""} <small className='text-gray'>{e?.cuenta_id?.cuenta ?? ""} </small></>
                    }))

                })
            }).catch(error => {
                message.error(getResponseError(error.response, "Error al obtner el usuario"))
            }).finally(() => {
                this.setState({ loading: false })
            })

    }

    /**
     * @memberof ModalUsuario
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {
        console.log("values", values);

        const formData = new FormData()
        formData.appendMultiple({
            ...values,
            avatar: undefined,
            usuario_id: this.props.usuario_id,
            cliente_id: this.props.cliente_id,
            cuentas_id: undefined,
        })

        if (values.avatar) {
            formData.append("avatar", values.avatar, values.avatar.name)
        }

        if (Array.isArray(values.cuentas_id) && values.cuentas_id?.length > 0) {
            for(let cuenta of values.cuentas_id){
                formData.append("cuentas_id", cuenta.value)
            }
        }else{
            formData.append("delete_cuentas", true)
        }

        if (this.props.usuario_id) {
            this.updateUsuario(formData)
        } else {
            this.addUsuario(formData)
        }

    }

    /**
     * @memberof ModalUsuario
     * @method addUsuario
     * @description Crea un nuevo usuario en el sistema
     */
    addUsuario = (values) => {
        this.setState({ loading: true })

        axios.post('/usuarios', values, {
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(response => {
                message.success('Usuario Añadido')
                this.props.onClose(true)
            }).catch(error => {
                console.log(error)
                message.error(getResponseError(error.response, "Error al añadir el usuario"))
            }).finally(() => this.setState({ loading: false }))

    }

    /**
     * @memberof ModalUsuario
     * @method updateUsuario
     * @description Crea un nuevo usuario en el sistema
     */
    updateUsuario = (values) => {
        this.setState({ loading: true })

        axios.put('/usuarios', values)
            .then(response => {
                message.success('Usuario Editado')
                this.props.onClose(true)
            }).catch(error => {
                message.error(getResponseError(error.response, "Error al editar el usuario"))
            }).finally(() => this.setState({ loading: false }))

    }






    render() {
        return (
            <Form
                layout="vertical"
                ref={this.modalUsuario}
                onFinish={this.onFinish}
            >
                <Spin spinning={this.state.loading}>

                    <Row align="center">
                        <Col xs={22} lg={20} >
                            <Form.Item
                                name="avatar"
                                className="center"
                            >
                                <AvatarLoader
                                    imageCrop={true}
                                    onRemove={() => {
                                        axios.put('/usuarios', {
                                            usuario_id: this.props.usuario_id,
                                            delete_avatar: true
                                        })
                                    }}

                                />
                            </Form.Item>
                            <Form.Item
                                name="nombre_completo"
                                rules={[{
                                    required: true,
                                    message: "Por favor, Ingrese el nombre"
                                }]}
                            >
                                <Input
                                    size="large"
                                    placeholder="Nombre Completo"
                                />
                            </Form.Item>
                            <Form.Item
                                name="email"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la cuenta"
                                }]}
                            >
                                <EmailInput
                                    size="large"
                                    placeholder="Email"
                                />
                            </Form.Item>

                            <Form.Item
                                name="rol_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el rol"
                                }]}
                            >
                                <SelectRol
                                    size="large"
                                    placeholder="Rol"
                                    params={{ 
                                        tipo: 3,
                                        cliente_id: this.props.cliente_id // props.cliente es opcional
                                    }}
                                    disabled={this.state.tiene_hijo}
                                />
                            </Form.Item>

                            <Form.Item
                                name="cuentas_id"
                                
                            >
                                <SelectCuenta
                                    size="large"
                                    placeholder="Acceso a cuentas"
                                    mode="multiple"
                                    params={{ 
                                       cliente_id: this.props.cliente_id // props.cliente es opcional
                                    }}
                                />
                            </Form.Item>

                            <Form.Item >
                                <Button htmlType="submit" type="primary" block size="large" className="mt-2">
                                    Aceptar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function Modals(props) {
    const { visible = false, onClose = () => { }, usuario_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        destroyOnClose={true}
        zIndex={1000}
        footer={false}
        closable={false}
    >
        <Row>
            <Col span={24} className="center">
                <img src={"/img/BXNKLogo.png"} width="164" />
            </Col>
        </Row>
        <Title level={1} className="text-center mb-2 mt-1">{usuario_id ? "Editar" : "Crear"} Usuario</Title>
        <ModalUsuario {...props} />
    </Modal>

}