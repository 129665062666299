import React from 'react'
import { Route, Routes } from "react-router-dom";
import Beneficiarios from '../../Components/Admin/Beneficiarios/Beneficiarios';

/**
 * 
 * @export
 * @function RouterBeneficiarios
 * @description Router for beneficiarios routes 
 */
function RouterBeneficiarios(props) {
  return (
    <Routes>
      <Route path='/' element={<Beneficiarios  {...props}/>}/>
    </Routes>
  )
}

export default RouterBeneficiarios