import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


function convertToMoney(string = false) {
  try {
    if (this) {
      // Eliminamos cualquier coma que haya en el número y nos aseguramos de que solo tenga 2 decimales
      var r = this.toString().replace(',', '').match(/^-?\d+(?:\.\d{0,2})?/)[0]
      // Si el parámetro string es verdadero, devolvemos una cadena con separador de miles, Si no, devolvemos un número
      if(string === true){
        let formated = parseFloat(r).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        // Agregamos el símbolo de moneda y el separador decimal con dos decimales
        return formated + (formated.includes('.') ? '' : '.00');
      }else{
        return parseFloat(r)
      }
    }
    else
      return 0.00
  } catch (error) {
    return 0.00
  }
}

Number.prototype.toMoney = convertToMoney


function hideCardNumbers(bool) {

  if(bool === true) return this

  let text = this.toString()
  // Obtenemos los últimos 4 caracteres del texto
  const lastFourChars = text.slice(-4);
  // Reemplazamos todas las letras del texto, excepto los últimos 4 caracteres, por asteriscos
  const hiddenText = text.slice(0, -4).replace(/[^\s]/g, '*');
  // Concatenamos los últimos 4 caracteres con el texto oculto
  return hiddenText + lastFourChars;
}

String.prototype.hideCard = hideCardNumbers

FormData.prototype.appendMultiple = function (values) {
  for (const name in values) {
    if (values[name])
      if ("File" in window && values[name] instanceof File)
        this.append(name, values[name], values[name].name);
      else
        this.append(name, values[name]);
  }
};


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
