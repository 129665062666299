import React, { useState } from 'react'
import { Col, Row, message, Radio, Space, Typography, Modal, Pagination } from "antd";
import axios from 'axios';
import { CardListReglasTemplate } from '../../Widgets/Cards';
import { getResponseError } from "../../Utils"
const { Title } = Typography
/**
 *
 *
 * @class TemplatesList
 * @extends {React.Component}
 */
class TemplatesList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            template_id: null,
            templates: {
                page: 1,
                limit: 20,
                total: 0,
                pages: 0,
                search: undefined,
                data: [],
            },
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.get()
    }

    /**
     * @memberOf TemplatesList
     * @method get
     * @descripcion Obtiene los reglas registradas en el sistema
     * */
    get = ({
        page = this.state.templates.page,
        limit = this.state.templates.limit,
    } = this.state.templates) => {

        this.setState({ loading: true })
        axios.get('/templates', {
            params: {
                page,
                limit,
                activo: true,
                tipo: this.props.tipo
            }
        }).then(({ data }) => {
            console.log('data t', data)
            this.setState({
                templates: {
                    ...data,
                },
                loading: false,
            });
        }).catch(error => {
            console.log("error", error);
            message.error(getResponseError(error.response, "Error al obtener las reglas"))
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    /**
     *
     *
     * @memberof TemplatesList
     */
    onChange = ({ target: { value } }) => {
        this.setState({ template_id: value })
        this.props.setTemplate(value)
    }

    render() {

        return (
            <>
                <Radio.Group name="template" className='radio-templates width-100' size="large" onChange={this.onChange}>
                    <Space direction="vertical"  >
                        {(this.state.templates?.data.map((template, index) =>
                            <Radio value={template._id} className='radio-option' >
                                <CardListReglasTemplate
                                    key={template._id}
                                    index={index}
                                    reglas={template?.reglas}
                                    title={(this.props.tipo == 0) ? "Transacciones al mes" : "Uso de Cuenta"}
                                    onClick={null} />
                            </Radio>
                        ))}
                    </Space>
                </Radio.Group>
                <Pagination
                    current={this.state.templates.page}
                    pageSize={this.state.templates.limit}
                    total={this.state.templates.total}
                    hideOnSinglePage={true}
                />
            </>
        )
    }
}

export default function ModalTemplates(props) {

    const { visible = false, onClose = () => { }, onSelect = () => { } } = props

    const [template_id, setTemplate] = useState(null)
    return <Modal
        open={visible}
        onCancel={onClose}
        destroyOnClose={true}
        zIndex={1000}
        width={700}
        closable={true}
        onOk={() => onSelect(template_id)}
        cancelText="Cancelar"
        okText="Agregar Reglas "
    >
        <Row>
            <Col span={24} className="center">
                <img src={"/img/BXNKLogo.png"} width="164" />
            </Col>
        </Row>
        <Title level={4} className="center">Templates</Title>
        <TemplatesList {...props} setTemplate={setTemplate} />
    </Modal>


}