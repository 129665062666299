import React, { useEffect } from 'react'
import { Route, Routes } from "react-router-dom";
import { Layout } from 'antd';

//componentes
import ThemeEdit from '../Components/ThemeEdit/index'

import '../Styles/Theme/Public/antd-stp-public-theme.css'
import Login from '../Components/Auth/Login';
import Recovery from '../Components/Auth/Recovery';
import UpdatePassword from '../Components/Auth/UpdatePassword';
import ClientConfirm from '../Components/Auth/ClientConfirm';

/**
 * @function PublicRoutes
 * @description Router para rutas publicas
 * @export
 */
function PublicRoutes({ setUser }) {


	return (
		<Layout>
			<Routes>
				<Route path="" element={<Login />} />
				<Route path='password/recovery' element={<Recovery />} />
				<Route path='recovery/:email/:token' element={<UpdatePassword />} />
				<Route path='register/:email/:token' element={<UpdatePassword />} />
				<Route path='confirm/:email' element={<ClientConfirm />} />
				<Route path="theme" element={<ThemeEdit />} />
			</Routes>
		</Layout>
	)
}

export default PublicRoutes;