import React, { Component } from "react";
import { Row, Col, Modal, Space, Typography, Form, InputNumber, message, Spin, Button, Select, Divider, Switch } from 'antd';
import axios from "axios"
import Decimal from "decimal.js"

import ReglaList from "./ReglasList";
import ModalTemplates from "./TemplatesList";
import { User } from '../../../Hooks/Logged';
import { getResponseError } from "../../Utils";
import { SelectCliente, SelectInstituciones } from "../../Widgets/Inputs/Selects";

const { Option } = Select; 
const { Title, Text } = Typography;

/**
 *
 *
 * @class FormCuenta
 * @extends {Component}
 */
class FormCuenta extends Component {

    constructor(props) {
        super(props);
        this.state = {
        	loading: false,
            cuenta: {},
            tipo_regla: 0,
            comision: 0,
            monto_total: 0,
            errores_uso_cuenta: [],
            reglas_copia_initial: [],
        }
    }

    formRef = React.createRef();

    componentDidMount() {
        if (this.props.cuenta_id){
            this.getCuenta()
        }
    }

    /**
     * @memberof FormCuenta
     * @method getCuenta
     * @description Obtiene informacion de la cuenta
     */
    getCuenta = () => {
    	this.setState({ loading: true })
        axios.get('/cuenta', {
            params: {
                cuenta_id: this.props.cuenta_id
            }
        })
        .then(({ data }) => {
        	console.log("data", data);
        	let reglas_cuenta = data.reglas_comision.filter(regla => regla.tipo_regla === 1)
        	this.setState({
        		cuenta: data,
        		reglas_copia_initial: reglas_cuenta
        	})
        	this.formRef.current.setFieldsValue({
                reglas_uso_cuenta: reglas_cuenta,
            })
            
        }).catch(error => {
            console.log("error", error)
            message.error(getResponseError(error.response, "Error al obtener la cuenta"))
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

     /**
     * @memberof FormCuenta
     * @method onValuesChange
     * @description CAda vez que cambian los valores del formulario, realiza los calculos para el obro de comision
     */
    onValuesChange = (value, allFields) => {

    	let { monto_a_debloquear, monto_a_cobrar, tipo_regla } = allFields

    	if(monto_a_debloquear !== undefined && monto_a_debloquear !== null  && monto_a_cobrar !== undefined && monto_a_cobrar !== null && tipo_regla !== undefined){

    		let porcentaje = Decimal.div(monto_a_cobrar, 100)

    		let comision = tipo_regla === 0 ? Decimal(monto_a_cobrar).toDecimalPlaces(2).toNumber() : Decimal.mul(monto_a_debloquear, porcentaje).toDecimalPlaces(2).toNumber()

    		let monto_total = Decimal.sub(monto_a_debloquear, comision).toDecimalPlaces(2).toNumber() 

    		this.setState({
    			comision,
    			monto_total
    		})
    	}

        this.verificarReglas(value, allFields)
    	
    }

    /**
     * @memberof FormCuenta
     * @method onChangeSwitch
     * @description Cambia el status para mostrar las reglas, y vualve a cargar la informacion de las reglas iniciales
     */
    onChangeSwitch = (checked) => {
    	this.setState({
    		update_reglas: checked
    	})

    	if(checked){
    		this.formRef.current.setFieldsValue({
    			reglas_uso_cuenta: this.state.reglas_copia_initial
    		})
    	}
    }


     /**
     * @memberof FormCuenta
     * @method onFinish
     * @description Cambia el status para mostrar las reglas, y vualve a cargar la informacion de las reglas iniciales
     */
    onFinish = (values) => {
    	this.setState({loading: true})
    	axios.post('/cuenta/desbloquear',{
    		...values,
    		cuenta_id: this.props.cuenta_id
    	}).then(()=>{
    		message.success("Cuenta desbloqueada")
    		this.props.onClose(true)
    	}).catch(error => {
    		message.error(error?.response?.data?.message ?? "Error al desbloquear la cuenta")
    	}).finally(()=>this.setState({loading: false}))
    }


    /**
     *
     *
     * @memberof FormCuenta
     * @description verifica los limites de las reglas
     * @param {*} regla_actual
     * @param {*} regla_anterior 
     * @param {Number} index elemento de la lista
     * @param {Number} tipo tipo de regla
     */
    onChangeLimites = (regla_actual, regla_anterior, index, tipo) => {

        let li = regla_actual.limite_inferior;
        let ls = regla_actual.limite_superior;
        let ls_anterior = regla_anterior?.limite_superior ?? 0;
        let errores = {}

        errores[index] = {};

        //reglas de validaciones para limites superiores e inferiores
        if (li == undefined || (index === 0 && li < 0))
            errores[index]["limite_inferior"] = 'Ingrese el mínimo';
        if (!ls)
            errores[index]["limite_superior"] = 'Ingrese el máximo';

        if (li > ls)
            errores[index]["limite_inferior"] = 'El valor del límite inferior debe ser menor al límite superior';

        if (li < ls_anterior)
            errores[index]["limite_inferior"] = 'El valor del límite inferior debe ser mayor al límite superior anterior';

        if (index > 0 && li != ls_anterior + 1 )
            errores[index]["limite_inferior"] = 'El valor del límite inferior debe ser la continuación al límite superior anterior';

        if (ls < li)
            errores[index]["limite_superior"] = 'El valor del límite superior debe ser mayor al límite inferior';
        if (li == ls) {
            errores[index]["limite_inferior"] = 'Los límite deben ser diferentes';
            errores[index]["limite_superior"] = 'Los límite deben ser diferentes';
        }

        if(index === 0 && li > 1)
            errores[index]["limite_inferior"] = "No debe de ser mayor a 1"

        //reglas para monto y porcentajes
        // if (regla_actual.monto_absoluto == 0 && regla_actual.porcentaje == 0) {
        //     errores[index]["monto_absoluto"] = 'Se debe asignar un monto o porcentaje';
        //     errores[index]["porcentaje"] = 'Se debe asignar un porcentaje o monto';
        // }


        //almacen de errores en el state para renderizarlos en cada regla
        //almacen de errores en el state para renderizarlos en cada regla
        if (Object.keys(errores[index]).length > 0) {
            if (tipo == 0) {
                this.setState({ errores_spei: errores })
            }

            if (tipo == 1)
                this.setState({ errores_uso_cuenta: errores })
                console.log("errores", errores);

            return
        } else {

            if (tipo == 0) {
                this.setState({ errores_spei: [] })
            }

            if (tipo == 1)
                this.setState({ errores_uso_cuenta: [] })
        }
    }

    /**
     *
     *
     * @memberof FormCuenta
     * @description Revisa la cantidad de reglas creadas para deshabilitar los templates
     * se realiza de este modo ya que el form.list nunca esta vacio, por lo que se valida con un campo del form.list
     */
    ReglasExisten = (reglas) => {

        if (Array.isArray(reglas) && reglas.length > 0) {
            let tamano = reglas.filter(regla => regla?.tipo_regla != undefined).length;
            if (tamano > 0) {
                return true
            }
            return false
        }

        return false

    }

    /**
     *
     *
     * @memberof FormCuenta
     * @method verificarReglas
     * @description cada vez que se cambia un valor de un formulario se validan las reglas
     */
    verificarReglas = (changedValues, allFields) => {
        const { reglas_spei, reglas_uso_cuenta } = this.formRef.current?.getFieldsValue();

        if (reglas_spei && Array.isArray(reglas_spei))
            reglas_spei.forEach((regla, index) => {
                if (index >= 0 && regla) {
                    this.onChangeLimites(regla, reglas_spei[index - 1], index, 0)
                }

            });
        if (reglas_uso_cuenta && Array.isArray(reglas_uso_cuenta))
            reglas_uso_cuenta.forEach((regla, index) => {
                if (index >= 0 && regla) {
                    this.onChangeLimites(regla, reglas_uso_cuenta[index - 1], index, 1)
                }
            });


        let disabled_spei = this.ReglasExisten(reglas_spei)
        let disabled_uso_cuenta = this.ReglasExisten(reglas_uso_cuenta)

        this.setState({
            disabled_templates_spei: disabled_spei,
            disabled_templates_uso_cuenta: disabled_uso_cuenta
        })


    }

    onChangePorcentaje = (index, key) => {

        let values = this.formRef.current.getFieldsValue()
        let reglas = values[key]

        //Si es de clase hibrida se puede ambos
        if (reglas[index].clase === 2) return;

        reglas[index].monto_absoluto = 0

        this.formRef.current.setFieldsValue({
            [key]: reglas
        })

    }


    onChangeMonto = (index, key) => {
        let values = this.formRef.current.getFieldsValue()
        let reglas = values[key]

        //Si es de clase hibrida se puede ambos
        if (reglas[index].clase === 1) return;

        reglas[index].porcentaje = 0

        this.formRef.current.setFieldsValue({
            [key]: reglas
        })
    }

    /**
    * @memberof FormCuenta
    * @method onChangeClase
    * @description Cuando se cambia el valor de la clase, pone el porcentaje o el monto en 0
    */
    onChangeClase = (index, key) => {
        let values = this.formRef.current.getFieldsValue()
        let reglas = values[key]

        if (reglas[index].clase === 0) {
            reglas[index].porcentaje = 0
        }

        if (reglas[index].clase === 1) {
            reglas[index].monto_absoluto = 0
        }

        this.formRef.current.setFieldsValue({
            [key]: reglas
        })
    }





    render() {

    	const { cuenta, tipo_regla, comision, monto_total, update_reglas } = this.state;

        return (
            <>
            	<Spin spinning={this.state.loading}>
	                <Form
	                    layout="vertical"
	                    ref={this.formRef}
	                    onFinish={this.onFinish}
	                    preserve={false}
	                    initialValues={{
	                    	tipo_regla: 0
	                    }}
	                   	onValuesChange={this.onValuesChange}
	                >
	                    <Row justify="center" gutter={[16,8]}>
	                    	<Col span={20} className="center">
	                    		<Text strong className="font-20 mb-1">Saldo bloqueado:  $ {cuenta.saldo_bloqueado?.toMoney(true)} mxn </Text>
	                    	</Col>
	                    	<Col span={20}>
	                    		<Form.Item
	                                name="monto_a_debloquear"
	                                rules={[{
	                                    required: true,
	                                    message: "Por favor, Ingrese el monto a desbloquear"
	                                }]}
	                            >
	                                <InputNumber
	                                    size="large"
	                                    placeholder="Monto a desbloquear"
	                                    className="width-100"
	                                    prefix={"$"}
	                                    min={1}
	                                    max={cuenta.saldo_bloqueado}
	                                    addonAfter="MXN"
			                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
			                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
			                            precision={2}
	                                />
	                            </Form.Item>
	                    	</Col>
	                    	<Col span={12}>
	                    		<Form.Item
	                                name="monto_a_cobrar"
	                                rules={[{
	                                    required: true,
	                                    message: "Por favor, Ingrese el monto a cobrar"
	                                }]}
	                            >
	                                <InputNumber
	                                    size="large"
	                                    placeholder="Monto a cobrar"
	                                    className="width-100"
	                                    prefix={tipo_regla === 1 ? null : "$"}
	                                    min={0}
	                                    max={tipo_regla === 1 ? 100 : null}
	                                    addonAfter={tipo_regla === 1 ? "%" : "MXN"}
			                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
			                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
			                            precision={2}
	                                />
	                            </Form.Item>
	                    	</Col>
	                    	<Col span={8}>
	                    		<Form.Item
	                                name="tipo_regla"
	                                rules={[{
	                                    required: true,
	                                    message: "Por favor, Seleccione el tipo de regla"
	                                }]}
	                            >
	                                <Select
	                                	size="large"
	                                	placeholder="Metodo de cobro"
	                                	onSelect={(value)=>this.setState({tipo_regla: value })}
	                                >
	                                	<Option value={0}>Monto</Option>
	                                	<Option value={1}>Porcentual</Option>
	                                </Select>
	                            </Form.Item>
	                    	</Col>
	                    	<Col span={10}>
	                    		<Text strong style={{fontSize: "16px"}}>Comisión:  $ {comision.toMoney(true)} </Text>
	                    	</Col>
	                    	<Col span={10} className="flex-right">
	                    		<Text strong style={{fontSize: "16px"}}>Monto a Ingresar:  $ {monto_total.toMoney(true)} </Text>
	                    	</Col>
	                    	<Col span={20}>
	                    		<Form.Item 
	                    			label="Actualizar esquema de cobro (uso de cuenta)" 
	                    			valuePropName="checked"
	                    			name="update_reglas"
	                    		>
									<Switch 
										onChange={(checked)=>this.onChangeSwitch(checked)}
									/>
								</Form.Item>
	                    	</Col>
	                    	<Col span={20}>
	                    		{update_reglas ? <ReglaList
	                                tipo={1}
	                                tipo_name="Uso Cuenta (Ingreso)"
	                                name="reglas_uso_cuenta"
	                                errores={this.state.errores_uso_cuenta}
	                                showTemplates={() => this.setState({ modal_visible: true, tipo: 1 })}
	                                showButtonTemplate={false}
	                                disablebtnTemplate={false}
	                                verificarReglas={this.verificarReglas}
	                                onChangePorcentaje={(index) => this.onChangePorcentaje(index, "reglas_uso_cuenta")}
	                                onChangeMonto={(index)=>this.onChangeMonto(index, "reglas_uso_cuenta")}
                                    onChangeClase={(index) => this.onChangeClase(index, "reglas_uso_cuenta")}
                                    //le mando las reglas de uso de cuenta para ver la clase, y asi mostrar o quitar campos
                                    reglas={this.state.cuenta?.reglas_comision?.filter(e => e.tipo_regla === 1)}

	                            /> : null }
	                    	</Col>
	                    	<Col span={20} >
	                            <Form.Item >
	                                <Button
	                                    htmlType="submit"
	                                    type="primary"
	                                    block
	                                    size="large"
	                                    className="mt-2"
	                                >
	                                    Aceptar
	                                </Button>
	                            </Form.Item>
	                        </Col>
	                    </Row>
	                </Form>
                </Spin>
            </>
        )
    }
}



export default function ModalCuenta(props) {

    let user = React.useContext(User)

    const { visible = false, onClose = () => { }, cuenta_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        destroyOnClose={true}
        zIndex={1000}
        footer={false}
        closable={false}
        width={900}
    >
        <Row>
            <Col span={24} className="center">
                <img src={"/img/BXNKLogo.png"} width="64" />
            </Col>
        </Row>
        <Title level={2} className="text-center mb-2 mt-1">Descongelar Cuenta</Title>
        <FormCuenta {...props} user={user}/>
    </Modal>

}