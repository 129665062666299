import React from 'react'
import { Layout, Col, Row, Typography, List, Card, Space, message, Button, Popconfirm } from "antd";
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom'
import { EditOutlined, DeleteOutlined, ArrowLeftOutlined } from '@ant-design/icons';

import { User } from '../../../Hooks/Logged';
import usePermissions from '../../../Hooks/usePermissions';
import { getResponseError } from '../../Utils';
import FloatingButton from '../../Widgets/FloatingButton/FloatingButton';
import useSearch from '../../../Hooks/Search';
const { Content } = Layout;
const { Title, Text } = Typography

class CuentaReglas extends React.Component {

    constructor(props) {
        super(props)
        let { cuenta_id } = this.props.params;

        this.state = {
            loading: false,
            searching: true,
            filtroSearch: '',
            reglas: {
                page: 1,
                limit: 10,
                total: 0,
                pages: 0,
                search: undefined,
                data: [],
            },
            cuenta_id,
            cuenta_regla_id: null,
        }
    }

    componentDidMount() {

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.get()
    }


    /**
    * @method componentDidUpdate
    * @description Actualiza la vista si se buscan datos
    */
    componentDidUpdate() {

        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching == true) {
            this.get();
            this.state.searching = false;
        }
    }
    /**
     * @memberof CuentaReglas
     * @method get
     * @description Obtiene informacion de la cuenta
     */
    get = ({
        page = this.state.reglas.page,
        limit = this.state.reglas.limit,
        search = this.props.search
    } = this.state.reglas) => {

        this.setState({ loading: true })
        axios.get('/cuenta/reglas', {
            params: {
                cuenta_id: this.state.cuenta_id,
                page,
                limit,
                search
            }
        })
            .then(({ data }) => {
                console.log('dataaa', data)

                this.setState({
                    reglas: {
                        ...data,
                    },
                    loading: false,
                    searching: true,
                    filtroSearch: search
                });
            }).catch(error => {
                console.log("error", error)
                message.error(getResponseError(error.response, "Error al obtener las reglas"))
            }).finally(() => {
                this.setState({ loading: false })
            })
    }



    render() {


        return (
            <>
                <Content className="p-1">
                    <Row>
                        <Col span={24} className="" style={{ paddingRight: '0.5rem' }}>
                            <Row className='main'>
                                <Col xs={24}>
                                    <Title level={5}> Reglas de Cuenta </Title>
                                    <Button icon={<ArrowLeftOutlined />} onClick={() => this.props.navigate(-1)}></Button>
                                </Col>
                                <Col xs={24}>
                                    <List
                                        loading={this.state.loading}
                                        className="component-list"
                                        itemLayout="horizontal"
                                        locale={{ emptyText: "Sin Reglas para esta cuenta" }}
                                        dataSource={this.state.reglas?.data}
                                        pagination={{
                                            current: this.state.reglas.page,
                                            pageSize: this.state.reglas.limit,
                                            total: this.state.reglas.total,
                                            position: 'bottom',
                                            className: "flex-left",
                                            onChange: (page, limit) => {
                                                this.get({ page, limit })
                                            },

                                        }}
                                        header={<Row className="header-list width-100 ">
                                            <Col xs={6}>
                                                <Text>Nombre</Text>
                                            </Col>
                                            <Col xs={6} className="center">
                                                <Text>Descripción</Text>
                                            </Col>
                                            <Col xs={4} className="center">
                                                <Text>Activo</Text>
                                            </Col>
                                            <Col xs={4} className="center">
                                                <Text>Tipo</Text>
                                            </Col>

                                        </Row>
                                        }

                                        renderItem={(item, index) => (
                                            <List.Item className="component-list-item" key={item._id}>
                                                <Card className="card-list width-100" bordered={false}>
                                                    <Row className="width-100" align={"middle"}>
                                                        <Col xs={6} >
                                                            <Text>{item.nombre}</Text>
                                                        </Col>
                                                        <Col xs={6} className="center">
                                                            <Text>{item.descripcion}</Text>
                                                        </Col>
                                                        <Col xs={4} className="center">
                                                            <Text>{item.activo ? 'Activo' : 'Inactivo'}</Text>
                                                        </Col>
                                                        <Col xs={4} className="center">
                                                            <Text>{item.tipo == 1 ? 'Absoluto' : 'Porcentual'}</Text>
                                                        </Col>
                                                        <Col xs={4} className="center">
                                                            <Space>
                                                                <Button
                                                                    type="primary"
                                                                    icon={<EditOutlined />}
                                                                    onClick={() => this.setState({ modal_visible: true, cuenta_regla_id: item._id })}
                                                                    disabled={!this.props.editCuentas || index == 0}
                                                                />

                                                                <Popconfirm
                                                                    placement="topRight"
                                                                    title="¿Deseas eliminar esta regla?"
                                                                    onConfirm={() => axios.delete('/cuenta/regla', {
                                                                        params: {
                                                                            cuenta_regla_id: item._id,
                                                                            cuenta_id: this.state.cuenta_id
                                                                        }
                                                                    }).then((response) => {
                                                                        message.success('Eliminado Correctamente')
                                                                        this.get()
                                                                    })
                                                                        .catch((error) => {
                                                                            message.error(getResponseError(error.response,'No se pudo eliminar'));
                                                                        })
                                                                    }
                                                                    okText="Sí"
                                                                    cancelText="No"
                                                                    disabled={!this.props.deleteCuentas}
                                                                >
                                                                    <Button
                                                                        type="danger"
                                                                        icon={<DeleteOutlined />}
                                                                        disabled={!this.props.deleteCuentas || index == 0}
                                                                    />
                                                                </Popconfirm>
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </List.Item>
                                        )}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <FloatingButton onClick={() => this.setState({ modal_visible: true })} disabled={!this.props.createCuentas} />
                </Content >


            </>
        )
    }
}

export default function (props) {
    const params = useParams()

    let user = React.useContext(User)

    let permissions = usePermissions(user?.rol_id?.permisos, {
        editCuentas: ["cuentas", "edit"],
        createCuentas: ["cuentas", "create"],
        deleteCuentas: ["cuentas", "delete"]
    })

    const [search, setSearch] = useSearch();

    return (<CuentaReglas {...props} navigate={useNavigate()} search={search} params={params} {...permissions} />)
}