import React, { Component } from "react";
import { Layout, Menu, Row, Col } from 'antd';
import { useNavigate } from "react-router-dom"

import { RiPencilRuler2Line } from "react-icons/ri";

import { UserOutlined, KeyOutlined } from "@ant-design/icons";
import { FaSitemap, FaBook, FaUserFriends, FaDollarSign, FaUserTie, FaHandHoldingUsd } from "react-icons/fa"
import { AiOutlineUsergroupAdd } from "react-icons/ai"
import { IoWalletOutline } from 'react-icons/io5'
//componentes
import {
    IconDashboard,
    IconTransacciones
} from '../Widgets/Icons'
import { User } from '../../Hooks/Logged'
import usePermissions from '../../Hooks/usePermissions';

//css
import './sidebar.css'


const { Sider } = Layout;

/**
 *
 *
 * @export
 * @class Admin_Sidebar
 * @extends {Component}
 */
class AdminSidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            collapsed: false
        }
    }


    /**
     *
     *
     * @memberof AdminSidebar
     */
    toogle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }
    render() {
        return (
            <Sider
                width={100}
                collapsedWidth={0}
                breakpoint="lg"
                className="sidebar"
            >
                <Row>
                    <Col span={24} className="center mb-1">
                        <img src={"/img/BXNKLogo.png"} width="64" />
                    </Col>
                </Row>
                <Menu
                    mode="inline"
                    defaultSelectedKeys={['dashboard']}
                >

                    {this.props.dashboard ? <Menu.Item
                        title="Dashboard"
                        key="dashboard"
                        icon={<IconDashboard />}
                        onClick={() => this.props.navigate("/admin/dashboard",)} /> : null}

                    {this.props.transacciones ? <Menu.Item
                        title="Transacciones"
                        key="transacciones"
                        icon={<IconTransacciones />}
                        onClick={() => this.props.navigate("/admin/transacciones",)} /> : null}

                    {this.props.cuentas ? <Menu.Item
                        title="Cuentas"
                        key="cuentas"
                        icon={<KeyOutlined />}
                        onClick={() => this.props.navigate("/admin/cuentas",)} /> : null}

                    {this.props.clientes ? <Menu.Item
                        title="Clientes"
                        key="clientes"
                        icon={<IoWalletOutline />}
                        onClick={() => this.props.navigate("/admin/clientes",)} /> : null}

                    {this.props.beneficiarios ? <Menu.Item
                        title="Beneficiarios"
                        key="beneficiarios"
                        icon={<FaUserFriends />}
                        onClick={() => this.props.navigate("/admin/beneficiarios",)} /> : null}

                    {this.props.reglas ? <Menu.Item
                        title="Reglas"
                        key="reglas"
                        icon={<RiPencilRuler2Line />}
                        onClick={() => this.props.navigate("/admin/reglas",)} /> : null}

                    {this.props.bloqueVendedores ? <Menu.Item
                        title="Bloques Vendedores"
                        key="bloques-vendedores"
                        icon={<AiOutlineUsergroupAdd />}
                        onClick={() => this.props.navigate("/admin/bloques-vendedores",)} /> : null}

                    {this.props.usuarios ? <Menu.Item
                        title="Usuarios"
                        key="usuarios"
                        icon={<UserOutlined />}
                        onClick={() => this.props.navigate("/admin/usuarios",)} /> : null}

                    {this.props.roles ? <Menu.Item
                        title="Roles"
                        key="roles"
                        icon={<FaSitemap />}
                        onClick={() => this.props.navigate("/admin/roles",)} /> : null}

                    {this.props.bitacora ? <Menu.Item
                        title="Bitacora"
                        key="bitacora"
                        icon={<FaBook />}
                        onClick={() => this.props.navigate("/admin/bitacora",)} /> : null}

                    { this.props.cobranza ? <Menu.Item
                        title="Cobranza"
                        key="cobranza"
                        icon={<FaDollarSign />}
                        onClick={() => this.props.navigate("/admin/cobranza", )} /> : null }

                    {this.props.vendedores ? <Menu.Item
                        title="Vededores"
                        key="vendedores"
                        icon={<FaUserTie />}
                        onClick={() => this.props.navigate("/admin/vendedores", )} /> : null }
                    
                    {this.props.revendedores && <Menu.Item
                        title="Revendedores"
                        key="revendedores"
                        icon={<FaHandHoldingUsd />}
                        onClick={() => this.props.navigate("/admin/revendedores",)} /> }

                        
                </Menu>

            </Sider>
        )
    }
}

export default function View(props) {

    let user = React.useContext(User)

    let permissions = usePermissions(user?.rol_id?.permisos, {
        dashboard: ["dashboard", "read"],
        transacciones: ["transacciones", "access"],
        beneficiarios: ["beneficiarios", "access"],
        cuentas: ["cuentas", "access"],
        clientes: ["clientes", "access"],
        usuarios: ["usuarios", "access"],
        roles: ["roles", "access"],
        bitacora: ["bitacora", "access"],
        reglas: ["reglas", "access"],
        vendedores: ["vendedores", "access"],
        revendedores: ["revendedores", "access"],
        bloqueVendedores: ["bloques-vendedores", "access"],
        cobranza: ["cobranza"],

    })

    return <AdminSidebar navigate={useNavigate()} {...props} {...permissions} />
}

