import React, { useState } from "react";
import { Button, Modal, Radio } from 'antd';
// import ReglaList from "../Cuentas/ReglasList";
import BloquesVendedoresList from "./BloquesVendedoresList";
import { User } from '../../../Hooks/Logged';



export default function BloqueVendedor(props) {

    const { onAccept } = props

    const [visible, setVisible] = useState(false)
    const [value, setValue] = useState(false)

    const user = React.useContext(User)

    return <>
        {/* <Button onClick=></Button> */}

        { user?.rol_id?.tipo === 5 || user?.rol_id?.tipo === 4 ? null : <Button type="secondary" onClick={() => setVisible(!visible)} block className="mb-1">Usar Template</Button>}
        <Modal
            open={visible}
            onCancel={() => setVisible(false)}
            title="Selección de la Regla"
            destroyOnClose={true}
            onOk={() => {
                setVisible(false)
                if (onAccept)
                    onAccept(value)
            }}
            zIndex={1000}
            width={600}
        >

            <Radio.Group style={{ width: '100%' }}>
                <BloquesVendedoresList
                    option={true}
                    onSelectOption={e => {
                        setValue(e)//console.log(e);
                    }}
                />
            </Radio.Group>
            {/* <FormReglaTemplates {...props} onClose={() => setVisible(!visible)} /> */}
        </Modal>
    </>


}

