import React from 'react'
import { Layout, Col, Row, Pagination, Typography, message, } from "antd";
import axios from 'axios';
import { useSearchParams, useNavigate } from 'react-router-dom'

import { User } from '../../../Hooks/Logged'
import usePermissions from '../../../Hooks/usePermissions';
import { CardTemplateRegla, } from '../../Widgets/Cards';
import FloatingButton from '../../Widgets/FloatingButton/FloatingButton';
import DrawerTemplate from './DrawerTemplate';
import useSearch from '../../../Hooks/Search';
import { getResponseError } from '../../Utils'
const { Content } = Layout;
const { Title } = Typography

class Reglas extends React.Component {

    constructor(props) {
        super(props)
        let { page, limit, filters, search, sort } = this.props.params
        this.state = {
            loading: false,
            templates: {
                page: page ?? 1,
                limit: limit ?? 20,
                total: 0,
                pages: 0,
                search: search ?? undefined,
                data: [],
            },
            searching: true,
            filtroSearch: '',
            template_id: undefined,
            tipo: undefined
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        console.log('props', this.props)
        // this.props.updateFilterSearch(true);
        this.get()

    }



    /**
     * @memberOf reglas
     * @method get
     * @descripcion Obtiene los reglas registradas en el sistema
     * */
    get = ({
        page = this.state.templates.page,
        limit = this.state.templates.limit,
        search = this.props.search
    } = this.state.templates) => {

        this.setState({ loading: true })
        axios.get('/templates', {
            params: {
                page,
                limit,
                search,
            }
        }).then(({ data }) => {
            this.setState({
                templates: {
                    ...data,
                },
                loading: false,
                searching: true,
                filtroSearch: search
            });
        }).catch(error => {
            console.log("error", error);
            message.error(getResponseError(error.response, "Error al obtener las reglas"))
        }).finally(() => {
            this.setState({ loading: false })
        })
    }



    /**
     *
     *
     * @memberof Reglas
     */
    deleteTemplate = (id) => {

        axios.delete('/template', {
            params: {
                template_id: id
            }
        }).then((res) => {
            message.success('Eliminado Correctamente')
            this.get();
        })
            .catch((error) => {
                message.error(getResponseError(error.response,'No se pudo eliminar'))
            })
    }

    openDrawer = (id) => {
        this.setState({ template_id: id, drawer_reglas: true })
    }
    render() {

        return (
            <>
                <Content className="p-1">
                    <Row className="mb-3">
                        <Col span={24} className="">
                            <Row className='main'>
                                <Col xs={24}>
                                    <Title level={5}> Reglas  </Title>
                                    <Title level={5}> Templates de Reglas aplicables a todas las cuentas  </Title>
                                </Col>
                                <Row gutter={[16, 16]} className="width-100">
                                    {(this.state.templates?.data.map(template =>
                                        <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={24}>
                                            <CardTemplateRegla
                                                key={template._id}
                                                reglas={template?.reglas}
                                                tipo={template.tipo}
                                                activo={template.activo}
                                                onClick={() => this.openDrawer(template?._id)} onDelete={() => this.deleteTemplate(template?._id)} />
                                        </Col>))}
                                </Row>
                                <Pagination
                                    current={this.state.templates.page}
                                    pageSize={this.state.templates.limit}
                                    total={this.state.templates.total}
                                    hideOnSinglePage={true}
                                />
                            </Row>
                        </Col>
                    </Row>
                </Content>
                <FloatingButton onClick={() => this.setState({ drawer_reglas: true })} />

                <DrawerTemplate
                    visible={this.state.drawer_reglas}
                    onClose={() => {
                        this.setState({ drawer_reglas: false, template_id: null });
                        this.get()
                    }}
                    template_id={this.state.template_id}

                />

            </>
        )
    }
}

export default function (props) {

    let user = React.useContext(User)
    const [search, setSearch] = useSearch();
    const [params, setParams] = useSearchParams();

    let permissions = usePermissions(user?.rol_id?.permisos, {
        editReglas: ["reglas", "edit"],
        updateReglas: ["reglas", "update"],
        createReglas: ["reglas", "create"],
        deleteTemplates: ["reglas", "delete"],

    })

    return (<Reglas {...props} navigate={useNavigate()} search={search} setParams={setParams} params={params} {...permissions} />)
}