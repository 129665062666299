import React from 'react'
import { Layout, Col, Row, Typography, message, Tabs, Space, Button } from "antd";
import axios from 'axios';
import { ArrowLeftOutlined } from "@ant-design/icons"
import { useNavigate, useParams } from 'react-router-dom'

//Componentes
import Roles from './Listas/Roles';
import Reglas from './Listas/Reglas';
import Cuentas from './Listas/Cuentas';
import User from '../../../Hooks/Logged';
import Usuarios from './Listas/Usuarios';
import Comisiones from './Listas/Comisiones';
import { getResponseError } from '../../Utils';
import CardInfoCliente from './Cards/CardCliente';
import Beneficiarios from './Listas/Beneficiarios';
import Transacciones from './Listas/Transacciones';
import usePermissions from '../../../Hooks/usePermissions';


const { Content } = Layout;
const { TabPane } = Tabs;
const { Title } = Typography

class DetalleCliente extends React.Component {

    constructor(props) {
        super(props)
        let { cliente_id } = this.props.params
        this.state = {
            loading: false,
            cliente_id,
            cliente: {},
            cuenta: undefined,
            cuentas: [],
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getCliente()
    }

    /**
     * @memberOf DetalleCliente
     * @method getCliente
     * @descripcion Obtiene la informacion del cliente
     * */
    getCliente = () => {
        this.setState({ loading: true })
        axios.get('/cliente/' + this.state.cliente_id, {
            params:{
                usuario: true //obtiene info del usuario padre
            }
        }).then(({ data }) => {
            this.setState({
                cliente: data
            })
        }).catch(error => {
            message.error(getResponseError(error.response, "Error al obtener la información del cliente"))
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

     /**
     * @memberOf DetalleCliente
     * @method setCuenta
     * @descripcion Guarda en el state la cuenta selccionada, para que sea igual en todos los tabs
     * */
    setCuenta = (cuenta) => this.setState({cuenta})

    render() {

        return (
            <>

                <Content className="p-1">
                    <Row>
                        <Col span={24} className="" style={{ paddingRight: '0.5rem' }}>
                            <Row className='main'>
                                <Col xs={24}>
                                    <Space direction="horizontal" size="middle" align="baseline" className='pb-1'>
                                        <Button icon={<ArrowLeftOutlined />} onClick={(e) => this.props.navigate(-1)}></Button>
                                        <Title level={5}> Información de {this.state.cliente?.nombre} </Title>
                                    </Space>
                                </Col>

                                <Col span={24}>
                                    <CardInfoCliente cliente_id={this.state.cliente_id} />
                                </Col>
                                <Col xs={24} className="mt-2">
                                    <Tabs defaultActiveKey="1">
                                        {
                                            this.props.accessCuentas && (
                                                <TabPane tab="Cuentas" key="2">
                                                    <Cuentas cliente_id={this.state.cliente_id} cliente={this.state.cliente}/>
                                                </TabPane>
                                            )
                                        }
                                        {
                                            this.props.accessTransacciones && (
                                                <TabPane tab="Transacciones" key="1">
                                                    <Transacciones 
                                                        cliente_id={this.state.cliente_id} 
                                                        cuenta={this.state.cuenta}
                                                        setCuenta={this.setCuenta}
                                                    />
                                                </TabPane>
                                            )
                                        }
                                        {
                                            this.props.accessBeneficiarios && (
                                                <TabPane tab="Beneficiarios" key="3">
                                                    <Beneficiarios cliente_id={this.state.cliente_id} />
                                                </TabPane>
                                            )
                                        }
                                        {
                                            this.props.accessUsuarios && (
                                                <TabPane tab="Usuarios Designados" key="4">
                                                    <Usuarios cliente_id={this.state.cliente_id} usuario_padre={this.state.cliente?.usuario_id}/>
                                                </TabPane>
                                            )
                                        }
                                        {
                                            this.props.accessRoles && (
                                                <TabPane tab="Roles" key="5">
                                                    <Roles cliente_id={this.state.cliente_id} />
                                                </TabPane>
                                            )
                                        }
                                        <TabPane tab="Comisiones" key="6">
                                            <Comisiones 
                                                cliente_id={this.state.cliente_id} 
                                                cuenta={this.state.cuenta}
                                                setCuenta={this.setCuenta}
                                            />
                                        </TabPane>
                                        <TabPane tab="Reglas de Cuenta" key="7">
                                            <Reglas cliente_id={this.state.cliente_id} 
                                                cuenta={this.state.cuenta}
                                                setCuenta={this.setCuenta}
                                            />
                                        </TabPane>
                                    </Tabs>
                                </Col>

                            </Row>
                        </Col>
                    </Row>

                </Content >


            </>
        )
    }
}

export default function (props) {
    const params = useParams()

    let user = React.useContext(User)
    let permissions = usePermissions(user?.rol_id?.permisos, {
        accessCuentas: ["clientes", "cuentas", "access"],
        accessTransacciones: ["clientes", "transacciones", "access"],
        accessBeneficiarios: ["clientes", "beneficiarios", "access"],
        accessUsuarios: ["clientes", "usuarios", "access"],
        accessRoles: ["clientes", "roles", "access"]
    })

    return (<DetalleCliente {...props} navigate={useNavigate()} params={params} {...permissions} />)
}