import React from 'react'
import { Route, Routes } from "react-router-dom";

//componentes
import Clientes from '../../Components/Admin/Clientes/Clientes';
import DetalleCliente from '../../Components/Admin/Clientes/DetalleCliente';
import CuentaReglas from '../../Components/Admin/Cuentas/CuentaReglas';

/**
 * 
 * @export
 * @function RouterClientes
 * @description Routerde usuarios
 */
function RouterClientes(props) {
  return (
    <Routes>
      <Route path='/' element={<Clientes {...props}/>}/>
      <Route path='/:cliente_id' element={<DetalleCliente {...props}/>}/>
    </Routes>
  )
}

export default RouterClientes