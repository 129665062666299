import React, { useContext } from 'react'

let Search = React.createContext([]);
const useSearch = () => useContext(Search);

let ShowSearch = React.createContext([]);
const useShowSearch = () => useContext(ShowSearch);

export {
    useShowSearch,
    useSearch,
    Search,
    ShowSearch
}
export default useSearch;
