import React from 'react'
import { Layout, Col, Row, Button, Typography, Modal, List, Card, Statistic, Space, message, Tooltip } from "antd";
import axios from 'axios';
import moment from 'moment';
import { useSearchParams, useNavigate } from 'react-router-dom'
import {  FilePdfOutlined, CheckOutlined, CheckCircleOutlined, WarningOutlined, CloseCircleOutlined, EyeOutlined } from '@ant-design/icons';

import { Cuenta, SetCuenta } from '../../../Hooks/Cuentas';
import { User } from '../../../Hooks/Logged';
import useSearch from '../../../Hooks/Search';
import { getResponseError } from '../../Utils';
import { CardCuentaInfo } from '../../Widgets/Cards';
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import usePermissions from '../../../Hooks/usePermissions';
import DrawerFiltros from '../../Widgets/DrawerFiltros/DrawerFiltros';
import FloatingButton from '../../Widgets/FloatingButton/FloatingButton';

//Modal
import ModalTransferencia from '../../Admin/Transacciones/ModalTransferencia';
import ModalDetalle from '../../Admin/Transacciones/ModalDetalle';
import ModalEstadoCuenta from '../../Admin/Cuentas/ModalEstadoCuenta';



const { Content } = Layout;
const { Title, Text } = Typography

class Transacciones extends React.Component {

	constructor(props) {
		super(props)
		let { page, limit, filters, search } = this.props.params
		this.state = {
			loading: false,
			transacciones: {
				page: page ?? 1,
				limit: limit ?? 10,
				total: 0,
				pages: 0,
				search: search ?? undefined,
				filters: filters ?? [],
				data: [],
			},
			searching: true,
			transaccion_id: null,
			filtroSearch: '',
			beneficiarios: {
				page: 1,
				limit: 10,
				data: [],
				loading: false
			},
			transferir: false,
			capturar: false,
			liberar: false,
		}
	}

	componentDidMount() {

		if (this.props.cuenta?.cuenta) {
			this.getTransacciones()
			this.getCuenta()
		}
		this.getBeneficiarios()
	}

	componentDidUpdate(prevProps) {
		if (prevProps.cuenta?.cuenta !== this.props.cuenta?.cuenta) {
			this.getTransacciones()
		}

		const filtro = this.state.filtroSearch;
		const propFiltro = this.props.search;
		if (filtro !== propFiltro && this.state.searching === true) {
			this.getTransacciones();
			this.setState({searching: false})
		}
	}

	/**
	 * @memberOf Transacciones
	 * @method getTransacciones
	 * @descripcion Obtiene las transacciones relacionadas a un cuenta
	 * */
	getTransacciones = ({
		page = this.state.transacciones.page,
		limit = this.state.transacciones.limit,
		search = this.props.search,
		filters = this.state.transacciones.filters,
	} = this.state.transacciones) => {
		this.setState({ loading: true })

		axios.get('/transacciones', {
			params: {
				page,
				limit,
				cliente_id: this.props.user?.cliente_id,
				cuenta: this.props.cuenta?.cuenta,
				filters,
				search,
				tipo_dato: "local"
			}
		}).then(({ data }) => {
			this.setState({
				transacciones: {
					...data
				},
				searching: true,
				filters: filters,
				filtroSearch: search,

			})
		}).catch(error => {
			message.error(getResponseError(error.response, "Error al obtener las transacciones"))
		}).finally(() => {
			this.setState({ loading: false })
		})
	}

	/**
	 * @memberOf Transacciones
	 * @method getTransacciones
	 * @descripcion Obtiene informacion actualizada de la cuenta
	 * */
	getCuenta = () => {
		axios.get('/cuenta', {
            params: {
                cuenta_id: this.props.cuenta._id
            }
        }).then(({data})=> {
        	this.props.setCuenta(data)
        }).catch(error => {
        	console.log("error", error);

        })
	}


	/**
	 * @memberOf Transacciones
	 * @method getBeneficiarios
	 * @descripcion Obtiene los beneficiarios del usuario
	 * */
	getBeneficiarios = ({
		page,
		limit,
	} = this.state.beneficiarios, { beneficiarios } = this.state) => {
		this.setState({ beneficiarios: { ...beneficiarios, loading: true } })
		axios.get('/beneficiarios', {
			params: {
				page,
				limit
			}
		}).then(({ data }) => {
			this.setState({
				beneficiarios: { ...beneficiarios, ...data }
			})
		}).catch(error => {
			message.error(getResponseError(error.response, "Error al obtener los beneficiarios"))
		}).finally(() => {
			this.setState(state => { state.beneficiarios.loading = false; return state })
		})
	}


	/**
	 *
	 *
	 * @memberof Transacciones
	 * @description Envia la peticion al servidor para liberar transacciones
	 */
	solicitarLiberacion = () => {
		this.setState({ loading: true })
		axios.put('/transacciones/liberar', {
			cuenta: this.props.cuenta?.cuenta,
		}).then(res => {
			if (res.data.data.length > 0)
				Modal.info({
					title: 'Resumen de transacciones ejecutadas.',
					width: 800,
					content: (
						<List
							footer={null}
							dataSource={res.data.data}
							size="small"
							className='width-100'
							header={
								<Row className='width-100' justify="space-around">
									<Col> </Col>
									<Col span={6}>Concepto</Col>
									<Col span={8}>Descripción</Col>
									<Col span={8}>Estado</Col>
								</Row>
							}
							renderItem={item => (
								<List.Item>
									<Row className='width-100' justify="space-around">
										<Col>
											{(item.estado === 'LIQUIDADO') ? <CheckCircleOutlined style={{ color: '#51B56D' }} /> : null}
											{(item.estado === 'CANCELADO') ? <CloseCircleOutlined style={{ color: '#FF0000' }} /> : null}
											{(item.estado === 'PENDIENTE') ? <WarningOutlined style={{ color: '#d99816' }} /> : null}

										</Col>
										<Col span={6}>
											<Tooltip placement="top" title={item.transaccion_id}>
												{item.concepto}
											</Tooltip>
										</Col>
										<Col span={8}>{item.message}</Col>
										<Col span={8}>{item.estado}</Col>
									</Row>
								</List.Item>
							)}
						/>
					),
					onOk() { },
					okText: "Cerrar"
				});
			else
				message.info('No hay transacciones pendientes de ejecutar')

		}).catch(error => {
			console.log('error', error.response)
			message.error(getResponseError(error.response, 'Ocurrio un error'))
		}).finally(() => {
			this.setState({ loading: false })
			this.getTransacciones()
		})

	}

	getPDF = (transaccion_id) => {
		let urlPDF = new URL(axios.defaults.baseURL + '/transaccion/comprobante');
		urlPDF.searchParams.append('Authorization', sessionStorage.getItem('token'))
		urlPDF.searchParams.append('id', transaccion_id)
		urlPDF.searchParams.append('cuenta', this.props.cuenta?.cuenta)
		return urlPDF.href
	}

	generarPdf = (id, index) => {
		this.setState({loading: true})
		axios.get(this.getPDF(id), { responseType: 'blob', })
			.then(response => window.open(URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))))
			.catch(e => {
				message.error(e?.message || "Error al generar el ODF")
			})
			.finally(()=>this.setState({loading: false}))
	}



	render() {

		const contentMenuPlus = (
			<div className="flex-column">
				{this.props.transferirTransacciones ?
					<Button type="primary" className="width-100 mb-05" onClick={() => this.setState({ modal_visible: true, capturar: false, transferir: true })}>
						Realizar Transacción
					</Button>
					: null}
				{this.props.capturarTransacciones ?
					<Button type="primary" className="width-100" onClick={() => this.setState({ modal_visible: true, capturar: true, transferir: false })}>
						Programar Transacción
					</Button> : null}
			</div>
		);
		return (

			<>
				<Content className="pt-1 pb-1 pl-1">
					<Row gutter={[16, 16]} className="m-0">
						<Col xs={24} lg={17} className="">
							<Row className='main'>
								<Col xs={24}>
									<Title level={5}> Transacciones </Title>
									<Button onClick={() => { this.setState({ visibleFilters: true }) }}> Filtros </Button>
								</Col>
								<Col xs={24}>
									<List
										loading={this.state.loading}
										className="component-list"
										itemLayout="horizontal"
										locale={{ emptyText: "Sin Transacciones" }}
										dataSource={this.state.transacciones?.data}
										pagination={{
											current: this.state.transacciones.page,
											pageSize: this.state.transacciones.limit,
											total: this.state.transacciones.total,
											position: 'bottom',
											className: "flex-left",
											showSizeChanger: true,
											pageSizeOptions: [20, 50, 100, 500],
											showQuickJumper: true,
											locale: { jump_to: "Ir a la página", page: '' },
											onChange: (page, limit) => {
												this.getTransacciones({ page, limit })
											},
											onShowSizeChange: (page, limit) => {
												this.getTransacciones({ page, limit })
											}
										}}
										header={<Row className="header-list width-100 ">
											<Col xs={0} sm={0} md={0} lg={5} className=" center">
												<Text>Concepto</Text>
											</Col>
											<Col xs={0} sm={0} md={0} lg={3} className="center">
												<Text>Fecha</Text>
											</Col>
											<Col xs={0} sm={0} md={0} lg={3} className="center">
												<Text>Cuenta</Text>
											</Col>
											<Col xs={0} sm={0} md={0} lg={3} className="center">
												<Text>Beneficiario</Text>
											</Col>
											<Col xs={0} sm={0} md={0} lg={3} className="center">
												<Text ellipsis>Estado</Text>
											</Col>
											<Col xs={0} sm={0} md={0} lg={4} className="center">
												<Text>Monto</Text>
											</Col>
										</Row>
										}

										renderItem={(item, index) => (
											<List.Item className="component-list-item font-10">
												<Card className="card-list width-100" bordered={false}>
													<Row className="width-100" align={"middle"}>

														<Col xs={5} className="flex-left">
															
															<CustomAvatar
																color={item.tipo === 1 ? "#4caa21" : "#bb2a2a"}
																name={item.ordenante?.nombre}
															/>

															<Tooltip  title={item.concepto}>
																<Text ellipsis strong>{item.concepto}</Text>
															</Tooltip>
														</Col>
														<Col xs={3} className="center">
															<Tooltip  title={moment(item.fecha_operacion ?? item.createdAt).format("DD/MM/YYYY HH:mm")}>
																<Text ellipsis className="text-gray">{moment(item.fecha_operacion ?? item.createdAt).format("DD/MM/YYYY HH:mm")}</Text>
															</Tooltip>
														</Col>
														<Col xs={3} className="center">
															<Tooltip  title={item.ordenante?.nombre}>
																<Text ellipsis className="text-gray">{item.ordenante?.nombre}</Text>
															</Tooltip>
															
														</Col>
														<Col xs={3} className="center">
															<Tooltip  title={item.beneficiarios[0]?.nombre ?? "ND"}>
																<Text ellipsis className="text-gray">{item.beneficiarios[0]?.nombre ?? "ND"}</Text>
															</Tooltip>															
														</Col>
														<Col xs={3} className="center">
															<Tooltip  title={item.estado ?? "No Enviado a STP"}>
																<Text ellipsis className="text-gray">{item.estado ?? "No Enviado a STP"}</Text>
															</Tooltip>															
														</Col>
														<Col xs={4} className="center">
															<Statistic
																value={item.monto.toMoney(true)}
																precision={2}
																prefix={item.tipo === 2 ? "-$" : "$"}
																valueStyle={{ 
																	fontWeight: 'bold', 
																	fontSize: 16,
																	color: item.tipo === 1 ? "#4caa21" : "#bb2a2a"
																}}
															/>
														</Col>
														<Col xs={3} className="center">
															<Space>
																<Button
																	type="primary"
																	icon={<EyeOutlined />}
																	onClick={() => {
																		this.setState({ modal_detalle: true, transaccion_id: item._id,})
																	}}
																/>
																<Tooltip title="Descargar comprobante de transacción">
																	<Button
																		onClick={() => this.generarPdf(item._id, index)}
																		type="primary"
																		icon={<FilePdfOutlined />}
																	/>
																</Tooltip>
															</Space>
														</Col>
													</Row>
												</Card>
											</List.Item>
										)}
									/>
								</Col>
							</Row>
						</Col>
						<Col xs={24} md={24} lg={7} className="card">
							<CardCuentaInfo
								title={'Mi Cuenta'}
								subtitle={"Beneficiarios"}
								data={this.state.beneficiarios.data}
								loading={this.state.beneficiarios.loading}
								cuenta={this.props.cuenta}
								customer={true}
								openModal={()=> {
									this.setState({modal_visible_estado: true})
								}}
							/>
						</Col>
					</Row>
				</Content>


				{this.props.liberarTransacciones ? <FloatingButton
					icon={<CheckOutlined />}
					background="#3EA741"
					content={"Liberar Transacciones"}
					style={{ marginRight: '100px' }}
					onClick={this.solicitarLiberacion}
				/>
					: null
				}

				{(this.props.capturarTransacciones || this.props.transferirTransacciones) ?
					<FloatingButton
						content={contentMenuPlus}
					/> : null}


				<ModalTransferencia
					visible={this.state.modal_visible}
					onClose={(transaccion) => {
						this.setState({ modal_visible: false, transaccion_id: null })
						if(transaccion._id){
							this.getTransacciones()
							this.getCuenta()
						}
					}}
					cliente_id={this.props.user?.cliente_id}
					user_rol={this.props.user?.rol_id?.tipo}
					capturar={this.state.capturar}
					transferir={this.state.transferir}
					tipo={2} //egreso
					cliente={true}
				/>

				<ModalDetalle
					visible={this.state.modal_detalle}
					onClose={() => {
						this.setState({ modal_detalle: false, transaccion_id: null })
					}}
					transaccion_id={this.state.transaccion_id}
					transaccion={this.state.transaccion}
				/>

				<DrawerFiltros
					title="Filtrar Transacciones"
					ref={ref => this.drawerFiltros = ref}
					updateFilters={filters => this.getTransacciones({
						page: 1,
						filters
					})}
					onClose={() => this.setState({ visibleFilters: false })}
					visible={this.state.visibleFilters}
					cliente_id={this.props.user?.cliente_id}
					cuentas={false}
					clientes={false}
					beneficiarios={true}
					monto={true}
				/>

				<ModalEstadoCuenta
                    visible={this.state.modal_visible_estado}
                    onClose={() => {
                        this.setState({ modal_visible_estado: false })
                    }}
                    cuenta_id={this.props.cuenta?._id}
                />
			</>
		)
	}
}

export default function Componente (props) {

	const [params, setParams] = useSearchParams();
	let user = React.useContext(User)
	let cuenta = React.useContext(Cuenta)
	let setCuenta = React.useContext(SetCuenta)
	const [search, setSearch] = useSearch();

	let permissions = usePermissions(user?.rol_id?.permisos, {
		capturarTransacciones: ["transacciones", "capturar"],
		transferirTransacciones: ["transacciones", "transferir"],
		liberarTransacciones: ["transacciones", "liberar"],
	})

	return (<Transacciones 
		{...props} 
		search={search} 
		setSearch={setSearch}
		{...permissions} 
		user={user} 
		cuenta={cuenta} 
		setCuenta={setCuenta}
		navigate={useNavigate()} 
		setParams={setParams} 
		params={params} 
	/>)
}