import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'

import { IconCuenta } from '../Icons'
const { Option } = Select

/**
 * @const SelectTipoCuenta
 * @description Select para las cuentas registrados en el sistema
 */
const SelectTipoCuenta = (props) => {

    let {
        placeholder = "Tipo de Cuenta"
    } = props

    return (
        <Select {...props} placeholder={placeholder}>
            {[
                {
                    clave: '3',
                    nombre: 'Tarjeta de Débito',
                },
                {
                    clave: '10',
                    nombre: 'Teléfono Celular',
                },
                {
                    clave: '40',
                    nombre: 'CLABE',
                }
            ]?.map(({ nombre, clave }) => <Option value={clave} key={clave}>
                {nombre ?? ""}
            </Option>)}
        </Select>
    );
}



export default SelectTipoCuenta;