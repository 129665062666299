import React, { Component } from "react";
import { Row, Col, Form, Select, InputNumber, Input, Button, Typography, Tooltip } from 'antd';
import { PercentageOutlined, MinusOutlined, DollarOutlined } from "@ant-design/icons"

const { Option } = Select;

/**
 *
 *
 * @class ItemRegla
 * @extends {Component}
 */
export default class ItemRegla extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clase: this.props.cuenta_hija ? 1 : 0,
            actual: this.props.min_uso,
            monto_actual: this.props.min_spei
        }
    }

    componentDidMount() {
        if (this.props.regla?.clase !== undefined) {
            this.setState({
                clase: this.props.regla?.clase,
                actual: this.props.regla.porcentaje,
                monto_actual: this.props.regla.monto_absoluto
            })
        }
    }

    renderMessages = (error) => {
        return <span>{error ?? ""}</span>;
    }


    onChangePorcentaje = (value) => {
        if (value !== null && value !== undefined) {
            this.props.onChangePorcentaje(this.props.name)
            this.setState({ actual: value })
        }
    }

    onChangeMonto = (value) => {
        if (value !== null && value !== undefined) {
            this.props.onChangeMonto(this.props.name)
            this.setState({ monto_actual: value })
        }
    }

    onChangeClase = (clase) => {
        this.props.onChangeClase(this.props.name)
        this.setState({ clase })
    }

    renderMontoCampo = () => {

        const { tipo, index, name, errores } = this.props;

        let col = 7

        //Si el tipo es igual a uso de cuenta
        if (this.props.tipo === 1) col = 5

        //if(this.state.clase === 1) return

        return <Col xs={col} className="flex">
            <Form.Item
                name={[name, 'monto_absoluto']}
                validateStatus={(errores[index] != null && Object.entries(errores[index]).length > 0) ? "error" : "sucess"}
                help={this.renderMessages(errores[index]?.monto_absoluto)}
                initialValue={this.props.tipo === 1 ? 0 : this.props.min_spei ?? 3}
            >
                <InputNumber
                    prefix={"$"}
                    type="number"
                    placeholder="0.00"
                    className="width-100"
                    onChange={this.onChangeMonto}
                    min={0}
                    precision={2}
                    disabled={this.state.clase === 1}
                />
            </Form.Item>

        </Col>

    }


    renderPorcentajeMonto = () => {

        const { tipo, index, name, errores } = this.props;

        let col = 5

        if (this.props.tipo === 0) return
        return <Col xs={col} className="flex">
            <Form.Item
                name={[name, 'porcentaje']}
                validateStatus={(errores[index] != null && Object.entries(errores[index]).length > 0) ? "error" : "sucess"}
                help={this.renderMessages(errores[index]?.porcentaje)}
                initialValue={this.props.min_uso ?? 0}
            >
                <InputNumber
                    type="number"
                    placeholder="0"
                    className="width-100"
                    addonAfter={<PercentageOutlined />}
                    onChange={this.onChangePorcentaje}
                    precision={2}
                    min={0}
                    max={99}
                    disabled={this.state.clase === 0}
                />
            </Form.Item>

        </Col>

    }

    render() {
        const { tipo, index, name, errores } = this.props;

        return (
            <React.Fragment key={index}>
                <Row align="center" className="width-100" gutter={[8, 8]} >
                    <Form.Item
                        name={[name, '_id']}
                        noStyle
                    >
                        <Input type="hidden" />
                    </Form.Item>
                    <Form.Item
                        name={[name, 'tipo_regla']}
                        noStyle
                        initialValue={tipo}
                    >
                        <Input type="hidden" />
                    </Form.Item>
                    <Col xs={this.props.tipo === 1 ? 5 : 7} className="">
                        <Form.Item
                            name={[name, 'limite_inferior']}
                            initialValue={0}
                            validateStatus={(errores[index] != null && Object.entries(errores[index]).length > 0) ? "error" : "sucess"}
                            help={this.renderMessages(errores[index]?.limite_inferior)}
                        >
                            <InputNumber
                                prefix={this.props.tipo === 1 ? "$" : null}
                                min={0}
                                placeholder="0"
                                className="width-100"
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            />

                        </Form.Item>
                    </Col>
                    <Col xs={this.props.tipo === 1 ? 5 : 7} className="">
                        <Form.Item
                            name={[name, 'limite_superior']}
                            initialValue={1}
                            validateStatus={(errores[index] != null && Object.entries(errores[index]).length > 0) ? "error" : "sucess"}
                            help={this.renderMessages(errores[index]?.limite_superior)}
                        >
                            <InputNumber
                                prefix={this.props.tipo === 1 ? "$" : null}
                                min={1}
                                placeholder="0"
                                className="width-100"
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Form.Item>
                    </Col>
                    {this.renderMontoCampo()}
                    {this.renderPorcentajeMonto()}
                    {this.props.tipo === 1 ? <Col xs={3} className="">
                        <Form.Item
                            name={[name, 'clase']}
                            // validateStatus={(errores[index] != null && Object.entries(errores[index]).length > 0) ? "error" : "sucess"}
                            // help={this.renderMessages(errores[index]?.porcentaje)}
                            initialValue={this.props.cuenta_hija ? 1 : 0}
                        >
                            <Select
                                disabled={this.props.cuenta_hija}
                                onChange={this.onChangeClase}
                            >
                                <Option value={0}>Monto Fijo</Option>
                                <Option value={1}>Porcentual</Option>
                                <Option value={2}>Hibrido</Option>
                            </Select>
                        </Form.Item>
                    </Col> : null}

                    <Col span={this.props.tipo === 1 ? 1 : 3} className="text-center ">
                        <Button
                            icon={<MinusOutlined />}
                            onClick={() => { this.props.remove(name) }}
                            type="primary"
                            shape="circle"
                            size="small"
                            className="btn-delete-status"
                            title="Eliminar Regla" />

                    </Col>
                </Row>
                {this.props.min_spei ? <Row style={{marginTop: -5, marginBottom: 10}}>
                    <Col style={{ flex: 1 }}>
                        Monto Minimo: $ {this.props.min_spei.toMoney(true)} <br />
                    </Col>
                    <Col style={{ flex: 1 }}>
                        Monto Cobrado al Cliente: $ {this.state.monto_actual.toMoney(true)} <br />
                    </Col>
                    <Col style={{ flex: 1 }}>
                        Utilidad Neta: $ {(this.state.monto_actual - this.props.min_spei).toMoney(true)}
                    </Col>
                </Row> : null}
                {this.props.min_uso ? <Row style={{marginTop: -5, marginBottom: 10}}>
                    <Col style={{ flex: 1 }}>
                        Monto Minimo: {this.props.min_uso.toMoney(true)}% <br />
                    </Col>
                    <Col style={{ flex: 1 }}>
                        Monto Cobrado al Cliente: {this.state.actual.toMoney(true)}% <br />
                    </Col>
                    <Col style={{ flex: 1 }}>
                        Utilidad Neta: {(this.state.actual - this.props.min_uso).toMoney(true)} %
                    </Col>
                </Row> : null}
            </React.Fragment >
        )
    }
}

// {this.props.min_uso ? <Tooltip placement="topRight" title={<div>



// </div>}>
//     <DollarOutlined  style={{marginTop: "10px", marginLeft: "4px"}}/>
// </Tooltip> : null }
