import React, { Component } from "react";
import { Row, Col, Modal, Typography, Form, Input, message, Spin, Button, DatePicker, Select, Space, Switch } from 'antd';
import moment from "moment";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import axios from "axios"

const { Title, Text } = Typography;
const { Option } = Select;

class Configuracion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    modalConfifuracion = React.createRef();

    componentDidMount() {
        if (this.props.cliente_id) {
            this.getCliente()
        }
    }

    /**
     * @memberOf Configuracion
     * @method getCliente
     * @descripcion Obtiene la informacion del cliente
     * */
    getCliente = () => {
        this.setState({ loading: true })
        axios.get('/cliente/' + this.props.cliente_id, {
            params:{
            }
        }).then(({ data }) => {
            console.log("data", data);
            this.modalConfifuracion.current.setFieldsValue({...data})
        }).catch(error => {
            message.error(error.response.data.message ?? "Error al obtner al cliente")
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    /**
    * @memberof Configuracion
    * @method onFinish
    * @description Se ejecuta al dar aceptar al formulario
    */
    onFinish = (values) => {
        if (this.props.cliente_id) {
            this.updateCliente(values)
        } 
    }


    /**
     * @memberof Configuracion
     * @method updateCliente
     * @description Actualizala informacion del cliente
     */
    updateCliente = (values) => {
        this.setState({ loading: true })
        axios.put('/cliente', {
            cliente_id: this.props.cliente_id,
            ...values
        })
        .then(({ data }) => {
            message.success("Cliente actualizado")
            this.props.onClose()
        }).catch(error => {
            message.error(error.response.data.message ?? "Error al actualizar el cliente")
        }).finally(() => {
            this.setState({ loading: false })
        })

    }



    render() {
        return (
            <Form
                layout="vertical"
                ref={this.modalConfifuracion}
                onFinish={this.onFinish}
                onValuesChange={this.onValuesChange}
            >
                <Spin spinning={this.state.loading}>
                    <Row justify="center">
                        <Col xs={20} md={20}>
                            <Form.Item
                                name="endpoint"
                                requiredMark={true}
                                label={"Endpoint para envio de notificaciones"}
                                rules={[{
                                    required: true,
                                    message: "Por favor, el endpoint"
                                }]}
                            >
                                <Input
                                    
                                    placeholder="https://dominio.com/notificacion"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={20}>
                            <div className="mb-1">Lista de IPs permitidos</div>
                            <Form.List 
                                name="ips"
                            >
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <Row style={{marginBottom: '8px'}}>
                                                <Col span={20}>
                                                    <Form.Item
                                                        className="m-0"
                                                            {...restField}
                                                        name={name}
                                                        rules={[
                                                            { required: true, message: 'Ingrese una IP valida' },
                                                            {
                                                                pattern: new RegExp("^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)|(https?:\/\/[^\s/$.?#][^\s]*))$"),
                                                                message: 'Debe de ser un ip valida'
                                                            }
                                                        ]}
                                                >
                                                    <Input 
                                                        placeholder="198.0.0.1"
                                                    />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={4} className="center">
                                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                                </Col>
                                            </Row>
                                                
                                        ))}
                                        <Col span={24}>
                                            <Form.Item className="mt-1">
                                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                   Añadir IP
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </>
                                )}
                            </Form.List>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" block size="large" className="mt-2">
                                    Aceptar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function Modals(props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        destroyOnClose={true}
        zIndex={1000}
        footer={false}
        closable={false}
    >
        <Row>
            <Col span={24} className="center">
                <img src={"/img/BXNKLogo.png"} width="164" />
            </Col>
        </Row>
        <Title level={3} className="text-center mb-2 mt-1">Editar Configuración de Enlace Externo</Title>
        <Configuracion {...props} />
    </Modal>

}