import React, { Component } from 'react';
import { Row, Col, Card, Button, Popconfirm, Spin, PageHeader, Layout, message, Space, List, Typography } from 'antd'

import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import { EditOutlined, DeleteOutlined, MailOutlined } from '@ant-design/icons';


import FloatingButton from '../../Widgets/FloatingButton/FloatingButton';
import ModalRoles from './ModalRoles'

import useSearch from '../../../Hooks/Search';
import Logged from '../../../Hooks/Logged'
import usePermissions from '../../../Hooks/usePermissions';
import { getResponseError } from '../../Utils';

const { Content } = Layout;
const { Text, Title } = Typography;

/**
 * @export
 * @class Roles
 * @extends {Component}
 * @description Vista de Roles
 */
export class Roles extends Component {

	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			modal_visible: false,

			rol_id: undefined,
			roles: {
				data: [],
				limit: 10,
				page: 1,

				total: 0,
				pages: 0,
			},
			searching: true,
			filtroSearch: '',

		}
	}

	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.get()
	}

	/**
     * @method componentDidUpdate
     * @description Actualiza la vista si se buscan datos
     */
    componentDidUpdate() {

        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;
        if (filtro !== propFiltro && this.state.searching === true) {
            this.get();
            this.state.searching = false;
        }
    }

	/**
	 * @methodOf  Roles
	 * @method get
	 *
	 * @description Obtiene las Roles
	 * */
	get = ({
		page = this.state.roles.page,
		limit = this.state.roles.limit,
		search = this.props.search
	} = this.state.roles) => {
		this.setState({ loading: true, roles: { ...this.state.roles, page, limit } })
		axios.get('/roles', {
			params: {
				page,
				limit,
				search,
			}
		}).then(({ data }) => {

			this.setState({
				roles: { ...this.state.roles, ...data },
				loading: false,
                searching: true,
                filtroSearch: search
			});

		}).catch(error => {
			console.log(error)
			message.error(getResponseError(error.response, 'Error al traer la información'))
		})
	}

	render() {
		return (
			<>
				<Content className="p-1">
					<Row>
						<Col span={24}>
							<Row className='main'>
								<Col xs={24}>
									<Title level={5}> Roles </Title>
								</Col>
								<Col xs={24}>
									<List
										loading={this.state.roles.loading}
										className="component-list"
										itemLayout="horizontal"
										locale={{ emptyText: "Sin Roles " }}
										dataSource={this.state.roles.data}
										pagination={{
											current: this.state.roles.page,
											pageSize: this.state.roles.limit,
											total: this.state.roles.total,
											position: 'bottom',
											className: "flex-left",
											pageSizeOptions: [20, 50, 100, 500],
											showQuickJumper: true,
											locale: { jump_to: "Ir a la página", page: '' },
											onChange: (page, limit) => {
												this.get({ page, limit })
											},
											onShowSizeChange: (page, limit) => {
												this.get({ page, limit })
											}
										}}
										header={<Row className="header-list width-100 pr-1 pl-1" gutter={[16, 16]}>
											<Col xs={24} lg={10} className="center">
												<Text strong>Nombre</Text>
											</Col>
											<Col xs={24} lg={10} className="center">
												<Text strong>Descripción</Text>
											</Col>
										</Row>}

										renderItem={item => (
											<List.Item className="component-list-item">
												<Card className="card-list width-100">
													<Row className="width-100 " gutter={[16, 16]}>

														<Col xs={24} lg={10} className="center">
															<Text>{item.nombre}</Text>
														</Col>
														<Col xs={24} lg={10} className="center">
															<Text>{item.descripcion}</Text>
														</Col>
														<Col xs={24} lg={4} align="right">
															<Space size="small" direction="horizontal">
																<Button
																	type="primary"
																	disabled={!this.props.editRoles}
																	icon={<EditOutlined />}
																	title="Editar"
																	onClick={() => this.setState({ modal_visible: true, rol_id: item._id })}
																/>
																<Popconfirm
																	placement="topRight"
																	disabled={!item.editable || !this.props.deleteRoles}
																	title="¿Deseas eliminar este rol?"
																	onConfirm={() => axios.delete('/roles',
																		{ params: { rol_id: item._id } }).then(() => {
																			this.get()
																			message.success('Rol eliminado')
																		}).catch(error => {
																			message.error(getResponseError(error.response, "No se pudo eliminar"))
																		})}
																	okText="Sí"
																	cancelText="No"
																>
																	<Button
																		disabled={!item.editable || !this.props.deleteRoles   }
																		type="primary"
																		icon={<DeleteOutlined />}
																		title="Eliminar"
																		danger
																	/>
																</Popconfirm>
															</Space>
														</Col>
													</Row>
												</Card>
											</List.Item>
										)}
									/>
								</Col>
							</Row>
						</Col>
					</Row>


				</Content>

				{this.props.createRoles ? <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modal_visible: true })} /> : null}


				<ModalRoles
					visible={this.state.modal_visible}
					onClose={() => {
						this.setState({ modal_visible: false, rol_id: undefined })
						this.get()
					}}
					rol_id={this.state.rol_id}
				/>
			</>
		)
	}
}

export default function (props) {

	let user = React.useContext(Logged)
	let permissions;

	permissions = usePermissions(user?.rol_id?.permisos, {
		editRoles: ["roles", "edit"],
		createRoles: ["roles", "create"],
		deleteRoles: ["roles", "delete"]
	})
	const [search, setSearch] = useSearch()


	return <Roles
		{...props}
		search={search}
		hijo={user?.rol_id?.tipo}
		setSearch={setSearch}
		navigate={useNavigate()}
		{...permissions}
	/>

}