import React from 'react'
import { Layout, Col, Row, PageHeader, Typography, List, Card, Statistic, Space, message, Button, Popconfirm, Modal, Collapse } from "antd";
import axios from 'axios';
import moment from 'moment';
import { KeyOutlined } from "@ant-design/icons";
import { useSearchParams, useNavigate } from 'react-router-dom'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

//componentes
import { User } from '../../../Hooks/Logged';
import useSearch from '../../../Hooks/Search';
import { getResponseError } from '../../Utils';
import CustomAvatar from '../../Widgets/Avatar';
import { tipoClienteCuenta } from '../../Utils';
import usePermissions from '../../../Hooks/usePermissions';
import FloatingButton from '../../Widgets/FloatingButton/FloatingButton';

//modales
import ModalRevendedores from "./ModalRevendedores"
import ModalCuenta from "../Cuentas/ModalCuenta"


const { Content } = Layout;
const { Title, Text } = Typography
const { Panel } = Collapse;

class Revendedores extends React.Component {

	constructor(props) {
		super(props)
		let { page, limit, filters, search, sort } = this.props.params
		this.state = {
			loading: false,
			usuarios: {
				page: 1,
				limit: 10,
				total: 0,
				pages: 0,
				search: undefined,
				data: [],
			},
			searching: true,
			filtroSearch: '',
			usuario_id: undefined,
			tipo: undefined
		}
	}

	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.getRevendedores()

	}


	/**
	 * @memberOf Clientes
	 * @method getRevendedores
	 * @descripcion Obtiene los usuarios revendedores (tipo: 5) registrados en el sistema
	 * */
	getRevendedores = ({
		page = this.state.usuarios.page,
		limit = this.state.usuarios.limit,
		search = this.state.usuarios.search,
	} = this.state.usuarios) => {
		this.setState({ loading: true })

		axios.get('/revendedores', {
			params: {
				page,
				limit,
				search
			}
		})
			.then(({ data }) => {
				this.setState({ usuarios: data })
			}).catch(error => {
				console.log("error", error);
				message.error(error.response?.data?.message ?? "Error al obtener la información")

			}).finally(() => this.setState({ loading: false }))
	}


	render() {
		return (
			<>
				<Content className="p-1">
					<Row className="mb-3">
						<Col span={24} className="">
							<Row className='main'>
								<Col xs={24}>
									<Title level={5}> Revendedores </Title>
								</Col>
								<Col xs={24}>
									<List
										loading={this.state.loading}
										className="component-list"
										itemLayout="horizontal"
										locale={{ emptyText: "Sin Revendedores" }}
										dataSource={this.state.usuarios?.data}
										pagination={{
											current: this.state.usuarios.page,
											pageSize: this.state.usuarios.limit,
											total: this.state.usuarios.total,
											position: 'bottom',
											className: "flex-left",
											showSizeChanger: true,
											pageSizeOptions: [10, 20, 50, 100, 500],
											showQuickJumper: true,
											locale: { jump_to: "Ir a la página", page: '' },
											onChange: (page, limit) => {
												this.getUsuarios({ page, limit })
											},
											onShowSizeChange: (page, limit) => {
												this.getUsuarios({ page, limit })
											}
										}}
										header={<Row className="header-list width-100 ">
											<Col xs={0} sm={0} md={2} className="center">
											</Col>
											<Col xs={0} sm={0} md={6} className="center">
												<Text>Nombre</Text>
											</Col>
											<Col xs={0} sm={0} md={6} className="center">
												<Text>Email</Text>
											</Col>
											<Col xs={0} sm={0} md={4} className="center">
												<Text>Rol</Text>
											</Col>
											<Col xs={0} sm={0} md={3} className="center">
												<Text>Tipo</Text>
											</Col>
											<Col xs={0} sm={0} md={3} className="center">
												<Text>Acciones</Text>
											</Col>
										</Row>
										}

										renderItem={item => (
											<List.Item className="component-list-item" key={item._id}>
												<Card className="card-list collapse-padding width-100" bordered={false}>
													<Row className="width-100" align={"middle"} gutter={[2,6]}>
														<Col xs={24} sm={12} md={2} className="center">
															<CustomAvatar
																name={item.nombre_completo}
																url={item?.avatar?.name ? {
																	url: axios.defaults.baseURL + "/usuarios/" + item._id,
																	avatar: item.avatar.file,
																} : null}
															/>
														</Col>
														<Col xs={24} sm={12} md={6} className="center">
															<Text>{item.nombre_completo}</Text>
														</Col>
														<Col xs={24} sm={12} md={6} className="center">
															<Text>{item.email}</Text>
														</Col>
														<Col xs={24} sm={12} md={4} className="center">
															<Text>{item.rol_id?.nombre}</Text>
														</Col>
														<Col xs={24} sm={12} md={3} className="flex-column">
															<Text>{item.parent_user ? "Usuario Hijo" : "Usuario Padre"}</Text>
															{tipoClienteCuenta(item.cliente_id?.tipo_cliente)}
														</Col>
														<Col xs={24} sm={12} md={3} className="flex-right">
															<Space>
																<Button
																	title="Editar Cuenta Principal"
																	type="primary"
																	icon={<KeyOutlined />}
																	disabled={!this.props.editRevendedores}
																	onClick={() => {
																		this.setState({ modal_visible_cuentas: true, cuenta_id: item.cliente_id.cuenta_id[0] })
																	}}
																/>

															</Space>
														</Col>
													</Row>
												</Card>
											</List.Item>
										)}
									/>
								</Col>

							</Row>
						</Col>
					</Row>
				</Content>
				{this.props.createRevendedores && <FloatingButton onClick={() => this.setState({ modal_visible: true })} />}
				<ModalRevendedores
					visible={this.state.modal_visible}
					onClose={(flag) => {
						this.setState({ modal_visible: false })
						if (flag === true)
							this.getRevendedores()
					}}
					tipo="revendedor"
					title="Crear Revendedor"
				/>
				<ModalCuenta
					visible={this.state.modal_visible_cuentas}
					onClose={(flag) => {
						this.setState({ modal_visible_cuentas: false, cuenta_id: undefined })
						if (flag === true)
							this.getRevendedores()
					}}
					cuenta_id={this.state.cuenta_id}
				/>

			</>
		)
	}
}

export default function (props) {

	let user = React.useContext(User)

	let permissions = usePermissions(user?.rol_id?.permisos, {
		editRevendedores: ["revendedores", "edit"],
		createRevendedores: ["revendedores", "create"],
	})

	const [params, setParams] = useSearchParams();

	const [search, setSearch] = useSearch();

	return (<Revendedores {...props} navigate={useNavigate()} search={search} setParams={setParams} params={params} {...permissions} user={user} />)
}