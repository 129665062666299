import React from 'react'
import { Layout, Col, Row, Typography, List, Card, message } from "antd";
import { useSearchParams, useNavigate } from 'react-router-dom'
import axios from 'axios';

import { User } from '../../../Hooks/Logged';
import usePermissions from '../../../Hooks/usePermissions';
import moment from 'moment';
import { getResponseError } from '../../Utils'

const { Content } = Layout;
const { Title, Text } = Typography

class Bitacora extends React.Component {

    constructor(props) {
        super(props)
        let { page, limit} = this.props.params
        this.state = {
            loading: false,
            bitacora: {
                page: page ?? 1,
                limit: limit ?? 15,
                total: 0,
                data: [],
            }
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.get()
    }

    get = ({
        page = this.state.bitacora.page,
        limit = this.state.bitacora.limit,
    } = this.state.bitacora) => {
        this.setState({ loading: true })
        axios.get('/bitacora', {
            params: {
                page,
                limit
            }
        }).then(({ data }) => {
            this.setState({
                bitacora: {
                    ...data,
                }
            });
        }).catch(error => {
            message.error(getResponseError(error.response),"Error al obtener la bitacora")
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render() {

        return (
            <>
                <Content className="pt-1 ">
                    <Row className='main pl-1 pr-1'>
                        <Col xs={24}>
                            <Title level={5}> Bitacora </Title>
                        </Col>
                        <Col xs={24}>
                            <List
                                loading={this.state.loading}
                                className="component-list"
                                itemLayout="horizontal"
                                locale={{ emptyText: "Sin Bitacora" }}
                                dataSource={this.state.bitacora?.data}
                                pagination={{
                                    current: this.state.bitacora.page,
                                    pageSize: this.state.bitacora.limit,
                                    total: this.state.bitacora.total,
                                    position: 'bottom',
                                    className: "flex-left",
                                    showSizeChanger: true,
                                    pageSizeOptions: [20, 50, 100, 500],
                                    showQuickJumper: true,
                                    locale: { jump_to: "Ir a la página", page: '' },
                                    onChange: (page, limit) => {
                                        this.get({ page, limit })
                                    },
                                    onShowSizeChange: (page, limit) => {
                                        this.get({ page, limit })
                                    }
                                }}
                                header={<Row className="header-list width-100 ">
                                    <Col xs={20} className="">
                                        <Text>Evento</Text>
                                    </Col>
                                    <Col xs={4} className="center">
                                        <Text>Fecha</Text>
                                    </Col>
                                </Row>
                                }

                                renderItem={item => (
                                    <List.Item className="component-list-item">
                                        <Card className="card-list width-100 pointer-hand" bordered={false} >
                                            <Row className="width-100" align={"middle"}>
                                                <Col xs={20} >
                                                    <Text ellipsis>{item.descripcion}</Text>
                                                </Col>
                                                <Col xs={4} className="center">
                                                    <Text ellipsis className="text-gray">{moment(item.createdAt).format("DD/MM/YY HH:mm")}</Text>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />
                        </Col>
                    </Row>
                </Content>

            </>
        )
    }
}

export default function (props) {
    //let user = React.useContext(Logged)
    const [params, setParams] = useSearchParams();

    let user = React.useContext(User)

    let permissions = usePermissions(user?.rol_id?.permisos, {})

    return (<Bitacora {...props} navigate={useNavigate()} setParams={setParams} params={params} {...permissions} />)
}