import React, { useState, useEffect } from 'react';
import { Select, message, Modal } from 'antd'
import axios from 'axios'
import { IconCuenta } from '../Icons';
import { getResponseError } from '../../Utils';

const { Option } = Select

/**
 * @const SelectCuenta
 * @description Select para las cuentas registrados en el sistema
 */
const SelectCuenta = (props) => {

    const {
        value,
        onChange,
        placeholder = "Seleccione la Cuenta",
        onSelect = () => { },
        params = {},
        onClear = () => { },
        tagRender
    } = props

    const [cuentas, setCuentas] = useState({
        data: [],
        page: 1,
        limit: 500,
        total: 0,
        search: null,
    })
    const [cliente_id, setCliente] = useState(null)

    /**
     * @const getCuentas
     * @description Obitiene las cuentas
     */
    const getCuentas = ({ page, limit, search } = cuentas) => {
        axios.get('/cuentas', {
            params: {
                page,
                limit,
                search,
                ...params,
            }
        }).then(({ data }) => {
            setCuentas(data)

        }).catch(error => {
            console.log("ERR, error", error)
            message.error(getResponseError(error.response, 'Error al obtener las cuentas'))
        })
    }

    //componentDidMount
    useEffect(() => {
        getCuentas()
    }, [])

    useEffect(() => {
        if (value)
            onChange(value?.value ?? value)
    }, [value])


    useEffect(() => {

        if (params.cliente_id != cliente_id) {
            getCuentas({ page: 1, limit: 10, cliente_id: params.cliente_id })
            setCliente(params.cliente_id)
        }
    }, [params.cliente_id])



    return (
        <Select
            {...props}
            placeholder={placeholder}
            showSearch
            filterOption={false}
            labelInValue
            onSearch={(search) => getCuentas({ search })}
            onChange={(data) => onChange(data)}
            onSelect={(cuenta) => onSelect(cuenta.value)}
            value={value}
            onClear={() => {
                onClear()
                onChange(undefined)
                onSelect(undefined)
            }}
            suffixIcon={<IconCuenta />}
            tagRender={tagRender}
        >
            {cuentas?.data?.map(({ _id, nombre, cuenta }) => <Option value={_id} key={cuenta}>
                {nombre ?? ""} <small className='text-gray'>{cuenta ?? ""} </small>
            </Option>)}
        </Select>
    );
}



export default SelectCuenta;