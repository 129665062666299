import React from 'react'
import { Route, Routes } from "react-router-dom";

//componentes
import Usuarios from '../../Components/Admin/Usuarios/Usuarios';

/**
 * 
 * @export
 * @function RouterUsuarios
 * @description Router de usuarios
 */
function RouterUsuarios(props) {
  return (
    <Routes>
      <Route path='/' element={<Usuarios {...props}/>}/>
    </Routes>
  )
}

export default RouterUsuarios