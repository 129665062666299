import React, { Component } from "react";
import { Row, Col, Modal, Typography, message, Form, Statistic, Spin, Tag, Divider, Collapse } from 'antd';
import axios from "axios"
import { ClockCircleOutlined } from "@ant-design/icons"
import { getResponseError } from '../../Utils'
import { Cuenta } from '../../../Hooks/Cuentas';
import { User } from '../../../Hooks/Logged';

const { Panel } = Collapse;
const { Text, Title } = Typography;

const moment = require('moment');


/**
 *
 *
 * @class TransaccionesDetalle
 * @extends {Component}
 */
class TransaccionesDetalle extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            transaccion: {
                _id: 0,
                concepto: '',
                monto: 0,
                beneficiarios: [{
                    nombre: '',
                    cuenta: ''
                }],
                tipo: 0,
                tipo_pago: 0,
                referencia_numerica: 0,
                ejecutada: false
            },
        }

    }

    modalRef = React.createRef();

    componentDidMount() {

        console.log('DES');

        if (this.props.transaccion_id != null && this.props.transaccion == null)
            this.get()
        if (!this.props.transaccion_id && this.props.transaccion) {
            this.setState({ transaccion: this.props.transaccion })
        }
    }


    /**
    * @memberof ModalTran
    * @method get
    * @description Obtiene la informacion de la transacciones para el modal de detalle. Actualiza el state
    * con informacion obtenida.
    */
    get = () => {
        this.setState({ loading: true })
        axios.get('/transacciones/' + this.props.transaccion_id, {
            params: {
                id: this.props.transaccion_id
            }
        }).then(({ data }) => {
            this.setState({
                transaccion: data
            })

        }).catch(error => {
            message.error(getResponseError(error.response, "Error al obtener la información de la transacción"))
        }).finally(() => {
            this.setState({ loading: false })
        })

    }

    /**
    * @memberof ModalTran
    * @method renderTipo
    * @description Renderiza el tipo de transaccion que es dependiendo de quien la vea si un admin o el cliente
    */
    renderTipo = () => {
        const { transaccion } = this.state;
        const { cuenta } = this.props;

        //Si la cuenta en el hook es igual a la del beneficiario es un
        if (cuenta?.cuenta && cuenta?.cuenta === transaccion?.beneficiarios[0]?.cuenta) {
            return "Ingreso";
        } else {
            return transaccion.tipo === 1 ? "Ingreso" : "Egreso";
        }
    }


    renderMonto = () => {
        const { transaccion } = this.state;
        const { cuenta } = this.props;

        if (cuenta?.cuenta && cuenta?.cuenta === transaccion?.beneficiarios[0]?.cuenta) {
            return <Statistic
                value={transaccion.monto}
                valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: '#00FF19' }}
                prefix={'$'}
                suffix={'MXN'}
            />;
        } else if (cuenta?.cuenta && cuenta?.cuenta === transaccion.ordenante?.cuenta) {

            return <Statistic
                value={transaccion.monto}
                valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: '#FF0000' }}
                prefix={'$'}
                suffix={'MXN'}
            />;

        } else {
            const valueStyle = {
                fontSize: '16px',
                fontWeight: 'bold',
                color: transaccion.tipo === 1 ? '#00FF19' : '#FF0000'
            };

            return <Statistic
                value={transaccion.monto}
                valueStyle={valueStyle}
                prefix={'$'}
                suffix={'MXN'}
            />;
        }
    }

    render() {
        const { transaccion } = this.state;
        return (
            <Spin spinning={this.state.loading}>
                <Form layout="vertical"  >
                    <Row justify="center">

                        <Col span={12} >
                            <Form.Item label="STP ID" >
                                <Text type="secondary" >{transaccion.id_stp ?? "-"}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={12} >
                            <Form.Item label="Clave Rastreo" >
                                <Text type="secondary" >{transaccion.clave_rastreo ?? "-"}</Text>
                            </Form.Item>
                        </Col>

                        <Col span={12} >
                            <Form.Item label="Concepto" >
                                <Text type="secondary" >{transaccion.concepto}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={12} >
                            <Form.Item label="Fecha" >
                                <Text type="secondary" >{moment(transaccion.fecha_operacion).format('LL HH:mm')}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={12} >
                            <Form.Item label="Ordenante/Cuenta" >
                                <Text>{transaccion?.ordenante?.nombre ?? ""} / {transaccion?.ordenante?.cuenta?.hideCard()}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={12} >
                            <Form.Item label="Beneficiario/Cuenta" >
                                <div>
                                    {transaccion.beneficiarios.length > 0 ? <Text>{transaccion.beneficiarios[0].nombre} / {transaccion.beneficiarios[0].cuenta.hideCard()}</Text> : "n/a"}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col span={12} >
                            <Form.Item label="Tipo" >
                                <Text type="secondary" >
                                    {this.renderTipo()}
                                </Text>
                            </Form.Item>
                        </Col>
                        <Col span={12} >
                            <Form.Item label="Monto" >
                                {this.renderMonto()}
                            </Form.Item>
                        </Col>
                        {/* !this.props.transaccion_id && this.props.transaccion ? null : <> <Col span={12} >
                            <Form.Item label="Comisión" >
                                <Statistic
                                    value={transaccion.comision}
                                    valueStyle={{ fontSize: '16px', fontWeight: 'bold' }}
                                    prefix={'$'}
                                    suffix={'MXN'}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12} >
                            <Form.Item label="Monto Total" >
                                {this.renderMonto()}
                            </Form.Item>
                        </Col> </> */}

                        <Col span={12} >
                            <Form.Item label="Referencia Númerica" >
                                <Text type="secondary" >{transaccion.referencia_numerica}</Text>
                            </Form.Item>
                        </Col>

                        {transaccion.liberada != null || transaccion.liberada != undefined ?
                            <>
                                <Col span={12}>
                                    <Form.Item label={<><ClockCircleOutlined />&nbsp; Liberada</>}>
                                        {transaccion.liberada == true ? <Tag color="green">Liberada</Tag> : <Tag color="orange">No Liberada</Tag>}
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item label={<><ClockCircleOutlined />&nbsp; Fecha Programada</>}>
                                        <Text>  {moment(transaccion.fecha_ejecucion).format('DD/MM/YYYY') + ' a las ' + transaccion.hora_ejecucion.hora + ":" + transaccion.hora_ejecucion.minutos + " hrs"}</Text>
                                    </Form.Item>
                                </Col>
                            </>

                            : null}


                        {transaccion.estado != null ?
                            <Col span={12}>
                                <Form.Item label={"Estado"}>
                                    {transaccion.estado}
                                </Form.Item>
                            </Col>
                            : null}
                        {transaccion.descripcion_error != null ?
                            <Col span={24}>
                                <Form.Item label={"Descripción del Error"}>
                                    <Text>{transaccion.descripcion_error} </Text>
                                </Form.Item>
                            </Col>
                            : null}
                        {transaccion?.transacciones_hijas?.length > 0 && (this.props.user.rol_id.tipo === 1 || this.props.user.rol_id.tipo === 5) && !this.props.sin_hijos ? <Col span={24}>
                            <Collapse>
                                <Panel header={<Title level={5} className="m-0"> Pago de comisiones </Title>} key="1">
                                    <Row gutter={[16, 16]}>
                                        {
                                            transaccion.transacciones_hijas.map(tran => <>
                                                <Col span={12}>
                                                    <Form.Item label="Ordenante/Cuenta" >
                                                        <Text>{tran?.ordenante?.nombre ?? ""} / {tran?.ordenante?.cuenta?.hideCard()}</Text>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item label="Beneficiario/Cuenta" >
                                                        <Text>
                                                            {tran.beneficiarios.length > 0 ? <Text>{tran.beneficiarios[0].nombre} / {tran.beneficiarios[0].cuenta.hideCard()}</Text> : "n/a"}
                                                        </Text>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item label="Concepto" >
                                                        <Text>{tran?.concepto ?? ""}</Text>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item label="Monto" >
                                                        <Statistic
                                                            value={tran.monto_total}
                                                            valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: '#FF0000' }}
                                                            prefix={'$'}
                                                            suffix={'MXN'}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Divider className="m-0" />
                                            </>)
                                        }
                                    </Row>
                                </Panel>
                            </Collapse>
                        </Col> : null}
                    </Row>
                </Form>
            </Spin >

        )
    }
}



export default function (props) {

    let cuenta = React.useContext(Cuenta)

    let user = React.useContext(User)

    const { visible = false, onClose = () => { } } = props
    return <Modal
        open={visible}
        onCancel={onClose}
        title={null}
        closable={true}
        destroyOnClose={true}
        footer={null}
    >
        <Row>
            <Col span={24} className="center">
                <img src={"/img/BXNKLogo.png"} width="164" />
            </Col>
        </Row>
        <Title level={3} className="text-center mb-2 mt-1"> Detalle Transacción</Title>

        <TransaccionesDetalle {...props} cuenta={cuenta} user={user} />
    </Modal>

}