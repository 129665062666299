import React from 'react'
import { Layout, Col, Row, Typography, Button, message, Card, Space, Spin, Tooltip } from "antd";
import axios from 'axios';
import { Link } from 'react-router-dom';
import ReactEcharts from "echarts-for-react";
import { QuestionCircleOutlined } from "@ant-design/icons"
//componentes
import { User } from '../../Hooks/Logged'
import { getResponseError } from '../Utils';
import { Cuenta, SetCuenta } from '../../Hooks/Cuentas';
import usePermissions from '../../Hooks/usePermissions';
import { IconMoney, IconTransferencia, IconBeneficiario } from '../Widgets/Icons'
import { CardResumenFinanciero, CardTransaccionReciente, CardCuentaInfo } from '../Widgets/Cards'
//Modales
import ModalEstadoCuenta from '../Admin/Cuentas/ModalEstadoCuenta';
import ModalTransferencia from '../Admin/Transacciones/ModalTransferencia';
import { ModalBeneficiario } from '../Admin/Beneficiarios/ModalBeneficiario';
//css
import '../../Styles/Modules/dashboard.css'


const { Text } = Typography;
const { Content } = Layout;

class Dashboard extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			transacciones: [],
			modal_transaccion: false,
			cuenta: {
				balance: 0
			},
			ingresos: 0,
			egresos: 0,
			datos: [],
			fechas: [],
			beneficiarios: [],
			loading_transacciones: false,
			loading_grafica: false,
			loading: false
		}
	}

	componentDidMount() {
		console.log('props', this.props.usuario?.rol_id?.permisos)
		if (this.props.cuenta?._id) {
			this.getInfo()
			this.getHistorial()
			this.getTransacciones()
			this.getBeneficiarios()

		}

	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.cuenta?._id !== this.props.cuenta?._id) {
			this.getInfo()
			if ((this.props.usuario?.rol_id?.tipo != 3) || (this.props.verTransacciones && this.props.usuario?.rol_id?.tipo == 3))
				this.getHistorial()
				
			this.getTransacciones()
		}
	}

	/**
	 * @memberOf Transacciones
	 * @method getInfo
	 * @descripcion Obtiene informacion de la cuenta, juneto con sus ingreso y egresos del mes
	 * */
	getInfo = () => {
		this.setState({ loading: true })
		axios.get('/dashboard', { params: { cuenta_id: this.props.cuenta._id } })
			.then(response => {
				this.setState({
					cuenta: response.data.cuenta,
					ingresos: response.data.transacciones.ingresos,
					egresos: response.data.transacciones.egresos
				})

			}).catch(error => {
				message.error(getResponseError(error?.response, "Error al obtener estatus de la cuenta"))
			})
			.finally(() => {
				this.setState({ loading: false })
			})
	}

	/**
	 * @memberOf Transacciones
	 * @method getHistorial
	 * @descripcion Obtiene informacion historica de ingreso o egresos de una cuenta en el ultimo año
	 * */
	getHistorial = () => {
		this.setState({ loading_grafica: true })
		
		
		axios.get('/dashboard/grafica', { params: { cuenta: this.props.cuenta?.cuenta } })
			.then(response => {
				this.setState({
					datos: response.data.totales,
					fechas: response.data.fechas.reverse(),
				})

			}).catch(error => {
				console.log("error", error);
				message.error(getResponseError(error.response, "Error al obtener historial de la cuenta"))
			}).finally(() => this.setState({ loading_grafica: false }))
	}

	/**
	 * @memberOf Transacciones
	 * @method getTransacciones
	 * @descripcion Obtiene las ultimas transacciones realizadas
	 * */
	getTransacciones = () => {
		this.setState({ loading_transacciones: true })
		axios.get('/dashboard/recientes', { params: { cuenta: this.props.cuenta?.cuenta } })
			.then(response => {
				this.setState({
					transacciones: response.data
				})

			}).catch(error => {
				console.log("error", error);
				message.error(getResponseError(error.response, "Error al obtener transacciones recientes"))
			}).finally(() => this.setState({ loading_transacciones: false }))
	}

	/**
	 * @memberOf Transacciones
	 * @method getBeneficiarios
	 * @descripcion Obtiene los beneficiarios registrados
	 * */
	getBeneficiarios = ({
		page,
		limit
	} = this.state.beneficiarios, { beneficiarios } = this.state) => {
		this.setState({ beneficiarios: { ...beneficiarios, loading: true } })
		axios.get('/beneficiarios', {
			params: {
				page,
				limit
			}
		}).then(({ data }) => {
			this.setState({
				beneficiarios: { ...beneficiarios, ...data }
			})
		}).catch(error => {
			message.error(getResponseError(error.response, "Error al obtener los beneficiarios"))
		}).finally(() => {
			this.setState(state => { state.beneficiarios.loading = false; return state })
		})
	}

	/**
	 * @memberOf Transacciones
	 * @method getTransacciones
	 * @descripcion Obtiene informacion actualizada de la cuenta
	 * */
	getCuenta = () => {
		axios.get('/cuenta', {
			params: {
				cuenta_id: this.props.cuenta._id
			}
		}).then(({ data }) => {
			console.log("data", data);
			this.props.setCuenta(data)
		}).catch(error => {
			console.log("error", error);

		})
	}


	renderSaldo = () => {
		if ((this.props.usuario?.rol_id?.tipo != 3) || (this.props.puedeVerSaldo && this.props.usuario?.rol_id?.tipo == 3))
			return <>
				<Text>Saldo Disponible <Tooltip placement="topLeft" title={"Saldo utilizable"}> <QuestionCircleOutlined /> </Tooltip> </Text>
				<Text strong style={{ fontSize: '28px' }}>$ {(this.state?.cuenta?.balance ? this.state.cuenta.balance - this.state.cuenta.balance_congelado : 0).toMoney(true)}</Text>
			</>
	}


	renderOriginal = () => {
		if ((this.props.usuario?.rol_id?.tipo != 3) || (this.props.puedeVerSaldo && this.props.usuario?.rol_id?.tipo == 3))
			return <small>
				Saldo Congelado: $ {(this.state?.cuenta?.balance_congelado ?? 0).toMoney(true)} mxn&nbsp;&nbsp;
				<Tooltip
					placement="topLeft"
					title={"Saldo congelado: es el total de la suma de los balances de los clientes, más las transacciones PENDIENTES de la cuenta concentradora"}
				>
					<QuestionCircleOutlined />
				</Tooltip>
			</small>
	}


	render() {

		let { ingresos, egresos } = this.state;
		let { cuenta } = this.props;

		const validacion = ((this.props.usuario?.rol_id?.tipo != 3) || (this.props.puedeVerSaldo && this.props.usuario?.rol_id?.tipo == 3))

		console.log('validacion', validacion);
		return (
			<>
				<Content className="pt-1 pl-1">
					<Row>
						<Col xs={24} md={17} className="" style={{ paddingRight: '0.5rem' }}>
							<Spin spinning={this.state.loading} className="width-100 flex">
								<Row gutter={[16, 16]} className="mb-1">
									<Col xs={24} md={7} className="flex-left-column ">
										<Button
											type="primary"
											icon={<IconMoney />}
											className="btn-green-money"
										/>
										{this.renderSaldo()}
										{this.renderOriginal()}
										{this.state.cuenta?.saldo_bloqueado > 0 ? <small>
											Saldo Bloqueado: $ {(this.state?.cuenta?.saldo_bloqueado ?? 0).toMoney(true)} mxn&nbsp;&nbsp;
											<Tooltip
												placement="topLeft"
												title={"Saldo bloqueado: cantidad de fondos que se encuentra fuera de los límites de uso "}
											>
												<QuestionCircleOutlined />
											</Tooltip>
										</small> : null}
										<Link to={"/customer/transacciones"}><Button type="primary" className="mt-1">Ver Movimientos</Button></Link>
									</Col>
									<Col xs={24} md={17}>
										<Row className="m-0" gutter={[16, 8]}>
											<Col span={24} className="pb-1">
												<Text strong>Resumen</Text>
											</Col>
											<Col xs={24} md={12}>
												<CardResumenFinanciero
													title="Ingresos del Mes"
													validacion={validacion}
													monto={ingresos}
												/>
											</Col>
											<Col xs={24} md={12}>
												<CardResumenFinanciero
													title="Egresos del Mes"
													validacion={validacion}
													monto={egresos}
													tipo={2}
												/>
											</Col>

										</Row>
									</Col>
								</Row>
							</Spin>
							<Row gutter={[0, 16]}>
								<Col span={24}>
									<Card size="small" loading={this.state.loading_grafica}>
										<Row>
											<Col span={18}>
												<Text>Ingresos vs Egresos</Text>
											</Col>
											<Col span={6} className="flex-right">
												<Text>1 Año</Text>
											</Col>
											<Col span={24}>
												<ReactEcharts
													style={{ height: '380px', maxWidth: '1600px', margin: "0 auto" }}
													theme="light"
													className="pd-1"
													notMerge={true}
													option={{
														tooltip: {
															show: true,
															trigger: 'axis',
															backgroundColor: 'rgba(50,50,50,0.7)',
															borderColor: '#333',
															position: function (point, params, dom, rect, size) {
																return [point[0], point[1] - 100];
															},
															textStyle: {
																color: "#ccc"
															}
														},
														xAxis: [{
															type: 'category',
															axisTick: {
																alighWithLabel: true
															},
															axisLine: {
																onZero: false,
															},
															axisPointer: {
																label: 'axis'
															},
															data: this.state.fechas
														}],
														yAxis: [{
															type: 'value',
														}],
														series: this.state?.datos?.map((item, index) => {
															return {
																name: item._id == 1 ? "Ingresos" : "Egresos",
																type: 'line',
																emphasis: {
																	focus: 'series'
																},
																data: this.state?.fechas.map((fecha) => this.state.datos[index]?.totales[`${String(fecha)}`]),
																smooth: true,
															}
														})





													}}
												/>
											</Col>
										</Row>
									</Card>
								</Col>
								<Col lg={10} xxl={8} className="">
									<Text strong>Links Rápidos</Text>
									<Space style={{ width: '100%' }} className="dashboard-button-container">
										<Button
											disabled={!this.props.transferirTransacciones}
											className="btn-fast-link"
											onClick={() => { this.setState({ modal_transaccion: true }) }}
										>
											Transferencia Rápida
											<IconTransferencia />
										</Button>
										<Button
											disabled={!this.props.createBeneficiarios}
											className="btn-fast-link"
											onClick={() => this.setState({ modal_benficiario: true })}
										>
											Nuevo Beneficiario
											<IconBeneficiario />
										</Button>
									</Space>
								</Col>
								<Col xs={24} lg={14} xxl={14} className="">
									<Text strong>Transacciones Recientes</Text>
									<div className="mt-1">
										{
											this.state.transacciones.map((e, index) => {
												return <CardTransaccionReciente {...e} key={index} loading={this.state.loading_transacciones} customer={true} />
											})
										}
									</div>
								</Col>
							</Row>

						</Col>
						<Col xs={24} md={7} className="grid" style={{ paddingLeft: '0.5rem' }}>
							<CardCuentaInfo
								customer={true}
								title={'Mi Cuenta'}
								subtitle={"Beneficiarios"}
								cuenta={this.props.cuenta}
								data={this.state.beneficiarios.data}
								validacion={validacion}
								openModal={()=> {
									this.setState({modal_visible_estado: true})
								}}
							/>
						</Col>
					</Row>
				</Content>

				<ModalBeneficiario
					cliente_id={this.props.cliente_id}
					visible={this.state.modal_benficiario}
					onClose={() => this.setState({ modal_benficiario: false })}
				/>
				<ModalTransferencia
					cuenta_id={this.props.cuenta?._id}
					cliente_id={this.props.cliente_id}
					visible={this.state.modal_transaccion}
					programada={false}
					capturar={false}
					transferir={true}
					cliente={true}
					onClose={(transaccion) => {
						this.setState({ modal_transaccion: false })
						if (transaccion._id) {
							this.getTransacciones()
							this.getCuenta()
							this.getInfo()
						}
					}}
					tipo={2} //egreso
				/>

				<ModalEstadoCuenta
                    visible={this.state.modal_visible_estado}
                    onClose={() => {
                        this.setState({ modal_visible_estado: false })
                    }}
                    cuenta_id={this.props.cuenta?._id}
                />
			</>
		)
	}
}

export default function (props) {
	let user = React.useContext(User)
	let cuenta = React.useContext(Cuenta)

	let permissions = usePermissions(user?.rol_id?.permisos, {
		transferirTransacciones: ["transacciones", "transferir"],
		verTransacciones: ["transacciones", "read"],
		createBeneficiarios: ["beneficiarios", "create"],
		puedeVerSaldo: ["cuentas", "ver_saldo"],

	})
	let setCuenta = React.useContext(SetCuenta)

	return (<Dashboard
		{...props}
		cuenta={cuenta}
		cliente_id={user.cliente_id}
		{...permissions}
		setCuenta={setCuenta}
		usuario={user}

	/>)
}