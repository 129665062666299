import React, { Component } from "react";
import { Row, Col, Drawer, Form, Tabs, Input, Switch, message, Spin, Button } from 'antd';
import axios from "axios"
import ReglaList from "../Cuentas/ReglasList";
import BloqueVendedor from "../BloqueVendedores/BloqueVendedor";
import { getResponseError } from "../../Utils";

class FormReglaTemplates extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            check_activo: true,
            tab: "2",
            errores: [],
        }
    }

    modalRef = React.createRef();

    componentDidMount() {

        if (this.props.template_id) {
            this.get()
        }
    }

    /**
     * @memberof FormReglaTemplates
     * @method get
     * @description Obtiene informacion de la regla
     */
    get = () => {

        this.setState({ loading: true })
        axios.get('/template/' + this.props.template_id, {
            params: {
                template_id: this.props.template_id,
            }
        })
            .then(({ data }) => {
                this.setState({ tipo: data.tipo, check_activo: data.activo })
                this.modalRef.current.setFieldsValue({
                    ...data,

                })
            }).catch(error => {
                console.log("error", error)
                message.error(error?.response?.data ?? "Error al obtener la regla")
            }).finally(() => {
                this.setState({ loading: false })
            })

    }


    /**
     * @memberof FormReglaTemplates
     * @method submit
     * @description Se ejecuta al dar enter al formulario
     */
    submit = (values) => {

        values.activo = this.state.check_activo;
        if (this.props.template_id)
            this.update(values)
        else
            this.add(values)

    }

    /**
     * @memberof FormReglaTemplates
     * @method add
     * @description Crea una nueva regla en el sistema
     */
    add = (values) => {
        this.setState({ loading: true })

        axios.post('/templates', {
            ...values
        })
            .then(({ data }) => {
                message.success('Template añadido.')
                this.props.onClose(data)
            }).catch(error => {
                message.error(getResponseError(error.response, "Error al añadir el template."))
            }).finally(() => this.setState({ loading: false }))

    }

    /**
     * @memberof FormReglaTemplates
     * @method update
     * @description Actualiza una cuenta en el sistema
     */
    update = (values) => {
        this.setState({ loading: true })

        axios.put('/templates', {
            template_id: this.props.template_id,
            ...values
        })
            .then(response => {
                message.success('Template editado.')
                this.props.onClose(true)
            }).catch(error => {
                console.log(error)
                message.error(getResponseError(error.response, "Error al editar el template."))
            }).finally(() => this.setState({ loading: false }))

    }
    onChangeTipo = (check) => {
        this.setState({ tipo: check })
    }

    /**
     *
     *
     * @memberof DrawerTemplate
     * @method verificarReglas
     * @description cada vez que se cambia un valor de un formulario se validan las reglas
     */
    verificarReglas = (changedValues, allFields) => {
        const { reglas } = this.modalRef.current?.getFieldsValue()
        if (reglas && Array.isArray(reglas))
            reglas.forEach((regla, index) => {
                if (index >= 0 && regla) {
                    this.onChangeLimites(regla, reglas[index - 1], index, 0)
                }
            })

    }


    /**
    *
    *
    * @memberof ModalCuenta
    * @description verifica los limites de las reglas
    * @param {*} regla_actual
    * @param {*} regla_anterior 
    * @param {Number} index elemento de la lista
    * @param {Number} tipo 
    */
    onChangeLimites = (regla_actual, regla_anterior, index, tipo) => {

        let li = regla_actual.limite_inferior;
        let ls = regla_actual.limite_superior;
        let ls_anterior = regla_anterior?.limite_superior ?? 0;
        let errores = {}

        errores[index] = {};

        //reglas de validaciones para limites superiores e inferiores
        if (li === undefined || (index === 0 && li < 0))
            errores[index]["limite_inferior"] = 'Ingrese el mínimo';
        if (!ls)
            errores[index]["limite_superior"] = 'Ingrese el máximo';

        if (li > ls)
            errores[index]["limite_inferior"] = 'El valor del límite inferior debe ser menor al límite superior';

        if (li < ls_anterior)
            errores[index]["limite_inferior"] = 'El valor del límite inferior debe ser mayor al límite superior anterior';

        if (index > 0 && li != ls_anterior + 1)
            errores[index]["limite_inferior"] = 'El valor del límite inferior debe ser la continuación al límite superior anterior';

        if (ls < li)
            errores[index]["limite_superior"] = 'El valor del límite superior debe ser mayor al límite inferior';
        if (li == ls) {
            errores[index]["limite_inferior"] = 'Los límite deben ser diferentes';
            errores[index]["limite_superior"] = 'Los límite deben ser diferentes';
        }

        if (index === 0 && li > 0)
            errores[index]["limite_inferior"] = "No debe de ser mayor a 0"

        //reglas para monto y porcentajes
        // if (regla_actual.monto_absoluto == 0 && regla_actual.porcentaje == 0) {
        //     errores[index]["monto_absoluto"] = 'Se debe asignar un monto o porcentaje';
        //     errores[index]["porcentaje"] = 'Se debe asignar un porcentaje o monto';
        // }
        //almacen de errores en el state para renderizarlos en cada regla
        if (Object.keys(errores[index]).length > 0) {
            this.setState({ errores: errores })
            return Promise.reject('Hay errores que solucionar')
        }
        this.setState({ errores: [] })

        return Promise.resolve();
    }


    onChangePorcentaje = (index) => {

        let values = this.modalRef.current.getFieldsValue()
        let reglas = values.reglas
        reglas[index].monto_absoluto = 0

        this.modalRef.current.setFieldsValue({
            reglas: reglas
        })

    }


    onChangeMonto = (index) => {
        let values = this.modalRef.current.getFieldsValue()
        let reglas = values.reglas
        reglas[index].porcentaje = 0

        this.modalRef.current.setFieldsValue({
            reglas: reglas
        })
    }

    render() {

        return (

            <Tabs defaultActiveKey={this.state.tab} destroyInactiveTabPane={true}>
                <Tabs.TabPane tab="Template SPEI " key="1" disabled={this.props.template_id != null}>
                    <Form
                        layout="vertical"
                        ref={this.modalRef}
                        onFinish={this.submit}
                        initialValues={{ tipo: 0 }}
                        validateTrigger="onChange"
                        onValuesChange={this.verificarReglas}
                    >
                        <Spin spinning={this.state.loading}>

                            <Form.Item
                                name="tipo"
                                noStyle
                            >
                                <Input type="hidden" />
                            </Form.Item>
                            <Row align="center">
                                <ReglaList
                                    tipo={0}
                                    tipo_name="Reglas"
                                    name="reglas"
                                    errores={this.state.errores}
                                    onChangePorcentaje={this.onChangePorcentaje}
                                    onChangeMonto={this.onChangeMonto}
                                />
                            </Row>
                            <Row className="mt-1">
                                <Form.Item
                                    name="activo"
                                    label="¿Template Activo?"
                                >
                                    <Switch checked={this.state.check_activo} onChange={(e) => this.setState({ check_activo: e })} />
                                </Form.Item>
                            </Row>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" block size="large" className="mt-2" disabled={this.state.errores.length > 0}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Spin>
                    </Form>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Template Uso de Cuenta" key="2" disabled={this.props.template_id != null}>
                    <Form
                        layout="vertical"
                        ref={this.modalRef}
                        onFinish={this.submit}
                        initialValues={{ tipo: 1 }}
                        validateTrigger="onChange"
                        onValuesChange={this.verificarReglas}

                    >
                        <Spin spinning={this.state.loading}>

                            <Form.Item
                                name="tipo"
                                noStyle
                            >
                                <Input type="hidden" />
                            </Form.Item>
                            <Row align="center">
                                <ReglaList
                                    tipo={1}
                                    tipo_name="Reglas"
                                    name="reglas"
                                    errores={this.state.errores}
                                    showButtonTemplate={false}
                                    onChangePorcentaje={this.onChangePorcentaje}
                                    onChangeMonto={this.onChangeMonto}
                                />
                            </Row>

                            <Row className="mt-1">
                                <Form.Item
                                    name="activo"
                                    label="¿Template Activo?"
                                >
                                    <Switch checked={this.state.check_activo} onChange={(e) => this.setState({ check_activo: e })} />
                                </Form.Item>
                            </Row>

                            <Form.Item >
                                <Button htmlType="submit" type="primary" block size="large" className="mt-2" disabled={!(this.state.errores.length == 0)}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Spin>
                    </Form>
                </Tabs.TabPane>
                {/* <Tabs.TabPane tab="Template Bloques de Vendedores" key="3" disabled={this.props.template_id != null}>
                    <Form
                        layout="vertical"
                        ref={this.modalRef}
                        onFinish={this.submit}
                        initialValues={{ tipo: 2 }}
                        validateTrigger="onChange"
                        onValuesChange={this.verificarReglas}

                    >
                        <Spin spinning={this.state.loading}>

                            <Form.Item
                                name="tipo"
                                noStyle
                            >
                                <Input type="hidden" />
                            </Form.Item>
                            <Row align="center">
                                <BloqueVendedor />
                            </Row>

                            <Row className="mt-1">
                                <Form.Item
                                    name="activo"
                                    label="¿Template Activo?"
                                >
                                    <Switch checked={this.state.check_activo} onChange={(e) => this.setState({ check_activo: e })} />
                                </Form.Item>
                            </Row>

                            <Form.Item >
                                <Button htmlType="submit" type="primary" block size="large" className="mt-2" disabled={!(this.state.errores.length == 0)}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Spin>
                    </Form>
                </Tabs.TabPane> */}
            </Tabs >

        )
    }
}

export default function (props) {


    console.log('props regla')
    const { visible, onClose, template_id } = props
    return <Drawer
        open={visible}
        onCancel={onClose}
        onClose={onClose}
        title="Template de la Regla"
        footer={null}
        destroyOnClose={true}
        zIndex={1000}
        width={600}
    >
        <FormReglaTemplates template_id={template_id} onClose={onClose} />
    </Drawer>


}

