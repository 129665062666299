import React, { Component } from "react";
import { Route, Routes, Redirect } from "react-router-dom";
import { Layout } from "antd";


// Routers
import RouterRoles from "./Customer/RouterRoles";
import RouterUsuarios from "./Customer/RouterUsuarios";
import RouterDashboard from "./Customer/RouterDashboard";
import RouterTransacciones from "./Customer/RouterTransacciones";
import RouterBeneficiarios from "./Customer/RouterBeneficiarios";

import { SetCuenta, Cuenta } from "../Hooks/Cuentas";
import { Search, ShowSearch } from "../Hooks/Search"

// Components
import Header from "../Components/Header/Header";
import { User } from '../Hooks/Logged';
import CustomerSidebar from "../Components/Sidebar/CustomerSidebar.js"

//css
import '../Styles/Global.scss'
import '../Styles/Global/admin.scss'


const { Content } = Layout;

class CustomerRoutes extends Component {

	constructor(props) {
		super(props);

		this.state = {
			cuenta: 0,
			search: null,
			showSearch: true
		}
	}

	setCuenta = (cuenta) => this.setState({ cuenta })

	setSearch = (e) => this.setState({ search: e })

	setShowSearch = (e) => this.setState({ showSearch: e })

	render() {
		const { cuenta } = this.state;
		const { setCuenta } = this;

		return (
			<Cuenta.Provider value={cuenta}>
				<SetCuenta.Provider value={setCuenta}>
					<Layout className="main-layout">
						<CustomerSidebar />
						<ShowSearch.Provider value={[this.state.showSearch, this.setShowSearch]}>
							<Search.Provider value={[this.state.search, this.setSearch]}>
								<Content>
									<Header />
									<Routes>
										<Route path="dashboard/*" 		element={<RouterDashboard />} />
										<Route path="transacciones/*"   element={<RouterTransacciones />} />
										<Route path="beneficiarios/*"   element={<RouterBeneficiarios />} />
										<Route path="usuarios/*" 		element={<RouterUsuarios />} />
										<Route path="roles/*" 			element={<RouterRoles />} />
									</Routes>
								</Content>
							</Search.Provider>
						</ShowSearch.Provider>
					</Layout>
				</SetCuenta.Provider>
			</Cuenta.Provider >
		)
	}
}

export default function (props) {

	let user = React.useContext(User)

	return <CustomerRoutes {...props} user={user}/>
}