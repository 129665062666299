import React from 'react';
import { Select } from 'antd'

const { Option } = Select

/**
 * @const SelectTipoPago
 * @description Select para las cuentas registrados en el sistema
 */
const SelectTipoPago = (props) => {

    return (
        <Select {...props}>
            {[

                { clave: 0, nombre: "Devoluciones " },
                { clave: 1, nombre: "Tercero a Tercero " },
                { clave: 5, nombre: "Participante a Tercero " },
                { clave: 16, nombre: "Devolución Extemporánea " },
                { clave: 17, nombre: "Retorno " },
                { clave: 18, nombre: "Retorno Extemporáneo " },
                { clave: 19, nombre: "Cobros presenciales de una ocasión " },
                { clave: 20, nombre: "Cobros no presenciales de una ocasión " },
                { clave: 21, nombre: "Cobros no presenciales recurrentes " },
                { clave: 22, nombre: "Cobros no presenciales recurrentes y no recurrentes a nombre de un tercero " },
                { clave: 23, nombre: "Retorno especial " },
                { clave: 24, nombre: "Retorno Extemporáneo especial" }

            ]?.map(({ nombre, clave }) => <Option value={clave} key={clave}>
                {nombre ?? ""}
            </Option>)}
        </Select>
    );
}



export default SelectTipoPago;