import React, { useState } from "react";
import { Row, Col, Form, Button, Typography } from 'antd';
import { PlusOutlined } from "@ant-design/icons"
import ReglaItem from "./ReglaItem"


const { Text } = Typography
/**
 *
 *
 * @const ReglaList
 */
function ReglaList(props) {

    const {
        tipo,
        tipo_name,
        name,
        errores,
        showTemplates,
        showButtonTemplate,
        disablebtnTemplate,
        onChangePorcentaje = () => {},
        onChangeMonto = () => {},
        onChangeClase = () => {},
        reglas = [],
        cuenta_hija = false,
        min_uso = 0,
        min_spei = 0,
    } = props;

    const onRemove = () => {
        setTimeout(function() {
            props.verificarReglas()
        }, 500);

    }
    // console.log(
    //     {
    //         min_uso,
    //         min_spei,
    //     }
    // );
    return (

        <>
            <Row className=" width-100 " justify="space-around" style={{ borderBottom: '1px solid #A1D707' }}>
                <Col span={showButtonTemplate ? 18 : 24} ><Text strong style={{ fontSize: 20, color: '#A1D707' }}>{tipo_name}</Text></Col>
                {(showButtonTemplate) ? <Col span={6}><Button type="secondary" onClick={showTemplates} block className="mb-1 " disabled={(disablebtnTemplate)}>Usar Template</Button></Col> : null}
            </Row>
            <Form.List
                name={name}
            >
                {(fields, functions) => {
                    const { add, remove } = functions
                    return (
                        <>
                            <Row 
                                className="width-100" 
                                gutter={[8, 18]}
                                align="center"
                            >
                                <Col span={tipo === 1 ? 5 : 7} className="center"> Desde</Col>
                                <Col span={tipo === 1 ? 5 : 7} className="center"> Hasta</Col>
                                <Col span={tipo === 1 ? 5 : 7} className="center"> Monto</Col>
                                { tipo === 1 ? <Col span={5} className="center"> Porcentaje</Col> : null }
                                { tipo === 1 ? <Col span={3} className="center"> Tipo </Col> : null  }
                                <Col span={tipo === 1 ? 1 : 3} className="text-center ">
                                    <Button
                                        onClick={() => add()}
                                        type="primary"
                                        className="mt-05"
                                        icon={<PlusOutlined />}  >
                                    </Button>
                                </Col>
                            </Row>
                            {
                                fields.map(({ key, name, ...restField }, index) => {
                                    return <ReglaItem
                                        name={name}
                                        key={key}
                                        tipo={tipo}
                                        index={index}
                                        {...restField}
                                        errores={errores}
                                        remove={(name) => {
                                            remove(name)
                                            onRemove()
                                        }}
                                        regla={reglas[index]}
                                        onChangePorcentaje={onChangePorcentaje}
                                        onChangeMonto={onChangeMonto}
                                        onChangeClase={onChangeClase}
                                        cuenta_hija={cuenta_hija}
                                        min_uso={min_uso}
                                        min_spei={min_spei}
                                    />

                                })
                            }
                        </>
                    )
                }}
            </Form.List>
        </>
    )
}




export default ReglaList