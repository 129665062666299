import React from 'react'
import { Layout, Col, Row, Typography, List, Button, Card, Space, message, Tooltip, Tag, Popconfirm, Switch } from "antd";
import { EditOutlined, QuestionCircleOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import axios from 'axios';

import ModalCuenta from '../../Cuentas/ModalCuenta';
import ModalCobroComision from '../../Cuentas/ModalCobroComision';
import ModalEstadoCuenta from '../../Cuentas/ModalEstadoCuenta';
import FloatingButton from '../../../Widgets/FloatingButton/FloatingButton';
import { CodificarCuenta, TipoCuentaText } from '../../../Utils';
import { User } from '../../../../Hooks/Logged';
import { useNavigate, useParams } from 'react-router-dom'
import usePermissions from '../../../../Hooks/usePermissions';
import { getResponseError, EstadoCuenta, tipoClienteCuenta } from '../../../Utils';
import ButtonView from '../../../Widgets/ButtonView';

import { AiOutlineEdit, AiOutlineFilePdf, } from 'react-icons/ai';
import { MdAttachMoney, MdMoneyOffCsred, } from 'react-icons/md';

import ViewButton from '../../../Widgets/ButtonView';
const { Content } = Layout;
const { Text } = Typography

class Cuentas extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            cuentas: {
                page: 1,
                limit: 10,
                total: 0,
                pages: 0,
                // filters: filters ?? [],
                // sort: sort ?? {},
                data: [],
            }
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.get()
    }

    get = ({
        page = this.state.cuentas.page,
        limit = this.state.cuentas.limit
    } = this.state.cuentas) => {
        this.setState({ loading: true })
        axios.get('/cuentas', {
            params: {
                page,
                limit,
                cliente_id: this.props.cliente_id
            }
        }).then(({ data }) => {
            this.setState({
                cuentas: data
            })
        }).catch(error => {
            message.error(getResponseError(error.response, "Error al obtener las cuentas."))
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    updateCuentas = (cuenta_id) => {
        return axios.post('/cuenta/comisionista', {
            cliente_id: this.props.cliente_id,
            cuenta_id
        })
            .then(e => {
                this.get()
            })
            .catch(e => {
                message.error(e.response?.data?.message ?? 'Error al actualizar las cuentas')
            })
    }



    /**
    *
    * @memberof Cuentas
    * 
    * @method bloquearCuenta
    * @description Generamos el PDF del estado de cuenta
    */
    bloquearCuenta = (cuenta) => {
        this.setState({ loading: true })
        axios.put('/cuenta', {
            cuenta_id: cuenta._id,
            cuenta_bloqueada: true
        }).then((response) => {
            message.success("Cuenta desbloqueada")
            this.get()
        }).catch((error) => {
            message.error(getResponseError(error.response, 'No se pudo hacer la accion'))
            this.setState({ loading: false })
        })
    }

    /**
     *
     * @memberof Cuentas
     * 
     * @method desbloquearcuenta
     * @description Generamos el PDF del estado de cuenta
     */
    desbloquearcuenta = (cuenta) => {
        this.setState({ modal_visible_comision: true, cuenta_id: cuenta._id })

    }


    /**
     *
     * @memberof Cuentas
     * 
     * @method renderButton
     * @description Renderiza el boton para acitvar o descativar la cuenta
     */
    renderButton = (cuenta) => {
        console.log("cuenta", cuenta);

        let { cuenta_bloqueada } = cuenta;

        let title = cuenta_bloqueada ? "¿Deseas desbloquear la cuenta?" : "¿Deseas bloquear esta cuenta?"
        let icon = cuenta_bloqueada ? <CheckCircleOutlined /> : <CloseCircleOutlined />
        let text_btn = cuenta_bloqueada ? "Desbloquear cuenta" : "Bloquear cuenta"
        let onConfirm = cuenta_bloqueada ? this.desbloquearcuenta : this.bloquearCuenta

        return <Popconfirm
            placement="topRight"
            title={title}
            onConfirm={() => onConfirm(cuenta)}
            okText="Sí"
            cancelText="No"
            disabled={cuenta.estado === "B"}
        >
            <Button
                type="primary"
                icon={icon}
                title={text_btn}
                disabled={cuenta.estado === "B"}
            />
        </Popconfirm>
    }


    /**
     *
     * @memberof Cuentas
     * 
     * @method renderEstado
     * @description Renderiza el boton para acitvar o descativar la cuenta
     */
    renderEstado = ({estado, cuenta_bloqueada}) => {

        if(estado === "B")
            return <Tag color={"red"}>Cuenta dada de Baja </Tag>

        if(cuenta_bloqueada)
            return <Tooltip 
                placement="topLeft" 
                title={"Cuenta temporalmente bloqueada por exceder límites; egresos bloqueados."}
            > <Tag color={"red"}>Cuenta Bloqueda <QuestionCircleOutlined /> </Tag> </Tooltip>

        return <Tag color={"lime"}>Cuenta Disponible </Tag>
    }

    render() {
        return (
            <>
                <Content className="p-1">
                    <Row>
                        <Col span={24} className="" style={{ paddingRight: '0.5rem', marginBottom: "10rem" }}>
                            <Row className='main'>
                                <Col xs={24}>
                                    <List
                                        loading={this.state.loading}
                                        className="component-list"
                                        itemLayout="horizontal"
                                        locale={{ emptyText: "Sin Cuentas" }}
                                        dataSource={this.state.cuentas?.data}
                                        pagination={{
                                            current: this.state.cuentas.page,
                                            pageSize: this.state.cuentas.limit,
                                            total: this.state.cuentas.total,
                                            position: 'bottom',
                                            className: "flex-left",
                                            showSizeChanger: true,
                                            pageSizeOptions: [20, 50, 100, 500],
                                            showQuickJumper: true,
                                            locale: { jump_to: "Ir a la página", page: '' },
                                            onChange: (page, limit) => {
                                                this.get({ page, limit })
                                            },
                                            onShowSizeChange: (page, limit) => {
                                                this.get({ page, limit })
                                            }
                                        }}
                                        header={<Row className="header-list width-100 ">
                                            <Col xs={0} sm={4}>
                                                <Text>Nombre</Text>
                                            </Col>
                                            <Col xs={0} sm={4} className="center">
                                                <Text>Alias</Text>
                                            </Col>
                                            <Col xs={0} sm={4} className="center">
                                                <Text>Saldo</Text>
                                            </Col>
                                            <Col xs={0} sm={2} className="center">
                                                <Text>Tipo Cuenta </Text>
                                            </Col>
                                            <Col xs={0} sm={3} className="center">
                                                <Text>Asesor</Text>
                                            </Col>
                                            <Col xs={0} sm={3} className="center">
                                                
                                            </Col>
                                            <Col xs={0} sm={3} className="center">
                                                <Text>Acciones</Text>
                                            </Col>
                                        </Row>
                                        }

                                        renderItem={item => (
                                            <List.Item className="component-list-item" key={item._id}>
                                                <Card className="card-list width-100" bordered={false}>
                                                    <Row className="width-100" align={"middle"} gutter={[2,6]}>
                                                       <Col xs={24} sm={12} lg={4} className="flex-left-column">
                                                            <Tooltip placement="topLeft"  title={item.nombre}>
                                                                <Text ellipsis>{item.nombre}</Text>
                                                            </Tooltip>
                                                            <Tooltip placement="topLeft"  title={item.cuenta}>
                                                                <small ellipsis>{item.cuenta}</small>
                                                            </Tooltip>
                                                        </Col>
                                                        <Col xs={24} sm={12} md={4} className="center ">
                                                            <Text>{item.alias}</Text>
                                                        </Col>
                                                        <Col xs={24} sm={12} md={4} className="center ">
                                                            <Tooltip
                                                                placement="topLeft"
                                                                title={<div>
                                                                    Balance disponible: ${item.balance.toMoney(true)} mxn <br />
                                                                    Balance bloqueado: $ {(item.saldo_bloqueado ?? 0).toMoney(true)} mxn
                                                                </div>}
                                                            >
                                                                <Text>$ {(item.balance + (item.saldo_bloqueado ?? 0)).toMoney(true)} mxn</Text>
                                                            </Tooltip>
                                                        </Col>
                                                        <Col xs={24} sm={12} md={2} className="center ">
                                                            <Text>{tipoClienteCuenta(item.tipo_cuenta)}</Text>
                                                        </Col>
                                                        <Col xs={24} sm={12} md={3} className="center ">
                                                            {item?.usuario_id?.nombre_completo ? <Tag color={"purple"}>{item?.usuario_id?.nombre_completo}</Tag> : "-"}
                                                        </Col>
                                                        <Col xs={24} sm={12} md={3} className="center ">
                                                            {this.renderEstado(item)}
                                                        </Col>
                                                        <Col xs={24} sm={12} md={4} className="flex-right">
                                                            <Space>
                                                                {this.props?.cliente?.rol?.tipo === 4 || this.props?.cliente?.rol?.tipo === 5 ? <ViewButton
                                                                    value={item.recibe_comision}
                                                                    tooltip='¿Esta cuenta recibirá las comisiones?'
                                                                    open={<MdAttachMoney size={22} style={{ position: "relative", top: 2 }} />}
                                                                    close={<MdMoneyOffCsred size={22} style={{ position: "relative", top: 2 }} />}
                                                                    disabled={this.state.cuentas.total <= 1}

                                                                    onChange={value => {
                                                                        console.log('value');
                                                                        this.updateCuentas(item._id)
                                                                    }}
                                                                /> : null}
                                                                {this.props.user?.rol_id?.super_admin ? this.renderButton(item) : null}
                                                                <Button
                                                                    title="Ver estado de cuenta"
                                                                    type='primary'
                                                                    className='btn-primary center'
                                                                    icon={<AiOutlineFilePdf />}
                                                                    disabled={item.estado === "B"}
                                                                    onClick={()=>this.setState({modal_visible_estado: true, cuenta_id: item._id})}
                                                                />
                                                                <Button
                                                                    type="primary"
                                                                    icon={<EditOutlined />}
                                                                    onClick={() => this.setState({ modal_visible: true, cuenta_id: item._id })}
                                                                    disabled={!this.props.editCuentas}
                                                                />

                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </List.Item>
                                        )}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <FloatingButton onClick={() => this.setState({ modal_visible: true })} disabled={!this.props.createCuentas} />
                </Content >
                <ModalCuenta
                    cuenta_id={this.state.cuenta_id}
                    cliente_id={this.props.cliente_id}
                    visible={this.state.modal_visible}
                    onClose={() => {
                        this.setState({ modal_visible: false, cuenta_id: null })
                        this.get()
                    }}
                    initialValues={{
                        tipo_cuenta: this.props.cliente?.tipo_cliente
                    }}
                    revendedor={this.props.user?.rol_id?.tipo === 5}
                />
                <ModalCobroComision
                    cuenta_id={this.state.cuenta_id}
                    visible={this.state.modal_visible_comision}
                    onClose={(flag) => {
                        this.setState({ modal_visible_comision: false, cuenta_id: null })
                        if (flag === true)
                            this.get()
                    }}
                />
                <ModalEstadoCuenta
                    visible={this.state.modal_visible_estado}
                    onClose={() => {
                        this.setState({ modal_visible_estado: false, cuenta_id: null })
                    }}
                    cuenta_id={this.state.cuenta_id}
                />
            </>
        )
    }
}

export default function (props) {
    let user = React.useContext(User)
    const params = useParams()


    let permissions = usePermissions(user?.rol_id?.permisos, {
        editCuentas: ["clientes", "cuentas", "edit"],
        createCuentas: ["clientes", "cuentas", "create"],
        deleteCuentas: ["clientes", "cuentas", "delete"]
    })
    return (<Cuentas {...props} {...permissions} navigate={useNavigate()} params={params} user={user} />)
}