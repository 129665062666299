import React, { Component } from "react";
import { Row, Col, Modal, Space, Typography, Form, Input, message, Spin, Button, Select, Divider, Switch, Tabs } from 'antd';
import { SelectCliente, SelectInstituciones, SelectUsuario } from "../../../Widgets/Inputs/Selects";
import axios from "axios"
import moment from "moment";
import ReglaList from "../../Cuentas/ReglasList";
import ModalTemplates from "../../Cuentas/TemplatesList";
import BloqueVendedor from "../../BloqueVendedores/BloqueVendedor"

import ModalSeleccionarBloqueVendedor from "../../BloqueVendedores/ModalSeleccionarBloqueVendedor"
import { getResponseError } from "../../../Utils";
import { User } from '../../../../Hooks/Logged';
import Decimal from 'decimal.js';

const { Title, Text } = Typography;
const { Option } = Select;

/**
 *
 *
 * @class FormCuenta
 * @extends {Component}
 */
export default class FormCuenta extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loading_rules: false,
            tipo: 1,
            esClabe: true,
            intentos: 1,
            errores_spei: [],
            errores_uso_cuenta: [],
            modal_visible: false,

            disabled_templates_spei: false,
            disabled_templates_uso_cuenta: false,

            cobro_comision: true
        }
        window.x = message
    }

    formRef = React.createRef();

    componentDidMount() {

        // console.log(this.props.tipo !== "cliente")
        if (this.props.user?.rol_id?.tipo == 5 || this.props.user?.rol_id?.tipo == 4) {
            this.getReglasMaster()
        }
    }

    getReglasMaster = () => {

        axios.get('/vendedores/reglas-padre')
            .then(response => {

                // console.log('response', response);
                let cuenta = response.data
                console.log('cuenta', cuenta);
                let min_spei = 0, min_uso = 0
                // if (cuenta.es_concentradora) {
                const reglas_spei_rev = cuenta?.reglas_comision.filter(regla => regla.tipo_regla === 0);
                if (reglas_spei_rev) min_spei = reglas_spei_rev[0]?.monto_absoluto
                const reglas_cuenta_rev = cuenta?.reglas_comision.filter(regla => regla.tipo_regla === 1);
                if (reglas_cuenta_rev) min_uso = reglas_cuenta_rev[0]?.porcentaje

                console.log('cuenta.bloques_vendedores', cuenta.bloques_vendedores);
                this.formRef.current?.setFieldsValue({
                    reglas_spei: reglas_spei_rev,
                    reglas_uso_cuenta: reglas_cuenta_rev,
                    //bloques_vendedores: cuenta.bloques_vendedores
                })


                console.log('--------');
                console.log({
                    min_uso,
                    min_spei
                });
                this.setState({
                    min_uso,
                    min_spei,
                    hay_cuenta_padre: true
                })
            })
            .catch(e => {
                console.log('e', e)
            })

    }


    /**
     * @memberof FormCuenta
     * @method submit
     * @description Se ejecuta al dar enter al formulario
     */
    submit = (values) => {

        //Verificamos que no haya errores
        let errores_spei = this.state.errores_spei.every(obj => Object.keys(obj).length === 0)
        console.log("this.state.errores_spei", this.state.errores_spei);
        let errores_uso_cuenta = this.state.errores_uso_cuenta.every(obj => Object.keys(obj).length === 0)
        console.log("this.state.errores_uso_cuenta", this.state.errores_uso_cuenta);

        // if(!errores_spei || !errores_uso_cuenta){
        //     message.error("Hay errores en las reglas por resolver")
        //     return
        // }


        try {
            if (values.bloques_vendedores)
                values.bloques_vendedores = this.validateBloques(values.bloques_vendedores)

            if (values.bloques_vendedores_monto || values.bloques_vendedores_porcentaje)
                values.bloques_vendedores = [
                    ...this.validateBloques(values.bloques_vendedores_monto, 1),
                    ...this.validateBloques(values.bloques_vendedores_porcentaje, 2),
                ]
        } catch (error) {
            return Modal.error({
                title: error
            })
        }
        values.intentos = this.state.intentos;

        let reglas_comision = []
        if (Array.isArray(values.reglas_spei))
            reglas_comision = values.reglas_spei

        if (Array.isArray(values.reglas_uso_cuenta))
            reglas_comision = [...reglas_comision, ...values.reglas_uso_cuenta]

        values.reglas_comision = reglas_comision

        console.log('values', values)
        console.log('this.state', this.state)

        // return ;
        this.props.onFinish({ reglas: { ...values } })
    }

    validateBloques = (bloques_vendedores, tipo = 3) => {

        let comisionPorcentaje = 0;

        if (!Array.isArray(bloques_vendedores) || bloques_vendedores.length < 1)
            return []
        //     throw "No hay ningún bloque"

        for (let x = 0; x < bloques_vendedores.length; x++) {
            const bloque_vendedor = bloques_vendedores[x]
            let comisionPorcentajeVendedores = 0

            bloques_vendedores[x].tipo = tipo

            if (!Array.isArray(bloque_vendedor.vendedores) || bloque_vendedor.vendedores.length < 1)
                throw "Hay bloques que no tienen vendedores"


            for (let y = 0; y < bloque_vendedor.vendedores.length; y++) {
                const vendedor = bloque_vendedor.vendedores[y];
                bloques_vendedores[x].vendedores[y].vendedor_id = vendedor?.vendedor_id?.value || vendedor?.vendedor_id
                bloques_vendedores[x].vendedores[y].comision_bloque = Decimal(bloque_vendedor.porcentaje_comision || 0).mul(vendedor.porcentaje_comision || 0).div(100).toDecimalPlaces(2).toNumber()
                comisionPorcentajeVendedores += vendedor.porcentaje_comision
            }

            console.log('comisionPorcentajeVendedores', comisionPorcentajeVendedores);
            if (comisionPorcentajeVendedores != 100)
                throw "Las comisiones de los vendedores no estan completas"

            comisionPorcentaje += parseFloat(bloque_vendedor.porcentaje_comision)
        }

        console.log('comisionPorcentaje', comisionPorcentaje);
        if (comisionPorcentaje != 100)
            throw "Las comisiones de los bloques no estan completas"

        return bloques_vendedores
    }

    /**
     *
     *
     * @memberof FormCuenta
     * @method verificarReglas
     * @description cada vez que se cambia un valor de un formulario se validan las reglas
     */
    verificarReglas = (changedValues, allFields) => {
        const { reglas_spei, reglas_uso_cuenta } = this.formRef.current?.getFieldsValue();
        console.log("reglas_spei", reglas_spei);

        if (reglas_spei && Array.isArray(reglas_spei))
            reglas_spei.forEach((regla, index) => {
                if (index >= 0 && regla) {
                    this.onChangeLimites(regla, reglas_spei[index - 1], index, 0)
                }

            });
        if (reglas_uso_cuenta && Array.isArray(reglas_uso_cuenta))
            reglas_uso_cuenta.forEach((regla, index) => {
                if (index >= 0 && regla) {
                    this.onChangeLimites(regla, reglas_uso_cuenta[index - 1], index, 1)
                }
            });


        let disabled_spei = this.ReglasExisten(reglas_spei)
        let disabled_uso_cuenta = this.ReglasExisten(reglas_uso_cuenta)

        this.setState({
            disabled_templates_spei: disabled_spei,
            disabled_templates_uso_cuenta: disabled_uso_cuenta
        })


    }


    /**
     *
     *
     * @memberof FormCuenta
     * @description Revisa la cantidad de reglas creadas para deshabilitar los templates
     * se realiza de este modo ya que el form.list nunca esta vacio, por lo que se valida con un campo del form.list
     */
    ReglasExisten = (reglas) => {

        if (Array.isArray(reglas) && reglas.length > 0) {
            let tamano = reglas.filter(regla => regla?.tipo_regla != undefined).length;
            if (tamano > 0) {
                return true
            }
            return false
        }

        return false

    }

    /**
     *
     *
     * @memberof FormCuenta
     * @description verifica los limites de las reglas
     * @param {*} regla_actual
     * @param {*} regla_anterior 
     * @param {Number} index elemento de la lista
     * @param {Number} tipo tipo de regla
     */
    onChangeLimites = (regla_actual, regla_anterior, index, tipo) => {

        let li = regla_actual.limite_inferior;
        let ls = regla_actual.limite_superior;
        let ls_anterior = regla_anterior?.limite_superior ?? 0;

        let errores = tipo === 0 ? this.state.errores_spei : this.state.errores_uso_cuenta

        errores[index] = {};

        //reglas de validaciones para limites superiores e inferiores
        if (li == undefined || (index === 0 && li < 0))
            errores[index]["limite_inferior"] = 'Ingrese el mínimo';
        if (!ls)
            errores[index]["limite_superior"] = 'Ingrese el máximo';

        if (li > ls)
            errores[index]["limite_inferior"] = 'El valor del límite inferior debe ser menor al límite superior';

        if (li < ls_anterior)
            errores[index]["limite_inferior"] = 'El valor del límite inferior debe ser mayor al límite superior anterior';

        if (index > 0 && li != ls_anterior + 1)
            errores[index]["limite_inferior"] = 'El valor del límite inferior debe ser la continuación al límite superior anterior';

        if (ls < li)
            errores[index]["limite_superior"] = 'El valor del límite superior debe ser mayor al límite inferior';
        if (li == ls) {
            errores[index]["limite_inferior"] = 'Los límite deben ser diferentes';
            errores[index]["limite_superior"] = 'Los límite deben ser diferentes';
        }

        if (index === 0 && li != 0)
            errores[index]["limite_inferior"] = "No debe de ser mayor a 0"

        //almacen de errores en el state para renderizarlos en cada regla
        if (Object.keys(errores[index]).length > 0) {
            if (tipo == 0) {
                this.setState({ errores_spei: errores })
            }

            if (tipo == 1) {
                this.setState({ errores_uso_cuenta: errores })
            }

            return
        }
    }

    /**
     *
     *
     * @memberof FormCuenta
     */
    addTemplate = (template_id) => {
        this.setState({ loading_rules: true })

        axios.get('/template/' + template_id, {
            params: {
                template_id: template_id,
            }
        })
            .then(({ data }) => {

                if (data.tipo == 0) {
                    this.formRef.current.setFieldsValue({ reglas_spei: data.reglas })
                    this.setState({ disabled_templates_spei: true })
                }
                if (data.tipo == 1) {
                    this.formRef.current.setFieldsValue({ reglas_uso_cuenta: data.reglas })
                    this.setState({ disabled_templates_uso_cuenta: true })

                }

            }).catch(error => {
                console.log('error', error)
                message.error(error?.response?.data ?? "No se pudo cargar la regla")
            }).finally(() => {
                this.setState({ loading_rules: false })
            })

    }

    /**
     * @memberof FormCuenta
     * @method onChangePorcentaje
     * @description Cuando se cambia el valor del porcentaje, el valor del monto lo vuelve 0
     */
    onChangePorcentaje = (index, key) => {

        let values = this.formRef.current.getFieldsValue()
        let reglas = values[key]

        //Si es de clase hibrida se puede ambos
        if (reglas[index].clase === 2) return;

        reglas[index].monto_absoluto = 0

        this.formRef.current.setFieldsValue({
            [key]: reglas
        })

    }

    /**
     * @memberof FormCuenta
     * @method onChangeMonto
     * @description Cuando se cambia el valor del porcentaje, el valor del monto lo vuelve 0
     */
    onChangeMonto = (index, key) => {
        let values = this.formRef.current.getFieldsValue()
        let reglas = values[key]

        //Si es de clase hibrida se puede ambos
        if (reglas[index].clase === 1) return;

        reglas[index].porcentaje = 0

        this.formRef.current.setFieldsValue({
            [key]: reglas
        })
    }

    /**
    * @memberof FormCuenta
    * @method onChangeClase
    * @description Cuando se cambia el valor de la clase, pone el porcentaje o el monto en 0
    */
    onChangeClase = (index, key) => {
        let values = this.formRef.current.getFieldsValue()
        let reglas = values[key]

        if (reglas[index].clase === 0) {
            reglas[index].porcentaje = 0
        }

        if (reglas[index].clase === 1) {
            reglas[index].monto_absoluto = 0
        }

        this.formRef.current.setFieldsValue({
            [key]: reglas
        })
    }

    /**
     * @memberof FormCuenta
     * @description Del cliente seleccionado actualiza el valor de tipo de cuenta
     */
    onSelectCliente = (cliente_id) => {
        if (cliente_id) {
            axios.get('/cliente/' + cliente_id)
                .then(({ data }) => {
                    if (this.formRef.current)
                        this.formRef.current.setFieldsValue({ tipo_cuenta: data.tipo_cliente })
                })
        }
    }


    render() {

        let sho

        return (
            <>
                <Form
                    layout="vertical"
                    ref={this.formRef}
                    onFinish={this.submit}
                    initialValues={{
                        ...this.props.initialValues,
                        tipo: 40,
                        cuenta_id: this.props.cuenta_id,
                        cobro_comision: true,
                        dia_corte: moment().format("D"),
                    }}
                    validateTrigger={["onChange", "onBlur"]}
                    onValuesChange={this.verificarReglas}
                    preserve={false}
                >
                    <Spin spinning={this.state.loading}>

                        {this.state.cobro_comision ? <Spin spinning={this.state.loading_rules} className="width-100">
                            <Row gutter={[8, 8]} className="width-100">
                                <Divider>Reglas de la Cuenta</Divider>
                                <Space size={35} direction="vertical" className="width-100" >
                                    <ReglaList
                                        tipo={0}
                                        tipo_name="SPEI (Egresos)"
                                        name="reglas_spei"
                                        errores={this.state.errores_spei}
                                        showTemplates={() => this.setState({ modal_visible: true, tipo: 0 })}
                                        showButtonTemplate={true}
                                        disablebtnTemplate={this.state.disabled_templates_spei}
                                        verificarReglas={this.verificarReglas}
                                        onChangePorcentaje={(index) => this.onChangePorcentaje(index, "reglas_spei")}
                                        onChangeMonto={(index) => this.onChangeMonto(index, "reglas_spei")}
                                        // min_uso = 0,
                                        // min_spei = 0,
                                        min_spei={this.state.min_spei}
                                    // min_spei = 0,
                                    />
                                    <ReglaList
                                        tipo={1}
                                        tipo_name="Uso Cuenta (Ingreso)"
                                        name="reglas_uso_cuenta"
                                        errores={this.state.errores_uso_cuenta}
                                        showTemplates={() => this.setState({ modal_visible: true, tipo: 1 })}
                                        showButtonTemplate={true}
                                        disablebtnTemplate={this.state.disabled_templates_uso_cuenta}
                                        verificarReglas={this.verificarReglas}
                                        onChangePorcentaje={(index) => this.onChangePorcentaje(index, "reglas_uso_cuenta")}
                                        onChangeMonto={(index) => this.onChangeMonto(index, "reglas_uso_cuenta")}
                                        onChangeClase={(index) => this.onChangeClase(index, "reglas_uso_cuenta")}
                                        //le mando las reglas de uso de cuenta para ver la clase, y asi mostrar o quitar campos
                                        reglas={this.state.cuenta?.reglas_comision?.filter(e => e.tipo_regla === 1)}
                                        cuenta_hija={this.props.tipo === "revendedor" || this.state.hay_cuenta_padre ? true : false}
                                        min_uso={this.state.min_uso}

                                    />

                                </Space>
                            </Row>
                        </Spin> : null}

                        <Row className=" width-100 mt-2" justify="space-around" style={{ borderBottom: '1px solid #A1D707' }}>
                            <Col span={18} ><Text strong style={{ fontSize: 20, color: '#A1D707' }}>Bloques de Vendedores</Text></Col>
                            <Col span={6}>
                                <ModalSeleccionarBloqueVendedor
                                    onAccept={e => {
                                        this.formRef.current.setFieldsValue({
                                            bloques_vendedores: e.map(({ vendedores, ...e }) => ({
                                                ...e,
                                                vendedores: vendedores.map(({ vendedor_id, ...args }) => ({
                                                    vendedor_id: {
                                                        key: vendedor_id?._id,
                                                        value: vendedor_id?._id,
                                                        label: vendedor_id?.nombre_completo
                                                    },
                                                    ...args
                                                }))
                                            }))
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                        {/* <Row className="width-100 mt-1" style={{ borderBottom: '1px solid #A1D707', position: "relative", right: '5px' }}>
                            <Space style={{ width: "100%", justifyContent: "space-between"}}>
                                <Text strong style={{ fontSize: 20, color: '#A1D707' }}>Vendedores</Text>
                                <ModalSeleccionarBloqueVendedor />
                            </Space>
                        </Row> */}
                        <Form.Item
                            style={{ margin: 0 }}
                            shouldUpdate
                        >
                            {({ getFieldValue }) => {

                                let isHibrido = false

                                let reglas_uso_cuenta = getFieldValue('reglas_uso_cuenta')
                                if (Array.isArray(reglas_uso_cuenta))
                                    for (const regla of reglas_uso_cuenta) {
                                        if (regla.clase == 2) {
                                            isHibrido = true
                                            break;
                                        }
                                    }

                                if (!isHibrido)
                                    return <BloqueVendedor {...this.props} />

                                return <Tabs

                                // onChange={e => {
                                //     console.log('e', e);
                                // }}
                                >
                                    <Tabs.TabPane tab="Reglas para Monto" key="item-1">
                                        <BloqueVendedor {...this.props} formListName='bloques_vendedores_monto' />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="Reglas para Porcentaje" key="item-2">
                                        <BloqueVendedor {...this.props} formListName='bloques_vendedores_porcentaje' />
                                    </Tabs.TabPane>
                                </Tabs>
                            }}
                        </Form.Item>
                        {/*  */}
                        <Row align="center">
                            <Col xs={22} lg={20} >
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Button
                                        style={{ width: "250px" }}
                                        size="large"
                                        className="mt-2"
                                        onClick={() => this.props.backStep()}
                                    >
                                        Atras
                                    </Button>
                                    <Form.Item >
                                        <Button
                                            htmlType="submit"
                                            type="primary"
                                            style={{ width: "250px" }}
                                            size="large"
                                            className="mt-2"
                                        //disabled={!this.state.errores_spei.length == 0 || !this.state.errores_uso_cuenta.length == 0}
                                        >
                                            Aceptar
                                        </Button>
                                    </Form.Item>

                                </div>
                            </Col>
                        </Row>
                    </Spin>
                </Form>

                <ModalTemplates
                    visible={this.state.modal_visible}
                    tipo={this.state.tipo}
                    onSelect={(template) => {
                        this.addTemplate(template)
                        this.setState({ modal_visible: false })
                    }}
                    onClose={() => this.setState({ modal_visible: false })}

                />
            </>
        )
    }
}
