import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'
import axios from 'axios'
import { MdOutlinePlace } from 'react-icons/md'
import { getResponseError } from '../../Utils';
const { Option } = Select 


/**
 * @const SelectPais
 * @description Select para las paises registrados en el sistema
 */
const SelectPais = (props) => {

    const { 
        value, 
        onChange, 
        placeholder = "Seleccione el País", 
        onSelect = () => {},
        onClaveSelect = () => {}, 
        disabled = false, 
        className = "", 
        params = {}, 
        bordered, 
        allowClear = true, 
        onClear = () => {},
        size="default"
    } = props

    const [ paises, setPaises ] = useState([])


    /**
     * @const getPaises
     * @description Obitiene las paises
     */
    const getPaises = ({page, limit, search} = paises) => {
        axios.get('/paises', {
            params: {
                page,
                limit,
                search,
                ...params,
            }
        }).then(({ data }) => {
            setPaises(data)
        }).catch(error => {
            console.log("ERR, error",error)
            message.error(getResponseError(error.response, 'Error al obtener los paises'))
        })
    }

    //componentDidMount
    useEffect(() => {
        getPaises()
    }, [])

    useEffect(() => {
        if(value)
            onChange(value?.value ?? value)
    }, [value])

    return (
        <Select
            size={size}
            bordered={bordered}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            allowClear={allowClear}
            showSearch
            onSearch={(search)=> getPaises({search})}
            filterOption={false}
            labelInValue
            onSelect={(cuenta)=> {
                onChange(cuenta.value)
                onSelect(cuenta.value)

                const pais = paises.find(p => p._id === cuenta.value)
                onClaveSelect(pais.clave)
            }}
            value={value}
            onClear={()=>{
                onClear()
                onChange(undefined)
                onSelect(undefined)
                onClaveSelect(undefined)
            }}
            suffixIcon={<MdOutlinePlace fontSize={20}/>}
        >
            {
                paises?.map(({ _id, nombre, clave }) => <Option value={_id} key={_id}>
                    {nombre ?? ""} 
                </Option>)
            }
        </Select>
    );
}



export default SelectPais;