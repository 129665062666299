import React from 'react'
import { Layout, Col, Row, Typography, Button, Card, Space, message, Spin, Tooltip } from "antd";
import axios from 'axios';
import { Link } from 'react-router-dom';
import ReactEcharts from "echarts-for-react";
import { QuestionCircleOutlined } from "@ant-design/icons"

//componentes
import User from '../../Hooks/Logged';
import { getResponseError } from "../Utils";
import { Cuenta } from '../../Hooks/Cuentas';
import { useShowSearch } from '../../Hooks/Search';
import usePermissions from '../../Hooks/usePermissions';
import { IconMoney, IconColoredCuenta, IconBeneficiario } from '../Widgets/Icons';
import { CardResumenFinanciero, CardTransaccionReciente, CardCuentaInfo } from '../Widgets/Cards';

//Modales
import ModalCuenta from './Cuentas/ModalCuenta';
import ModalEstadoCuenta from './Cuentas/ModalEstadoCuenta';
import { ModalBeneficiario } from './Beneficiarios/ModalBeneficiario';
import ModalRevendedores from '../Admin/Revendedores/ModalRevendedores';
import ModalTransferencia from '../Admin/Transacciones/ModalTransferencia';

//css
import '../../Styles/Modules/dashboard.css'

const { Text } = Typography;
const { Content } = Layout;

class Dashboard extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			transacciones: [],
			modal_transaccion: false,
			cuenta: {
				balance: 0
			},
			ingresos: 0,
			egresos: 0,
			datos: [],
			fechas: [],
			cuentas: {
				data: [],
				loading: false,
				page: 1,
				limit: 10
			},
			loading_grafica: false,
			loading_transacciones: false,
			loading: false
		}
	}

	componentDidMount() {
		// Quitamos el search en esta seccion
		console.log('USER', this.props.usuario?.rol_id)
		this.props.setShowSearch(true)
		if (this.props.usuario?.rol_id?.tipo === 5 && this.props.cuenta) {	
		    this.getLists();
		}else if(this.props.usuario?.rol_id?.tipo !== 5){
			this.getLists();
		}
	}


	getLists = () => {
		this.getInfo()
		this.getHistorial()

		this.getTransacciones()
		this.getCuentas()
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.cuenta !== prevProps.cuenta) {
			this.getLists()
			console.log('this.props', this.props.cuenta);
		}

	}


	componentWillUnmount() {
		this.props.setShowSearch(true)
	}

	/**
	* @method getInfo
	* @description Obtiene la informacion del dashboard (saldo de la cuenta, e informacion de ingresos y egresos)
	*/
	getInfo = () => {
		this.setState({ loading: true })
		axios.get('/dashboard', {
			params: {
				cuenta_id: this.props.cuenta?._id
			}
		})
			.then(response => {
				this.setState({
					cuenta: response.data.cuenta,
					ingresos: response.data.transacciones.ingresos,
					egresos: response.data.transacciones.egresos,
					spei_balance: response.data.spei_balance,
					uso_balance: response.data.uso_balance,
				})

			}).catch(error => {
				console.log("error", error);
				message.error(getResponseError(error.response, "Ocurrio un error al cargar la información del dashboard"))
			}).finally(() => this.setState({ loading: false }))
	}

	getHistorial = () => {
		this.setState({ loading_grafica: true })
		axios.get('/dashboard/grafica', {
			// cuenta_id: this.props.cuenta?._id
			params: {
				cuenta: this.props.cuenta?._id
			}
		})
			.then(response => {
				this.setState({
					datos: response.data.totales,
					fechas: response.data.fechas.reverse(),
				})

			}).catch(error => {
				console.log("error", error);
				message.error(getResponseError(error.response, "Error al obtener historial de la cuenta"))
			}).finally(() => this.setState({ loading_grafica: false }))
	}


	/**
	* @method gegetTransaccionestInfo
	* @description Obtiene las ultimas transacciones 
	*/
	getTransacciones = () => {
		this.setState({ loading_transacciones: false })
		axios.get('/dashboard/recientes', {
			params: {
				cuenta: this.props.cuenta?._id
			}
		})
			.then(response => {
				this.setState({
					transacciones: response.data
				})

			}).catch(error => {
				console.log("error", error);
				message.error(getResponseError(error.response, "Error al obtener transacciones recientes"))
			}).finally(() => this.setState({ loading_transacciones: false }))
	}

	getCuentas = ({
		page,
		limit
	} = this.state.cuentas, { cuentas } = this.state) => {
		this.setState({ cuentas: { ...cuentas, loading: true } })
		axios.get('/cuentas', {
			params: {
				page,
				limit
			}
		}).then(({ data }) => {
			this.setState({
				cuentas: { ...cuentas, ...data }
			})
		}).catch(error => {
			message.error(getResponseError(error.response, "Error al obtener los beneficiarios"))
		}).finally(() => {
			this.setState(state => { state.cuentas.loading = false; return state })
		})
	}


	renderSaldo = () => {
		let { cuenta, ingresos, egresos } = this.state
		if ((this.props.usuario?.rol_id?.tipo != 3) || (this.props.puedeVerSaldo && this.props.usuario?.rol_id?.tipo == 3))
			return <>
				<Text>Saldo Disponible <Tooltip placement="topLeft" title={"Saldo utilizable"}> <QuestionCircleOutlined /> </Tooltip> </Text>
				<Text strong style={{ fontSize: '28px' }}>$ {(cuenta?.balance ? cuenta?.balance - cuenta.balance_congelado : 0).toMoney(true)}</Text>
			</>
	}

	render() {
		let { cuenta, ingresos, egresos } = this.state;
		return (
			<>
				<Content className="pt-1 pl-1">
					<Row>
						<Col xs={24} lg={17} className="" style={{ paddingRight: '0.5rem' }}>
							<Spin spinning={this.state.loading} className="width-100 flex">
								<Row gutter={[16, 16]} className="mb-1">
									<Col xs={24} md={7} className="flex-left-column ">
										<Button
											type="primary"
											icon={<IconMoney />}
											className="btn-green-money"
										/>
										{this.renderSaldo()}
										<small className="mb-1">
											{(this.props.usuario?.rol_id?.tipo !== 4) &&
												<>
													Saldo Congelado: $ {(cuenta.balance_congelado ?? 0).toMoney(true)} mxn&nbsp;&nbsp;
													<Tooltip
														placement="topLeft"
														title={"Saldo restringido: es el total de la suma de los balances de los clientes, más las transacciones PENDIENTES de la cuenta concentradora"}
													>
														<QuestionCircleOutlined />
													</Tooltip>
												</>}


										</small>
										<Link to={"/admin/transacciones"}><Button type="primary">Ver Movimientos</Button></Link>
									</Col>
									<Col xs={24} md={17}>
										<Row className="m-0" gutter={[16, 8]}>
											<Col span={24} className="pb-1">
												<Text strong>Resumen</Text>
											</Col>
											<Col xs={24} md={12}>
												<CardResumenFinanciero
													title="Ingresos del Mes"
													monto={ingresos}

												/>
											</Col>
											<Col xs={24} md={12}>
												<CardResumenFinanciero
													title="Egresos del Mes"
													monto={egresos}
													tipo={2}
												/>
											</Col>

										</Row>
									</Col>
								</Row>
							</Spin>
							<Row>
								<Col span={24}>
									<Card size="small" loading={this.state.loading_grafica} style={{ minHeight: '400px' }}>
										<Row>
											<Col span={18}>
												<Text>Ingresos vs Egresos</Text>
											</Col>
											<Col span={6} className="flex-right">
												<Text>1 Año</Text>
											</Col>
											<Col span={24}>
												<ReactEcharts
													style={{ height: '380px', maxWidth: '1600px', margin: "0 auto" }}
													theme="light"
													className="pd-1"
													option={{
														tooltip: {
															show: true,
															trigger: 'axis',
															backgroundColor: 'rgba(50,50,50,0.7)',
															borderColor: '#333',
															position: function (point, params, dom, rect, size) {
																return [point[0], point[1] - 100];
															},
															textStyle: {
																color: "#ccc"
															}
														},
														xAxis: [{
															type: 'category',
															axisTick: {
																alighWithLabel: true
															},
															axisLine: {
																onZero: false,
															},
															axisPointer: {
																label: 'axis'
															},
															data: this.state.fechas
														}],
														yAxis: [{
															type: 'value',
														}],
														series: this.state?.datos?.map((item, index) => {
															return {
																name: item._id == 1 ? "Ingresos" : "Egresos",
																type: 'line',
																emphasis: {
																	focus: 'series'
																},
																data: this.state?.fechas.map((fecha) => this.state.datos[index]?.totales[`${String(fecha)}`]) || [],
																smooth: true,
															}
														})
													}}
												/>
											</Col>
										</Row>
									</Card>
								</Col>
								<Col xs={24} lg={10} xxl={10} className="mt-1">
									<Text strong>Links Rápidos</Text>
									<Space style={{ width: '100%' }} className="dashboard-button-container">
										<Button
											disabled={!this.props.createClientes}
											className="btn-fast-link"
											onClick={() => { this.setState({ modal_cliente: true }) }}
										>
											Nuevo Cliente
											<IconBeneficiario />
										</Button>
										<Button
											disabled={!this.props.createCuentas}
											className="btn-fast-link"
											onClick={() => this.setState({ modal_cuenta: true })}
										>
											Nueva Cuenta
											<IconColoredCuenta />
										</Button>
									</Space>
								</Col>
								<Col xs={24} lg={14} xxl={14} className="mt-1">
									<Text strong>Transacciones Recientes</Text>
									<div className="mt-1">
										{
											this.state.transacciones.map((e, index) => {
												return <CardTransaccionReciente {...e} key={index} loading={this.state.loading_transacciones} />
											})
										}
									</div>
								</Col>
							</Row>

						</Col>
						<Col xs={24} lg={7} className="grid" style={{ paddingLeft: '0.5rem' }}>
							<CardCuentaInfo
								title={(this.props.usuario?.rol_id?.tipo == 4) ? "Cuenta Cliente" : "Cuenta Concentradora"}
								loading={false}
								cuenta={this.state.cuenta}
								subtitle="Cuentas"
								data={this.state.cuentas.data}
								concentradora={this.props.usuario?.rol_id?.tipo != 4}
								spei_balance={this.state.spei_balance}
								uso_balance={this.state.uso_balance}
								openModal={()=> {
									this.setState({modal_visible_estado: true})
								}}
							/>
						</Col>
					</Row>
				</Content>
				<ModalTransferencia
					visible={this.state.modal_transaccion}
					onClose={() => {
						this.setState({ modal_transaccion: false })
						this.getInfo()
						this.getHistorial()
						this.getTransacciones()
						this.getCuentas()
					}}
				/>

				<ModalBeneficiario
					visible={this.state.modal_benficiario}
					onClose={() => {
						this.setState({ modal_benficiario: false })
						this.getInfo()
						this.getHistorial()
						this.getTransacciones()
						this.getCuentas()
					}}
				/>

				<ModalRevendedores
					visible={this.state.modal_cliente}
					onClose={() => {
						this.setState({ modal_cliente: false })
					}}
					title="Creación del Cliente"
					tipo="cliente"
				/>

				<ModalCuenta
					visible={this.state.modal_cuenta}
					onClose={() => {
						this.setState({ modal_cuenta: false })
					}}
				/>

				<ModalEstadoCuenta
                    visible={this.state.modal_visible_estado}
                    onClose={() => {
                        this.setState({ modal_visible_estado: false })
                    }}
                    cuenta_id={this.props.cuenta?._id}
                />
			</>
		)
	}
}

export default () => {
	const [showSearch, setShowSearch] = useShowSearch();

	let user = React.useContext(User)
	let cuenta = React.useContext(Cuenta)

	let permissions = usePermissions(user?.rol_id?.permisos, {
		createClientes: ["clientes", "create"],
		createCuentas: ["cuentas", "create"],
		puedeVerSaldo: ["cuentas", "ver_saldo"],
	})


	return <Dashboard cuenta={cuenta} usuario={user} setShowSearch={setShowSearch} {...permissions} />
}