import React from 'react';
import { Select } from 'antd'
import { UserOutlined } from '@ant-design/icons';


/**
 * @const SelectActividadEconimica
 * @description Select para las actividades econimicas aceptadas por STP
 */
const SelectActividadEconimica = (props) => {

    const {
        value,
        onChange,
        placeholder = "Seleccione la Actividad Económica",
        onSelect = () => { },
        params = {},
        onClear = () => { },
        suffixIcon = null,
    } = props


    return (
        <Select
            {...props}
            onSelect={(b) => {
                onChange(b)
                onSelect(b)
            }}

            onClear={() => {
                onClear()
                onChange(undefined)
                onSelect(undefined)
            }}
            showSearch={true}
            optionFilterProp="children"
            suffixIcon={suffixIcon ? <UserOutlined style={{ fontSize: '18px' }} /> : null}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            options={actividades}
        />


    );
}


const actividades = [
    {
        "value": 28,
        "label": "SERVICIOS - PASARELA DE PAGOS"
    },
    {
        "value": 29,
        "label": "SERVICIOS - TURÍSTICOS"
    },
    {
        "value": 30,
        "label": "FINTECH - CROWDFOUNDING"
    },
    {
        "value": 31,
        "label": "FINTECH - WALLET"
    },
    {
        "value": 32,
        "label": "FINTECH - MONEDAS VIRTUALES"
    },
    {
        "value": 33,
        "label": "SERVICIOS - OUTSOURCING"
    },
    {
        "value": 34,
        "label": "MANUFACTURA"
    },
    {
        "value": 35,
        "label": "SOCIEDADES FINANCIERAS DE OBJETO MÚLTIPLE REGULADAS"
    },
    {
        "value": 36,
        "label": "SOCIEDADES FINANCIERAS DE OBJETO MÚLTIPLE NO REGULADAS"
    },
    {
        "value": 37,
        "label": "INSTITUCIONES FINANCIERAS DEL EXTRANJERO"
    },
    {
        "value": 38,
        "label": "SERVICIOS DE INSTITUCIONES DE CRÉDITO, ORGANIZACIONES AUXILIARES E INSTITUCIONES DE SEGUROS Y FIANZAS"
    },
    {
        "value": 39,
        "label": "SERVICIOS RELACIONADOS CON INMUEBLES"
    },
    {
        "value": 40,
        "label": "SERVICIOS PROFESIONALES Y TÉCNICOS"
    },
    {
        "value": 41,
        "label": "SERVICIOS DE BUFETES JURÍDICOS"
    },
    {
        "value": 42,
        "label": "SERVICIOS DE ANÁLISIS DE SISTEMAS Y PROCESAMIENTO ELECTRÓNICO DE DATOS"
    },
    {
        "value": 43,
        "label": "SERVICIOS DE AGENCIAS DE COLOCACIÓN Y SELECCIÓN DE PERSONAL"
    },
    {
        "value": 44,
        "label": "SERVICIOS MEDICO GENERAL Y ESPECIALIZADO EN CONSULTORIOS"
    },
    {
        "value": 45,
        "label": "ESTABLECIMIENTOS PRIVADOS DE INSTRUCCIÓN, EDUCACIÓN, CULTURA E INVESTIGACIÓN"
    },
    {
        "value": 47,
        "label": "SOCIEDADES DE AHORRO Y CRÉDITO POPULAR"
    },
    {
        "value": 48,
        "label": "SOCIEDADES DE INVERSIÓN"
    },
    {
        "value": 49,
        "label": "COMPRAVENTA DE GAS PARA USO DOMESTICO O COMERCIAL"
    },
    {
        "value": 50,
        "label": "CONTRATACIÓN DE OBRAS COMPLETAS DE CONSTRUCCIÓN (CASAS, DEPARTAMENTOS, INMUEBLES, PAVIMENTACIÓN, NO RESIDENCIALES, VÍAS DE COMUNICACIÓN)"
    },
    {
        "value": 51,
        "label": "SERVICIOS DE ENSEÑANZA PRE PRIMARIA Y PRIMARIA"
    },
    {
        "value": 52,
        "label": "SERVICIOS DE ENSEÑANZA SECUNDARIA"
    },
    {
        "value": 53,
        "label": "SERVICIOS DE ENSEÑANZA COMERCIAL Y DE IDIOMA"
    },
    {
        "value": 54,
        "label": "CASAS DE BOLSA NACIONALES"
    },
    {
        "value": 55,
        "label": "ADMINISTRACIÓN DE INMUEBLES"
    },
    {
        "value": 56,
        "label": "COMPAÑÍAS DE SEGUROS NACIONALES"
    },
    {
        "value": 57,
        "label": "CASA DE CAMBIO"
    },
    {
        "value": 58,
        "label": "CONSTRUCCIÓN DE INMUEBLES"
    },
    {
        "value": 59,
        "label": "COMPRAVENTA DE ARTÍCULOS DE PLATA"
    },
    {
        "value": 60,
        "label": "COMPRAVENTA DE OTRAS JOYAS"
    },
    {
        "value": 61,
        "label": "COMPRAVENTA DE RELOJES"
    },
    {
        "value": 62,
        "label": "SERVICIOS DE BLINDAJE"
    },
    {
        "value": 63,
        "label": "COMPRAVENTA DE AUTOMÓVILES Y CAMIONES NUEVOS"
    },
    {
        "value": 64,
        "label": "COMPRAVENTA DE CASAS Y OTROS INMUEBLES"
    },
    {
        "value": 65,
        "label": "TARJETA DE CRÉDITO"
    },
    {
        "value": 66,
        "label": "CENTROS CAMBIARIOS"
    },
    {
        "value": 67,
        "label": "AGENCIA ADUANAL"
    },
    {
        "value": 68,
        "label": "MONTEPIO O CASAS DE EMPEÑO"
    },
    {
        "value": 69,
        "label": "FACTORING"
    },
    {
        "value": 70,
        "label": "ADMINISTRADORAS DE TARJETA DE CRÉDITO"
    },
    {
        "value": 71,
        "label": "NOTARIAS PUBLICAS"
    },
    {
        "value": 72,
        "label": "EMPRESAS TRANSPORTADORAS DE VALORES"
    },
    {
        "value": 73,
        "label": "JUEGOS DE FERIA Y APUESTAS"
    },
    {
        "value": 74,
        "label": "TRANSMISORES DE DINERO O DISPERSORES"
    }
]


export default SelectActividadEconimica;