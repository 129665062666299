import React from 'react'
import { Layout, Col, Row, Typography, List, Card, message } from "antd";
import axios from 'axios';

import { ModalBeneficiario } from '../../Beneficiarios/ModalBeneficiario';
import { TipoCuentaText } from '../../../Utils';

import { User } from '../../../../Hooks/Logged';
import usePermissions from '../../../../Hooks/usePermissions';
import { getResponseError } from '../../../Utils';

const { Content } = Layout;
const { Text } = Typography

class Beneficiarios extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            beneficiarios: {
                page: 1,
                limit: 10,
                total: 0,
                pages: 0,
                // filters: filters ?? [],
                // sort: sort ?? {},
                data: [],
            }
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.get()
    }

    get = ({
        page = this.state.beneficiarios.page,
        limit = this.state.beneficiarios.limit
    } = this.state.beneficiarios) => {
        this.setState({ loading: true })
        axios.get('/beneficiarios', {
            params: {
                page,
                limit,
                cliente_id: this.props.cliente_id
            }
        }).then(({ data }) => {
            this.setState({
                beneficiarios: data
            })
        }).catch(error => {
            message.error(getResponseError(error.response, "Error al obtener las cuentas"))
        }).finally(() => {
            this.setState({ loading: false })
        })
    }





    render() {
        return (
            <>
                <Content className="pt-1 ">
                    <Row className='main pl-1 pr-1'>
                        <Col xs={24}>
                            <List
                                loading={this.state.loading}
                                className="component-list"
                                itemLayout="horizontal"
                                locale={{ emptyText: "Sin Beneficiarios" }}
                                dataSource={this.state.beneficiarios?.data}
                                pagination={{
                                    current: this.state.beneficiarios.page,
                                    pageSize: this.state.beneficiarios.limit,
                                    total: this.state.beneficiarios.total,
                                    position: 'bottom',
                                    className: "flex-left",
                                    showSizeChanger: true,
                                    pageSizeOptions: [20, 50, 100, 500],
                                    showQuickJumper: true,
                                    locale: { jump_to: "Ir a la página", page: '' },
                                    onChange: (page, limit) => {
                                        this.get({ page, limit })
                                    },
                                    onShowSizeChange: (page, limit) => {
                                        this.get({ page, limit })
                                    }
                                }}
                                header={<Row className="header-list width-100 ">
                                    <Col xs={0} sm={0}  md={4} className=" center">
                                        <Text>Nombre</Text>
                                    </Col>
                                    <Col xs={0} sm={0}  md={4} className="center">
                                        <Text>Cuenta</Text>
                                    </Col>
                                    <Col xs={0} sm={0}  md={4} className="center">
                                        <Text>Alias</Text>
                                    </Col>
                                    <Col xs={0} sm={0}  md={4} className="center">
                                        <Text>Tipo</Text>
                                    </Col>
                                    <Col xs={0} sm={0}  md={4} className="center">
                                        <Text>Institución</Text>
                                    </Col>
                                    <Col xs={0} sm={0}  md={4}></Col>
                                </Row>
                                }

                                renderItem={item => (
                                    <List.Item className="component-list-item">
                                        <Card className="card-list width-100 pointer-hand" bordered={false} >
                                            <Row className="width-100" align={"middle"}>
                                                <Col xs={24} sm={12}  md={4} className="center">
                                                    <Text ellipsis className="text-gray">{item.nombre}</Text>
                                                </Col>
                                                <Col xs={24} sm={12}  md={4} className="center">
                                                    <Text ellipsis className="text-gray">{item.cuenta}</Text>
                                                </Col>
                                                <Col xs={24} sm={12}  md={4} className="center">
                                                    <Text ellipsis className="text-gray">{item.alias}</Text>
                                                </Col>
                                                <Col xs={24} sm={12}  md={4} className="center">
                                                    <Text ellipsis className="text-gray">{TipoCuentaText(item.tipo)}</Text>
                                                </Col>
                                                <Col xs={24} sm={12}  md={4} className="center">
                                                    <Text ellipsis className="text-gray">{item.institucion_id?.nombre}</Text>
                                                </Col>
                                                <Col xs={24} sm={12}  md={4} className="center">
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />
                        </Col>
                    </Row>
                </Content>
                <ModalBeneficiario
                    beneficiario_id={this.state.beneficiario_id}
                    cliente_id={this.props.cliente_id}
                    visible={this.state.modal_visible}
                    onClose={
                        () => {
                            this.setState({ modal_visible: false, beneficiario_id: null })
                            this.get(this.state.beneficiarios.page)
                        }
                    }
                />
            </>
        )
    }
}

export default function (props) {

    let user = React.useContext(User)

    let permissions = usePermissions(user?.rol_id?.permisos, {
        editBeneficiarios: ["clientes", "beneficiarios", "edit"],
        createBeneficiarios: ["clientes", "beneficiarios", "create"],
        deleteBeneficiarios: ["clientes", "beneficiarios", "delete"]
    })

    return (<Beneficiarios {...props} {...permissions} />)
}