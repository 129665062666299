import React, { Component, useContext } from 'react'
import { Row, Col, Form, Input, Button, Card, Spin, Divider, Typography, Checkbox, Alert, Layout, Modal } from 'antd';
import { Navigate  } from 'react-router-dom';
import { UserOutlined, LockOutlined } from '@ant-design/icons';


//css
import '../../Styles/Modules/Auth/auth.scss';
import axios from 'axios';

const { Title, Text } = Typography

/**
 * 
 * @class Recovery
 * @extends {Component}
 * @description Componente Recovery page
 */
class Recovery extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false
		}
	}

	componentDidMount(){
		console.log('props',this.props)
	}

	onFinish = (values) => {
		this.setState({loading: true})
        axios.put("/password/recovery", { email: values.email })
            .then(res => {
                Modal.success({
                    title: 'Restablecer contraseña.',
                    content: '¡El correo ha sido enviado!'
                })
            })
            .catch(res => {
                console.log(res)
                Modal.warning({
                    title: 'Error',
                    content: 'El correo no se ha podido enviar. Verifica que el correo sea correcto'
                });
            })
            .finally(() => {
                this.setState({
                    loading: false
                })
            })
	}

	render() {


		return(
			<Layout className="auth" style={{ backgroundImage: "url(/img/background/background-main.png)"}}>
				<Card className="card">
					<Row align="center">
						<Col span={24} className="center mb-1">
							<img src={"/img/BXNKLogo.png"} width="164"/>
						</Col>
						<Col span={24} className="text-center mb-2">
							<Title>Restablecer Contraseña</Title>
							<Text>Ingresa tu correo y te enviaremos un link para restablecerla</Text>
						</Col>
						<Col span={20}>
							<Form
								onFinish={this.onFinish}
							>
								<Form.Item
							      	name="email"
							      	rules={[{ required: true, message: 'Ingrese su correo electrónico' }]}
							    >
							      	<Input
							      		prefix={<UserOutlined />}
							      		placeholder="Correo Electrónico"
							      		size="large"
							      	/>
							    </Form.Item>

							    <Form.Item >
							     	<Button type="primary" htmlType="submit" loading={this.state.loading} block size="large">
							        	Enviarme el link
							      	</Button>
							    </Form.Item>
							</Form>
						</Col>
					</Row>
				</Card>
			</Layout>
		)
	}
}

export default function (props) {

	return <Recovery {...props} />
}