import React from 'react'
import { Route, Routes } from "react-router-dom";

import Transacciones from '../../Components/Admin/Transacciones/Transacciones';

/**
 * 
 * @export
 * @function RouterTransacciones
 * @description Router for dashboard routes 
 */
function RouterTransacciones(props) {
  return (
    <Routes>
      <Route path='/' element={<Transacciones {...props}/>}/>
    </Routes>
  )
}

export default RouterTransacciones