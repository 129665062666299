import React from 'react'
import { Route, Routes } from "react-router-dom";

import Cobranza from '../../Components/Admin/Cobranza/Cobranza';

/**
 * 
 * @export
 * @function RouterCobranza
 * @description Router for dashboard routes 
 */
function RouterCobranza(props) {
  return (
    <Routes>
      <Route path='/' element={<Cobranza {...props}/>}/>
    </Routes>
  )
}

export default RouterCobranza