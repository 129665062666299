import React, { useContext, useEffect, useState, useRef } from 'react';
import { Space, Dropdown, Col, Row, Layout, Typography, message, Select, Input, Form, Modal } from 'antd';
import { useNavigate } from "react-router-dom";
import axios from "axios"
import { DownOutlined } from '@ant-design/icons';
import Modal2FA from '../Auth/Modal2FA'
import './header.css'
import { User, SetUser } from '../../Hooks/Logged';
import { Cuenta, SetCuenta } from '../../Hooks/Cuentas';
import { useSearch, useShowSearch } from '../../Hooks/Search';
import { RenderRecibeComision } from '../Utils';
//Modales
import ModalClientes from './ModalClientes'
import ModalUsuarios from './ModalUsuario'
import { getResponseError } from '../Utils';

let myRef = React.createRef();

const { Header } = Layout;
const { Option } = Select;
const { Title, Text } = Typography;
const { Search } = Input;

/**
 * @const Navbar
 * @description Header del sistema
 * @param {*} {  showResponsiveSidebar, showSearch, setFilterSearch }
 */
const Navbar = ({ showResponsiveSidebar, admin }) => {

	const user = useContext(User)
	const setUser = useContext(SetUser)

	const refCuentas = useRef(null)

	const setCuenta = useContext(SetCuenta)
	const navigate = useNavigate()

	const [, setSearch] = useSearch()
	const [showSearch,] = useShowSearch()

	const [cuentas, setCuentas] = useState({
		data: [],
		page: 1,
		limit: 10,
		total: 0,
		search: null,
	})

	const [modalVisible, setModalVisible] = useState(false)
	const [modalUsuario, setModalUsuario] = useState(false)

	const [modalQr, setModalQR] = useState(false)
	const [qrcode, setQrcode] = useState(null)

	/**
	 * @const cerrarSesion
	 * @description Cierra la sesion
	 */
	const cerrarSesion = () => {
		setUser(undefined);
		sessionStorage.clear();
		navigate("/")
	};


	const items = [
		{
			key: 'logout',
			label: "Cerrar Sesión"
		}
	];

	if (!admin && !user?.parent_user) items.push({
		key: 'info',
		label: 'Información de la Cuenta'
	})

	if (admin || user?.parent_user) items.push({
		key: 'info-admin',
		label: 'Información de la Cuenta'
	})

	useEffect(() => {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

		if (user.rol_id?.tipo !== 1 || user.rol_id?.tipo == 4) { //si no es admin
			getCuentas()
		}
	}, [])

	useEffect(() => {
		if (cuentas?.data?.length > 0) {
			if (sessionStorage.getItem("cuenta_id") != "undefined") {
				let index = cuentas.data.findIndex(e => e._id.toString() === sessionStorage.getItem("cuenta_id"))
				if (index !== -1) {
					setData(index)
				} else {
					setData(0)
				}
			} else {
				setData(0)
			}
		}
	}, [cuentas])


	const setData = (value) => {
		if (value !== undefined && value !== -1) {
			setCuenta(cuentas?.data[value])
			sessionStorage.setItem("cuenta_id", cuentas?.data[value]?._id)
			refCuentas?.current?.setFieldsValue({
				cuenta: cuentas?.data[value]?._id ? {
					value: cuentas.data[value]._id,
					label: <>{cuentas.data[value].nombre ?? ""}<small> {cuentas.data[value].cuenta ?? ""}</small></>
				} : null
			})
		}
	}

	/**
	 * @const getCuentas
	 * @description Obitiene las cuentas
	 */
	const getCuentas = ({ page, limit, search } = cuentas) => {
		axios.get('/cuentas', {
			params: {
				page,
				limit,
				search,
			}
		}).then(({ data }) => {
			setCuentas(data)
		}).then(() => {

		}).catch(error => {
			console.log("ERR, error", error)
			message.error(getResponseError(error.response, 'Error al obtener las cuentas'))
		})
	}

	return (
		<>
			<Header className="header-admin">
				<Row justify={"space-between"} gutter={[0, 8]}>
					<Col xs={24} md={7} className="">
						<Title level={5} ellipsis>Buenas Tardes {user?.nombre_completo}</Title>
					</Col>
					{admin && showSearch ? (
						<Col xs={24} md={7} className="flex-left"> <Search
							placeholder="Buscar"
							onSearch={(e) => { setSearch(e) }}
							id="inputFilterSearch"
							enterButton
							ref={myRef}
							className="input-global-search"
						/>
						</Col>
					) : null}

					{!admin && showSearch ? (<>

						<Col xs={24} md={7} className="flex-left">
							<Form className='width-100' ref={refCuentas}>
								<Form.Item name="cuenta">
									<Select
										placeholder="Selecciona una cuenta"
										onChange={(e) => setData(e)}
									>
										{cuentas?.data?.map((cuenta, index) => <Option value={index} key={cuenta._id}>
											{cuenta.nombre ?? ""}
											<small> {cuenta.cuenta ?? ""}</small>
											{user?.rol_id?.tipo === 5 || user?.rol_id?.tipo === 4 ? RenderRecibeComision({cuenta, style: {marginLeft: "4px"}}) : null}
										</Option>)}
									</Select>

								</Form.Item>
							</Form>

						</Col>

					</>
					) : null}

					<Col xs={24} md={10} className="flex-right">
						<Dropdown
							menu={{
								items,
								onClick: (item) => {
									if (item.key === 'logout') cerrarSesion()
									if (item.key === 'info') setModalVisible(true)
									if (item.key === 'info-admin') setModalUsuario(true)
								}
							}}

						>
							<a onClick={(e) => e.preventDefault()}>
								<Space>
									<Text>{user?.nombre_completo}</Text>
									<DownOutlined />
								</Space>
							</a>
						</Dropdown>
					</Col>
					{!admin ?
						<Col xs={24} lg={{ span: 7, push: 7 }} md={{ span: 24, }} className='center'>
							<Search
								placeholder="Buscar"
								onSearch={(e) => { setSearch(e) }}
								id="inputFilterSearch"
								enterButton
								ref={myRef}
								className="input-global-search"
							/>
						</Col> : null}
				</Row>
			</Header >
			<ModalClientes
				visible={modalVisible}
				onClose={() => {
					setModalVisible(false)
				}}
				cliente_id={user?.cliente_id}
				auth_2FA={true}
				openQR={(qrcode) => {
					setModalQR(true)
					setQrcode(qrcode)
				}}
			/>
			<Modal2FA
				visible={modalQr}
				qrcode={qrcode}
				onClose={() => {
					//setModalQR(false)
				}}
				login={() => {
					setModalQR(false)
				}}
				logged={true}
			/>

			<ModalUsuarios
				visible={modalUsuario}
				onClose={() => {
					setModalUsuario(false)
				}}
				usuario_id={user._id}
				openQR={(qrcode) => {
					setModalQR(true)
					setQrcode(qrcode)
				}}
			/>
		</>
	);

}

export default Navbar;