import React, { forwardRef } from 'react'
import { Collapse, Layout, Col, Row, Pagination, Typography, message, List, Divider, Space, Button, Radio, } from "antd";
import axios from 'axios';
import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { useSearchParams, useNavigate } from 'react-router-dom'

import { User } from '../../../Hooks/Logged'
import usePermissions from '../../../Hooks/usePermissions';
import { CardTemplateRegla, } from '../../Widgets/Cards';
import FloatingButton from '../../Widgets/FloatingButton/FloatingButton';
import ModalBloqueVendedor from './ModalBloqueVendedor';
import useSearch from '../../../Hooks/Search';
import { getResponseError } from '../../Utils'


const { Content } = Layout
const { Title } = Typography
const { Panel } = Collapse

class Reglas extends React.Component {

    constructor(props) {
        super(props)
        let { page, limit, filters, search, sort } = this.props.params
        this.state = {
            loading: false,
            bloques_vendedores: {
                page: page ?? 1,
                limit: limit ?? 20,
                total: 0,
                pages: 0,
                search: search ?? undefined,
                data: [],
            },
            searching: true,
            filtroSearch: '',
            template_id: undefined,
            tipo: undefined
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        console.log('props', this.props)
        // this.props.updateFilterSearch(true);
        this.get()

    }



    /**
     * @memberOf reglas
     * @method get
     * @descripcion Obtiene los reglas registradas en el sistema
     * */
    get = ({
        page = this.state.bloques_vendedores.page,
        limit = this.state.bloques_vendedores.limit,
        search = this.props.search
    } = this.state.bloques_vendedores) => {

        this.setState({ loading: true })
        axios.get('/bloques-vendedores', {
            params: {
                page,
                limit,
                search,
            }
        }).then(({ data }) => {
            this.setState({
                bloques_vendedores: {
                    ...this.state.bloques_vendedores,
                    ...data,
                },
                loading: false,
                searching: true,
                filtroSearch: search
            });
        }).catch(error => {
            console.log("error", error);
            message.error(getResponseError(error.response, "Error al obtener las reglas"))
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    /**
     *
     *
     * @memberof Reglas
     */
    deleteTemplate = (id) => {
        axios.delete('/bloques-vendedores', {
            params: {
                bloque_vendedor_id: id
            }
        }).then((res) => {
            message.success('Eliminado Correctamente')
            this.get();
        })
            .catch((error) => {
                message.error(getResponseError(error.response, 'No se pudo eliminar'))
            })
    }

    openDrawer = (id) => {
        this.setState({ template_id: id, drawer_reglas: true })
    }
    render() {

        return (
            <>
                <Row gutter={[16, 16]} className="width-100">
                    <List
                        className="width-100"
                        size="large"
                        dataSource={this.state.bloques_vendedores.data}
                        renderItem={({ _id, nombre, bloques_vendedores }, index) => <>
                            <Collapse key={index} className='mb-1' >
                                <Panel header={this.props.option? <Radio value={_id} onClick={e => {
                                    e.stopPropagation()
                                    this.props.onSelectOption(bloques_vendedores)
                                }}>{nombre}</Radio>: nombre} key="1" extra={
                                    <Space>
                                        {!this.props.option && <>
                                            <Button
                                                icon={<EditOutlined />}
                                                onClick={e => {
                                                    e.stopPropagation()
                                                    this.props.onEdit(_id)
                                                }}
                                            />
                                            <Button danger icon={<DeleteOutlined />}
                                                onClick={e => {
                                                    e.stopPropagation()
                                                    this.deleteTemplate(_id)
                                                }}
                                            />
                                        </>}

                                    </Space>
                                }>
                                    <List
                                        size="small"
                                        bordered
                                        dataSource={bloques_vendedores}
                                        renderItem={({ nombre, porcentaje_comision, vendedores }) => {

                                            let bloque_comision_porcentaje = porcentaje_comision

                                            return <Row style={{ padding: '12px 16px' }}>
                                                <Row style={{ width: "100%" }}>
                                                    <Col span={16} >
                                                        <Title level={5}>{nombre}</Title>
                                                    </Col>
                                                    <Col span={8} align='center'>
                                                        <Title level={5}>{porcentaje_comision}% </Title>
                                                    </Col>
                                                </Row>
                                                <Row style={{ width: "100%" }}>
                                                    <List
                                                        style={{ width: "100%" }}
                                                        size="small"
                                                        dataSource={vendedores}
                                                        renderItem={({ vendedor_id, porcentaje_comision }) => <List.Item>
                                                            <Row style={{ width: "100%" }}>
                                                                <Col span={16} >
                                                                    {console.log('vendedor_id', vendedor_id)}
                                                                    <Title level={5}>{vendedor_id?.nombre_completo}</Title>
                                                                </Col>
                                                                <Col span={8} align='center'>
                                                                    <Title level={5}>{porcentaje_comision}% &nbsp; <small>({porcentaje_comision * (bloque_comision_porcentaje / 100)}%)</small></Title>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <Divider style={{ margin: 0 }} />
                                                                </Col>
                                                            </Row>
                                                        </List.Item>}
                                                    />
                                                </Row>
                                            </Row>
                                        }}
                                    />
                                </Panel>
                            </Collapse>
                        </>}
                    />
                </Row>
                <Pagination
                    current={this.state.bloques_vendedores.page}
                    pageSize={this.state.bloques_vendedores.limit}
                    total={this.state.bloques_vendedores.total}
                    // hideOnSinglePage={true}
                />
            </>
        )
    }
}

export default forwardRef(function (props, ref) {

    let user = React.useContext(User)
    const [search, setSearch] = useSearch();
    const [params, setParams] = useSearchParams();

    let permissions = usePermissions(user?.rol_id?.permisos, {
        editReglas: ["reglas", "edit"],
        updateReglas: ["reglas", "update"],
        createReglas: ["reglas", "create"],
        deleteTemplates: ["reglas", "delete"],

    })

    return (<Reglas ref={ref} {...props} navigate={useNavigate()} search={search} setParams={setParams} params={params} {...permissions} />)
})