import React, { Component } from "react";
import { Row, Col, Modal, Typography, message, Form, Statistic, Spin, Tag, Divider } from 'antd';
import axios from "axios"
import { ClockCircleOutlined } from "@ant-design/icons"
import { getResponseError } from '../../Utils'
import { Cuenta } from '../../../Hooks/Cuentas';
const { Text, Title } = Typography;

const moment = require('moment');

/**
 * @class TransaccionesDetalle
 * @extends {Component}
 */
class TransaccionesDetalle extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            transaccion: {
                _id: 0,
                concepto: '',
                monto: 0,
                beneficiarios: [{
                    nombre: '',
                    cuenta: ''
                }],
                tipo: 0,
                tipo_pago: 0,
                referencia_numerica: 0,
                ejecutada: false
            },
        }

    }

    modalRef = React.createRef();

    componentDidMount() {

        if(this.props.transaccion_id != null && this.props.transaccion == null)
            this.get()
        if(!this.props.transaccion_id && this.props.transaccion){
            this.setState({transaccion: this.props.transaccion})
        }
    }


    /**
    * @memberof ModalTran
    * @method get
    * @description Obtiene la informacion de la transacciones para el modal de detalle. Actualiza el state
    * con informacion obtenida.
    */
    get = () => {
        this.setState({ loading: true })
        axios.get('/transacciones/' + this.props.transaccion_id, {
            params: {
                id: this.props.transaccion_id
            }
        }).then(({ data }) => {
            this.setState({
                transaccion: data
            })

        }).catch(error => {
            message.error(getResponseError(error.response, "Error al obtener la información de la transacción"))
        }).finally(() => {
            this.setState({ loading: false })
        })

    }

    /**
    * @memberof ModalTran
    * @method renderTipo
    * @description Renderiza el tipo de transaccion que es dependiendo de quien la vea si un admin o el cliente
    */
    renderTipo = () => {
        const { transaccion } = this.state;
        const { cuenta } = this.props;

        //Si la cuenta en el hook es igual a la del beneficiario es un
        if (cuenta?.cuenta && cuenta?.cuenta === transaccion?.beneficiarios[0]?.cuenta) {
            return "Ingreso";
        } else {
            return transaccion.tipo === 1 ? "Ingreso" : "Egreso";
        }
    }



    getPDF = () => {
        let urlPDF = new URL(axios.defaults.baseURL + '/transaccion/comprobante');
        urlPDF.searchParams.append('Authorization', sessionStorage.getItem('token'))
        urlPDF.searchParams.append('id', this.state.transaccion._id)
        return urlPDF.href
        
    }

    render() {
        const { transaccion } = this.state;
        return (
            <Spin spinning={this.state.loading}>
                <Form layout="vertical"  >
                    <Row justify="center" gutter={[12,12]}>
                        <Col span={24} >
                            <Text strong>#{transaccion.clave_rastreo}</Text>
                        </Col>
                        <Col span={24} >
                            <Text strong>Fecha / Hora </Text> <Text className="ml-2">{moment(transaccion.fecha_operacion).format('MMM DD, YYYY / HH:mm')}</Text>
                        </Col>
                        <Divider/>
                        <Col span={12} className="flex-left-column">
                            <Text strong>Ordenante / Cuenta</Text>
                            <Text>{transaccion?.ordenante?.nombre ?? ""}</Text>
                            <Text>{transaccion?.ordenante?.cuenta?.hideCard()}</Text>
                        </Col>
                        <Col span={12} className="flex-right-column">
                            <Text strong>Beneficiario / Cuenta</Text>
                            <Text>{transaccion?.beneficiarios[0]?.nombre?? ""}</Text>
                            <Text>{transaccion?.beneficiarios[0]?.cuenta.hideCard()}</Text>
                        </Col>
                        <Col span={24} className="mt-2 mb-1">
                        </Col>
                        <Col span={24} >
                            <Text strong>Datos de la Transferencia</Text>
                            <Divider className="mt-0"/>
                        </Col>
                        <Col span={12} >
                            <Text>Monto</Text>
                        </Col>
                        <Col span={12} className="flex-right" >
                            <Statistic
                                value={transaccion.monto}
                                valueStyle={{ fontSize: '16px' }}
                                prefix={'$'}
                                suffix={'MXN'}
                            />
                        </Col>
                        <Divider className="m-0"/>
                        <Col span={12} >
                            <Text strong>Tipo</Text>
                        </Col>
                        <Col span={12} className="flex-right" >
                            <Text>{this.renderTipo()}</Text>
                        </Col>
                        <Divider className="m-0"/>
                        <Col span={12} >
                            <Text strong>Concepto</Text>
                        </Col>
                        <Col span={12} className="flex-right" >
                            <Text>{transaccion.concepto ?? "-"}</Text>
                        </Col>
                        <Divider className="m-0"/>
                        <Col span={12} >
                            <Text strong>Referencia Numerica</Text>
                        </Col>
                        <Col span={12} className="flex-right" >
                            <Text>{transaccion.referencia_numerica ?? "-"}</Text>
                        </Col>
                        <Divider className="m-0"/>
                        <Col span={12} >
                            <Text strong>Estado</Text>
                        </Col>
                        <Col span={12} className="flex-right" >
                            <Text>{transaccion.estado ?? "-"}</Text>
                        </Col>
                        <Divider className="mt-2"/>
                        <Col span={12}>
                            <Text>{moment().format('MMM DD, YYYY / HH:mm')}</Text>
                        </Col>
                        <Col span={12} className="flex-right" >
                            <a href={this.getPDF()} target="_blank">Descargar</a>
                        </Col>
                    </Row>
                </Form>
            </Spin >

        )
    }
}



export default function (props) {

    let cuenta = React.useContext(Cuenta)

    const { visible = false, onClose = () => { } } = props
    return <Modal
        open={visible}
        onCancel={onClose}
        title={null}
        closable={true}
        destroyOnClose={true}
        footer={null}
        width={700}
    >
        <Row>
            <Col span={24} className="center">
                <img src={"/img/BXNKLogo.png"} width="164" />
            </Col>
        </Row>
        <Title level={3} className="text-center mb-2 mt-1"> Comprobante De Transferencia</Title>

        <TransaccionesDetalle {...props} cuenta={cuenta} />
    </Modal>

}