import React, { Component } from "react";
import { Row, Col, Modal, Typography, Form, Input, message, Spin, Button, DatePicker, Select } from 'antd';
import axios from "axios";
import moment from "moment";

import { getResponseError } from '../../../Utils';
import { AvatarLoader } from "../../../Widgets/Uploaders";
import { EmailInput } from "../../../Widgets/Inputs/Inputs";
import { SelectCuenta, SelectEntidadFederativa, SelectPais, SelectActividadEconomica, SelectRol } from "../../../Widgets/Inputs/Selects";

const { Title, Text } = Typography;
const { Option } = Select;

export default class ModalUsuario extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            tipo: 1
        }
    }

    modalUsuario = React.createRef()
    
    componentDidMount() {
        if (this.props.data.cliente) 
            this.modalUsuario.current.setFieldsValue({ ...this.props.data.cliente})
    }

    /**
    * @memberof ModalUsuario
    * @method onFinish
    * @description Se ejecuta al dar enter al formulario
    */
    onFinish = (values) => {
        this.props.nextStep({ cliente: { ...values } })
    }
    
    render() {
        return (
            <Form
                layout="vertical"
                ref={this.modalUsuario}
                onFinish={this.onFinish}
                initialValues={{
                    tipo_cliente: 1
                }}
            >
                <Spin spinning={this.state.loading}>
                    <Row align="center">
                        <Col xs={22} lg={20} >
                            <Row gutter={[16, 0]}>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="nombre"
                                        requiredMark={true}
                                        label={this.props.cliente_id ? "Nombre" : <>Obligatorio</>}

                                        rules={[{
                                            required: true,
                                            message: "Por favor, Ingrese el nombre."
                                        }]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder="Nombre Completo"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="apellido_paterno"
                                        requiredMark={true}
                                        label={this.props.cliente_id ? "Apellido Paterno" : <>Obligatorio</>}

                                        rules={[{
                                            required: true,
                                            message: "Por favor, Ingrese el apellido paterno."
                                        }]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder="Apellido Paterno"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="apellido_materno"
                                        requiredMark={true}
                                        label={this.props.cliente_id ? "Apellido Materno" : <>Obligatorio</>}

                                        rules={[{
                                            required: true,
                                            message: "Por favor, Ingrese el apellido materno."
                                        }]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder="Apellido Materno"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="alias_local"
                                        label="Alias"
                                    >
                                        <Input
                                            size="large"
                                            placeholder="Alias"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="email"
                                        requiredMark={true}
                                        label={this.props.cliente_id ? "Email" : <>Obligatorio</>}

                                        rules={[{
                                            required: true,
                                            message: "Por favor, Ingrese el email."
                                        }]}
                                    >
                                        <EmailInput
                                            size="large"
                                            placeholder="Email"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        label={this.props.cliente_id ? "Fecha Nacimiento" : <>Obligatorio</>}
                                        name="fecha_nacimiento"

                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: "Por favor, seleccione ingrese la fecha de nacimiento."
                                    //     },
                                    //     {
                                    //         validator: this.validateEdad
                                    //     }
                                    // ]}

                                    >
                                        <DatePicker
                                            showToday={false}
                                            placeholder="Selecciona Fecha Nacimiento"
                                            className="w-100"
                                            size="large"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="pais_nacimiento"
                                        requiredMark={true}
                                        label={this.props.cliente_id ? "País de Nacimiento" : <>Obligatorio</>}
                                        rules={[{
                                            required: true,
                                            message: "Por favor, selecciona el país."
                                        }]}
                                    >
                                        <SelectPais
                                            size="large"
                                            placeholder="País de nacimiento"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="pais"
                                        requiredMark={true}
                                        label={this.props.cliente_id ? "País de Residencia" : <>Obligatorio</>}
                                        rules={[{
                                            required: true,
                                            message: "Por favor, selecciona el país."
                                        }]}
                                    >
                                        <SelectPais
                                            size="large"
                                            placeholder="País donde reside"
                                            onClaveSelect={pais_clave => this.setState({ pais_clave })}
                                        />
                                    </Form.Item>
                                </Col>
                                { this.props.tipo === "cliente" ? <Col xs={24} md={12}>
                                    <Form.Item
                                        name="tipo_cliente"
                                        label={this.props.cliente_id ? "Tipo de Cliente" : <>Obligatorio</>}
                                        requiredMark={true}
                                        rules={[{
                                            required: true,
                                            message: "Por favor, selecciona el país."
                                        }]}
                                    >
                                        <Select 
                                            placeholder="Tipo de Cliente"
                                            size="large"
                                        >
                                            <Option value={0}>Personal</Option>
                                            <Option value={1}>Cliente</Option>
                                        </Select>
                                    </Form.Item>
                                </Col> : null}
                            </Row>

                            <Form.Item >
                                <Button htmlType="submit" type="primary" block size="large" className="mt-2">
                                    Aceptar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}
