import React from 'react'
import { Route, Routes } from "react-router-dom";

import Bitacora from '../../Components/Admin/Bitacora/Bitacora';

/**
 * 
 * @export
 * @function RouterBitacora
 * @description Router for dashboard routes 
 */
function RouterBitacora(props) {
  return (
    <Routes>
      <Route path='/' element={<Bitacora {...props}/>}/>
    </Routes>
  )
}

export default RouterBitacora