import React from "react"
import { User } from "../Hooks/Logged"
import { Tag } from "antd";

const TipoCuentaText = value => {
    switch (parseInt(value)) {
        case 3:
            return "Tarjeta de Débito"
        case 10:
            return "Teléfono Celular"
        case 40:
            return "CLABE"
        default:
            return "ND"
    }
}


const TipoTransaccionText = value => {
    switch (parseInt(value)) {
        case 1:
            return "Ingreso"
        case 2:
            return "Egreso"
        default:
            return "ND"
    }
}

const CodificarCuenta = value => {

    if (value != null && parseInt(value.length) >= 5)
        return '****' + value.substr(-4);

    return value
}
const TipoUsuario = value => {

    let tipos = {
        1: 'Dueño',
        2: 'Administrador',
        3: 'Usuario',
    }

    return tipos[parseInt(value)] ?? 'N/A'
}

const tipoOrden = value => {

    let tipo = {
        1: "Ingreso",
        2: "Egreso"
    }

    return value ? tipo[parseInt(value)] : "N/A"
}

const EstadoCuenta = value => {

    let tipo = {
        "P": "Alta",
        "A": "Alta",
        "B": "Baja"
    }

    return value ? tipo[value] : "Pendiente"
}


const tipoClienteCuenta = value => {
    
    let tipo = {
        0 : <Tag color="orange">Personal</Tag>,
        1 : <Tag color="cyan">Cliente</Tag>,
        2 : <Tag color="magenta">Vendedor</Tag>,
        3 : <Tag color="volcano">Revendedor</Tag>,
    }

    return value !== null && value !== undefined ? tipo[value] : "-"
}

const tipoActividadEconomica = (value) => {

    let actividades = {
        28: "SERVICIOS - PASARELA DE PAGOS",
        29: "SERVICIOS - TURÍSTICOS",
        30: "FINTECH - CROWDFOUNDING",
        31: "FINTECH - WALLET",
        32: "FINTECH - MONEDAS VIRTUALES",
        33: "SERVICIOS - OUTSOURCING",
        34: "MANUFACTURA",
        35: "SOCIEDADES FINANCIERAS DE OBJETO MÚLTIPLE REGULADAS",
        36: "SOCIEDADES FINANCIERAS DE OBJETO MÚLTIPLE NO REGULADAS",
        37: "INSTITUCIONES FINANCIERAS DEL EXTRANJERO",
        38: "SERVICIOS DE INSTITUCIONES DE CRÉDITO, ORGANIZACIONES AUXILIARES E INSTITUCIONES DE SEGUROS Y FIANZAS",
        39: "SERVICIOS RELACIONADOS CON INMUEBLES",
        40: "SERVICIOS PROFESIONALES Y TÉCNICOS",
        41: "SERVICIOS DE BUFETES JURÍDICOS",
        42: "SERVICIOS DE ANÁLISIS DE SISTEMAS Y PROCESAMIENTO ELECTRÓNICO DE DATOS",
        43: "SERVICIOS DE AGENCIAS DE COLOCACIÓN Y SELECCIÓN DE PERSONAL",
        44: "SERVICIOS MEDICO GENERAL Y ESPECIALIZADO EN CONSULTORIOS",
        45: "ESTABLECIMIENTOS PRIVADOS DE INSTRUCCIÓN, EDUCACIÓN, CULTURA E INVESTIGACIÓN",
        47: "SOCIEDADES DE AHORRO Y CRÉDITO POPULAR",
        48: "SOCIEDADES DE INVERSIÓN",
        49: "COMPRAVENTA DE GAS PARA USO DOMESTICO O COMERCIAL",
        50: "CONTRATACIÓN DE OBRAS COMPLETAS DE CONSTRUCCIÓN (CASAS, DEPARTAMENTOS, INMUEBLES, PAVIMENTACIÓN, NO RESIDENCIALES, VÍAS DE COMUNICACIÓN)",
        51: "SERVICIOS DE ENSEÑANZA PRE PRIMARIA Y PRIMARIA",
        52: "SERVICIOS DE ENSEÑANZA SECUNDARIA",
        53: "SERVICIOS DE ENSEÑANZA COMERCIAL Y DE IDIOMA",
        54: "CASAS DE BOLSA NACIONALES",
        55: "ADMINISTRACIÓN DE INMUEBLES",
        56: "COMPAÑÍAS DE SEGUROS NACIONALES",
        57: "CASA DE CAMBIO",
        58: "CONSTRUCCIÓN DE INMUEBLES",
        59: "COMPRAVENTA DE ARTÍCULOS DE PLATA",
        60: "COMPRAVENTA DE OTRAS JOYAS",
        61: "COMPRAVENTA DE RELOJES",
        62: "SERVICIOS DE BLINDAJE",
        63: "COMPRAVENTA DE AUTOMÓVILES Y CAMIONES NUEVOS",
        64: "COMPRAVENTA DE CASAS Y OTROS INMUEBLES",
        65: "TARJETA DE CRÉDITO",
        66: "CENTROS CAMBIARIOS",
        67: "AGENCIA ADUANAL",
        68: "MONTEPIO O CASAS DE EMPEÑO",
        69: "FACTORING",
        70: "ADMINISTRADORAS DE TARJETA DE CRÉDITO",
        71: "NOTARIAS PUBLICAS",
        72: "EMPRESAS TRANSPORTADORAS DE VALORES",
        73: "JUEGOS DE FERIA Y APUESTAS",
        74: "TRANSMISORES DE DINERO O DISPERSORES"
    }

    return actividades[value] ?? "N/D"
}


/**
 * 
 * @param {*} response 
 * @param {String} si_falla_text 
 * @returns {String}
 */
const getResponseError = (response, si_falla_text) => {

    if (typeof (response.data) === "string")
        return response.data

    if (typeof (response.data.message) === "string")
        return response.data.message

    if (typeof (response.data.message.data) === "string")
        return response.data.message.data

    return si_falla_text;
}

/**
 * 
 */
const RenderRecibeComision = ({cuenta, style}) => {

    const user = React.useContext(User)

    if(cuenta.cliente_id === user.cliente_id && cuenta.recibe_comision)
        return <Tag style={{...style}}>Recibe Comisión</Tag>

    return null
  
}


export {
    TipoCuentaText,
    TipoTransaccionText,
    CodificarCuenta,
    TipoUsuario,
    tipoActividadEconomica,
    tipoOrden,
    EstadoCuenta,
    getResponseError,
    tipoClienteCuenta,
    RenderRecibeComision
}