import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'

import { IconCuenta } from '../Icons'
import axios from "axios";
import { getResponseError } from '../../Utils';

const { Option } = Select 

/**
 * @const SelectInstituciones
 * @description Select para las instituciones registrados en el sistema
 */
const SelectInstituciones = (props) => {

    const { 
        value, 
        onChange, 
        placeholder = "Seleccione la Institución", 
        onSelect = () => {}, 
        disabled = false, 
        className = "", 
        params = {}, 
        bordered, 
        allowClear = true, 
        onClear = () => {},
        size="default"
    } = props

    const [ instituciones, setInstituciones ] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
    })


    /**
     * @const getInstituciones
     * @description Obtiene las instituciones
     */
    const getInstituciones = ({page, limit, search} = instituciones) => {
        axios.get('/instituciones', {
            params: {
                page,
                limit,
                search,
            },
        }).then(({data}) => {
            setInstituciones(data)
        }).catch(error => {
            console.log("ERR, error",error)
            message.error(getResponseError(error.response, 'Error al obtener las instituciones'))
        })


    }

    useEffect(() => {
        getInstituciones()
    }, [])

    useEffect(() => {
        if(value)
            onChange(value?.value ?? value)
    }, [value])


    return (
        <Select
            size={size}
            bordered={bordered}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            allowClear={allowClear}
            showSearch
            filterOption={false}
            labelInValue
            onSearch={(search)=> getInstituciones({search})}
            onSelect={(institucion)=> {
                onChange(institucion.value)
                onSelect(institucion.value)
            }}
            value={value}
            onClear={()=>{
                onClear()
                onChange(undefined)
                onSelect(undefined)
            }}
            suffixIcon={<IconCuenta/>}
        >
            {
                instituciones?.data?.map(({ _id, nombre, clave }) => <Option value={_id} key={_id}>
                    {nombre ?? ""} 
                </Option>)
            }
        </Select>
    );
}



export default SelectInstituciones;