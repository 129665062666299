import React from 'react'
import { Layout, Col, Row, Typography, List, Card, Form, Select, Statistic, Space, message, Button, Spin, Tooltip } from "antd";
import axios from 'axios';
import moment from 'moment';
import { EyeOutlined, FileExcelOutlined } from '@ant-design/icons';

//Componentes
import { User } from '../../../../Hooks/Logged';
import { getResponseError } from '../../../Utils';
import CustomAvatar from '../../../Widgets/Avatar';
import { IconCuenta } from "../../../Widgets/Icons";
import usePermissions from '../../../../Hooks/usePermissions';
import ReglaList from "../../Cuentas/ReglasList";

//Modales
import ModalDetalle from '../../../Admin/Transacciones//ModalDetalle';
import ModalTransferencia from '../../../Admin/Transacciones/ModalTransferencia';

const { Content } = Layout;
const { Text, Title } = Typography
const { Option } = Select;


class Comisiones extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			transacciones: {
				page: 1,
				limit: 10,
				total: 0,
				pages: 0,
				data: [],
			},
			cuentas: {
				page: 1,
				limit: 10,
				total: 0,
				pages: 0,
				data: [],
			},
			cuenta_id: undefined,
			cuenta: {},
			errores_spei: [],
            errores_uso_cuenta: [],
            loadingButton: false,
		}
	}

	refCuentas = React.createRef();
	refReglas = React.createRef();

	componentDidMount() {
		this.getCuentas()
	}

	componentDidUpdate(prevProps){
		if(this.props.cuenta != prevProps.cuenta){
			if(this.refCuentas.current){
				this.refCuentas.current.setFieldsValue({cuenta: this.props.cuenta})
				this.get({cuenta: this.props.cuenta, page: 1})
			}
		}
	}


	/**
     * @memberof Comisiones
     * @method get
     * @description Obtiene las transacciones del la cuenta seleccionada, que hayan pagado comision
     */
	get = ({
		page = this.state.transacciones.page,
		limit = this.state.transacciones.limit,
		cuenta = this.state.cuenta,
	} = this.state.transacciones) => {
		this.setState({ loading: true })
		axios.get('/transacciones', {
			params: {
				page,
				limit,
				cuenta,
				tipo_dato: "local",
				comisiones: true,
			}
		}).then(({ data }) => {
			this.setState({
				transacciones: data,
				cuenta: cuenta
			})

		}).catch(error => {
			console.log('error', error)
			message.error(getResponseError(error.response, "Error al obtener las transacciones"))
		}).finally(() => {
			this.setState({ loading: false })
		})
	}


	/**
     * @memberof Comisiones
     * @method getCuentas
     * @description Obtiene las cunatas del cliente 
     */
	getCuentas = ({ page, limit, search } = this.state.cuentas) => {

		axios.get('/cuentas', {
			params: {
				page,
				limit,
				search,
				cliente_id: this.props.cliente_id
			}
		}).then(({ data }) => {
			this.refCuentas.current.setFieldsValue({ cuenta: data.data[0].cuenta })
			this.setState({
				cuentas: data,
				cuenta_id: data.data[0].cuenta
			})
			this.get({ cuenta: data.data[0].cuenta })
				
		}).catch(error => {
			console.log("ERR, error", error)
			message.error(getResponseError(error.response, 'Error al obtener las cuentas'))
		})
	}

	/**
     * @memberof Comisiones
     * @method submit
     * @description Se ejecuta al dar enter al formulario, guardando las reglas
     */
    submit = (values) => {

        values.intentos = this.state.intentos;

        let reglas_comision = []
        if (Array.isArray(values.reglas_spei)) {
            reglas_comision = values.reglas_spei
        }
        if (Array.isArray(values.reglas_uso_cuenta)) {
            reglas_comision = [...reglas_comision, ...values.reglas_uso_cuenta]
        }

        values.reglas_comision = reglas_comision;
        if (this.state.cuenta._id){
        	values.cuenta_id = this.state.cuenta._id
            this.update(values)
        }
        

    }

    /**
     * @memberof FormCuenta
     * @method update
     * @description Actualiza una cuenta en el sistema
     */
    update = (values) => {
        this.setState({ loading: true })
        axios.put('/cuenta', values)
        .then(response => {
            message.success('Cuenta Editada')
        }).catch(error => {
            console.log(error)
            message.error(getResponseError(error.response, "Error al editar la cuenta"))
        }).finally(() => this.setState({ loading: false }))

    }


    /**
	 * @memberof Comiciones
	 * @method changeData
	 * @description se ejecuta al cambiar de cuenta, manda a traes las  trasnacciones y creglas de la cuenta
	 */
	changeData = (x, values) => {
		let { cuenta } = this.refCuentas?.current?.getFieldsValue();
		this.get({ page: 1, limit: 10, cuenta: cuenta.value })
		this.props.setCuenta(cuenta.value)
	}

	/**
	 * @memberof Transacciones
	 * 
	 * @method getTransacciones
     * @desription Obtiene las transacciones en formato csv del cliente
	 */
	getTransacciones =  (tipo) => {

        let url = new URL(axios.defaults.baseURL + '/transacciones/csv')
        url.searchParams.set('Authorization', sessionStorage.getItem('token'))
        url.searchParams.set("cuenta", this.state.cuenta)
        url.searchParams.set("comisiones", true)
        //window.open(url, '_blank');

        this.setState({loadingButton: true})
        axios.get(url.href, { responseType: 'blob', })
        .then(response =>{
        	// Crea un objeto URL a partir de la respuesta blob
	        const url = window.URL.createObjectURL(new Blob([response.data]));

	        // Crea un enlace temporal para descargar el archivo
	        const link = document.createElement('a');
	        link.href = url;

	        // Obtiene el nombre de archivo del encabezado Content-Disposition
	        const fileName = `comisiones-${this.state.cuenta}.csv`;

	        link.setAttribute('download', fileName);

	        // Agrega el enlace al documento y simula un clic para descargar el archivo
	        document.body.appendChild(link);
	        link.click();

	        // Elimina el enlace del documento después de la descarga
	        document.body.removeChild(link);
        })
        .catch(error => {
        	console.log("error", error);
            message.error("Error al obtener el Archivo")
        })
        .finally(e => this.setState({loadingButton: false}))

    }

	render() {
		return (
			<>
				<Content className="pl-1">
					<Row justify={"end"} gutter={[16,16]}>
						<Col xs={24} md={12} lg={16} className="flex-right">
							{ this.props.user.rol_id?.tipo === 1 ? <Tooltip placement="topRight" title={"Descargar transacciones"}>
								<Button 
			                        type="primary"
			                        onClick={()=> this.getTransacciones()}
			                        icon={<FileExcelOutlined style={{color: "white"}}/>}
			                        style={{marginRight: "4px"}}
			                        loading={this.state.loadingButton}
			                    />
		                    </Tooltip> : null }
						</Col>
						<Col xs={24} md={12} lg={8}>
							<Form ref={this.refCuentas} onValuesChange={(x, values) => this.changeData(x, values)} >
								<Form.Item noStyle name="cuenta">
									<Select
										placeholder="Seleccione la Cuenta"
										showSearch
										filterOption={false}
										className='width-100'
										labelInValue
										onSearch={(search) => this.getCuentas({ search })}
										suffixIcon={<IconCuenta />}
									>
										{this.state.cuentas?.data?.map(({ _id, nombre, cuenta }) => <Option value={cuenta} key={cuenta}>
											{nombre ?? ""} <small className='text-gray'>{cuenta ?? ""} </small>
										</Option>)}
									</Select>
								</Form.Item>
							</Form>
						</Col>
					</Row>	
					<Row>
						<Col>
							<Title level={4}>Transacciones con comisión</Title>
						</Col>
						<Col span={24} className="" style={{ paddingRight: '0.5rem' }}>
							<Row className='main'>
								<Col xs={24}>
									<List
										loading={this.state.loading}
										className="component-list"
										itemLayout="horizontal"
										locale={{ emptyText: "Sin Transacciones" }}
										dataSource={this.state.transacciones?.data}
										pagination={{
											current: this.state.transacciones.page,
											pageSize: this.state.transacciones.limit,
											total: this.state.transacciones.total,
											position: 'bottom',
											className: "flex-left",
											showSizeChanger: true,
											pageSizeOptions: [20, 50, 100, 500],
											showQuickJumper: true,
											locale: { jump_to: "Ir a la página", page: '' },
											onChange: (page, limit) => {
												this.get({ page, limit })
											},
											onShowSizeChange: (page, limit) => {
												this.get({ page, limit })
											}
										}}
										header={<Row className="header-list width-100 ">
											<Col xs={0} sm={0} md={5} className=" center">
												<Text>Concepto</Text>
											</Col>
											<Col xs={0} sm={0} md={5} className="center">
												<Text>Fecha</Text>
											</Col>
											<Col xs={0} sm={0} md={5} className="center">
												<Text>Beneficiario</Text>
											</Col>
											<Col xs={0} sm={0} md={3} className="center">
												<Text ellipsis>Estado</Text>
											</Col>
											<Col xs={0} sm={0} md={3} className="center">
												<Text>Comisión</Text>
											</Col>
										</Row>
										}

										renderItem={item => (
											<List.Item className="component-list-item">
												<Card className="card-list width-100" bordered={false}>
													<Row className="width-100" align={"middle"}>
														<Col xs={24} sm={12} md={5} className="">
															<Text ellipsis strong>{item.concepto} </Text>
														</Col>
														<Col xs={24} sm={12} md={5} className="center">
															<Text ellipsis className="text-gray">{moment(item.fecha_operacion).format("DD/MM/YYYY HH:mm")}</Text>
														</Col>
														<Col xs={24} sm={12} md={5} className="center">
															<Text ellipsis className="text-gray">{item.beneficiarios[0]?.nombre ?? "ND"}</Text>
														</Col>
														<Col xs={24} sm={12} md={3} className="center">
															<Text ellipsis className="text-gray">{item.estado ?? "No Enviado a STP"}</Text>
														</Col>
														<Col xs={24} sm={12} md={3} className="center">
															<Statistic
																value={item.comision.toMoney(true)}
																precision={2}
																prefix={"$"}
																valueStyle={{ fontWeight: 'bold', fontSize: 16 }}
															/>
														</Col>
														<Col xs={24} sm={12} md={2} className="flex-right">
															<Button
																type="primary"
																icon={<EyeOutlined />}
																onClick={() => {
																	this.setState({ modal_detalle: true, transaccion_id: item._id })	
																}}
															/>
														</Col>


													</Row>
												</Card>
											</List.Item>
										)}
									/>
								</Col>
							</Row>
						</Col>

					</Row>
				</Content>

				<ModalTransferencia
					visible={this.state.modalVisible}
					onClose={() => {
						this.get()
						this.setState({ modalVisible: false, transaccion_id: undefined })
					}}
					cliente_id={this.props.cliente_id}
					transaccion_id={this.state.transaccion_id}
					cuenta_id={this.state.cuenta_id}
				/>

				<ModalDetalle
					visible={this.state.modal_detalle}
					onClose={() => {
						this.setState({ modal_detalle: false, transaccion_id: null })
					}}
					transaccion_id={this.state.transaccion_id}
				/>

			</>
		)
	}
}

export default function (props) {
	let user = React.useContext(User)

	let permissions = usePermissions(user?.rol_id?.permisos, {})
	return (<Comisiones {...props} {...permissions} user={user}/>)
}