import React, { Component } from "react";
import { Row, Col, Modal, Space, Typography, Form, Input, message, Spin, Button, Select, Divider, Switch, Tabs } from 'antd';
import { SelectCliente, SelectInstituciones, SelectUsuario } from "../../Widgets/Inputs/Selects";
import axios from "axios"
import moment from "moment";
import ReglaList from "./ReglasList";
import ModalTemplates from "./TemplatesList";
import BloqueVendedor from "../BloqueVendedores/BloqueVendedor";
import ModalSeleccionarBloqueVendedor from "../BloqueVendedores/ModalSeleccionarBloqueVendedor";
import { getResponseError } from "../../Utils";
import { User } from '../../../Hooks/Logged';
import Decimal from 'decimal.js';

const { Title, Text } = Typography;
const { Option } = Select;

/**
 *
 *
 * @class FormCuenta
 * @extends {Component}
 */
class FormCuenta extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loading_rules: false,
            tipo: 1,
            esClabe: true,
            intentos: 1,
            errores_spei: [],
            errores_uso_cuenta: [],
            modal_visible: false,

            disabled_templates_spei: false,
            disabled_templates_uso_cuenta: false,

            cobro_comision: true,
            min_uso: 0,
            min_spei: 0,
            errores: {},
        }
        window.x = message
    }

    formRef = React.createRef();

    componentDidMount() {

        if (this.props.cuenta_id)
            this.get()
        else {
            this.getCuentaDefault()
            this.getCuentaPadre()
        }

        if (this.props.revendedor)
            this.getCuentaRevendedor()

        if (this.props.cliente_id) {
            this.formRef.current.setFieldsValue({
                cliente_id: {
                    value: this.props.cliente_id,

                }
            })
        }


        this.formRef?.current.setFieldsValue({
            institucion_id: {
                value: "6483558172954ba67db75c39",
                key: "6483558172954ba67db75c39",
                label: "STP"
            }
        })
    }

    /**
     * @memberof FormCuenta
     * @method getCuentaRevendedor
     * @description En caso de que el usuario logeado sea un revendedor y este quiera crear una cuenta nueva
     * obtenemos la cuenta concentradora de este para obtener los minimos de las reglas
     */
    getCuentaRevendedor = () => {

        this.setState({ loading: true })
        axios.get('/cuenta', {
            params: {
                cuenta_id: "concentradora"
            }
        })
            .then(response => {
                let cuenta = response.data
                let min_spei = 0, min_uso = 0
                if (cuenta.es_concentradora) {
                    const reglas_spei_rev = cuenta?.reglas_comision.find(regla => regla.tipo_regla === 0);
                    if (reglas_spei_rev) min_spei = reglas_spei_rev.monto_absoluto
                    const reglas_cuenta_rev = cuenta?.reglas_comision.find(regla => regla.tipo_regla === 1);
                    if (reglas_cuenta_rev) min_uso = reglas_cuenta_rev.porcentaje
                }

                this.setState({
                    min_uso,
                    min_spei,
                    hay_cuenta_padre: true
                })

            }).catch(error => {
                console.log("error", error)
                message.error(error?.response?.data?.message ?? "Error al obtener la cuenta concentradora")
            }).finally(() => {
                this.setState({ loading: false })
            })

    }

    /**
     * @memberof FormCuenta
     * @method getCuentaDefault
     * @description Obtiene la cuenta del revendedor o vendedor que recibe la comision, para poner un minimo en los costos de spei y 
     * udo de cuenta
     */
    getCuentaPadre = () => {
        console.clear()
        console.log('getCuentaPadre')
        this.setState({ loading: true })
        axios.get('/cuenta', {
            params:{
                cliente_id: this.props.cliente_id
            }
        })
            .then(({ data }) => {
                console.log(data);
                let cuenta = data
                let reglas_spei = cuenta.reglas_comision.filter(regla => regla.tipo_regla == 0)
                let reglas_cuenta = cuenta.reglas_comision.filter(regla => regla.tipo_regla == 1)

                let min_spei = 0, min_uso = 0
                if (cuenta) {
                    const reglas_spei_rev = cuenta?.reglas_comision.find(regla => regla.tipo_regla === 0);
                    if (reglas_spei_rev) min_spei = reglas_spei_rev.monto_absoluto
                    const reglas_cuenta_rev = cuenta?.reglas_comision.find(regla => regla.tipo_regla === 1);
                    if (reglas_cuenta_rev) min_uso = reglas_cuenta_rev.porcentaje
                }

                this.formRef.current.setFieldsValue({
                    reglas_spei: reglas_spei,
                    reglas_uso_cuenta: reglas_cuenta,
                    hay_cuenta_padre: true
                })

                this.setState({
                    min_uso,
                    min_spei,
                })
            }).catch(error => {
                console.log("error", error)
                //message.error(getResponseError(error.response, "Error al obtener la cuenta"))
            }).finally(() => {
                this.setState({ loading: false })
            })

    }
    /**
     * @memberof FormCuenta
     * @method getCuentaDefault
     * @description Obtiene informacion de la cuenta de STP por default para ponerla en el formulario
     */
    getCuentaDefault = () => {
        console.log('getCuentaDefault')
        this.setState({ loading: true })
        axios.get('/instituciones', {
            params: {
                search: "STP"
            }
        })
            .then(response => {
                let institucion = response.data.data[0]
                if (institucion) {
                    this.formRef.current.setFieldsValue({
                        institucion_id: {
                            value: institucion._id,
                            label: institucion.nombre
                        }
                    })
                }

            }).catch(error => {
                console.log("error", error)
                message.error(getResponseError(error.response, "Error al obtener la institución"))
            }).finally(() => {
                this.setState({ loading: false })
            })

    }

    /**
     * @memberof FormCuenta
     * @method get
     * @description Obtiene informacion de la cuenta
     */
    get = () => {
        console.log('get')
        this.setState({ loading: true })
        axios.get('/cuenta', {
            params: {
                cuenta_id: this.props.cuenta_id
            }
        })
            .then(({ data }) => {
                let cuenta = data
                console.log("cuenta", cuenta);
                let cliente = cuenta.cliente_id;
                let reglas_spei = cuenta.reglas_comision.filter(regla => regla.tipo_regla == 0)
                let reglas_cuenta = cuenta.reglas_comision.filter(regla => regla.tipo_regla == 1)

                let min_spei = 0, min_uso = 0
                if (cuenta.cuenta_revendedor_padre) {
                    const reglas_spei_rev = cuenta.cuenta_revendedor_padre?.reglas_comision.find(regla => regla.tipo_regla === 0);
                    if (reglas_spei_rev) min_spei = reglas_spei_rev.monto_absoluto
                    const reglas_cuenta_rev = cuenta.cuenta_revendedor_padre?.reglas_comision.find(regla => regla.tipo_regla === 1);
                    if (reglas_cuenta_rev) min_uso = reglas_cuenta_rev.porcentaje
                }


                cliente = (cliente ? {
                    value: cliente._id,
                    label: cliente.nombre + " " + cliente.apellido_paterno + " " + cliente.apellido_materno
                } : undefined)

                let institucion = cuenta.institucion_id ? {
                    value: cuenta.institucion_id._id,
                    label: cuenta?.institucion_id?.nombre
                } : undefined

                this.formRef.current.setFieldsValue({
                    ...cuenta,
                    reglas_spei: reglas_spei,
                    reglas_uso_cuenta: reglas_cuenta,
                    cliente_id: cliente,
                    institucion_id: institucion,
                    usuario_id: cuenta?.usuario_id?._id ? {
                        label: cuenta.usuario_id.nombre_completo,
                        value: cuenta.usuario_id._id
                    } : null
                })

                if (reglas_spei.length > 0 || reglas_cuenta.length > 0)
                    this.setState({ disabled_templates: true })


                console.log("min_uso", min_uso);
                console.log("min_spei", min_spei);
                console.log("cuenta.es_concentradora", cuenta.es_concentradora);

                this.setState({
                    cobro_comision: cuenta.cobro_comision,
                    cuenta,
                    min_uso,
                    min_spei,
                    hay_cuenta_padre: cuenta.cuenta_revendedor_padre || cuenta.es_concentradora,
                })
            }).catch(error => {
                console.log("error", error)
                message.error(getResponseError(error.response, "Error al obtener la cuenta"))
            }).finally(() => {
                this.setState({ loading: false })
            })

    }

    /**
     * @memberof FormCuenta
     * @method generar
     * @description Genera una CLABE aleatoria
     */
    generar = () => {

        this.setState({ loading: true })
        axios.get('/cuenta/generar', {

        })
            .then(({ data }) => {
                this.formRef.current.setFieldsValue({
                    cuenta: data
                })

            }).catch(error => {
                console.log("error", error)
                message.error(getResponseError(error.response, "Error al generar CLABE aleatoria"))
            }).finally(() => {
                this.setState({ loading: false })
            })

    }

    /**
     * @memberof FormCuenta
     * @method submit
     * @description Se ejecuta al dar enter al formulario
     */
    submit = (values) => {
        console.log("values", values);

        try {
            if (values.bloques_vendedores)
                values.bloques_vendedores = this.validateBloques(values.bloques_vendedores)

            if (values.bloques_vendedores_monto || values.bloques_vendedores_porcentaje)
                values.bloques_vendedores = [
                    ...this.validateBloques(values.bloques_vendedores_monto, 1),
                    ...this.validateBloques(values.bloques_vendedores_porcentaje, 2),
                ]
        } catch (error) {

            console.log('e', error);
            return Modal.error({
                title: error
            })

        }
        values.intentos = this.state.intentos;

        let reglas_comision = []
        if (Array.isArray(values.reglas_spei)) {
            reglas_comision = values.reglas_spei
        }
        if (Array.isArray(values.reglas_uso_cuenta)) {
            reglas_comision = [...reglas_comision, ...values.reglas_uso_cuenta]
        }

        values.reglas_comision = reglas_comision;
        if (this.props.cuenta_id)
            this.update(values)
        else
            this.add(values)

    }

    validateBloques = (bloques_vendedores, tipo = 3) => {

        let comisionPorcentaje = 0;

        if (!Array.isArray(bloques_vendedores) || bloques_vendedores.length < 1)
            return []
        //     throw "No hay ningún bloque"

        for (let x = 0; x < bloques_vendedores.length; x++) {
            const bloque_vendedor = bloques_vendedores[x]
            let comisionPorcentajeVendedores = 0

            bloques_vendedores[x].tipo = tipo

            if (!Array.isArray(bloque_vendedor.vendedores) || bloque_vendedor.vendedores.length < 1)
                throw "Hay bloques que no tienen vendedores"


            for (let y = 0; y < bloque_vendedor.vendedores.length; y++) {
                const vendedor = bloque_vendedor.vendedores[y];
                bloques_vendedores[x].vendedores[y].vendedor_id = vendedor?.vendedor_id?.value || vendedor?.vendedor_id
                bloques_vendedores[x].vendedores[y].comision_bloque = Decimal(bloque_vendedor.porcentaje_comision || 0).mul(vendedor.porcentaje_comision || 0).div(100).toDecimalPlaces(2).toNumber()
                comisionPorcentajeVendedores += vendedor.porcentaje_comision
            }


            if (comisionPorcentajeVendedores != 100)
                throw "Las comisiones de los vendedores no estan completas"

            comisionPorcentaje += parseFloat(bloque_vendedor.porcentaje_comision)
        }


        if (comisionPorcentaje != 100)
            throw "Las comisiones de los bloques no estan completas"

        return bloques_vendedores
    }

    /**
     * @memberof FormCuenta
     * @method add
     * @description Crea una nueva cuenta en el sistema
     */
    add = (values) => {
        this.setState({ loading: true })
        if (this.props.cliente_id) {
            values.cliente_id = this.props.cliente_id
        }
        axios.post('/cuenta', values)
            .then(response => {
                message.success('Cuenta Añadida')
                this.props.onClose(true)
            }).catch(error => {
                message.error(getResponseError(error.response, "Error al añadir la cuenta"))
                if (error?.response?.data?.clabe) {
                    this.formRef.current.setFieldsValue({
                        cuenta: error?.response?.data?.clabe
                    })
                    this.setState({
                        intentos: ++this.state.intentos
                    })
                }
            }).finally(() => this.setState({ loading: false }))

    }

    /**
     * @memberof FormCuenta
     * @method update
     * @description Actualiza una cuenta en el sistema
     */
    update = (values) => {
        this.setState({ loading: true })

        axios.put('/cuenta', values)
            .then(response => {
                message.success('Cuenta Editada')
                this.props.onClose(true)
            }).catch(error => {
                console.log(error)
                message.error(getResponseError(error.response, "Error al editar la cuenta"))
            }).finally(() => this.setState({ loading: false }))

    }

    /**
     *
     *
     * @memberof FormCuenta
     * @method verificarReglas
     * @description cada vez que se cambia un valor de un formulario se validan las reglas
     */
    verificarReglas = (changedValues, allFields) => {

        const { reglas_spei, reglas_uso_cuenta } = this.formRef.current?.getFieldsValue();

        if (reglas_spei && Array.isArray(reglas_spei))
            reglas_spei.forEach((regla, index) => {
                if (index >= 0 && regla) {
                    this.onChangeLimites(regla, reglas_spei[index - 1], index, 0, regla.monto_absoluto)
                }

            });
        if (reglas_uso_cuenta && Array.isArray(reglas_uso_cuenta))
            reglas_uso_cuenta.forEach((regla, index) => {
                if (index >= 0 && regla) {
                    this.onChangeLimites(regla, reglas_uso_cuenta[index - 1], index, 1, regla.porcentaje)
                }
            });


        let disabled_spei = this.ReglasExisten(reglas_spei)
        let disabled_uso_cuenta = this.ReglasExisten(reglas_uso_cuenta)

        this.setState({
            disabled_templates_spei: disabled_spei,
            disabled_templates_uso_cuenta: disabled_uso_cuenta
        })


    }


    /**
     *
     *
     * @memberof FormCuenta
     * @description Revisa la cantidad de reglas creadas para deshabilitar los templates
     * se realiza de este modo ya que el form.list nunca esta vacio, por lo que se valida con un campo del form.list
     */
    ReglasExisten = (reglas) => {

        if (Array.isArray(reglas) && reglas.length > 0) {
            let tamano = reglas.filter(regla => regla?.tipo_regla != undefined).length;
            if (tamano > 0) {
                return true
            }
            return false
        }

        return false

    }

    /**
     *
     *
     * @memberof FormCuenta
     * @description verifica los limites de las reglas
     * @param {*} regla_actual
     * @param {*} regla_anterior 
     * @param {Number} index elemento de la lista
     * @param {Number} tipo tipo de regla
     * @param {Number} monto puede ser el monto_absoluto o porcentaje dependiendo del tipo
     */
    onChangeLimites = (regla_actual, regla_anterior, index, tipo, monto) => {
        let li = regla_actual.limite_inferior;
        let ls = regla_actual.limite_superior;
        let ls_anterior = regla_anterior?.limite_superior ?? 0;

        let errores = tipo === 0 ? this.state.errores_spei : this.state.errores_uso_cuenta

        errores[index] = {};

        //reglas de validaciones para limites superiores e inferiores
        if (li == undefined || (index === 0 && li < 0)) {
            errores[index]["limite_inferior"] = 'Ingrese el mínimo';
        }
        if (!ls) {
            errores[index]["limite_superior"] = 'Ingrese el máximo';
        }
        if (li > ls) {
            errores[index]["limite_inferior"] = 'El valor del límite inferior debe ser menor al límite superior';
        }
        if (li < ls_anterior) {
            errores[index]["limite_inferior"] = 'El valor del límite inferior debe ser mayor al límite superior anterior';
        }
        if (index > 0 && li != ls_anterior + 1) {
            errores[index]["limite_inferior"] = 'El valor del límite inferior debe ser la continuación al límite superior anterior';
        }
        if (ls < li) {
            errores[index]["limite_superior"] = 'El valor del límite superior debe ser mayor al límite inferior';
        }
        if (li == ls) {
            errores[index]["limite_inferior"] = 'Los límite deben ser diferentes';
            errores[index]["limite_superior"] = 'Los límite deben ser diferentes';
        }
        if (index === 0 && li != 0) {
            errores[index]["limite_inferior"] = "No debe de ser mayor a 0"
        }

        //Si la cuenta es "hija" de un revendedor, las reglas deben de tener un limite
        if (this.state.hay_cuenta_padre) {
            if (tipo === 0) { //spei
                if (monto < this.state.min_spei) {
                    errores[index]["monto_absoluto"] = `Debe de ser menor que ${this.state.min_spei}`
                }
            }

            if (tipo === 1) { //uso
                if (monto < this.state.min_uso) {
                    errores[index]["porcentaje"] = `Debe de ser menor que ${this.state.min_uso}`
                }
            }
        }

        //almacen de errores en el state para renderizarlos en cada regla

        if (Object.keys(errores[index]).length > 0) {
            if (tipo == 0) {
                this.setState({ errores_spei: errores })
            }

            if (tipo == 1) {
                this.setState({ errores_uso_cuenta: errores })
            }

            return
        }
    }

    /**
     *
     *
     * @memberof FormCuenta
     */
    addTemplate = (template_id) => {
        this.setState({ loading_rules: true })

        axios.get('/template/' + template_id, {
            params: {
                template_id: template_id,
            }
        })
            .then(({ data }) => {

                if (data.tipo == 0) {
                    this.formRef.current.setFieldsValue({ reglas_spei: data.reglas })
                    this.setState({ disabled_templates_spei: true })
                }
                if (data.tipo == 1) {
                    this.formRef.current.setFieldsValue({ reglas_uso_cuenta: data.reglas })
                    this.setState({ disabled_templates_uso_cuenta: true })

                }

            }).catch(error => {
                console.log('error', error)
                message.error(error?.response?.data ?? "No se pudo cargar la regla")
            }).finally(() => {
                this.setState({ loading_rules: false })
            })

    }

    /**
     * @memberof FormCuenta
     * @method onChangePorcentaje
     * @description Cuando se cambia el valor del porcentaje, el valor del monto lo vuelve 0
     */
    onChangePorcentaje = (index, key) => {

        let values = this.formRef.current.getFieldsValue()
        let reglas = values[key]

        //Si es de clase hibrida se puede ambos
        if (reglas[index].clase === 2) return;

        reglas[index].monto_absoluto = 0

        this.formRef.current.setFieldsValue({
            [key]: reglas
        })

    }

    /**
     * @memberof FormCuenta
     * @method onChangeMonto
     * @description Cuando se cambia el valor del porcentaje, el valor del monto lo vuelve 0
     */
    onChangeMonto = (index, key) => {
        let values = this.formRef.current.getFieldsValue()
        let reglas = values[key]

        //Si es de clase hibrida se puede ambos
        if (reglas[index].clase === 1) return;

        reglas[index].porcentaje = 0

        this.formRef.current.setFieldsValue({
            [key]: reglas
        })
    }

    /**
    * @memberof FormCuenta
    * @method onChangeClase
    * @description Cuando se cambia el valor de la clase, pone el porcentaje o el monto en 0
    */
    onChangeClase = (index, key) => {
        let values = this.formRef.current.getFieldsValue()
        let reglas = values[key]

        if (reglas[index].clase === 0) {
            reglas[index].porcentaje = 0
        }

        if (reglas[index].clase === 1) {
            reglas[index].monto_absoluto = 0
        }

        this.formRef.current.setFieldsValue({
            [key]: reglas
        })
    }

    /**
     * @memberof FormCuenta
     * @description Del cliente seleccionado actualiza el valor de tipo de cuenta
     */
    onSelectCliente = (cliente_id) => {
        if (cliente_id) {
            axios.get('/cliente/' + cliente_id)
                .then(({ data }) => {
                    if (this.formRef.current) this.formRef.current.setFieldsValue({ tipo_cuenta: data.tipo_cliente })
                })
        }
    }


    render() {

        let tipo_cuenta_options = [
            { value: 0, label: 'Personal' },
            { value: 1, label: 'Cliente' },
            { value: 2, label: 'Vendedor' },
        ]

        if (this.props.user?.rol_id?.tipo !== 5)
            tipo_cuenta_options.push({ value: 3, label: "Revendedor" })

        return (
            <>
                <Form
                    layout="vertical"
                    ref={this.formRef}
                    onFinish={this.submit}
                    initialValues={{
                        ...this.props.initialValues,
                        tipo: 40,
                        cuenta_id: this.props.cuenta_id,
                        cobro_comision: true,
                        dia_corte: moment().format("D"),

                    }}
                    validateTrigger={["onChange", "onBlur"]}
                    onValuesChange={this.verificarReglas}
                    preserve={false}
                    scrollToFirstError={true}
                >
                    <Form.Item
                        name="cuenta_id"
                        noStyle
                    >
                        <Input type="hidden" />
                    </Form.Item>
                    <Spin spinning={this.state.loading}>

                        <Row align="center">
                            <Col xs={22} lg={20} >
                                <Form.Item
                                    name="tipo"
                                    requiredMark={true}
                                    label={this.props.cuenta_id ? "Tipo de Cuenta" : <>Obligatorio</>}
                                    rules={[{
                                        required: true,
                                        message: "Por favor, seleccione el tipo"
                                    }]}
                                >
                                    <Select
                                        placeholder="Tipo de Cuenta"
                                        size="large"
                                        options={[
                                            { value: 3, label: 'Tarjeta de Débito' },
                                            { value: 10, label: 'Teléfono celular' },
                                            { value: 40, label: 'CLABE' },

                                        ]}
                                        onSelect={e => e == 40 ? this.setState({ esClabe: true }) : this.setState({ esClabe: false })}
                                        disabled={true}
                                    />

                                </Form.Item>
                                {this.state.esClabe && !this.props.cuenta_id ? <a onClick={e => this.generar()}>Generar CLABE</a> : null}
                                <Form.Item
                                    name="cuenta"
                                    requiredMark={true}
                                    label={this.props.cuenta_id ? "Cuenta" : <>Obligatorio</>}
                                    rules={[{
                                        required: true,
                                        message: "Por favor, seleccione la cuenta"
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Cuenta"
                                        disabled={this.props.cuenta_id}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="institucion_id"
                                    size="large"
                                    requiredMark={true}
                                    label={this.props.cuenta_id ? "Institucion" : <>Obligatorio</>}
                                    rules={[{
                                        required: true,
                                        message: "Por favor, Seleccione la institución de la cuenta"
                                    }]}

                                >
                                    <SelectInstituciones size="large" disabled={true} />
                                </Form.Item>
                                <Form.Item
                                    name="nombre"
                                    requiredMark={true}
                                    label={this.props.cuenta_id ? "Nombre" : <>Obligatorio</>}
                                    rules={[{
                                        required: true,
                                        message: "Por favor, Ingrese el nombre de la cuenta"
                                    }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Nombre"
                                    />
                                </Form.Item>



                                <Form.Item
                                    name="alias"
                                    requiredMark={true}
                                    label={this.props.cuenta_id ? "Alias" : <>Obligatorio</>}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Alias"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="cliente_id"
                                    requiredMark={true}
                                    label={this.props.cuenta_id ? "Cliente" : <>Obligatorio</>}
                                    {...(this.props.cliente_id ? { hidden: true } : {})}

                                    rules={[
                                        this.props.cuenta_id ? {
                                            required: true,
                                            message: "Por favor, seleccione el cliente de la cuenta"
                                        } : {}]}
                                >
                                    <SelectCliente
                                        size="large"
                                        disabled={this.props.cuenta_id}
                                        onSelect={this.onSelectCliente}
                                    />
                                </Form.Item>

                                <Row gutter={[16, 0]}>

                                    <Col xs={24} md={12}>
                                        <Form.Item
                                            name="tipo_cuenta"
                                            size="large"
                                            requiredMark={true}
                                            label={this.props.cuenta_id ? "Tipo Cuenta" : <>Obligatorio</>}
                                            rules={[{
                                                required: true,
                                                message: "Por favor, Seleccione el tipo de cuenta"
                                            }]}
                                        >
                                            <Select
                                                placeholder="Tipo de Cuenta"
                                                size="large"
                                                options={tipo_cuenta_options}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item
                                            name="dia_corte"
                                            size="large"
                                            requiredMark={true}
                                            label={"Dia de corte"}
                                            rules={[{
                                                required: true,
                                                message: "Por favor, Seleccione el origen del estado de cuenta"
                                            }]}
                                        >
                                            <Select
                                                placeholder="Dia del mes"
                                                allowClear
                                                size="large"
                                            //disabled={this.props.cuenta_id}
                                            >
                                                <Option value="1">1</Option>
                                                <Option value="2">2</Option>
                                                <Option value="3">3</Option>
                                                <Option value="4">4</Option>
                                                <Option value="5">5</Option>
                                                <Option value="6">6</Option>
                                                <Option value="7">7</Option>
                                                <Option value="8">8</Option>
                                                <Option value="9">9</Option>
                                                <Option value="10">10</Option>
                                                <Option value="11">11</Option>
                                                <Option value="12">12</Option>
                                                <Option value="13">13</Option>
                                                <Option value="14">14</Option>
                                                <Option value="15">15</Option>
                                                <Option value="16">16</Option>
                                                <Option value="17">17</Option>
                                                <Option value="18">18</Option>
                                                <Option value="19">19</Option>
                                                <Option value="20">20</Option>
                                                <Option value="21">21</Option>
                                                <Option value="22">22</Option>
                                                <Option value="23">23</Option>
                                                <Option value="24">24</Option>
                                                <Option value="25">25</Option>
                                                <Option value="26">26</Option>
                                                <Option value="27">27</Option>
                                                <Option value="28">28</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    {this.props.cuenta_id ? <Col xs={24}>
                                        <Form.Item
                                            name="usuario_id"
                                            size="large"
                                            requiredMark={true}
                                            label={this.props.cuenta_id ? "Asesor" : <>Obligatorio</>}
                                            rules={[{
                                                required: true,
                                                message: "Por favor, Seleccione el asesor de cuenta"
                                            }]}
                                        >
                                            <SelectUsuario
                                                params={{ admins: true }}
                                                size="large"
                                            />
                                        </Form.Item>
                                    </Col> : null}
                                    <Col xs={24} md={12}>
                                        {this.props.user?.rol_id?.super_admin ? <Form.Item
                                            label="Cobro de Comisión"
                                            name="cobro_comision"
                                            size="large"
                                            requiredMark={false}
                                            valuePropName="checked"
                                        >
                                            <Switch onChange={(checked) => this.setState({ cobro_comision: checked })} />
                                        </Form.Item> : null}
                                    </Col>
                                    <Col xs={24} md={12}>
                                        { this.props.user?.rol_id?.tipo === 5 ? null : <Form.Item
                                            name="dias_prueba"
                                            size="large"
                                            requiredMark={true}
                                            label={this.props.cuenta_id ? "Dias de prueba" : <>Obligatorio</>}
                                            rules={[{
                                                required: true,
                                                message: "Por favor, Seleccione los dias de prueba de la cuenta"
                                            }]}
                                        >
                                            <Select
                                                placeholder="Dias de prueba"
                                                allowClear
                                                size="large"
                                            //disabled={this.props.cuenta_id}
                                            >
                                                <Option value={0}>0</Option>
                                                <Option value={10}>10</Option>
                                                <Option value={20}>20</Option>
                                                <Option value={30}>30</Option>
                                                <Option value={40}>40</Option>
                                                <Option value={50}>50</Option>
                                                <Option value={60}>60</Option>
                                                <Option value={70}>70</Option>
                                                <Option value={80}>80</Option>
                                                <Option value={90}>90</Option>

                                            </Select>
                                        </Form.Item>}
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                        {this.state.cobro_comision ? <Spin spinning={this.state.loading_rules} className="width-100">
                            <Row gutter={[8, 8]} className="width-100">
                                <Divider>Reglas de la Cuenta</Divider>
                                <Space size={35} direction="vertical" className="width-100" >
                                    <ReglaList
                                        tipo={0}
                                        tipo_name="SPEI (Egresos)"
                                        name="reglas_spei"
                                        errores={this.state.errores_spei}
                                        showTemplates={() => this.setState({ modal_visible: true, tipo: 0 })}
                                        showButtonTemplate={true}
                                        disablebtnTemplate={this.state.disabled_templates_spei}
                                        verificarReglas={this.verificarReglas}
                                        onChangePorcentaje={(index) => this.onChangePorcentaje(index, "reglas_spei")}
                                        onChangeMonto={(index) => this.onChangeMonto(index, "reglas_spei")}
                                        //le mando las SPEI
                                        reglas={this.state.cuenta?.reglas_comision?.filter(e => e.tipo_regla === 0)}
                                        min_spei={this.state.min_spei}
                                    />
                                    <ReglaList
                                        tipo={1}
                                        tipo_name="Uso Cuenta (Ingreso)"
                                        name="reglas_uso_cuenta"
                                        errores={this.state.errores_uso_cuenta}
                                        showTemplates={() => this.setState({ modal_visible: true, tipo: 1 })}
                                        showButtonTemplate={true}
                                        disablebtnTemplate={this.state.disabled_templates_uso_cuenta}
                                        verificarReglas={this.verificarReglas}
                                        onChangePorcentaje={(index) => this.onChangePorcentaje(index, "reglas_uso_cuenta")}
                                        onChangeMonto={(index) => this.onChangeMonto(index, "reglas_uso_cuenta")}
                                        onChangeClase={(index) => this.onChangeClase(index, "reglas_uso_cuenta")}
                                        //le mando las reglas de uso de cuenta para ver la clase, y asi mostrar o quitar campos
                                        reglas={this.state.cuenta?.reglas_comision?.filter(e => e.tipo_regla === 1)}
                                        cuenta_hija={this.state.hay_cuenta_padre ? true : false}
                                        min_uso={this.state.min_uso}
                                    />
                                    {/* <Row className=" width-100 " style={{ borderBottom: '1px solid #A1D707' }}>
                                        <Text strong style={{ fontSize: 20, color: '#A1D707' }}>Vendedores</Text>
                                    </Row> */}
                                </Space>
                            </Row>
                        </Spin> : null}


                        <Row className=" width-100 mt-2" justify="space-around" style={{ borderBottom: '1px solid #A1D707', marginBottom: '1em ' }}>
                            <Col span={18} ><Text strong style={{ fontSize: 20, color: '#A1D707' }}>Bloques de Vendedores</Text></Col>
                            <Col span={6}>
                                <ModalSeleccionarBloqueVendedor
                                    onAccept={e => {
                                        this.formRef.current.setFieldsValue({
                                            bloques_vendedores: e.map(({ vendedores, ...e }) => ({
                                                ...e,
                                                vendedores: vendedores.map(({ vendedor_id, ...args }) => ({
                                                    vendedor_id: {
                                                        key: vendedor_id?._id,
                                                        value: vendedor_id?._id,
                                                        label: vendedor_id?.nombre_completo
                                                    },
                                                    ...args
                                                }))
                                            }))
                                        })
                                    }}
                                />
                            </Col>
                        </Row>

                        <Form.Item
                            style={{ margin: 0 }}
                            shouldUpdate
                        >
                            {({ getFieldValue }) => {

                                let isHibrido = false

                                let reglas_uso_cuenta = getFieldValue('reglas_uso_cuenta')
                                if (Array.isArray(reglas_uso_cuenta))
                                    for (const regla of reglas_uso_cuenta) {
                                        if (regla.clase == 2) {
                                            isHibrido = true
                                            break;
                                        }
                                    }

                                if (!isHibrido)
                                    return <BloqueVendedor {...this.props} />

                                return <Tabs>
                                    <Tabs.TabPane tab="Reglas para Monto" key="item-1">
                                        <BloqueVendedor {...this.props} formListName='bloques_vendedores_monto' />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="Reglas para Porcentaje" key="item-2">
                                        <BloqueVendedor {...this.props} formListName='bloques_vendedores_porcentaje' />
                                    </Tabs.TabPane>
                                </Tabs>
                            }}
                        </Form.Item>
                        {/*  */}
                        <Row align="center">
                            <Col xs={22} lg={20} >
                                <Form.Item >
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        block
                                        size="large"
                                        className="mt-2"
                                    >
                                        Aceptar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Spin>
                </Form>

                <ModalTemplates
                    visible={this.state.modal_visible}
                    tipo={this.state.tipo}
                    onSelect={(template) => {
                        this.addTemplate(template)
                        this.setState({ modal_visible: false })
                    }}
                    onClose={() => this.setState({ modal_visible: false })}

                />
            </>
        )
    }
}



export default function ModalCuenta(props) {

    let user = React.useContext(User)

    const { visible = false, onClose = () => { }, cuenta_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        destroyOnClose={true}
        zIndex={1000}
        footer={false}
        closable={false}
        width={850}
    >
        <Row>
            <Col span={24} className="center">
                <img src={"/img/BXNKLogo.png"} width="164" />
            </Col>
        </Row>
        <Title level={1} className="text-center mb-2 mt-1">{cuenta_id ? "Editar" : "Crear"} Cuenta</Title>
        <FormCuenta {...props} user={user} />
    </Modal>

}