import React from 'react'
import { Layout, Col, Row, Typography, List, Card, Form, Select, Statistic, Space, message, Button, Spin } from "antd";
import axios from 'axios';
import moment from 'moment';
import { EyeOutlined, } from '@ant-design/icons';

//Componentes
import { User } from '../../../../Hooks/Logged';
import { getResponseError } from '../../../Utils';
import CustomAvatar from '../../../Widgets/Avatar';
import { IconCuenta } from "../../../Widgets/Icons";
import usePermissions from '../../../../Hooks/usePermissions';
import ReglaList from "../../Cuentas/ReglasList";

//Modales
import ModalDetalle from '../../../Admin/Transacciones//ModalDetalle';
import ModalTransferencia from '../../../Admin/Transacciones/ModalTransferencia';

const { Content } = Layout;
const { Text, Title } = Typography
const { Option } = Select;


class Comisiones extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			transacciones: {
				page: 1,
				limit: 10,
				total: 0,
				pages: 0,
				data: [],
			},
			cuentas: {
				page: 1,
				limit: 10,
				total: 0,
				pages: 0,
				data: [],
			},
			cuenta_id: undefined,
			cuenta: {},
			errores_spei: [],
            errores_uso_cuenta: [],
		}
	}

	refCuentas = React.createRef();
	refReglas = React.createRef();

	componentDidMount() {
		this.getCuentas()
		if(this.props.cuenta){
			if(this.refCuentas.current){
				this.refCuentas.current.setFieldsValue({cuenta: this.props.cuenta})
				this.get({cuenta: this.props.cuenta, page: 1})
			}
		}
	}

	componentDidUpdate(prevProps){
		if(this.props.cuenta != prevProps.cuenta){
			if(this.refCuentas.current){
				this.refCuentas.current.setFieldsValue({cuenta: this.props.cuenta})
				this.get({cuenta: this.props.cuenta, page: 1})
			}
		}
	}


	/**
     * @memberof Comisiones
     * @method get
     * @description Obtiene las transacciones del la cuenta seleccionada, que hayan pagado comision
     */
	get = ({
		page = this.state.transacciones.page,
		limit = this.state.transacciones.limit,
		cuenta = this.state.cuenta,
	} = this.state.transacciones) => {
		this.setState({ loading: true })
		axios.get('/transacciones', {
			params: {
				page,
				limit,
				cuenta,
				tipo_dato: "local",
				comisiones: true,
			}
		}).then(({ data }) => {
			this.setState({
				transacciones: data,
				cuenta: cuenta
			})

		}).catch(error => {
			console.log('error', error)
			message.error(getResponseError(error.response, "Error al obtener las transacciones"))
		}).finally(() => {
			this.setState({ loading: false })
		})
	}


	/**
     * @memberof Comisiones
     * @method getCuentas
     * @description Obtiene las cunatas del cliente 
     */
	getCuentas = ({ page, limit, search } = this.state.cuentas) => {

		axios.get('/cuentas', {
			params: {
				page,
				limit,
				search,
				cliente_id: this.props.cliente_id
			}
		}).then(({ data }) => {
			this.refCuentas.current.setFieldsValue({ cuenta: data.data[0].cuenta })
			this.setState({
				cuentas: data,
				cuenta_id: data.data[0].cuenta
			})
			if(data?.data[0]?._id){
				this.getCuenta(data?.data[0]?._id)
			}
		}).catch(error => {
			console.log("ERR, error", error)
			message.error(getResponseError(error.response, 'Error al obtener las cuentas'))
		})
	}

	/**
     * @memberof Comisiones
     * @method submit
     * @description Se ejecuta al dar enter al formulario, guardando las reglas
     */
    submit = (values) => {

        values.intentos = this.state.intentos;

        let reglas_comision = []
        if (Array.isArray(values.reglas_spei)) {
            reglas_comision = values.reglas_spei
        }
        if (Array.isArray(values.reglas_uso_cuenta)) {
            reglas_comision = [...reglas_comision, ...values.reglas_uso_cuenta]
        }

        values.reglas_comision = reglas_comision;
        if (this.state.cuenta._id){
        	values.cuenta_id = this.state.cuenta._id
            this.update(values)
        }
        

    }

    /**
     * @memberof FormCuenta
     * @method update
     * @description Actualiza una cuenta en el sistema
     */
    update = (values) => {
        this.setState({ loading: true })
        axios.put('/cuenta', values)
        .then(response => {
            message.success('Cuenta Editada')
        }).catch(error => {
            console.log(error)
            message.error(getResponseError(error.response, "Error al editar la cuenta"))
        }).finally(() => this.setState({ loading: false }))

    }

	/**
	 * @memberof Comiciones
	 * @method getCuenta
	 * @description Obtiene la informacion de la cuenta seleccionada
	 */
	getCuenta = (cuenta_id) => {

		axios.get('/cuenta', {
            params: {
                cuenta_id
            }
        }).then(({ data }) => {

			let min_spei = 0, min_uso = 0, cuenta = data
            if(cuenta.cuenta_revendedor_padre){
                const reglas_spei_rev = cuenta.cuenta_revendedor_padre?.reglas_comision.find(regla => regla.tipo_regla === 0);
                if (reglas_spei_rev) min_spei = reglas_spei_rev.monto_absoluto
                const reglas_cuenta_rev = cuenta.cuenta_revendedor_padre?.reglas_comision.find(regla => regla.tipo_regla === 1);
                if (reglas_cuenta_rev) min_uso = reglas_cuenta_rev.porcentaje
            }

			let reglas_spei = data.reglas_comision.filter(regla => regla.tipo_regla == 0)
            let reglas_cuenta = data.reglas_comision.filter(regla => regla.tipo_regla == 1)

			this.refReglas.current.setFieldsValue({
                reglas_spei: reglas_spei,
                reglas_uso_cuenta: reglas_cuenta,
            })
			this.setState({
				cuenta: data,
                min_uso,
                min_spei,
                hay_cuenta_padre: cuenta.cuenta_revendedor_padre || cuenta.es_concentradora,
			})

		}).catch(error => {
			console.log("ERR, error", error)
			message.error("Error al obtener la cuenta")
		})
	}

	/**
	 * @memberof Comiciones
	 * @method onChangePorcentaje
	 * @description Cuando se cambia el valor del porcentaje, el valor del monto lo vuelve 0
	 */
	onChangePorcentaje = (index, key) => {

        let values = this.refReglas.current.getFieldsValue()
        let reglas = values[key]

        //Si es de clase hibrida se puede ambos
        if(reglas[index].clase === 2) return;

        reglas[index].monto_absoluto = 0

        this.refReglas.current.setFieldsValue({
            [key]: reglas
        })

    }

    /**
	 * @memberof Comiciones
	 * @method onChangeMonto
	 * @description Cuando se cambia el valor del Monto, el valor del porcentaje lo vuelve 0
	 */
    onChangeMonto = (index, key) => {
        
        let values = this.refReglas.current.getFieldsValue()
        let reglas = values[key]

        //Si es de clase hibrida se puede ambos
        if(reglas[index].clase === 2) return;

        reglas[index].porcentaje = 0

        this.refReglas.current.setFieldsValue({
            [key]: reglas
        })
    }

    /**
	 * @memberof Comiciones
	 * @method onChangeClase
	 * @description Cuando se cambia el valor de la clase, pone el porcentaje o el monto en 0
	 */
    onChangeClase = (index, key) => {
        let values = this.refReglas.current.getFieldsValue()
        let reglas = values[key]

        if(reglas[index].clase === 0){
            reglas[index].porcentaje = 0
        }

        if(reglas[index].clase === 1){
            reglas[index].monto_absoluto = 0
        }

        this.refReglas.current.setFieldsValue({
            [key]: reglas
        })
    }

    /**
     * @memberof Comiciones
     * @method verificarReglas
     * @description cada vez que se cambia un valor de un formulario se validan las reglas
     */
    verificarReglas = (changedValues, allFields) => {
        const { reglas_spei, reglas_uso_cuenta } = this.refReglas.current?.getFieldsValue();

        if (reglas_spei && Array.isArray(reglas_spei))
            reglas_spei.forEach((regla, index) => {
                if (index >= 0 && regla) {
                    this.onChangeLimites(regla, reglas_spei[index - 1], index, 0, regla.monto_absoluto)
                }

            });
        if (reglas_uso_cuenta && Array.isArray(reglas_uso_cuenta))
            reglas_uso_cuenta.forEach((regla, index) => {
                if (index >= 0 && regla) {
                    this.onChangeLimites(regla, reglas_uso_cuenta[index - 1], index, 1, regla.porcentaje)
                }
            });


        let disabled_spei = this.ReglasExisten(reglas_spei)
        let disabled_uso_cuenta = this.ReglasExisten(reglas_uso_cuenta)

        this.setState({
            disabled_templates_spei: disabled_spei,
            disabled_templates_uso_cuenta: disabled_uso_cuenta
        })


    }


    /**
     * @memberof FormCuenta
     * @description Revisa la cantidad de reglas creadas para deshabilitar los templates
     * se realiza de este modo ya que el form.list nunca esta vacio, por lo que se valida con un campo del form.list
     */
    ReglasExisten = (reglas) => {

        if (Array.isArray(reglas) && reglas.length > 0) {
            let tamano = reglas.filter(regla => regla?.tipo_regla != undefined).length;
            if (tamano > 0) {
                return true
            }
            return false
        }

        return false

    }

    /**
     *
     *
     * @memberof FormCuenta
     * @description verifica los limites de las reglas
     * @param {*} regla_actual
     * @param {*} regla_anterior 
     * @param {Number} index elemento de la lista
     * @param {Number} tipo tipo de regla
     */
    onChangeLimites = (regla_actual, regla_anterior, index, tipo, monto) => {

        let li = regla_actual.limite_inferior;
        let ls = regla_actual.limite_superior;
        let ls_anterior = regla_anterior?.limite_superior ?? 0;
        let errores = tipo === 0 ? this.state.errores_spei : this.state.errores_uso_cuenta

        errores[index] = {};

        //reglas de validaciones para limites superiores e inferiores
        if (li == undefined || (index === 0 && li < 0))
            errores[index]["limite_inferior"] = 'Ingrese el mínimo';
        if (!ls)
            errores[index]["limite_superior"] = 'Ingrese el máximo';

        if (li > ls)
            errores[index]["limite_inferior"] = 'El valor del límite inferior debe ser menor al límite superior';

        if (li < ls_anterior)
            errores[index]["limite_inferior"] = 'El valor del límite inferior debe ser mayor al límite superior anterior';

        if (index > 0 && li != ls_anterior + 1)
            errores[index]["limite_inferior"] = 'El valor del límite inferior debe ser la continuación al límite superior anterior';

        if (ls < li)
            errores[index]["limite_superior"] = 'El valor del límite superior debe ser mayor al límite inferior';
        if (li == ls) {
            errores[index]["limite_inferior"] = 'Los límite deben ser diferentes';
            errores[index]["limite_superior"] = 'Los límite deben ser diferentes';
        }

        if (index === 0 && li != 0)
            errores[index]["limite_inferior"] = "No debe de ser mayor a 0"

        //Si la cuenta es "hija" de un revendedor, las reglas deben de tener un limite
        if(this.state.hay_cuenta_padre){
            if(tipo === 0){ //spei
                if(monto < this.state.min_spei){
                    errores[index]["monto_absoluto"] = `Debe de ser menor que ${this.state.min_spei}`
                }
            }

            if(tipo === 1){ //uso
                if(monto < this.state.min_uso){
                    errores[index]["porcentaje"] = `Debe de ser menor que ${this.state.min_uso}`
                }
            }
        }

        //almacen de errores en el state para renderizarlos en cada regla
        if (Object.keys(errores[index]).length > 0) {
            if (tipo == 0) {
                this.setState({ errores_spei: errores })
            }

            if (tipo == 1){
                this.setState({ errores_uso_cuenta: errores })
            }

            return
        }
    }


    /**
	 * @memberof Comiciones
	 * @method changeData
	 * @description se ejecuta al cambiar de cuenta, manda a traes las  trasnacciones y creglas de la cuenta
	 */
	changeData = (x, values) => {
		let { cuenta } = this.refCuentas?.current?.getFieldsValue();
		this.get({ page: 1, limit: 10, cuenta: cuenta.value })

		let cuenta_new = this.state.cuentas.data.find(e => e.cuenta === cuenta.value)
		if(cuenta_new) this.getCuenta(cuenta_new._id)

		this.props.setCuenta(cuenta.value)
	}

	render() {
		return (
			<>
				<Content className="pl-1">
					<Row justify={"end"}>
						<Col span={8}>
							<Form ref={this.refCuentas} onValuesChange={(x, values) => this.changeData(x, values)} >
								<Form.Item noStyle name="cuenta">
									<Select
										placeholder="Seleccione la Cuenta"
										showSearch
										filterOption={false}
										className='width-100'
										labelInValue
										onSearch={(search) => this.getCuentas({ search })}
										suffixIcon={<IconCuenta />}
									>
										{this.state.cuentas?.data?.map(({ _id, nombre, cuenta }) => <Option value={cuenta} key={cuenta}>
											{nombre ?? ""} <small className='text-gray'>{cuenta ?? ""} </small>
										</Option>)}
									</Select>
								</Form.Item>
							</Form>
						</Col>
					</Row>
					<Form 
						ref={this.refReglas}
						onValuesChange={this.verificarReglas}
						onFinish={this.submit}
					>
					{ this.state.cuenta?.cobro_comision ?  <Spin spinning={this.state.loading}> <Title level={4}>Reglas de Cuentas</Title> 
							<Row gutter={[16,16]} align="center">
								<Col xs={24} lg={15}>
		                            <ReglaList
		                                tipo={0}
		                                tipo_name="SPEI (Egresos)"
		                                name="reglas_spei"
		                                errores={this.state.errores_spei}
		                                showTemplates={() => this.setState({ modal_visible: true, tipo: 0 })}
		                                showButtonTemplate={true}
		                                disablebtnTemplate={true}
		                                verificarReglas={this.verificarReglas}
		                                onChangePorcentaje={(index) => this.onChangePorcentaje(index, "reglas_spei")}
		                                onChangeMonto={(index) => this.onChangeMonto(index, "reglas_spei")}
		                                //le mando las SPEI
                                        reglas={this.state.cuenta?.reglas_comision?.filter(e => e.tipo_regla === 0)}
                                        min_spei={this.state.min_spei}

		                            />
		                        </Col>
		                    </Row>
		                    <Row gutter={[16,16]} align="center">
		                        <Col xs={24} lg={15}>
		                            <ReglaList
		                                tipo={1}
		                                tipo_name="Uso Cuenta (Ingreso)"
		                                name="reglas_uso_cuenta"
		                                errores={this.state.errores_uso_cuenta}
		                                showTemplates={() => this.setState({ modal_visible: true, tipo: 1 })}
		                                showButtonTemplate={true}
		                                disablebtnTemplate={true}
		                                verificarReglas={this.verificarReglas}
		                                onChangePorcentaje={(index) => this.onChangePorcentaje(index, "reglas_uso_cuenta")}
		                                onChangeMonto={(index) => this.onChangeMonto(index, "reglas_uso_cuenta")}
		                                onChangeClase={(index) => this.onChangeClase(index, "reglas_uso_cuenta")}
		                                //le mando las reglas de uso de cuenta para ver la clase, y asi mostrar o quitar campos
		                                reglas={this.state.cuenta?.reglas_comision?.filter(e => e.tipo_regla === 1)}
                                        cuenta_hija={this.state.hay_cuenta_padre ? true : false}
                                        min_uso={this.state.min_uso}

		                            />
								</Col>
							</Row>
							<Row gutter={[16,16]} align="center">
	                            <Col xs={22} lg={10} >
	                                <Form.Item >
	                                    <Button
	                                        htmlType="submit"
	                                        type="primary"
	                                        block
	                                        size="large"
	                                        className="mt-2"
	                                        //disabled={!this.state.errores_spei.length == 0 || !this.state.errores_uso_cuenta.length == 0}
	                                    >
	                                        Aceptar
	                                    </Button>
	                                </Form.Item>
	                            </Col>
							</Row>
						</Spin> : null }
					</Form>
				</Content>

				<ModalTransferencia
					visible={this.state.modalVisible}
					onClose={() => {
						this.get()
						this.setState({ modalVisible: false, transaccion_id: undefined })
					}}
					cliente_id={this.props.cliente_id}
					transaccion_id={this.state.transaccion_id}
					cuenta_id={this.state.cuenta_id}
				/>

				<ModalDetalle
					visible={this.state.modal_detalle}
					onClose={() => {
						this.setState({ modal_detalle: false, transaccion_id: null })
					}}
					transaccion_id={this.state.transaccion_id}
				/>

			</>
		)
	}
}

export default function (props) {
	let user = React.useContext(User)

	let permissions = usePermissions(user?.rol_id?.permisos, {})
	return (<Comisiones {...props} {...permissions} />)
}