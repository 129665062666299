import React from 'react'
import { Layout, Col, Row, Typography, Button, Card, Space, message, Spin, List, Statistic, Tooltip, Tabs} from "antd";
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ReactEcharts from "echarts-for-react";
import { QuestionCircleOutlined } from "@ant-design/icons"

//componentes
import { IconMoney } from '../../Widgets/Icons';
import CustomAvatar from '../../Widgets/Avatar';
import { CardResumenFinanciero, CardTransaccionReciente, CardCuentaInfo } from '../../Widgets/Cards'
import Clientes from "./Listas/Clientes"
import Transacciones from "./Listas/Transacciones"

//Modales
import ModalCobranza from "./ModalCobranza";


const { Text, Title } = Typography;
const { Content } = Layout;

class Dashboard extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			clientes: {
				data: [],
				page: 1,
				limit: 50,
				total: 0,
			},
			total_cobrado: 0,
			spei: 0,
			uso_cuenta: 0,
			cuenta_SPEI : "",
			cuenta_uso_cuenta :"",


			cuenta: {
				balance: 0
			},
			ingresos: 0,
			egresos: 0,
			datos: [],
			fechas: [],
			cuentas: {
				data: [],
				loading: false,
				page: 1,
				limit: 10
			},
			loading_grafica: false,
			loading_transacciones: false,
		}
	}

	componentDidMount() {

		this.getCuentas()
		this.getClientes()
		this.getGrafica()

	}

	/**
	* @method getCuentas
	* @description Obtiene las cuentas de comisiones de SPEI y de uso de cuenta
	*/
	getCuentas = ({
		month_year_spei = this.state.month_year_spei, 
		month_year_uso = this.state.month_year_uso
	} = this.state) => {
		this.setState({loading: true})
		axios.get('/cobranza',{
			params:{
				month_year_spei,
				month_year_uso,
			}
		})
		.then(({data}) => {
			this.setState({
				total_cobrado: data.total_cobrado,
				spei: data.comisiones?.total_spei,
				uso_cuenta: data.comisiones?.total_uso_cuenta,
				cuenta_SPEI : data.cuenta_SPEI,
				cuenta_uso_cuenta : data.cuenta_uso_cuenta,
				month_year_spei,
				month_year_uso,
			})
		})
		.catch(error => {
			console.log("error", error);
			message.error(error.response?.data?.message ?? "Error al obtener informacion de las cuentas")

		}).finally(()=>this.setState({loading: false}))
	}

	/**
	* @method getClientes
	* @description Obtiene las cuentas de comisiones de SPEI y de uso de cuenta
	*/
	getClientes = ({
		page = this.state.clientes.page,
		limit = this.state.clientes.limit
	} = this.state.clientes) => {
		this.setState({loading: true})
		axios.get('/cobranza/clientes',{
			params:{
				page,
				limit,
			}
		})
		.then(({data}) => {
			
			this.setState({
				clientes: data
			})
		})
		.catch(error => {
			console.log("error", error);
			message.error(error.response?.data?.message ?? "Error al obtener informacion de las cuentas")

		}).finally(()=>this.setState({loading: false}))
	}	


	/**
	* @method getGrafica
	* @description Obtiene las cuentas de comisiones de SPEI y de uso de cuenta
	*/
	getGrafica = () => {
		this.setState({loading: true})
		axios.get('/cobranza/grafica',{})
		.then(({data}) => {
			this.setState({
				datos: data.totales,
				fechas: data.fechas.reverse(),
			})
		})
		.catch(error => {
			console.log("error", error);
			message.error(error.response?.data?.message ?? "Error al obtener informacion de las cuentas")
		})
	}


	render() {

		let { cuenta, ingresos, egresos } = this.state;

		return (
			<>
				<Content className="p-1">
					<Row gutter={[16,16]}>
						<Col xs={24} md={8}>
							<Row className="m-0" gutter={[16,16]}>
								<Col xs={24} className="flex-left-column ">
									<Button
										type="primary"
										icon={<IconMoney />}
										className="btn-green-money"
									/>
									<div>
										<Text>Monto Total Actual</Text> &nbsp;
										<Tooltip
											placement="topLeft"
											title={"Monto Total Actual: es el total de la suma de los balances ACTUALES de las cuentas de comisión, SPEI y Uso de Cuenta"}
										>
											<QuestionCircleOutlined />
										</Tooltip>
									</div>
									<Text strong style={{ fontSize: '28px' }}>$ {this.state.total_cobrado?.toMoney(true)}</Text>
								</Col>
								<Col xs={24}>
									<CardResumenFinanciero
										title={<>Monto cobrado en cuenta SPEI de {moment().format("MMMM").toUpperCase()} <br/><small>CLABE: {this.state.cuenta_SPEI}</small></>}
										monto={this.state.spei}
										select={true}
										onSelect={(month_year_spei)=>this.getCuentas({month_year_spei})}
									/>
								</Col>
								<Col xs={24}>
									<CardResumenFinanciero
										title={<>Monto cobrado en cuenta de Uso de cuenta de {moment().format("MMMM").toUpperCase()} <br/><small>CLABE: {this.state.cuenta_uso_cuenta}</small></>}
										monto={this.state.uso_cuenta}
										select={true}
										onSelect={(month_year_uso)=>this.getCuentas({month_year_uso})}
									/>
								</Col>

							</Row>
						</Col>
						<Col xs={24} md={16}>
							<Card size="small" loading={this.state.loading_grafica} style={{ minHeight: '370px' }}>
								<Row>
									<Col span={18}>
										<Text>INGRESOS de SPEI vs Uso de Cuenta</Text>
									</Col>
									<Col span={6} className="flex-right">
										<Text>1 Año</Text>
									</Col>
									<Col span={24}>
										<ReactEcharts
											style={{ height: '320px', maxWidth: '1600px', margin: "0 auto" }}
											theme="light"
											className="pd-1"
											option={{
												tooltip: {
													show: true,
													trigger: 'axis',
													backgroundColor: 'rgba(50,50,50,0.7)',
													borderColor: '#333',
													position: function (point, params, dom, rect, size) {
														return [point[0] - 300, point[1] - 100];
													},
													textStyle: {
														color: "#ccc"
													}
												},
												xAxis: [{
													type: 'category',
													axisTick: {
														alighWithLabel: true
													},
													axisLine: {
														onZero: false,
													},
													axisPointer: {
														label: 'axis'
													},
													data: this.state.fechas,
													axisLabel: {
												      rotate: 45, // Grados de inclinación
												    },
												}],
												yAxis: [{
													type: 'value',
												}],
												series: this.state?.datos?.map((item, index) => {
													return {
														name: item._id == 1 ? "Uso de Cuenta" : "SPEI",
														type: 'line',
														emphasis: {
															focus: 'series'
														},
														data: this.state?.fechas.map((fecha) => (this.state.datos[index]?.totales[`${String(fecha)}`])?.toMoney(true)),
														smooth: true,
														color: item._id == 1 ? "#BE62E6" : "#2E99E6",
													}
												})
											}}
										/>
									</Col>
								</Row>
							</Card>
						</Col>
						<Col xs={24}>
							<Tabs>
							  	<Tabs.TabPane tab="Transacciones" key="item-1">
							    	<Transacciones/>
							  	</Tabs.TabPane>
							  	<Tabs.TabPane tab="Clientes" key="item-2">
							    	<Clientes/>
							  	</Tabs.TabPane>
							</Tabs>
						</Col>
						
					</Row>
				</Content>
				<ModalCobranza
					visible={this.state.modal_visible}
					onClose={(flag)=>{
						this.setState({modal_visible: false, cliente_id: undefined})
						if(flag === true)
							this.getClientes()
					}}
					cliente_id={this.state.cliente_id}
				/>
			</>
		)
	}
}

export default () => {




	return <Dashboard />
}