import React, { Component } from 'react'
import { Row, Col, Button, Card, Typography, Layout, Modal, Form, Input } from 'antd';
import { useNavigate, useParams  } from 'react-router-dom';
import { NumberOutlined } from '@ant-design/icons';

//css
import '../../Styles/Modules/Auth/auth.scss';
import axios from 'axios';

const { Title, Text } = Typography

/**
 * 
 * @class ClientConfirm
 * @extends {Component}
 * @description Componente ClientConfirm page
 */
class ClientConfirm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false
		}
	}

	onFinish = (values) => {
		this.setState({loading: true})
        axios.put("/account/confirm/update", {
			...values,
			email: this.props.params.email
		})
            .then(res => {
                Modal.success({
                    title: 'Cuenta Confirmada',
                    content: 'Se te ha enviado un mensaje de bienvenida a tu correo para poder configurar y acceder a tu cuenta.',
					onOk: () => this.props.navigate("/"),
					okText: "Volver al inicio",
					closable: false,
					maskClosable: false
                })
            })
            .catch(error => {
				const msg = error.response?.data?.message
                Modal.error({
                    title: 'Error al confirmar tu cuenta',
                    content: msg || "La clave es invalida"
                });
            })
            .finally(() => {
                this.setState({
                    loading: false
                })
            })
	}

	render() {


		return(
			<Layout className="auth" style={{ backgroundImage: "url(/img/background/background-main.png)"}}>
				<Card className="card" loading={this.state.loading}>
					<Row align="center">
						<Col span={24} className="center mb-1">
							<img src={"/img/BXNKLogo.png"} width="164"/>
						</Col>
						<Col span={24} className="text-center mb-2">
							<Title>Confirmar Cuenta</Title>
							<Text>Ingresa el codigo que se te fue enviado a tu correo.</Text>
						</Col>
						<Col span={20}>
							<Form
								onFinish={this.onFinish}
							>
								<Form.Item
							      	name="codigo"
							      	rules={[{ required: true, message: 'Ingrese el codigo' }]}
							    >
							      	<Input
							      		prefix={<NumberOutlined />}
							      		placeholder="000000"
							      		size="large"
							      	/>
							    </Form.Item>

							    <Form.Item >
							     	<Button type="primary" htmlType="submit" loading={this.state.loading} block size="large">
							        	Confirmar
							      	</Button>
							    </Form.Item>
							</Form>
						</Col>
					</Row>
				</Card>
			</Layout>
		)
	}
}

export default function (props) {

	const navigate = useNavigate()
	const params = useParams()

	return <ClientConfirm {...props} navigate={navigate} params={params} />
}