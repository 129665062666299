import React, { Component } from "react";
import { Row, Col, Modal, Typography, Form, Input, message, Spin, Button, DatePicker, Select, Space, Switch } from 'antd';
import moment from "moment";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import axios from "axios"

const { Title, Paragraph } = Typography;
const { Option } = Select;

class Configuracion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            cliente: {},
        }
    }

    modalConfifuracion = React.createRef();

    componentDidMount() {
        if (this.props.cliente_id) {
            this.getCliente()
        }
    }

    /**
     * @memberOf Configuracion
     * @method getCliente
     * @descripcion Obtiene la informacion del cliente
     * */
    getCliente = () => {
        this.setState({ loading: true })
        axios.get('/cliente/' + this.props.cliente_id, {
            params:{
            }
        }).then(({ data }) => {
            console.log("data", data);
            this.setState({cliente: data})
        }).catch(error => {
            message.error(error.response.data.message ?? "Error al obtner al cliente")
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    /**
    * @memberof Configuracion
    * @method onFinish
    * @description Se ejecuta al dar aceptar al formulario
    */
    onFinish = (values) => {
        if (this.props.cliente_id) {
            this.updateCliente(values)
        } 
    }


    /**
     * @memberof Configuracion
     * @method updateCliente
     * @description Actualizala informacion del cliente
     */
    updateCliente = (values) => {
        this.setState({ loading: true })
        axios.put('/cliente/generate/key', {
            cliente_id: this.props.cliente_id,
        })
        .then(({ data }) => {
            message.success("llave generada")
            this.setState({secret_key: data})
        }).catch(error => {
            message.error(error.response.data.message ?? "Error al generar la lleva")
        }).finally(() => {
            this.setState({ loading: false })
        })

    }



    render() {

        let { cliente, secret_key } = this.state;

        return (
            <Form
                layout="vertical"
                ref={this.modalConfifuracion}
                onFinish={this.onFinish}
                onValuesChange={this.onValuesChange}
            >
                <Spin spinning={this.state.loading}>
                    <Row justify="center">
                        <Col xs={20} md={20}>
                            {
                                secret_key ? <>
                                    <Paragraph>Nueva llave generada</Paragraph>
                                    <Paragraph copyable={true}>{secret_key}</Paragraph>
                                    <Paragraph type="warning"> Por favor, guarde esta clave, ya que no se mostrará nuevamente y necesitará generar una nueva si la pierde. </Paragraph>
                                </> : !cliente.secret_key ? <Paragraph style={{textAlign: "justify"}}>
                                    Aun no has generado una llave para hacer peticiones de forma externa, crea una haciendo click en 'Generar nueva llave'
                                </Paragraph> : <><Paragraph style={{textAlign: "justify"}}>
                                    Ya tiene una llave secreta en uso. Si genera una nueva, recuerde que deberá reemplazarla en todos los sistemas en los que se utiliza actualmente.
                                </Paragraph>
                                <Paragraph style={{textAlign: "justify"}}>
                                    Haga click en 'Generar nueva llave' para crear una nueva llave
                                </Paragraph></>
                            }
                        </Col>
                        <Col xs={20} md={20}>
                            
                            
                            <Form.Item >
                                <Button htmlType="submit" type="primary" block size="large" className="mt-2">
                                    Generar nueva llave
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function Modals(props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        destroyOnClose={true}
        zIndex={1000}
        footer={false}
        closable={false}
    >
        <Row>
            <Col span={24} className="center">
                <img src={"/img/BXNKLogo.png"} width="164" />
            </Col>
        </Row>
        <Title level={3} className="text-center mb-2 mt-1">Generación de llave</Title>
        <Configuracion {...props} />
    </Modal>

}