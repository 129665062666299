

const IconDashboard = () => {
	return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="13" y="4" width="7" height="16" rx="3.5" stroke="white" strokeWidth="1.5"/>
		<rect x="3" y="4" width="6" height="6" rx="3" stroke="white" strokeWidth="1.5"/>
		<rect x="3" y="14" width="6" height="6" rx="3" stroke="white" strokeWidth="1.5"/>
	</svg>
}

const IconMoney = () => {
	return <svg width="10" height="21" viewBox="0 0 10 21" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M3.554 20.728L3.972 16.878L5.116 17.054L4.72 20.728H3.554ZM6.634 3.942L5.49 3.766L5.886 0.421999H7.03L6.634 3.942ZM5.226 18.22C4.77133 18.22 4.25067 18.1907 3.664 18.132C3.092 18.0733 2.52 18.0073 1.948 17.934C1.39067 17.846 0.899333 17.7727 0.474 17.714L0.65 16.064C1.06067 16.108 1.53733 16.1667 2.08 16.24C2.63733 16.2987 3.18733 16.35 3.73 16.394C4.27267 16.4233 4.73467 16.438 5.116 16.438C6.06933 16.438 6.78067 16.2253 7.25 15.8C7.71933 15.3747 7.954 14.7147 7.954 13.82C7.954 13.336 7.85867 12.9547 7.668 12.676C7.47733 12.3827 7.15467 12.1553 6.7 11.994C6.26 11.818 5.666 11.6567 4.918 11.51C3.87667 11.29 3.01867 11.0333 2.344 10.74C1.66933 10.4467 1.17067 10.0287 0.848 9.486C0.525333 8.94333 0.364 8.21733 0.364 7.308C0.364 6.31067 0.554667 5.51867 0.936 4.932C1.332 4.33067 1.88933 3.898 2.608 3.634C3.32667 3.37 4.17 3.238 5.138 3.238C5.578 3.238 6.07667 3.26733 6.634 3.326C7.19133 3.37 7.734 3.42867 8.262 3.502C8.80467 3.57533 9.274 3.64133 9.67 3.7L9.516 5.372C9.10533 5.328 8.62867 5.27667 8.086 5.218C7.558 5.15933 7.03733 5.11533 6.524 5.086C6.02533 5.042 5.61467 5.02 5.292 5.02C4.33867 5.02 3.62 5.196 3.136 5.548C2.652 5.88533 2.41 6.41333 2.41 7.132C2.41 7.704 2.51267 8.13667 2.718 8.43C2.938 8.72333 3.29 8.958 3.774 9.134C4.258 9.29533 4.896 9.47133 5.688 9.662C6.45067 9.82333 7.10333 10.0067 7.646 10.212C8.20333 10.4173 8.65067 10.6667 8.988 10.96C9.32533 11.2387 9.57467 11.598 9.736 12.038C9.912 12.478 10 13.0207 10 13.666C10 14.7513 9.802 15.6313 9.406 16.306C9.02467 16.966 8.47467 17.45 7.756 17.758C7.052 18.066 6.20867 18.22 5.226 18.22Z" fill="white"/>
	</svg>
}


const IconTransferencia = () => {
	return <svg width="79" height="58" viewBox="0 0 79 58" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M0.00351967 44.8656C0.517674 43.7994 1.33997 43.4264 2.53908 43.4352C8.46594 43.4756 14.3963 43.5513 20.3232 43.4017C27.6781 43.2152 33.899 36.9393 34.1754 29.5955C34.3075 26.1241 34.8287 22.7618 36.6582 19.7426C40.3911 13.5846 45.9094 10.5566 53.09 10.5337C58.5485 10.5144 64.007 10.5337 69.4655 10.5337H70.6804C70.2878 10.2575 70.1029 10.1097 69.9004 9.98831C66.3013 7.83359 62.7016 5.68298 59.1014 3.53647C58.397 3.11948 57.9005 2.5547 57.9639 1.70313C58.0273 0.888513 58.552 0.395874 59.3197 0.149553C59.3933 0.110129 59.4604 0.0596943 59.5187 0H59.8708C61.8658 1.15771 63.8714 2.30134 65.8576 3.47664C69.8159 5.82256 73.7671 8.17962 77.7113 10.5478C79.4351 11.5788 79.4298 13.0286 77.6937 14.0667C72.1342 17.3897 66.573 20.7086 61.0101 24.0233C60.8117 24.1483 60.6046 24.2589 60.3903 24.3541C60.0091 24.516 59.5827 24.5367 59.1876 24.4126C58.7926 24.2884 58.4548 24.0275 58.2351 23.6767C58.0086 23.3454 57.9121 22.9423 57.964 22.5445C58.016 22.1466 58.2128 21.7818 58.5168 21.5196C58.7787 21.2859 59.0657 21.0817 59.3725 20.9109C62.792 18.8629 66.2138 16.819 69.638 14.7793C69.9285 14.6033 70.212 14.4186 70.5184 14.2268C70.4216 14.1511 70.3828 14.0913 70.3423 14.0913C64.0932 14.0913 57.8406 13.9893 51.5951 14.13C44.4321 14.2919 38.213 20.5027 37.8414 27.6566C37.69 30.6564 37.4435 33.6298 36.0701 36.3693C32.6066 43.2821 26.9773 46.8291 19.2667 46.9593C13.6938 47.0526 8.1173 46.9488 2.53908 46.9998C1.33997 47.0103 0.515915 46.6321 0 45.5694L0.00351967 44.8656Z" fill="url(#paint0_linear_16_934)"/>
		<path d="M0 12.3688C0.50492 11.3622 1.32232 10.9887 2.52989 11.0003C8.3559 11.0531 14.1678 11.0003 19.9849 11.0598C24.1444 11.1077 27.8536 12.4349 31.1073 14.8727C32.0607 15.5884 32.2672 16.547 31.6458 17.3238C31.0049 18.127 29.9933 18.2477 28.9976 17.5072C25.8427 15.1421 22.2412 14.2612 18.2424 14.3306C13.0432 14.4199 7.84039 14.3306 2.63936 14.3719C1.42296 14.3818 0.540229 14.0645 0 13.0299V12.3688Z" fill="url(#paint1_linear_16_934)"/>
		<path d="M70.5631 43.5157L67.8265 41.8178C64.9517 40.0404 62.0698 38.2775 59.2037 36.4838C57.9046 35.671 57.6927 34.3163 58.6785 33.4384C59.3158 32.8676 60.1089 32.8496 61.0211 33.4113C65.076 35.9052 69.1297 38.4027 73.1823 40.9038C74.6834 41.8286 76.1838 42.754 77.6837 43.68C79.4205 44.7638 79.4433 46.2287 77.724 47.2926C72.1738 50.7234 66.6202 54.1475 61.0631 57.565C59.9093 58.2748 58.8728 58.1068 58.3178 57.1278C57.7628 56.1488 58.12 55.1752 59.3018 54.4437C62.8268 52.2617 66.3541 50.0863 69.8838 47.9176C70.1484 47.773 70.3754 47.5648 70.5456 47.3107H69.6702C63.7173 47.2998 57.7645 47.3685 51.8117 47.2547C47.6465 47.1752 43.9838 45.5423 40.8025 42.7769C39.9148 42.0038 39.752 40.9237 40.3718 40.1452C41.0284 39.3143 42.0999 39.195 43.012 39.9934C46.2056 42.7841 49.9086 43.7975 54.0423 43.7234C59.5241 43.6241 65.0095 43.6981 70.5001 43.6981L70.5631 43.5157Z" fill="url(#paint2_linear_16_934)"/>
		<defs>
		<linearGradient id="paint0_linear_16_934" x1="-13.5781" y1="-100.714" x2="192.893" y2="4.72516" gradientUnits="userSpaceOnUse">
		<stop stopColor="#FD6B3D"/>
		<stop offset="1" stopColor="#B358B0"/>
		</linearGradient>
		<linearGradient id="paint1_linear_16_934" x1="-5.5" y1="-4" x2="30.5" y2="46" gradientUnits="userSpaceOnUse">
		<stop stopColor="#FD6B3D"/>
		<stop offset="1" stopColor="#B358B0"/>
		</linearGradient>
		<linearGradient id="paint2_linear_16_934" x1="33.2969" y1="-20.5714" x2="138.234" y2="29.1646" gradientUnits="userSpaceOnUse">
		<stop stopColor="#FD6B3D"/>
		<stop offset="1" stopColor="#B358B0"/>
		</linearGradient>
		</defs>
	</svg>
}

const IconBeneficiario = () => {
	return <svg width="73" height="66" viewBox="0 0 73 66" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M56.0672 65.8765C53.0186 65.1252 50.1949 63.9796 47.9347 61.6609C46.4033 60.0952 45.2606 58.192 44.5985 56.1043C43.9365 54.0167 43.7735 51.8027 44.1227 49.6406C44.4719 47.4785 45.3236 45.4285 46.6092 43.6554C47.8949 41.8823 49.5786 40.4356 51.5251 39.4316C53.4716 38.4276 55.6265 37.8944 57.8165 37.8747C60.0066 37.8551 62.1707 38.3496 64.1349 39.3185C66.099 40.2873 67.8085 41.7036 69.1257 43.4533C70.443 45.2031 71.3313 47.2375 71.7193 49.393C71.787 49.7841 71.9133 50.1659 72.0272 50.5508V53.3222C71.4575 55.9027 70.6908 58.3846 69.0033 60.5124C66.6138 63.5239 63.4267 65.1005 59.7654 65.8888L56.0672 65.8765ZM60.3166 49.5901C60.3166 48.2752 60.409 47.1082 60.2704 45.9657C60.2181 45.5162 59.6915 44.8264 59.3066 44.774C58.1672 44.6139 56.9909 44.7155 55.5837 44.7155V49.5931H50.7923C50.7923 50.9788 50.7215 52.2383 50.8447 53.4823C50.8724 53.7594 51.5437 54.1536 51.944 54.1875C53.0987 54.2829 54.2627 54.2213 55.5806 54.2213C55.5806 54.9296 55.5806 55.4746 55.5806 56.0196C55.6391 59.5762 55.014 59.099 58.9432 59.1236C59.9194 59.1236 60.3381 58.7017 60.3197 57.741C60.2981 56.5955 60.3197 55.4469 60.3197 54.2213H62.0657C65.3944 54.1906 65.2558 54.8895 65.1788 50.6524C65.1788 50.2921 64.5168 49.6855 64.1103 49.6393C62.9063 49.5008 61.6961 49.5901 60.3166 49.5901Z" fill="url(#paint0_linear_16_954)"/>
		<path d="M10.0561 48.6601C14.0099 47.6316 17.8467 46.6524 21.6681 45.627C22.8059 45.35 23.9159 44.9696 24.9845 44.4907C26.6781 43.687 27.0353 42.1474 25.9299 40.774C24.8706 39.4561 24.5072 37.7994 23.0446 36.5585C21.8436 35.5361 21.0985 33.5438 22.5734 31.3667C23.3556 30.2151 22.6535 28.0072 22.5088 26.2828C22.364 24.5584 21.7359 23.0034 19.6296 22.5569C22.2594 19.3975 24.8644 16.4752 28.4642 14.6431C32.3133 12.6785 35.2386 13.1558 38.3549 16.1581C38.6628 16.466 39.0662 16.8109 39.4634 16.8694C44.7352 17.6577 47.4696 25.5222 44.2271 30.6093C43.9838 30.988 43.7067 31.3483 43.4511 31.7116C45.2987 33.2359 44.7691 36.6139 42.5273 37.6978C42.3177 37.8032 42.1409 37.964 42.0162 38.1628C41.2833 39.3945 40.572 40.6478 39.679 42.1936L41.8776 44.7494C39.0662 52.0443 40.5627 57.8672 44.341 62.9789C35.0877 67.2899 19.4787 66.2491 8.99062 55.1205C3.74917 49.6186 0.595863 42.4575 0.0762817 34.8764C-0.443299 27.2952 1.70354 19.7709 6.14535 13.6053C15.0753 1.04486 32.0731 -3.51251 46.1578 2.87088C61.1355 9.64535 66.8507 25.0819 65.1325 35.8533L60.6213 34.523C60.8615 26.4676 58.4658 19.3082 52.7906 13.3929C48.7797 9.19175 43.5442 6.36493 37.8314 5.31585C26.5365 3.11415 14.712 8.49061 8.75353 18.5199C3.09069 28.038 3.72194 40.9034 10.0561 48.6601Z" fill="url(#paint1_linear_16_954)"/>
		<defs>
		<linearGradient id="paint0_linear_16_954" x1="39.1167" y1="-22.1573" x2="123.801" y2="3.63459" gradientUnits="userSpaceOnUse">
		<stop stopColor="#FD6B3D"/>
		<stop offset="1" stopColor="#B358B0"/>
		</linearGradient>
		<linearGradient id="paint1_linear_16_954" x1="-11.2454" y1="-140.005" x2="186.082" y2="-79.9687" gradientUnits="userSpaceOnUse">
		<stop stopColor="#FD6B3D"/>
		<stop offset="1" stopColor="#B358B0"/>
		</linearGradient>
		</defs>
	</svg>

}

const IconTransacciones = () => {
	return <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<line x1="4.75" y1="5.25" x2="8.25" y2="5.25" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
		<line x1="4.75" y1="9.25" x2="10.25" y2="9.25" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
		<line x1="4.75" y1="13.25" x2="12.25" y2="13.25" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
		<rect x="1" y="1" width="15" height="18" rx="2" stroke="white" strokeWidth="1.5"/>
	</svg>

}

const IconCuenta = () => {
	return <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M0.959902 4.42602C0.959902 4.10571 0.955759 3.79963 0.965081 3.49408C0.966117 3.45576 1.03111 3.40267 1.07746 3.38455C1.64662 3.16134 2.21811 2.94408 2.78856 2.72424C4.87824 1.91867 6.96765 1.11284 9.05707 0.307262C9.3142 0.208087 9.56952 0.104509 9.82924 0.0128432C9.88672 -0.00735441 9.96544 -0.00269343 10.0232 0.0195757C12.7126 1.05276 15.4009 2.08879 18.0893 3.12483C18.35 3.2253 18.6108 3.32603 18.871 3.42624V4.42602H0.959902ZM9.92375 3.0979C10.3567 3.09091 10.6863 2.76904 10.6781 2.36094C10.6698 1.94715 10.3148 1.60483 9.90511 1.6157C9.50582 1.62632 9.17385 1.97615 9.18058 2.37933C9.18758 2.78691 9.51566 3.10437 9.92375 3.0979Z" fill="#A1A1A3"/>
		<path d="M0.407471 4.74243H19.437V6.22851H0.407471V4.74243Z" fill="#A1A1A3"/>
		<path d="M2.04514 7.15549H1.43921V6.54102H4.87876V7.14772H4.27904V14.22H4.8772V14.8319H1.44024V14.2275H2.04514V7.15549Z" fill="#A1A1A3"/>
		<path d="M11.0835 7.15581H10.4783V6.54419H13.9155V7.14727H13.3156V14.2206H13.9148V14.8325H10.4775V14.227H11.0837V7.15581H11.0835Z" fill="#A1A1A3"/>
		<path d="M9.39426 7.1547H8.79377V14.2208H9.39503V14.8365H5.96118V14.2259H6.56582V7.15703H5.96248V6.54126H9.39426V7.1547Z" fill="#A1A1A3"/>
		<path d="M17.8351 14.2274H18.4315V14.8359H14.9968V14.2271H15.6048V7.15822H14.9981V6.54297H18.4286V7.15019H17.8351V14.2274Z" fill="#A1A1A3"/>
		<path d="M19.8579 15.1543V16H0V15.1543H19.8579Z" fill="#A1A1A3"/>
	</svg>

}

const IconMoneda = () => {
	return <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M9.50593 20C5.63764 19.8286 2.76909 18.0067 1.03021 14.4667C-0.991252 10.3487 0.0414833 5.30215 3.4326 2.31566C7.6928 -1.43642 14.1051 -0.523336 17.2982 4.29003C20.9293 9.76362 18.4891 17.4195 12.4515 19.4971C11.4985 19.8251 10.5201 19.9874 9.50593 20ZM10.6659 17.954C10.6659 17.4188 10.681 16.9173 10.6597 16.4165C10.648 16.1433 10.7126 16.014 10.9959 15.9312C11.8767 15.6748 12.6213 15.1873 13.1789 14.4295C14.1443 13.1153 13.9731 11.3264 12.7561 10.2532C12.4295 9.9652 12.063 9.70883 11.6787 9.50866C11.0275 9.16941 10.3358 8.91023 9.68401 8.57239C9.17727 8.30971 8.67122 8.02244 8.22567 7.66633C7.76293 7.29618 7.81863 6.71883 8.33568 6.42032C8.66297 6.23068 9.05557 6.09653 9.42961 6.06422C10.5187 5.9701 11.5535 6.15904 12.4336 6.9471C12.8757 6.28617 13.2979 5.65474 13.7847 4.92778C12.7836 4.56114 11.8437 4.21698 10.8577 3.85596V2.04805H8.48076V3.98871C8.33293 4.03366 8.21329 4.06948 8.09434 4.10741C7.33595 4.34762 6.66281 4.72971 6.14438 5.35904C5.17216 6.54043 5.22441 8.07722 6.30596 9.15115C6.63944 9.48197 7.02035 9.78469 7.42671 10.0116C8.06684 10.3698 8.75854 10.631 9.40692 10.9759C9.96042 11.2702 10.5339 11.5617 11.0055 11.967C11.5253 12.4137 11.4387 13.1301 10.8811 13.534C10.6356 13.711 10.3317 13.8851 10.0443 13.9034C9.37735 13.9455 8.68291 13.9919 8.03934 13.85C7.37652 13.7039 6.76114 13.3359 6.08525 13.0486C5.75659 13.5845 5.38187 14.1942 5.00095 14.8151C5.96424 15.6031 7.06298 15.96 8.25799 16.1334V17.954H10.6652H10.6659Z" fill="#AAAAAC"/>
	</svg>

}

const IconFiltro = () => {
	return <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M8.00001 16C7.71668 16 7.47901 15.904 7.28701 15.712C7.09501 15.52 6.99934 15.2827 7.00001 15V9L1.20001 1.6C0.950009 1.26667 0.912343 0.916667 1.08701 0.55C1.26168 0.183334 1.56601 0 2.00001 0H16C16.4333 0 16.7377 0.183334 16.913 0.55C17.0883 0.916667 17.0507 1.26667 16.8 1.6L11 9V15C11 15.2833 10.904 15.521 10.712 15.713C10.52 15.905 10.2827 16.0007 10 16H8.00001Z" fill="white"/>
	</svg>
}

const IconColoredCuenta = () => {
	return <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M42.2035 5C29.613 5 19.4071 15.2059 19.4071 27.7965C19.4071 33.0681 21.1993 37.9199 24.1988 41.7818L21.1168 44.8638L16.445 40.192C16.3308 40.0803 16.1774 40.0177 16.0176 40.0177C15.8578 40.0177 15.7044 40.0803 15.5902 40.192L12.6056 43.1766C12.4939 43.2908 12.4313 43.4442 12.4313 43.604C12.4313 43.7638 12.4939 43.9172 12.6056 44.0314L17.2774 48.7032L13.9104 52.0702L9.23864 47.3984C9.12443 47.2867 8.971 47.2241 8.81121 47.2241C8.65142 47.2241 8.49798 47.2867 8.38378 47.3984L5.39924 50.383C5.28748 50.4972 5.2249 50.6506 5.2249 50.8104C5.2249 50.9702 5.28748 51.1236 5.39924 51.2378L10.071 55.9096L5.17427 60.8063C5.06263 60.9191 5 61.0714 5 61.23C5 61.3887 5.06263 61.541 5.17427 61.6537L8.34628 64.8257C8.57875 65.0582 8.96119 65.0582 9.19365 64.8257L28.2107 45.8087C32.2139 48.9137 37.1373 50.597 42.2035 50.5929C54.7941 50.5929 65 40.387 65 27.7965C65 15.2059 54.7941 5 42.2035 5ZM54.2917 39.8846C51.0672 43.1166 46.7703 44.8938 42.2035 44.8938C37.6367 44.8938 33.3399 43.1166 30.1154 39.8846C26.8834 36.6601 25.1062 32.3633 25.1062 27.7965C25.1062 23.2297 26.8834 18.9328 30.1154 15.7083C33.3399 12.4763 37.6367 10.6991 42.2035 10.6991C46.7703 10.6991 51.0672 12.4763 54.2917 15.7083C57.5237 18.9328 59.3009 23.2297 59.3009 27.7965C59.3009 32.3633 57.5237 36.6601 54.2917 39.8846Z" fill="url(#paint0_linear_269_132)"/>
		<defs>
			<linearGradient id="paint0_linear_269_132" x1="-5.3125" y1="-123.572" x2="175.688" y2="-68.5801" gradientUnits="userSpaceOnUse">
				<stop stop-color="#FD6B3D"/>
				<stop offset="1" stop-color="#B358B0"/>
			</linearGradient>
		</defs>
	</svg>
	
}

export {
	IconDashboard,
	IconMoney,
	IconTransferencia,
	IconBeneficiario,
	IconTransacciones,
	IconCuenta,
	IconColoredCuenta,
	IconMoneda,
	IconFiltro
}