import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'
import { UserOutlined } from '@ant-design/icons';
import { getResponseError } from '../../Utils';

import axios from 'axios'


const { Option } = Select 


/**
 * @const SelectCliente
 * @description Select para los clientes registrados en el sistema
 */
const SelectCliente = (props) => {

    const { 
        value, 
        onChange, 
        placeholder = "Seleccione el Cliente", 
        onSelect = () => {}, 
        disabled = false, 
        className = "", 
        params = {}, 
        bordered, 
        allowClear = true, 
        onClear = () => {},
        size="default",
        suffixIcon=null,
        mode = null
    } = props

    const [ clientes, setClientes ] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
    })


    /**
     * @const getClientes
     * @description Obitiene las clientes
     */
    const getClientes = ({page, limit, search} = clientes) => {

        axios.get('/clientes', {
            params: {
                page,
                limit,
                search,
                ...params,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            setClientes(data)
        }).catch(error => {
            console.log("ERR, error",error)
            message.error(getResponseError(error.response, 'Error al obtener los clientes'))
        })
    }

    //componentDidMount
    useEffect(() => {
        getClientes()
    }, [])

    useEffect(() => {
        if(value)
            onChange(value?.value ?? value)
    }, [value])

    return (
        <Select
            {...props}
            size={size}
            bordered={bordered}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            allowClear={allowClear}
            showSearch
            filterOption={false}
            labelInValue
            onSearch={(search)=> getClientes({search})}
            onSelect={(cliente)=> {
                if(mode === null){
                    onChange(cliente.value)
                    onSelect(cliente.value)
                }
            }}
            onChange={(values)=>{
                if(mode === "multiple"){
                    let arr = values.map(e => e.value)
                    onChange(arr)
                    onSelect(arr)
                }
            }}
            value={value}
            onClear={()=>{
                onClear()
                onChange(undefined)
                onSelect(undefined)
            }}
            suffixIcon={suffixIcon ? <UserOutlined style={{fontSize: '18px'}}/> : null}
        >
            {
                clientes?.data?.map(({ _id, nombre, apellido_paterno, apellido_materno, email }) => <Option value={_id} key={_id}>
                    {nombre ?? ""} {apellido_paterno ?? ""} {apellido_materno ?? ""} / {email ?? ""}
                </Option>)
            }
        </Select>
    );
}



export default SelectCliente;