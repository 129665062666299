import React, { Component, useContext, useState } from "react";
import { Row, Col, Modal, Typography, Form, Input, message, Tabs, Spin, InputNumber, Button, Select, Space, TimePicker, DatePicker, Steps } from 'antd';
import { ArrowLeftOutlined } from "@ant-design/icons";
import { IconMoneda } from '../../Widgets/Icons';
import { SelectCuenta, SelectBeneficiario, SelectTipoPago } from "../../Widgets/Inputs/Selects";
import { FormBeneficiario } from "../Beneficiarios/ModalBeneficiario";
import axios from "axios"
import { getResponseError } from "../../Utils";
import usePermissions from "../../../Hooks/usePermissions";
import { User } from '../../../Hooks/Logged';

//componentes 
import Reglas from "./Forms/Reglas";
import Cuenta from "./Forms/Cuenta";
import Revendedor from "./Forms/Revendedor";

const moment = require('moment')
const { Title, Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;


class FormRevendedores extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            current: 0,
            loading: false,
        }
    }
    modalRef = React.createRef();

    componentDidMount() {

    }



    renderStep = (current) => {

        let props = {
            data: this.state.data,
            nextStep: this.nextStep,
            backStep: this.backStep,
            onFinish: this.onFinish,
            tipo: this.props.tipo,
            user: this.props.user,
        }

        const steps = [
            <Revendedor {...props} />,
            <Cuenta {...props} />,
            <Reglas {...props} />
        ]

        return steps[current]
        // return steps.map((e, index) => <div style={{ display: (index == current) ? "block" : "none" }}>
        //     {e}
        // </div>)

    }

    nextStep = (data) => {
        console.log("data", data);
        this.setState({
            data: {
                ...this.state.data,
                ...data,
            },
            current: this.state.current + 1,

        },
            (this.state.current == 2) ? () => this.onFinish(this.state.data) : undefined
        )
    }

    backStep = (step) => {
        console.log("step", step);
        this.setState({ current: this.state.current - 1 })
    }

    onFinish = (values) => {
        console.log("values X", values);
        this.setState({ loading: true })

        let url = ''
        switch (this.props.tipo) {
            case 'personal':
                url = '/cliente/new'
                break;    
            case 'cliente':
                url = '/cliente/new'
                break;
            case 'vendedor':
                url = '/vendedor/new'
                break;
            case 'revendedor':
                url = '/revendedor'
                break;
        }
        axios.post(url, {
            ...this.state.data,
            ...values
        }).then(response => {
            message.success("Creado Exitosamente")
            this.props.onClose(true)
        }).catch(error => {
            message.error(error.response.data.message ?? "Error al crear el revendedor")
        }).finally(() => this.setState({ loading: false }))

    }

    render() {
        return (

            <Spin spinning={this.state.loading}>
                <Steps
                    className="mb-2"
                    current={this.state.current}
                    progressDot={"a"}
                    items={[
                        {
                            title: 'Información',
                        },
                        {
                            title: 'Cuenta',
                        },
                        {
                            title: 'Comisiones',
                        },
                    ]}
                />
                <Row>
                    <Col span={24}>
                        {this.renderStep(this.state.current)}
                    </Col>
                </Row>
            </Spin>
        )
    }
}

export default function ModalTransferencia(props) {
    
    const {
        visible = false,
        onClose = () => { },
        title
    } = props

    let user = React.useContext(User)
    
    return <Modal
        open={visible}
        onCancel={onClose}
        destroyOnClose={true}
        zIndex={1000}
        footer={false}
        closable={false}
        width={800}
    >
        <Row>
            <Col span={24} className="center mb-1">
                <img src={"/img/BXNKLogo.png"} width="164" />
            </Col>
        </Row>
        <Title level={3} className="text-center mb-2 mt-1">
            {title}
        </Title>
        <FormRevendedores {...props} user={user}/>
    </Modal >
}