import React, { Component } from "react";
import { Row, Col, Modal, Typography, Form, Input, message, Spin, InputNumber, Button, Select } from 'antd';

//componentes


import axios from "axios"

const { Title, Paragraph } = Typography;


class Modal2FA extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accionModal: '',
            loading: false,
            datosConsultados: false,
            return: false,
            tipo: this.props.tipo || 1,
        }
    }

    modal2FA = React.createRef();

    componentDidMount() { }



    /**
     * @memberof Modal2FA
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {

        let ruta = '/verificacion'
        if (this.props.logged) ruta = '/customer/verificacion'
        axios.post(ruta, {
            ...values,
            ...this.props.values
        }).then(response => {
            message.success(this.props.logged ? 'Varificación de 2 pasos activada' : 'Sesión Iniciada correctamente')
            this.props.login(response)
        })
            .catch(error => {
                console.log('e', error);
                message.error(error?.response?.data?.message ?? "Error de validación")
            })

    }


    render() {
        return (
            <Form
                layout="vertical"
                ref={this.modal2FA}
                onFinish={this.props.onFinish || this.onFinish}
                autocomplete="off"
            >
                <Spin spinning={this.state.loading}>

                    <Row align="center">
                        <Col xs={22} lg={20} className="center ">
                            {this.props.qrcode ? <Paragraph align="justify">
                                Para mejorar la seguridad de tu cuenta, es necesario instalar una aplicación como Google Authenticator.
                                Esta app generará códigos únicos que deberás introducir cada vez que inicies sesión.
                                Para configurarla, solo necesitarás escanear el código QR que aparece a continucación con la cámara de tu teléfono.
                                De esta manera, podrás proteger tus datos personales y evitar accesos no autorizados a tu cuenta.
                            </Paragraph> : <Paragraph align="justify">
                                Por favor, ingresa el código generado por Google Authenticator en el siguiente para continuar
                            </Paragraph>}
                        </Col>
                        <Col xs={22} lg={20} className="center ">
                            {this.props.qrcode ? <img src={this.props.qrcode} /> : null}
                        </Col>
                        <Col xs={22} lg={20} className="mt-2">

                            <Form.Item
                                name="token"
                                rules={[{
                                    required: true,
                                    message: "Por favor, Ingrese el token"
                                }]}
                            >
                                <Input
                                    size="large"
                                    placeholder="Código"
                                />
                            </Form.Item>

                            <Form.Item >
                                <Button htmlType="submit" type="primary" block size="large" className="mt-2">
                                    Aceptar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}





export default function Modals(props) {
    const { visible = false, onClose = () => { }, usuario_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        destroyOnClose={true}
        zIndex={1000}
        footer={false}
        closable={false}
    >
        <Row>
            <Col span={24} className="center">
                <img src={"/img/BXNKLogo.png"} width="164" />
            </Col>
        </Row>
        <Title level={1} className="text-center mb-2 mt-1">Verificación de 2 pasos</Title>
        <Modal2FA {...props} />
    </Modal>

}