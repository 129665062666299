import React, { Component } from "react";
import { Route, Routes, redirect, useNavigate } from "react-router-dom";
import { Layout } from "antd";


// Routers
import RouterRoles from "./Admin/RouterRoles";
import RouterDashboard from "./RouterDashboard";
import RouterUsuarios from "./Admin/RouterUsuarios";
import RouterTransacciones from "./Admin/RouterTransacciones";
import RouterBeneficiarios from "./Admin/RouterBeneficiarios";
import RouterClientes from "./Admin/RouterClientes";
import RouterCobranza from "./Admin/RouterCobranza";
import RouterVendedores from "./Admin/RouterVendedores";
import RouterRevendedores from "./Admin/RouterRevendedores";
import RouterBloqueVendedores from "./Admin/RouterBloqueVendedores";


// Components
import Header from "../Components/Header/Header";
import AdminSidebar from "../Components/Sidebar/AdminSidebar.js"
import { User, SetUser } from '../Hooks/Logged';
import { Search, ShowSearch } from "../Hooks/Search"
//css
import '../Styles/Global.scss'
import RouterCuentas from "./Admin/RouterCuentas";
import RouterBitacora from "./Admin/RouterBitacora";
import RouterReglas from "./Admin/RouterReglas";

import { PrivateRoutes } from "./Middlewares/PrivateRoutes"
import { Cuenta, SetCuenta } from "../Hooks/Cuentas"


const { Content } = Layout;

class AdminRoutes extends Component {

	constructor(props) {
		super(props)
		this.state = {
			search: null,
			showSearch: true,

			cuenta: null,
		}
	}


	setSearch = (e) => {
		this.setState({ search: e })
	}

	setShowSearch = (e) => {
		this.setState({ showSearch: e })
	}


	setCuenta = cuenta => this.setState({ cuenta })

	render() {
		const { setCuenta, } = this
		const { cuenta } = this.state
		return (

			<Cuenta.Provider value={cuenta}>
				<SetCuenta.Provider value={setCuenta}>

					<PrivateRoutes>
						<Layout className="main-layout">
							<AdminSidebar />
							<ShowSearch.Provider value={[this.state.showSearch, this.setShowSearch]}>
								<Search.Provider value={[this.state.search, this.setSearch]}>
									<Content>
										<Header admin={(this.props.user?.rol_id?.tipo !== 4 && this.props.user?.rol_id?.tipo !== 5)} />
										<Routes>
											<Route path="roles/*" element={<RouterRoles />} />
											<Route path="dashboard/*" element={<RouterDashboard />} />
											<Route path="usuarios/*" element={<RouterUsuarios />} />
											<Route path="cuentas/*" element={<RouterCuentas />} />
											<Route path="transacciones/*" element={<RouterTransacciones />} />
											<Route path="beneficiarios/*" element={<RouterBeneficiarios />} />
											<Route path="clientes/*" element={<RouterClientes />} />
											<Route path="bitacora/*" element={<RouterBitacora />} />
											<Route path="reglas/*" element={<RouterReglas />} />
											<Route path="bloques-vendedores/*" element={<RouterBloqueVendedores />} />
											<Route path="cobranza/*" element={<RouterCobranza />} />
											<Route path="vendedores/*" element={<RouterVendedores />} />
											<Route path="revendedores/*" element={<RouterRevendedores />} />
										</Routes>
									</Content>
								</Search.Provider>
							</ShowSearch.Provider>
						</Layout>
					</PrivateRoutes>
				</SetCuenta.Provider>
			</Cuenta.Provider >

		)
	}
}

export default function (props) {

	let user = React.useContext(User)

	return <AdminRoutes {...props} user={user} navigate={useNavigate()} />
} 
