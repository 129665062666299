import React from 'react'
import { Route, Routes } from "react-router-dom";
import Revendedores from '../../Components/Admin/Revendedores/Revendedores';

/**
 * 
 * @export
 * @function RouterRevendedores
 * @description Router para modulo de Revendedores del admin 
 */
function RouterRevendedores(props) {
  return (
    <Routes>
      <Route path='/' element={<Revendedores {...props} />} />
    </Routes>
  )
}

export default RouterRevendedores