import React, { Component } from "react";
import { Row, Col, Modal, Typography, Form, Input, message, Spin, Button, Switch } from 'antd';
import axios from "axios"
import { getResponseError } from "../Utils";

const { Title } = Typography;


class ModalCliente extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            direccion_id: undefined,
            pais_clave: undefined,
            mexico_clave: 187,
        }
    }

    modalUsuario = React.createRef();

    componentDidMount() {
        if (this.props.usuario_id) {
            this.get()
        }
    }

    /**
     * @memberof ModalCliente
     * @method get
     * @description Obtiene informacion del usuario
     */
    get = () => {

        this.setState({ loading: true })
        axios.get('/dashboard/cuenta-usuario/' + this.props.usuario_id, {
        })
            .then(({ data }) => {
                this.modalUsuario.current.setFieldsValue({
                    ...data
                })

                this.setState({
                    auth_2FA: data.auth_2FA,
                    secret: data.secret,
                })

            }).catch(error => {
                console.log(error)
                message.error(getResponseError(error.response, "Error al obtener el usuario"))
            }).finally(() => {
                this.setState({ loading: false })
            })

    }

    onFinish = (values) => {

        this.setState({ loading: true })
        axios.put('/customer/usuario', values)
            .then(response => {
                message.success("Actualizado con exito")
                this.props.onClose()
                if (response.data.type === "TEMP_SECRET")
                    this.props.openQR(response.data.qrcode)
            }).catch(error => {
                message.error(getResponseError(error.response, "Error al actualizar"))
            }).finally(() => this.setState({ loading: false }))
    }


    render() {
        return (
            <Form
                layout="vertical"
                ref={this.modalUsuario}
                onFinish={this.onFinish}
                onValuesChange={(value) => {
                    if (value.auth_2FA != undefined) {
                        this.setState({ auth_2FA_new: value.auth_2FA })
                    }

                }}
            >
                <Spin spinning={this.state.loading}>

                    <Row justify="center">
                        <Col xs={22} lg={20} >
                            <Row gutter={[16, 16]} align="center">
                                <Col xs={24} md={22}>
                                    <Form.Item
                                        name="nombre_completo"

                                    >
                                        <Input
                                            size="large"
                                            disabled
                                            placeholder="Nombre Completo"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={22}>
                                    <Form.Item
                                        name="email"

                                    >
                                        <Input
                                            size="large"
                                            disabled
                                            placeholder="Email"
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} md={11}>
                                    <Form.Item
                                        label="Verificación de 2 pasos"
                                        name="auth_2FA"
                                        valuePropName="checked"
                                    >
                                        <Switch />
                                    </Form.Item>
                                </Col>

                                {this.state.auth_2FA === true && this.state.secret === true && this.state.auth_2FA_new === false ? <Col xs={24} md={11}>
                                    <Form.Item
                                        name="token"
                                        rules={[{
                                            required: true,
                                            message: "Por favor, ingrese el token"
                                        }]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder="Ingrese código de verificación"
                                        />
                                    </Form.Item>
                                </Col> : <Col xs={24} md={11}></Col>}

                                <Col xs={24}>
                                    <Form.Item >
                                        <Button htmlType="submit" type="primary" block size="large" className="mt-2">
                                            Aceptar
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function Modals(props) {
    const { visible = false, onClose = () => { }, cliente_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        destroyOnClose={true}
        width={750}
        zIndex={1000}
        footer={false}
        closable={false}
    >
        <Row>
            <Col span={24} className="center">
                <img src={"/img/BXNKLogo.png"} width="164" />
            </Col>
        </Row>
        <Title level={3} className="text-center mb-2 mt-1">Información de la Cuenta</Title>
        <ModalCliente {...props} />
    </Modal>

}