import React, { Component } from "react";
import { Row, Col, Modal, Space, Typography, Form, InputNumber, message, Spin, Button, Select, Divider, Switch } from 'antd';
import axios from "axios"
import Decimal from "decimal.js"
import moment from 'moment';
import ReglaList from "./ReglasList";
import ModalTemplates from "./TemplatesList";
import { User } from '../../../Hooks/Logged';
import { getResponseError } from "../../Utils";
import { SelectCliente, SelectInstituciones } from "../../Widgets/Inputs/Selects";

const { Option } = Select; 
const { Title, Text } = Typography;

/**
 *
 *
 * @class FormCuenta
 * @extends {Component}
 */
class FormCuenta extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            cuenta: {},
        }
    }

    formRef = React.createRef();

    componentDidMount() {
        if(this.props.cuenta_id)
            this.getCuenta()
    }

     /**
     * @memberof FormCuenta
     * @method getCuenta
     * @description Obtiene informacion de la cuenta
     */
    getCuenta = () => {
        this.setState({ loading: true })
        axios.get('/cuenta', {
            params: {
                cuenta_id: this.props.cuenta_id,
                primer_movimiento: true,
            }
        })
        .then(({ data }) => {
            this.setState({
                mes_inicial: moment(data.primer_movimiento).format("MM"),
                año_inicial: moment(data.primer_movimiento).format("YYYY"),
                selected_year: parseInt(moment().format("YYYY")),
                cuenta: data
            })
            
        }).catch(error => {
            console.log("error", error)
            message.error(getResponseError(error.response, "Error al obtener la cuenta"))
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    renderMonth = () => {

        let { año_inicial, mes_inicial, selected_year } = this.state;

        let { dia_corte } = this.state.cuenta;

        año_inicial = parseInt(año_inicial)
        mes_inicial = parseInt(mes_inicial)

        if(mes_inicial === undefined) return <Option value={moment().format("MM")}>{moment().format("MMMM").toUpperCase()}</Option>

        let año_actual = parseInt(moment().format("YYYY"))
        let mes_actual = parseInt(moment().format("MM"))

        if(dia_corte > moment().date()){
            mes_actual--
            if(mes_actual == 0){
                mes_actual = 12;
                año_actual--
            }
        }

        let meses = []

        if(año_actual === año_inicial){
            for (let mes = mes_inicial; mes <= mes_actual; mes++) {

                const month_name = moment().month(mes - 1);

                meses.unshift(<Option value={month_name.format("MM")}>{month_name.format("MMMM")}</Option>);
            }
        }

        if(selected_year > año_inicial && selected_year < año_actual){
            for (let mes = 1; mes <= 12; mes++) {

                const month_name = moment().month(mes - 1);

                meses.unshift(<Option value={month_name.format("MM")}>{month_name.format("MMMM")}</Option>);
            }
        }

        if(selected_year < año_actual && selected_year === año_inicial){
            for (let mes = mes_inicial; mes <= 12; mes++) {

                const month_name = moment().month(mes - 1);

                meses.unshift(<Option value={month_name.format("MM")}>{month_name.format("MMMM")}</Option>);
            }
        }

        if(selected_year > año_inicial && selected_year === año_actual){
            for (let mes = 1; mes <= mes_actual; mes++) {

                const month_name = moment().month(mes - 1);

                meses.unshift(<Option value={month_name.format("MM")}>{month_name.format("MMMM")}</Option>);
            }
        }

        return meses

    }


    renderYear = () => {

        let { año_inicial } = this.state;

        if(!año_inicial) return <Option value={moment().format("YYYY")}>{moment().format("YYYY")}</Option>

        let año_actual = moment().format("YYYY")
        año_inicial = parseInt(año_inicial)

        let años = []
 
        for (let año = año_inicial; año <= año_actual; año++) {
            años.unshift(<Option value={año}>{año}</Option>);
        }

        return años

    }

    onFinish = (values) => {
        let urlPDF = new URL(axios.defaults.baseURL + '/cuenta/estado');
        urlPDF.searchParams.append('Authorization', sessionStorage.getItem('token'))
        urlPDF.searchParams.append('cuenta', this.state.cuenta.cuenta)
        urlPDF.searchParams.append('month', values.month)
        urlPDF.searchParams.append('year', values.year)

        //window.open(urlPDF.href, '_blank');

        this.setState({loading: true})
        axios.get(urlPDF.href, { responseType: 'blob', })
        .then(response => window.open(URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))))
        .catch(error => {
            message.error("Error al obtener el Archivo")
        })
        .finally(e => this.setState({loading: false}))

    }

    onChangeValues = (value, allValues) => {

        let { month, year } = allValues;

        if(!month || !year) return

        month = parseInt(month);
        year = parseInt(year);

        let fechaInicio, fechaCorte;
        if (month === 1) {
            // Si el mes es enero, retrocede al año anterior
            fechaInicio = moment({ year: year - 1, month: 11, date: this.state.cuenta?.dia_corte + 1 });
            fechaCorte = moment({ year: year, month: 0, date: this.state.cuenta?.dia_corte });
        } else {
            fechaInicio = moment({ year: year, month: month - 2, date: this.state.cuenta?.dia_corte + 1 }).startOf("day");
            fechaCorte = moment({ year: year, month: month - 1, date: this.state.cuenta?.dia_corte }).endOf("day");
        }
        this.setState({
            periodo: `${fechaInicio.format("DD-MMM-YYYY")} al ${fechaCorte.format("DD-MMM-YYYY")}`
        })

    }

    render() {

    	const { cuenta, tipo_regla, comision, monto_total, update_reglas } = this.state;

        return (
            <>
            	<Spin spinning={this.state.loading}>
	                <Form
	                    layout="vertical"
	                    ref={this.formRef}
	                    onFinish={this.onFinish}
                        initialValues={{
                            //month: moment().format("MM"),
                            year: moment().format("YYYY")
                        }}
                        onValuesChange={this.onChangeValues}
	                >
	                    <Row justify="center" gutter={[16,8]}>
                            <Col span={10} className="">
                                <Text strong>Cuenta:</Text>
                            </Col>
                            <Col span={10} className="flex-right">
                                <Text>{cuenta?.cuenta?.hideCard()}</Text>
                            </Col>
                            <Col span={10} className="">
                                <Text strong>Día de corte:</Text>
                            </Col>
                            <Col span={10} className="flex-right">
                                <Text>{cuenta?.dia_corte}</Text>
                            </Col>
                            <Col span={20} className="center">
                                <Title level={4}>Seleccione el periodo</Title>
                            </Col>
	                    	<Col span={10}>
                                <Form.Item
                                    name="month"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, seleccione el mes"
                                    }]}
                                >
                                    <Select
                                        size="large"
                                        placeholder="Mes"
                                    >
                                        {this.renderMonth()}
                                        {/*<Option value={"01"}>Enero</Option>
                                        <Option value={"02"}>Febrero</Option>
                                        <Option value={"03"}>Marzo</Option>
                                        <Option value={"04"}>Abril</Option>
                                        <Option value={"05"}>Mayo</Option>
                                        <Option value={"06"}>Junio</Option>
                                        <Option value={"07"}>Julio</Option>
                                        <Option value={"08"}>Agosto</Option>
                                        <Option value={"09"}>Septiembre</Option>
                                        <Option value={"10"}>Octubre</Option>
                                        <Option value={"11"}>Noviembre</Option>
                                        <Option value={"12"}>Diciembre</Option>*/}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item
                                    name="year"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, seleccione el año"
                                    }]}
                                >
                                    <Select
                                        size="large"
                                        placeholder="Año"
                                        onSelect={(value)=>{
                                            console.log("value", value);
                                            this.setState({selected_year: value})
                                            this.formRef.current.setFieldsValue({month: null})
                                        }}
                                    >
                                        {this.renderYear()}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {this.state.periodo ? <>
                                <Col span={10} className="">
                                    <Text strong>Periodo:</Text>
                                </Col>
                                <Col span={10} className="flex-right">
                                    <Text>{this.state.periodo}</Text>
                                </Col>
                            </> : null}
                            <Col span={20}>
                                <Form.Item >
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        block
                                        size="large"
                                        className="mt-2"
                                    >
                                        Descargar
                                    </Button>
                                </Form.Item>
                            </Col>
	                    </Row>
	                </Form>
                </Spin>
            </>
        )
    }
}



export default function ModalCuenta(props) {

    let user = React.useContext(User)

    const { visible = false, onClose = () => { }, cuenta_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        destroyOnClose={true}
        zIndex={1000}
        footer={false}
        closable={false}
        width={900}
    >
        <Row>
            <Col span={24} className="center">
                <img src={"/img/BXNKLogo.png"} width="64" />
            </Col>
        </Row>
        <Title level={2} className="text-center mb-2 mt-1">Estado de Cuenta</Title>
        <FormCuenta {...props} user={user}/>
    </Modal>

}