import React from 'react'
import { Collapse, Layout, Col, Row, Pagination, Typography, message, List, Divider, Space, Button, } from "antd";
import axios from 'axios';
import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { useSearchParams, useNavigate } from 'react-router-dom'

import { User } from '../../../Hooks/Logged'
import usePermissions from '../../../Hooks/usePermissions';
import { CardTemplateRegla, } from '../../Widgets/Cards';
import FloatingButton from '../../Widgets/FloatingButton/FloatingButton';
import ModalBloqueVendedor from './ModalBloqueVendedor';
import useSearch from '../../../Hooks/Search';
import { getResponseError } from '../../Utils'

import BloquesVendedoresList from './BloquesVendedoresList';

const { Content } = Layout
const { Title } = Typography
const { Panel } = Collapse

class Reglas extends React.Component {

    constructor(props) {
        super(props)
        let { page, limit, filters, search, sort } = this.props.params
        this.state = {
            loading: false,
            bloques_vendedores: {
                page: page ?? 1,
                limit: limit ?? 20,
                total: 0,
                pages: 0,
                search: search ?? undefined,
                data: [],
            },
            searching: true,
            filtroSearch: '',
            template_id: undefined,
            tipo: undefined
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }

    render() {

        return (
            <>
                <Content className="p-1">
                    <Row className="mb-3" style={{ margin: "auto", maxWidth: 992 }}>
                        <Col span={24} className="" >
                            <Row className='main'>
                                <Col xs={24}>
                                    <Title level={5}> Reglas  </Title>
                                    <Title level={5}> Bloques Vendedores aplicables a todas las cuentas  </Title>
                                </Col>
                                <BloquesVendedoresList
                                    ref={ref => this.ref = ref}
                                    onEdit={(_id) => this.setState({ bloque_vendedor_id: _id, visibleModalBloqueVendedor: true })}
                                />
                            </Row>
                        </Col>
                    </Row>
                </Content>
                <FloatingButton onClick={() => this.setState({ visibleModalBloqueVendedor: true })} />

                <ModalBloqueVendedor
                    visible={this.state.visibleModalBloqueVendedor}
                    onClose={() => {
                        this.setState({ visibleModalBloqueVendedor: false, template_id: null });

                        console.log('this.ref',this.ref);
                        this.ref.get()
                    }}
                    bloque_vendedor_id={this.state.bloque_vendedor_id}
                />
            </>
        )
    }
}

export default function (props) {

    let user = React.useContext(User)
    const [search, setSearch] = useSearch();
    const [params, setParams] = useSearchParams();

    let permissions = usePermissions(user?.rol_id?.permisos, {
        editReglas: ["bloques-vendedores", "edit"],
        updateReglas: ["bloques-vendedores", "update"],
        createReglas: ["bloques-vendedores", "create"],
        deleteTemplates: ["bloques-vendedores", "delete"],

    })

    return (<Reglas {...props} navigate={useNavigate()} search={search} setParams={setParams} params={params} {...permissions} />)
}