import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'
import axios from 'axios'
import { getResponseError } from '../../Utils';
import { MdOutlinePlace } from 'react-icons/md'
const { Option } = Select 


/**
 * @const SelectEntidadFederativa
 * @description Select para las entidades registrados en el sistema
 */
const SelectEntidadFederativa = (props) => {

    const { 
        value, 
        onChange, 
        placeholder = "Seleccione la Entidad Federativa", 
        onSelect = () => {}, 
        disabled = false, 
        className = "", 
        params = {}, 
        bordered, 
        allowClear = true, 
        onClear = () => {},
        size="default"
    } = props

    const [ entidades, setEntidades ] = useState([])


    /**
     * @const getEntidades
     * @description Obitiene las entidades
     */
    const getEntidades = ({page, limit, search} = entidades) => {
        axios.get('/entidades-federativas', {
            params: {
                page,
                limit,
                search,
                ...params,
            }
        }).then(({ data }) => {
            setEntidades(data)
        }).catch(error => {
            console.log("ERR, error",error)
            message.error(getResponseError(error.response, 'Error al obtener las entidades'))
        })
    }

    //componentDidMount
    useEffect(() => {
        getEntidades()
    }, [])

    useEffect(() => {
        if(value)
            onChange(value?.value ?? value)
    }, [value])

    return (
        <Select
            size={size}
            bordered={bordered}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            allowClear={allowClear}
            showSearch
            filterOption={false}
            labelInValue
            onSearch={(search)=> getEntidades({search})}
            onSelect={(cuenta)=> {
                onChange(cuenta.value)
                onSelect(cuenta.value)
            }}
            value={value}
            onClear={()=>{
                onClear()
                onChange(undefined)
                onSelect(undefined)
            }}
            suffixIcon={<MdOutlinePlace fontSize={20}/>}
        >
            {
                entidades?.map(({ _id, nombre }) => <Option value={_id} key={_id}>
                    {nombre ?? ""} 
                </Option>)
            }
        </Select>
    );
}



export default SelectEntidadFederativa;