
import React from 'react';
import { Drawer, Form, Select, message, DatePicker, InputNumber } from 'antd';
import axios from 'axios';
import moment from 'moment';
import Cuenta from '../../../Hooks/Cuentas';

const { Option } = Select
const { RangePicker } = DatePicker

/**
 *
 *
 * @export
 * @class FiltrosDrawer
 * @extends {React.Component}
 * 
 * @description Permite filtrar según diversa información de la vista en la que se implemente
 */
class FiltrosDrawer extends React.Component {

    /**
     *
     *
     * @export
     * @class FiltrosDrawer
     * @extends {React.Component}
     * 
     * @state cuentas Objeto para paginar y buscar en el select de cuentas 
     * @state cuentas Objeto para paginar y buscar en el select de cuentas 
     * @state ordenes Objeto para paginar y buscar en el select de ordenes
     * @state ordenes_compras Objeto para paginar y buscar en el select de ordenes de compra
     * @state areas Objeto para paginar y buscar en el select de areas  
     * @state areas_dictionary Diccionario de Areas. Ya que el select solo nos da el ID, utilizamos este diccionario para obtener el objeto  
     * @state rubros_dictionary Diccionario de Rubros. Ya que el select solo nos da el ID, utilizamos este diccionario para obtener el objeto  
     * @state empresas Objeto para paginar y buscar en el select de Empresas
     * @state clasificadores Objeto para paginar y buscar en el select de Clasificadores 
     * 
     * @state paginator.data Lista de elementos para paginar
     * @state paginator.dictionary Ya que los selects funcionan solo con ID´s, utilizamos el diccionario para obtener el objeto al que el ID hace referencia
     * @state paginator.page Pagina actual
     * @state paginator.limit Limit de elementos por página
     * @state paginator.pages Total de paginas
     * @state paginator.total Total de Elementos en la lista
     * @state paginator.search Para buscar
     * @state paginator.search Loading, para declarar el actualizado.
     */
    state = {
        clientes: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },

        cuentas: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },
        beneficiarios: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },

    }

    /**
     *
     *
     * @memberof FiltrosDrawer
     * @methodof componentDidMount
     * @description Obtenemos todas las listas
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if (this.props.cuentas === undefined || this.props.cuentas !== false)
            this.getCuentas()

        if (this.props.beneficiarios === undefined || this.props.beneficiarios !== false)
            this.getBeneficiarios()

        if (this.props.clientes === undefined || this.props.clientes !== false)
            this.getClientes()


    }

    formFiltros = React.createRef()


     /**
     *
     *
     * @memberof FiltrosDrawer
     * @method clearForm
     * @description Limpia el formualario cuando se cambia de origen de datos (local o stp)
     */
    clearForm = () => {
        if(this.formFiltros.current){
            this.formFiltros.current.resetFields()
        }
    }

    getCuentas = ({ page, limit, search } = this.state.cuentas, { cuentas } = this.state) => {

        cuentas.loading = true;
        cuentas.page = page;
        cuentas.limit = limit;
        this.setState({ cuentas })

        axios.get('/cuentas', {
            params: {
                page,
                limit,
                search,
            }
        }).then((response) => {
            cuentas.data = (page === 1) ? response.data.data : [...cuentas.data, ...response.data.data];


            cuentas.dictionary = {}
            cuentas.data?.map(d => cuentas.dictionary[d.cuenta] = d)

            cuentas.total = response.total
            cuentas.pages = response.pages
            cuentas.loading = false;

            this.setState({ cuentas })

        }).catch(error => {
            console.log("ERR, error", error)
            message.error(error?.response?.data?.message ?? 'Error al obtener las cuentas')
        })
    }
    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.clientes]
     * @param {*} [{ clientes }=this.state]
     * @memberof FiltrosDrawer
     * @method getClientes
     * 
     * @description Para obtener los clientes en la lista
     */
    getClientes = ({ page, limit, search } = this.state.clientes, { clientes } = this.state) => {

        clientes.loading = true;
        clientes.page = page;
        clientes.limit = limit;

        this.setState({ clientes })

        axios.get('/clientes', {
            params: {
                page, limit, search
            }
        })
            .then(({ data }) => {
                clientes.data = (page === 1) ? data.data : [...clientes.data, ...data.data];

                clientes.dictionary = {}
                clientes.data.map(d => clientes.dictionary[d._id] = d)

                clientes.total = data.total
                clientes.pages = data.pages
                clientes.loading = false;

                this.setState({ clientes })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.clientes.loading = false
                    return state
                })
            })
    }

    /**
     * @const get
     * @description Obitiene las beneficiarios
     */
    getBeneficiarios = ({ page, limit, search } = this.state.beneficiarios, { beneficiarios } = this.state) => {

        beneficiarios.loading = true;
        beneficiarios.page = page;
        beneficiarios.limit = limit;
        this.setState({ beneficiarios })

        axios.get('/beneficiarios', {
            params: {
                page,
                limit,
                search,
                ...this.props.cliente_id ? { cliente_id: this.props.cliente_id } : {}
            },
        }).then((response) => {



            beneficiarios.data = (page === 1) ? response.data.data : [...beneficiarios.data, ...response.data.data];


            beneficiarios.dictionary = {}
            beneficiarios.data?.map(d => beneficiarios.dictionary[d.cuenta] = d)

            beneficiarios.total = response.total
            beneficiarios.pages = response.pages
            beneficiarios.loading = false;

            this.setState({ beneficiarios })

        }).catch(error => {
            console.log("ERR, error", error)
            message.error(error?.response?.data?.message ?? 'Error al obtener los beneficiarios')
        })
    }

    /**
 *
 *
 * @memberof FiltrosDrawer
 * @method submit
 * 
 * @description Permite obtener el arreglo para poder mostrarlo en la lista de guardados. 
 */
    submit = ({ cuentas, fechas, fecha, tipo_orden, beneficiarios, monto, clientes }) => {
        let filtros = []

        //Metodo para añadir al areglo de filtros.
        let addToFilter = (name, objectName, array) => array.map(element => filtros.push({
            _id: element,//clasificador,
            name: name,
            objectName,

        }))

        if (cuentas) filtros.push({ cuentas: cuentas })

        if (beneficiarios) filtros.push({ beneficiarios: beneficiarios })

        if (fechas)
            filtros.push({ fechas: fechas })

        if (fecha)
            filtros.push({ fecha: fecha })

        if (tipo_orden)
            filtros.push({ tipo_orden: tipo_orden })

        if (monto)
            filtros.push({ monto: monto })

        if (clientes) filtros.push({ clientes: clientes })

        if (this.props.updateFilters)
            this.props.updateFilters(filtros)
    }


    render() {

        const { onClose, visible } = this.props
        const { beneficiarios, cuentas, clientes } = this.state

        return (<Drawer
            title={this.props.title}
            placement="right"
            onClose={onClose}
            open={visible}
        >
            <Form
                ref={this.formFiltros}
                layout='vertical'
                onValuesChange={(x, values) => this.submit(values)}
                onFinish={this.submit}
                initialValues={{
                    ...this.props.initialValues
                }}
            >
                {/* RangePicker */}
                {(this.props.rango_fecha === undefined || this.props.rango_fecha !== false) ?
                    <Form.Item label="Rango de Fechas" name="fechas" >
                        <RangePicker placeholder={["Fecha Inicial", "Fecha Final"]} className="width-100" />
                    </Form.Item>
                    : null}

                {(this.props.fecha === undefined || this.props.fecha !== false) ?
                    <Form.Item label="Fecha de Operación" name="fecha" >
                        <DatePicker placeholder="Fecha de Operación" className="width-100" />
                    </Form.Item>
                    : null}
                {(this.props.tipo_operacion === undefined || this.props.tipo_operacion !== false) ?
                    <Form.Item label="Tipo de Orden" name="tipo_orden" >
                        <Select
                            className="width-100"
                            allowClear
                            filterOption={false}
                        >
                            <Option key='E' value='E'>Enviadas</Option>
                            <Option key='R' value='R'>Recibidas</Option>
                        </Select>
                    </Form.Item>
                    : null}

                {(this.props.cuentas === undefined || this.props.cuentas !== false) ?
                    <Form.Item label="Cuentas" name="cuentas">
                        <Select
                            className="width-100"
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}
                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !cuentas?.loading && cuentas.page < cuentas.pages) ? this.getCuentas({ page: cuentas.page + 1 }) : null}
                            onSearch={search => this.getCuentas({ search, page: 1 })}
                        >
                            {cuentas.data.map(cuenta => <Option key={cuenta.cuenta} value={cuenta.cuenta}>
                                {cuenta.nombre ?? ""} <small className='text-gray'>{cuenta.cuenta ?? ""} </small></Option>)}
                        </Select>
                    </Form.Item> : null}

                {(this.props.beneficiarios === undefined || this.props.beneficiarios !== false) ?
                    <Form.Item label="Beneficiarios" name="beneficiarios">
                        <Select
                            className="width-100"
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}
                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !beneficiarios?.loading && beneficiarios.page < beneficiarios.pages) ? this.getCuentas({ page: beneficiarios.page + 1 }) : null}
                            onSearch={search => this.getBeneficiarios({ search, page: 1 })}
                        >
                            {beneficiarios.data?.map(beneficiario => <Option key={beneficiario.cuenta} value={beneficiario.cuenta}>
                                {beneficiario.nombre ?? ""} <small className='text-gray'>{beneficiario.cuenta ?? ""} </small></Option>)}
                        </Select>
                    </Form.Item> : null}

                {(this.props.monto === undefined || this.props.monto !== false) ?
                    <Form.Item label="Monto" name="monto">
                        <InputNumber controls={false} className="w-100" />
                    </Form.Item> : null}

                {(this.props.clientes === undefined || this.props.clientes !== false) ?
                    <Form.Item
                        label="Clientes"
                        name="clientes"
                    >
                        <Select
                            className="width-100"
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}
                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !beneficiarios?.loading && beneficiarios.page < beneficiarios.pages) ? this.getCuentas({ page: beneficiarios.page + 1 }) : null}
                            onSearch={search => this.getClientes({ search, page: 1 })}
                        >

                            {
                                clientes?.data?.map(({ _id, nombre, apellido_paterno, apellido_materno, email }) => <Option value={_id} key={_id}>
                                    {nombre ?? ""} {apellido_paterno ?? ""} {apellido_materno ?? ""} / {email ?? ""}
                                </Option>)
                            }
                        </Select>
                    </Form.Item> : null}
            </Form>
        </Drawer >)
    }
};

export default React.forwardRef((props, ref) => {
    let cuenta = React.useContext(Cuenta)
    return <FiltrosDrawer {...props} cuenta={cuenta} ref={ref} />;
});