import React, { Component, createRef, useContext } from 'react'
import { Row, Col, Form, Input, Button, Card, Spin, Divider, Typography, Checkbox, Alert, Layout, Modal } from 'antd';
import { Navigate, useNavigate, useParams  } from 'react-router-dom';
import { UserOutlined, LockOutlined } from '@ant-design/icons';


//css
import '../../Styles/Modules/Auth/auth.scss';
import axios from 'axios';

const { Title, Text } = Typography

/**
 * 
 * @class UpdatePassword
 * @extends {Component}
 * @description Componente UpdatePassword page
 */
class UpdatePassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false
		}
	}

	formRef = createRef()

	async componentDidMount(){

        console.log("this.props", this.props);

        const { token, email } = this.props.params;
        if (token === undefined || email === undefined) {
            this.props.navigate('/')
        } else {
            this.formRef.current?.setFieldsValue({
				token, email
			})
        }
    }

	onFinish = async values => {
		this.setState({loading: true})
        axios.put('/password/recovery/update', values)
            .then(() => {
                this.props.navigate('/')
            })
            .catch((error) => {
                console.log(error.response)
                Modal.error({
                    title: "No fue posible actualizar.",
                    content: "El token es invalido o el correo no existe."
                })
            })
			.finally(() => this.setState({loading: false}))
    };

	render() {


		return(
			<Layout className="auth" style={{ backgroundImage: "url(/img/background/background-main.png)"}}>
				<Card className="card">
					<Row align="center">
						<Col span={24} className="center mb-1">
							<img src={"/img/BXNKLogo.png"} width="164"/>
						</Col>
						<Col span={24} className="text-center mb-2">
							<Title>Actualizar Contraseña</Title>
							<Text>Establece la nueva contraseña de tu cuenta</Text>
						</Col>
						<Col span={20}>
							<Form
								onFinish={this.onFinish}
								ref={this.formRef}
							>
								<Form.Item name="token" noStyle >
                                    <Input type="hidden" />
                                </Form.Item>
								<Form.Item
							      	name="email"
							      	rules={[{ required: true, message: 'Ingrese su correo electrónico' }]}
							    >
							      	<Input
							      		prefix={<UserOutlined />}
							      		placeholder="Correo Electrónico"
							      		size="large"
							      	/>
							    </Form.Item>

							    <Form.Item
							      name="password"
								  rules={[
									{
										required: true,
										message: 'Por favor ingresa tu nueva contraseña'
									},
									{
										min: 8,
										message: 'La contraseña debe de tener mínimo 8 caracteres'
									},
									{
										pattern: new RegExp("^(?=.*[0-9])(?=.*[a-z]|[A-Z]).+$"),
										message: 'Debe tener números y letras'
									},
									{
										pattern: new RegExp("^(?=.*[~`!@#$%^&*()\-_+={}[\]\|\/:;\"'<>,.?¿¡]).+$"),
										message: 'Debe de tener un caracter especial'
									},
								]}
								hasFeedback
							    >
							     	<Input.Password
							     		prefix={<LockOutlined />}
							     		placeholder="Contraseña"
							     		size="large"
							     	/>
							    </Form.Item>

								<Form.Item
							      name="confirm"
								  hasFeedback
								  dependencies={['password']}
							      rules={[
									{
										required: true, 
										message: 'Ingrese su nueva contraseña' 
									}, ({ getFieldValue }) => ({
										validator(rule, value) {
											if (!value || getFieldValue('password') === value) {
												return Promise.resolve();
											}
											return Promise.reject('Las contraseñas no coinciden.');
										}
									})
								]}
							    >
							     	<Input.Password
							     		prefix={<LockOutlined />}
							     		placeholder="Confirmar Contraseña"
							     		size="large"
							     	/>
							    </Form.Item>

							    <Form.Item >
							     	<Button type="primary" htmlType="submit" loading={this.state.loading} block size="large">
							        	Cambiar Contraseña
							      	</Button>
							    </Form.Item>
							</Form>
						</Col>
					</Row>
				</Card>
			</Layout>
		)
	}
}

export default function (props) {

	const navigate = useNavigate()
	const params = useParams()

	return <UpdatePassword {...props} navigate={navigate} params={params}/>
}