import React, { Component } from "react";
import { Row, Col, Modal, Typography, Form, Input, message, Spin, Button } from 'antd';
import axios from "axios"
import { SelectTipoCuenta } from "../../Widgets/Inputs/Selects";
import SelectInstituciones from "../../Widgets/Inputs/SelectInstituciones";
import { getResponseError } from "../../Utils";

const { Title } = Typography;
/**
 *
 *
 * @class FormBeneficiario
 * @extends {Component}
 */
class FormBeneficiario extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            beneficiario: {},
            cliente_id: this.props.cliente_id,
            cond: [],
            limite: 10
        }
    }

    modalRef = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if (this.props.beneficiario_id) {
            this.get()
        }
    }




    /**
     * @memberof FormBeneficiario
     * @method add
     * @description Añade un beneficiario a la BD
     */
    add = (values) => {
        axios.post('/beneficiario', {
            cliente_id: this.props.cliente_id ? this.props.cliente_id : this.state.cliente_id,
            ...values
        }).then(response => {
            message.success('Beneficiario creado')
            this.props.onClose(response.data)
            this.modalRef.current.resetFields()
        }).catch(error => {
            console.log(error)
            message.error(getResponseError(error.response, 'Error al crear beneficiario.'))
        }).finally(() => this.setState({ loading: false }))
    }


    /**
     * @memberof FormBeneficiario
     * @method get
     * @description Obtiene un beneficiario de la DB
     */
    get = () => {
        this.setState({ loading: true })
        axios.get(`/beneficiario/${this.props.beneficiario_id}`, {

        }).then(({ data }) => {
            this.modalRef.current.setFieldsValue({
                ...data
            })

        }).catch(error => {
            console.log(error)
            message.error(getResponseError(error.response, 'Error al obtener beneficiario.'))
        }).finally(() => this.setState({ loading: false }))
    }



    /**
     * @memberof FormBeneficiario
     * @method update
     * @description Actualiza la información de un beneficiario
     */
    update = (values) => {
        this.setState({ loading: true })
        axios.put('/beneficiario', {
            ...values,
            beneficiario_id: this.props.beneficiario_id,
        }).then(response => {
            message.success('Beneficiario Actualizado')
            this.props.onClose(response.data)
        }).catch(error => {
            message.error(getResponseError(error.response, 'Error al actualizar beneficiario.'))
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    /**
     * @memberof FormBeneficiario
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {
        this.setState({ loading: true })
        if (this.props.beneficiario_id) {
            this.update(values)
        } else {
            this.add(values)
        }
    }

    reqCuenta = (current) => {
        switch (current) {
            case '3':
                this.setState({limite: 16})
                break;
            case '10':
                this.setState({limite: 10})
                break;
            case '40':
                this.setState({limite: 18})
                break;
        }

    }

    render() {

        return (
            <Form
                layout="vertical"
                id="form-beneficiario"
                name="form-beneficiario"
                className="pl-1 pr-1"
                ref={this.modalRef}
                onFinish={this.onFinish}
            >
                <Spin spinning={this.state.loading}>
                    <Form.Item
                        name="nombre"
                        label={this.props.beneficiario_id ? "Nombre" : <>Obligatorio</>}
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese el nombre"
                        }]}
                    >
                        <Input
                            placeholder="Nombre"
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item
                        name="alias"
                        label={this.props.beneficiario_id ? "Alias" : <></>}
                    >
                        <Input
                            placeholder="Alias"
                            size="large"
                        />

                    </Form.Item>

                    <Form.Item
                        name="tipo"
                        label={this.props.beneficiario_id ? "Tipo" : <>Obligatorio</>}
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese el tipo de cuenta"
                        }]}
                        validateTrigger="onChange"
                    >
                        <SelectTipoCuenta
                            size="large"
                            onSelect={(e) => this.reqCuenta(e)}
                        />
                    </Form.Item>
                    <Form.Item
                        name="cuenta"
                        label={this.props.beneficiario_id ? "Cuenta" : <>Obligatorio</>}
                        rules={[
                            {
                                required: true,
                                message: "Por favor, ingrese la cuenta"
                            },{
                                min: this.state.limite,
                                message: `La cuenta debe tener ${this.state.limite} dígitos`
                            }
                            ,{
                                max: this.state.limite,
                                message: `La cuenta debe tener ${this.state.limite} dígitos`
                            },{
                                pattern: /^[0-9]+$/,
                                message: 'Cuenta invalida'
                            }
                        ]}
                        dependencies={['tipo']}
                    >
                        <Input
                            placeholder="Cuenta/Clabe/Tarjeta"
                            size="large"
                            max={18}
                        />

                    </Form.Item>
                    
                    {/*<Form.Item
                        name="rfc"
                        label={this.props.beneficiario_id ? "RFC" : <></>}
                        rules={[
                            {
                                pattern: /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
                                message: 'RFC invalido'
                            }
                        ]}
                    >
                        <Input
                            placeholder="RFC: XXXX000000X0"
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item
                        name="curp"
                        label={this.props.beneficiario_id ? "CURP" : <></>}
                        rules={
                            [
                                {
                                    pattern: /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
                                    message: 'Curp invalido'
                                }
                            ]
                        }
                    >
                        <Input
                            placeholder="CURP: XXXX000000XXXXXX0X"
                            size="large"
                        />
                    </Form.Item>*/}
                    
                    <Form.Item
                        name="institucion_id"
                        label={this.props.beneficiario_id ? "Institución" : <>Obligatorio</>}
                        rules={[{
                            required: true,
                            message: "Por favor, seleccione el tipo de Institución"
                        }]}

                    >
                        <SelectInstituciones
                            size="large"
                        />
                    </Form.Item>
                   
                    <Form.Item >
                        <Button htmlType="submit" type="primary" block size="large" className="mt-2">
                            Aceptar
                        </Button>
                    </Form.Item>
                </Spin>
            </Form>
        )
    }
}



function ModalBeneficiario(props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        destroyOnClose={true}
        zIndex={1000}
        footer={false}
        closable={false}
    >
        <Row>
            <Col span={24} className="center">
                <img src={"/img/BXNKLogo.png"} width="164" alt="isy-logo"/>
            </Col>
        </Row>
        <Title level={3} className="text-center mb-2 mt-1">{props.beneficiario_id ? "Editar Beneficiario" : "Crear Beneficiario"}</Title>
        <FormBeneficiario {...props} />
    </Modal>

}

export {
    ModalBeneficiario,
    FormBeneficiario
}

export default ModalBeneficiario