import React, { Component } from "react";
import { Row, Col, Modal, Typography, Form, Input, message, Spin,Image, Button, Select, DatePicker, Divider, Checkbox } from 'antd';

import { EmailInput } from "../../Widgets/Inputs/Inputs"
import { SelectCuenta, SelectEntidadFederativa, SelectPais, SelectActividadEconomica } from "../../Widgets/Inputs/Selects";
import { User } from '../../../Hooks/Logged';
import axios from "axios"
import moment from "moment";
import { getResponseError } from "../../Utils"
import { Link } from "react-router-dom";

const { Title } = Typography;
const { Option } = Select;

class ModalCliente extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            direccion_id: undefined,
            pais_clave: undefined,
            mexico_clave: 187,
            entidad: 'fisica',
        }
    }

    modalCliente = React.createRef();

    componentDidMount() {
        if (this.props.cliente_id) {
            this.get()
        }
    }

    /**
     * @memberof ModalCliente
     * @method get
     * @description Obtiene informacion del cliente
     */
    get = () => {

        this.setState({ loading: true })
        axios.get('/cliente/' + this.props.cliente_id, {})
            .then(response => {
                let cliente = response.data
                let direccion = cliente.direccion_id
                delete cliente.direccion_id
                this.setState({
                    direccion_id: direccion?._id,
                    entidad: (cliente.apellido_paterno) ? "fisica" : "moral",
                    pais_clave: cliente.pais?.clave
                })
                this.modalCliente.current.setFieldsValue({
                    ...cliente,
                    ...direccion,
                    actividad_economica: parseInt(cliente.actividad_economica),
                    fecha_nacimiento: moment(cliente.fecha_nacimiento),
                    cuenta_id: cliente.cuenta_id ? cliente.cuenta_id?.map(cuenta => ({
                        value: cuenta._id,
                        label: cuenta.nombre + " " + (cuenta.cuenta || "")
                    })) : undefined,
                    entidad_federativa_id: direccion?.entidad_federativa_id ? ({
                        value: direccion.entidad_federativa_id?._id,
                        label: direccion.entidad_federativa_id?.nombre ?? ""
                    }) : undefined,
                    pais: cliente.pais ? ({
                        value: cliente.pais?._id,
                        label: cliente.pais?.nombre ?? ""
                    }) : undefined,
                    pais_nacimiento: cliente.pais_nacimiento ? ({
                        value: cliente.pais_nacimiento?._id,
                        label: cliente.pais_nacimiento?.nombre ?? ""
                    }) : undefined,
                    entidad: (cliente.apellido_paterno) ? "fisica" : "moral"
                })

            }).catch(error => {
                console.log(error)
                message.error(getResponseError(error.response, "Error al obtener el cliente"))
            }).finally(() => {
                this.setState({ loading: false })
            })

    }

    /**
     * @memberof ModalCliente
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {
        values.direccion_id = this.state.direccion_id;
        if (Array.isArray(values.cuenta_id) && typeof values.cuenta_id[0] === 'object' && values.cuenta_id[0].hasOwnProperty('label')) {
            let array_cuentas = values.cuenta_id.map((c) => c.value);
            values.cuenta_id = array_cuentas
        }

        if (this.props.cliente_id) {
            this.update(values)
        } else {
            this.add(values)
        }

    }

    /**
     * @memberof ModalCliente
     * @method add
     * @description Crea un nuevo cliente en el sistema
     */
    add = (values) => {
        this.setState({ loading: true })

        axios.post('/cliente', values)
            .then(response => {
                message.success('Cliente Añadido')
                this.props.onClose(true)
            }).catch(error => {
                console.log('error', error)
                message.error(getResponseError(error.response, "Error al añadir el cliente."))
            }).finally(() => this.setState({ loading: false }))

    }

    /**
     * @memberof ModalCliente
     * @method update
     * @description Crea un nuevo cliente en el sistema
     */
    update = (values) => {
        this.setState({ loading: true })

        axios.put('/cliente', {
            cliente_id: this.props.cliente_id,
            ...values
        })
            .then(response => {
                message.success('Cliente Editado.')
                this.props.onClose(true)
            }).catch(error => {
                console.log('error', error)
                message.error(getResponseError(error.response, "Error al editar el cliente."))
            }).finally(() => this.setState({ loading: false }))

    }



    /**
     *
     *
     * @memberof ModalCliente
     * @description Verifica que el cliente sea mayor de edad 
     */
    validateEdad = (_, fecha) => {
        const minAge = moment().subtract(18, 'years');
        if (fecha && fecha.isAfter(minAge)) {
            return Promise.reject('Debe tener mas de 18 años');
        }
        return Promise.resolve();
    }

   
    render() {

        const { entidad } = this.state

         let tipo_cuenta_options = [
            { value: 0, label: 'Personal' },
            { value: 1, label: 'Cliente' },
            { value: 2, label: 'Vendedor' },
        ]

        if(this.props.user?.rol_id?.tipo !== 5)
            tipo_cuenta_options.push({value: 3, label: "Revendedor"})

        return (
            <Form
                layout="vertical"
                ref={this.modalCliente}
                onFinish={this.onFinish}
                onValuesChange={this.validateRFC_CURP}

                initialValues={{
                    entidad:this.state.entidad
                }}
            >
                <Spin spinning={this.state.loading}>

                    <Row justify="center">
                        <Col xs={22} lg={20} >
                            <Row gutter={[16, 16]} align="middle">
                                {
                                    !this.props.cliente_id && (
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                name="nueva_cuenta"
                                                valuePropName="checked"
                                                label={this.props.cliente_id ? "Nueva Cuenta" : <></>}
                                            >
                                                <Checkbox className="checkbox-text-resalt">¿Desea crear una cuenta? <br /> (Podrá verla en Usuarios)</Checkbox>
                                            </Form.Item>
                                        </Col>
                                    )
                                }
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="nombre"
                                        requiredMark={true}
                                        label={this.props.cliente_id ? "Nombre" : <>Obligatorio</>}

                                        rules={[{
                                            required: true,
                                            message: "Por favor, Ingrese el nombre."
                                        }]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder="Nombre Completo"
                                        />
                                    </Form.Item>
                                </Col>
                                
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="apellido_paterno"
                                        requiredMark={true}
                                        label={this.props.cliente_id ? "Apellido Paterno" : <>Obligatorio</>}

                                        rules={[{
                                            required: true,
                                            message: "Por favor, Ingrese el apellido paterno."
                                        }]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder="Apellido Paterno"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="apellido_materno"
                                        requiredMark={true}
                                        label={this.props.cliente_id ? "Apellido Materno" : <>Obligatorio</>}

                                        rules={[{
                                            required: true,
                                            message: "Por favor, Ingrese el apellido materno."
                                        }]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder="Apellido Materno"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="alias_local"
                                        label="Alias"
                                    >
                                        <Input
                                            size="large"
                                            placeholder="Alias"
                                        />
                                    </Form.Item>
                                </Col>
                                        
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="email"
                                        requiredMark={true}
                                        label={this.props.cliente_id ? "Email" : <>Obligatorio</>}

                                        rules={[{
                                            required: true,
                                            message: "Por favor, Ingrese el email."
                                        }]}
                                    >
                                        <EmailInput
                                            size="large"
                                            placeholder="Email"
                                        />
                                    </Form.Item>
                                </Col>
                                
                                
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        label={this.props.cliente_id ? "Fecha Nacimiento" : <>Obligatorio</>}
                                        name="fecha_nacimiento"

                                        rules={[
                                            {
                                                required: true,
                                                message: "Por favor, seleccione ingrese la fecha de nacimiento."
                                            },
                                            {
                                                validator: this.validateEdad
                                            }
                                        ]}

                                    >
                                        <DatePicker
                                            showToday={false}
                                            placeholder="Selecciona Fecha Nacimiento"
                                            className="w-100"
                                            size="large"
                                        />
                                    </Form.Item>
                                </Col>
                                        
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="pais_nacimiento"
                                        requiredMark={true}
                                        label={this.props.cliente_id ? "País de Nacimiento" : <>Obligatorio</>}

                                        rules={[{
                                            required: true,
                                            message: "Por favor, selecciona el país."
                                        }]}
                                    >
                                        <SelectPais
                                            size="large"
                                            placeholder="País de nacimiento"
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="pais"
                                        requiredMark={true}
                                        label={this.props.cliente_id ? "País de Residencia" : <>Obligatorio</>}
                                        rules={[{
                                            required: true,
                                            message: "Por favor, selecciona el país."
                                        }]}
                                    >
                                        <SelectPais
                                            size="large"
                                            placeholder="País donde reside"
                                            onClaveSelect={pais_clave => this.setState({ pais_clave })}
                                        />
                                    </Form.Item>
                                </Col>
                                
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="tipo_cliente"
                                        label={this.props.cliente_id ? "Tipo de Cliente" : <>Obligatorio</>}
                                        requiredMark={true}
                                        rules={[{
                                            required: true,
                                            message: "Por favor, selecciona el país."
                                        }]}
                                    >
                                        <Select 
                                            placeholder="Tipo de Cliente"
                                            size="large"
                                            options={tipo_cuenta_options}
                                        >
                                            
                                        </Select>
                                    </Form.Item>
                                </Col>
                                
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="actividad_economica"
                                        label={this.props.cliente_id ? "Actividad Ecónomica" : <></>}


                                    >
                                        <SelectActividadEconomica
                                            size="large"
                                            placeholder="Actividad Ecónomica."
                                        />
                                    </Form.Item>
                                </Col>
                                        
                                <Col xs={24}>
                                    <Form.Item
                                        name="cuenta_id"
                                        label={this.props.cliente_id ? "Cuentas" : <></>}

                                    >
                                        <SelectCuenta
                                            size="large"
                                            mode="multiple"
                                            placeholder="Seleccionar Cuentas"
                                            params={{
                                                sin_cliente: true,
                                                nin_cliente_id: this.props.cliente_id
                                            }}
                                        />
                                    </Form.Item>
                                </Col>

                                {/*<Col xs={24}>
                                    <Divider> Dirección </Divider>
                                </Col>

                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="calle"
                                        label={this.props.cliente_id ? "Calle" : <></>}


                                    >
                                        <Input
                                            size="large"
                                            placeholder="Calle"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="num_exterior"
                                        label={this.props.cliente_id ? "Número Exterior" : <></>}
                                    >
                                        <Input
                                            size="large"
                                            placeholder="Número Exterior"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="colonia"
                                        label={this.props.cliente_id ? "Colonia" : <></>}
                                    >
                                        <Input
                                            size="large"
                                            placeholder="Colonia"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="codigo_postal"
                                        label={this.props.cliente_id ? "Código Postal" : <></>}
                                    >
                                        <Input
                                            size="large"
                                            placeholder="Código Postal"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="alcaldia_municipio"
                                        label={this.props.cliente_id ? "Alcaldia / Municipio" : <></>}


                                    >
                                        <Input
                                            size="large"
                                            placeholder="Alcaldia / Municipio"
                                        />
                                    </Form.Item>
                                </Col>

                                {
                                    (this.state.pais_clave && this.state.pais_clave !== this.state.mexico_clave) ? (
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                name="entidad_federativa"
                                                requiredMark={true}
                                                label={this.props.cliente_id ? "Entidad Federativa" : <></>}
                                                // rules={[{
                                                //     required: true,
                                                //     message: "Por favor, ingrese la entidad federativa."
                                                // }]}
                                            >
                                                <Input
                                                    size="large"
                                                    placeholder="Entidad Federativa"
                                                />
                                            </Form.Item>
                                        </Col>
                                    ) : (
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                name="entidad_federativa_id"
                                                requiredMark={true}
                                                label={this.props.cliente_id ? "Entidad Federativa" : <>Obligatorio</>}
                                                rules={[{
                                                    required: true,
                                                    message: "Por favor, selecciona la entidad federativa."
                                                }]}
                                            >
                                                <SelectEntidadFederativa
                                                    size="large"
                                                />
                                            </Form.Item>
                                        </Col>
                                    )
                                }*/}

                                <Col xs={24}>
                                    <Form.Item >
                                        <Button htmlType="submit" type="primary" block size="large" className="mt-2">
                                            Aceptar
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function Modals(props) {

    let user = React.useContext(User)

    const { visible = false, onClose = () => { }, cliente_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        destroyOnClose={true}
        width={750}
        zIndex={1000}
        footer={false}
        closable={false}
    >
        <Row>
            <Col span={24} className="center">
                <img src={"/img/BXNKLogo.png"} width="164" />
            </Col>
        </Row>
        <Title level={3} className="text-center mb-2 mt-1">{cliente_id ? "Editar" : "Crear"} Cliente</Title>
        <ModalCliente {...props} user={user} />
    </Modal>

}