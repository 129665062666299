import React from 'react'
import { Layout, Col, Row, Typography, List, Card, message, Space, Button, Popconfirm } from "antd";
import { useSearchParams, useNavigate } from 'react-router-dom'
import axios from 'axios';

import { User } from '../../../Hooks/Logged';
import { TipoCuentaText } from '../../Utils';
import usePermissions from '../../../Hooks/usePermissions';
import ModalBeneficiario from "./ModalBeneficiario";
import FloatingButton from '../../Widgets/FloatingButton/FloatingButton';
import { DeleteOutlined } from '@ant-design/icons';
import useSearch from '../../../Hooks/Search';
import { getResponseError } from '../../Utils'
const { Content } = Layout;
const { Title, Text } = Typography

class Beneficiarios extends React.Component {

    constructor(props) {
        super(props)
        let { page, limit, search } = this.props.params
        this.state = {
            loading: false,
            modal_visible: false,
            searching: true,
            filtroSearch: '',
            beneficiarios: {
                page: page ?? 1,
                limit: limit ?? 10,
                total: 0,
                search: search ?? undefined,
                data: [],
            }
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.get()
    }

    /**
     * @method componentDidUpdate
     * @description Actualiza la vista si se buscan datos
     */
    componentDidUpdate() {

        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;
        if (filtro !== propFiltro && this.state.searching === true) {
            this.get();
            this.setState({searching: false});
        }
    }
    get = ({
        page = this.state.beneficiarios.page,
        limit = this.state.beneficiarios.limit,
        search = this.props.search
    } = this.state.beneficiarios) => {
        this.setState({ loading: true })
        axios.get('/beneficiarios', {
            params: {
                page,
                limit,
                search: search
            }
        }).then(({ data }) => {
            this.setState({
                beneficiarios: {
                    ...data,
                },
                loading: false,
                searching: true,
                filtroSearch: search
            });
        }).catch(error => {
            message.error(getResponseError(error.response, "Error al cargar los beneficiarios"))
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render() {

        return (
            <>
                <Content className="pt-1 ">
                    <Row className='main pl-1 pr-1'>
                        <Col xs={24}>
                            <Title level={5}> Beneficiarios </Title>
                        </Col>
                        <Col xs={24}>
                            <List
                                loading={this.state.loading}
                                className="component-list"
                                itemLayout="horizontal"
                                locale={{ emptyText: "Sin Beneficiarios" }}
                                dataSource={this.state.beneficiarios?.data}
                                pagination={{
                                    current: this.state.beneficiarios.page,
                                    pageSize: this.state.beneficiarios.limit,
                                    total: this.state.beneficiarios.total,
                                    position: 'bottom',
                                    className: "flex-left",
                                    showSizeChanger: true,
                                    pageSizeOptions: [20, 50, 100, 500],
                                    showQuickJumper: true,
                                    locale: { jump_to: "Ir a la página", page: '' },
                                    onChange: (page, limit) => {
                                        this.get({ page, limit })
                                    },
                                    onShowSizeChange: (page, limit) => {
                                        this.get({ page, limit })
                                    }
                                }}
                                header={<Row className="header-list width-100 ">
                                    <Col xs={0} sm={5} md={5} className=" center">
                                        <Text>Nombre</Text>
                                    </Col>
                                    <Col xs={0} sm={4} md={4} className="center">
                                        <Text>Cuenta</Text>
                                    </Col>
                                    <Col xs={0} sm={4} md={4} className="center">
                                        <Text>Alias</Text>
                                    </Col>
                                    <Col xs={0} sm={4} md={4} className="center">
                                        <Text>Tipo</Text>
                                    </Col>
                                    <Col xs={0} sm={5} md={5} className="center">
                                        <Text>Institución</Text>
                                    </Col>
                                    <Col xs={0} sm={2} md={2}></Col>
                                </Row>
                                }

                                renderItem={item => (
                                    <List.Item className="component-list-item">
                                        <Card className="card-list width-100 pointer-hand" bordered={false} >
                                            <Row className="width-100" align={"middle"}>
                                                <Col xs={24} sm={12} md={5} className="center">
                                                    <Text ellipsis className="text-gray">{item.nombre}</Text>
                                                </Col>
                                                <Col xs={24} sm={12} md={4} className="center">
                                                    <Text ellipsis className="text-gray">{item.cuenta}</Text>
                                                </Col>
                                                <Col xs={24} sm={12} md={4} className="center">
                                                    <Text ellipsis className="text-gray">{item.alias}</Text>
                                                </Col>
                                                <Col xs={24} sm={12} md={4} className="center">
                                                    <Text ellipsis className="text-gray">{TipoCuentaText(item.tipo)}</Text>
                                                </Col>
                                                <Col xs={24} sm={12} md={5} className="center">
                                                    <Text ellipsis className="text-gray">{item.institucion_id?.nombre}</Text>
                                                </Col>
                                                <Col xs={24} sm={12} md={2} className="flex-right">
                                                    <Space>
                                                        <Popconfirm
                                                            placement="topRight"

                                                            title="¿Deseas eliminar este beneficiario?"
                                                            onConfirm={() => axios.delete('/beneficiario', {
                                                                params: {
                                                                    beneficiario_id: item._id
                                                                }
                                                            }).then((response) => {
                                                                message.success('Eliminado')
                                                                this.get()
                                                            })
                                                                .catch((error) => {
                                                                    message.error(getResponseError(error.response, 'No se pudo eliminar'))
                                                                })
                                                            }
                                                            okText="Sí"
                                                            cancelText="No"
                                                            disabled={!this.props.deleteBeneficiarios}
                                                        >
                                                            <Button
                                                                type="danger"
                                                                icon={<DeleteOutlined />}
                                                                disabled={!this.props.deleteBeneficiarios}
                                                            />
                                                        </Popconfirm>

                                                    </Space></Col>

                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />
                        </Col>
                    </Row>
                </Content>
                <FloatingButton onClick={() => this.setState({ modal_visible: true })} disabled={!this.props.createBeneficiarios} />

                <ModalBeneficiario
                    beneficiario_id={this.state.beneficiario_id}
                    visible={this.state.modal_visible}
                    cliente_id={null}
                    user_rol={this.props.user_rol}
                    onClose={
                        () => {
                            this.setState({ modal_visible: false, beneficiario_id: null })
                            this.get(this.state.beneficiarios.page)
                        }
                    }
                />

            </>
        )
    }
}

export default function Componente (props)  {
    //let user = React.useContext(Logged)
    const [params, setParams] = useSearchParams();
    const [search] = useSearch()

    let user = React.useContext(User)

    let permissions = usePermissions(user?.rol_id?.permisos, {
        editBeneficiarios: ["beneficiarios", "edit"],
        createBeneficiarios: ["beneficiarios", "create"],
        deleteBeneficiarios: ["beneficiarios", "delete"]
    })

    return (<Beneficiarios {...props}
        navigate={useNavigate()}
        search={search}
        user_rol={user?.rol_id?.tipo}
        setParams={setParams} 
        params={params} 
        {...permissions} />)
}