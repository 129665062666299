import React, { useEffect, useState } from 'react'
import { Layout, Col, Row, Typography, List, Space, Button, Collapse, Divider, InputNumber, Input, Select, Form, Statistic, Checkbox, Tooltip } from "antd"
import { useSearchParams, useNavigate } from 'react-router-dom'

import { DeleteOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import SelectUsuario from '../../Widgets/Inputs/SelectUsuario'
import '../../../Styles/Modules/BloqueVendedores/BloqueVendedores.scss'

import { PlusOutlined } from "@ant-design/icons";

import Decimal from 'decimal.js'

import ViewButton from '../../Widgets/ButtonView'

const { Content } = Layout
const { Title, Text } = Typography
const { Panel } = Collapse




export default function BloqueVendedor({ formListName = "bloques_vendedores", cuenta_id }) {

    return <Form.List name={formListName} style={{ paddingTop: '1em'}}>
        {(fields, { add, remove }) => (
            <Space direction="vertical" style={{ width: "100%" }}>
                {fields.map(({ key, name, ...restField }) => (
                    <Collapse style={{ width: "100%" }} >
                        <Form.Item noStyle {...restField} name={[name, '_id']}>
                            <input type='hidden' />
                        </Form.Item>
                        <Panel header={
                            <Row style={{ position: "relative", left: -5 }} className="width-100" align={"middle"} gutter={[16, 16]}>
                                <Col xs={14}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'nombre']}
                                        style={{ margin: 0 }}
                                        rules={[{ required: true, message: 'Debe Ingresar el Nombre del Bloque' }]}
                                    >
                                        <Input onClick={e => e.stopPropagation()} placeholder='Nombre del Bloque' bordered={false} />
                                    </Form.Item>
                                </Col>
                                <Col style={{ flex: 2 }} className="center">
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'porcentaje_comision']}
                                        rules={[{ required: true, message: 'Debe ingresar el porcentaje del bloque' }]}
                                        style={{ margin: 0 }}
                                    >
                                        <InputNumber onClick={e => e.stopPropagation()} placeholder='Porcentaje' bordered={true} style={{ width: '100%', textAlign: "center" }} />
                                    </Form.Item>
                                </Col>
                                <Col style={{ flex: 1 }} className="center">
                                </Col>
                                <Button type='link' icon={<DeleteOutlined />}
                                    onClick={e => {
                                        e.stopPropagation()
                                        remove(name)
                                    }}
                                />
                            </Row>
                        }>
                            <Form.List
                                {...restField}
                                name={[name, 'vendedores']}
                            >
                                {(fields, { add, remove }) => (

                                    <List
                                        // loading={this.state.loading}
                                        className="component-list"
                                        itemLayout="horizontal"
                                        locale={{ emptyText: "Sin Beneficiarios" }}
                                        dataSource={fields}
                                        header={<>
                                            <Row className="header-list width-100 ">
                                                <Col xs={14}>
                                                    <Text strong>Vendedor</Text>
                                                </Col>
                                                <Col style={{ flex: 2 }} className="center">
                                                    <Text strong>Porcentaje</Text>
                                                </Col>
                                                <Col style={{ flex: 1 }} className="center">
                                                    <Text strong>Porcentaje Total</Text>
                                                </Col>
                                                <div style={{ width: 32, height: 10 }} />
                                            </Row>
                                            <Divider style={{ margin: '6px 0 14px 0' }} />
                                        </>}
                                        renderItem={({ key, ...restField }, index) => {

                                            let childName = restField.name
                                            return (
                                                <>
                                                    {(index !== 0) && <Divider style={{ margin: '6px 0 6px 0' }} />}
                                                    <List.Item className="component-list-item">
                                                        {/* <Card className="card-list width-100 pointer-hand" bordered={false} > */}
                                                        <Row className="width-100" align={"middle"} gutter={[16, 16]}>

                                                            <Col xs={14}>
                                                                <Row style={{ gap: 15 }}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[childName, 'ver_cuenta']}
                                                                        // rules={[{ required: true, message: 'Debe de seleccionar el Vendedor' }]}
                                                                        noStyle
                                                                    >
                                                                        <ViewButton />
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[childName, 'vendedor_id']}
                                                                        rules={[{ required: true, message: 'Debe de seleccionar el Vendedor' }]}
                                                                        style={{ margin: 0, flex: 1 }}
                                                                    >
                                                                        <SelectUsuario
                                                                            params={{
                                                                                tipo_rol: 4,
                                                                                cuenta_id,
                                                                                con_cuenta: true,
                                                                                revendedor_padre: true
                                                                            }}
                                                                        />
                                                                    </Form.Item>

                                                                </Row>
                                                            </Col>
                                                            <Col style={{ flex: 2 }} className="center">
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[childName, 'porcentaje_comision']}
                                                                    rules={[{ required: true, message: 'Debe ingresar el porcentaje de comisión' }]}
                                                                    style={{ margin: 0 }}
                                                                >
                                                                    <InputNumber
                                                                        min={0}
                                                                        max={100}
                                                                        formatter={(value) => `${value}%`}
                                                                        parser={(value) => value?.replace('%', '')}
                                                                        style={{ width: "100%", textAlign: "center" }} />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col style={{ flex: 1 }} className="center">
                                                                <Form.Item shouldUpdate noStyle>
                                                                    {({ getFieldValue }) => {
                                                                        let bloque = getFieldValue(formListName)
                                                                        let porcentaje_vendededor = bloque[name]?.vendedores[childName]?.porcentaje_comision || 0
                                                                        let porcentaje_comision = bloque[name]?.porcentaje_comision
                                                                        let comision_bloque = Decimal(porcentaje_comision || 0).mul(porcentaje_vendededor || 0).div(100).toDecimalPlaces(2).toNumber()
                                                                        return <Statistic
                                                                            value={comision_bloque}
                                                                            suffix={"%"}
                                                                            valueStyle={{
                                                                                fontWeight: 'bold',
                                                                                fontSize: 14,
                                                                                marginRigth: "5px",
                                                                            }}
                                                                        />
                                                                    }}
                                                                </Form.Item>

                                                            </Col>
                                                            <Button type='link' icon={<DeleteOutlined />} onClick={() => remove(childName)}></Button>
                                                        </Row>
                                                    </List.Item>
                                                </>
                                            )
                                        }}
                                        footer={<>
                                            <Divider style={{ margin: '6px 0 14px 0' }} />
                                            <Row className="header-list width-100 ">
                                                <Col xs={14}>
                                                    <Text style={{ position: "relative", left: 12 }} strong>Totales</Text>
                                                </Col>
                                                <Col style={{ flex: 2 }} className="center">
                                                    <Form.Item shouldUpdate>
                                                        {({ getFieldValue }) => {
                                                            let bloque = getFieldValue(formListName)
                                                            let total = bloque[name]?.vendedores?.reduce((accumulator, currentValue) => Decimal(accumulator).add(Decimal(currentValue?.porcentaje_comision || 0)), Decimal(0)).toDecimalPlaces(2).toNumber()
                                                            let isValid = (total < 100 || total > 100)
                                                            return <>
                                                                <Statistic
                                                                    value={total}
                                                                    precision={2}
                                                                    suffix={"%"}
                                                                    valueStyle={{
                                                                        color: isValid ? "#F50" : undefined
                                                                    }}
                                                                />
                                                                {isValid && <Statistic
                                                                    value={total < 100 ? 100 - total : total - 100}
                                                                    precision={2}
                                                                    prefix={(total < 100) ? "-" : "+"}
                                                                    suffix={"%"}
                                                                    valueStyle={{
                                                                        fontWeight: 'bold',
                                                                        fontSize: 14,
                                                                        marginRigth: "5px",
                                                                        color: "#F50"
                                                                    }}
                                                                />}
                                                            </>
                                                        }}
                                                    </Form.Item>
                                                </Col>
                                                <Col style={{ flex: 1, justifyContent: "end", textAlign: "end" }}  >
                                                    <Button type='dashed' onClick={() => add()}>Agregar</Button>
                                                    {/* <Text style={{position: "relative", right: 15}} strong>100%</Text> */}
                                                </Col>
                                                <div style={{ width: 32, height: 10 }} />
                                            </Row>
                                        </>}

                                    />
                                )}
                            </Form.List>
                        </Panel>
                    </Collapse>
                ))}

                <>
                    <Divider style={{ margin: '14px 0 6px 0' }} />
                    <Row className="header-list width-100 mb-2" >
                        <Col xs={14}>
                            {/* <Text strong>Vendedor</Text> */}
                        </Col>
                        <Col style={{ flex: 2 }} className='center'>
                            <Text strong style={{ position: "relative", right: 15 }}>

                                <Form.Item shouldUpdate>
                                    {({ getFieldValue }) => {
                                        let bloque = getFieldValue(formListName)
                                        let total = bloque?.reduce((accumulator, currentValue) => Decimal(accumulator).add(Decimal(currentValue?.porcentaje_comision || 0)), Decimal(0)).toDecimalPlaces(2).toNumber() || 0
                                        let isValid = (total < 100 || total > 100)
                                        return <>
                                            <Statistic
                                                value={total}
                                                precision={2}
                                                suffix={"%"}
                                                valueStyle={{
                                                    color: isValid ? "#F50" : undefined
                                                }}
                                            />
                                            {isValid && <Statistic
                                                value={total < 100 ? 100 - total : total - 100}
                                                precision={2}
                                                prefix={(total < 100) ? "-" : "+"}
                                                suffix={"%"}
                                                valueStyle={{
                                                    fontWeight: 'bold',
                                                    fontSize: 14,
                                                    marginRigth: "5px",
                                                    color: "#F50"
                                                }}
                                            />}
                                        </>
                                    }}
                                </Form.Item>

                            </Text>
                        </Col>
                        <Col style={{ flex: 1 }} className="center">
                            {/* <Text strong>Porcentaje Total</Text> */}
                        </Col>
                        <div style={{ width: 32, height: 10 }} />
                    </Row>
                </>
                <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Agregar Bloque
                    </Button>
                </Form.Item>
            </Space>
        )}
    </Form.List>

}