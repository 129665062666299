import React, { Component } from "react";
import { Row, Col, Drawer, Form, Tabs, Input, Switch, message, Spin, Button, Modal } from 'antd';
import axios from "axios"
// import ReglaList from "../Cuentas/ReglasList";
import BloqueVendedor from "../BloqueVendedores/BloqueVendedor";
import { getResponseError } from "../../Utils";

class FormReglaTemplates extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            check_activo: true,
            tab: "2",
            errores: [],
        }
    }

    modalRef = React.createRef();

    componentDidMount() {

        console.log('this.props.', this.props);
        if (this.props.bloque_vendedor_id) {
            this.get()
        }
    }

    /**
     * @memberof FormReglaTemplates
     * @method get
     * @description Obtiene informacion de la regla
     */
    get = () => {

        this.setState({ loading: true })
        axios.get('/bloques-vendedores/' + this.props.bloque_vendedor_id, {
            params: {
                bloque_vendedor_id: this.props.bloque_vendedor_id,
            }
        })
            .then(({ data }) => {
                this.setState({ tipo: data.tipo, check_activo: data.activo })
                this.modalRef.current.setFieldsValue({
                    ...data,
                    bloques_vendedores: data.bloques_vendedores.map(({ vendedores, ...e }) => ({
                        ...e,
                        vendedores: vendedores.map(({ vendedor_id, ...args  }) => ({
                            vendedor_id: {
                                key: vendedor_id?._id,
                                value: vendedor_id?._id,
                                label: vendedor_id?.nombre_completo
                            },
                            ...args
                        }))
                    }))
                })
            }).catch(error => {
                console.log("error", error)
                message.error(error?.response?.data ?? "Error al obtener la regla")
            }).finally(() => {
                this.setState({ loading: false })
            })

    }


    /**
     * @memberof FormReglaTemplates
     * @method submit
     * @description Se ejecuta al dar enter al formulario
     */
    submit = (values) => {

        values.bloques_vendedores = values.bloques_vendedores.map(({ vendedores, ...e }) => ({
            ...e,
            vendedores: vendedores.map(({ vendedor_id, ...args  }) => ({
                vendedor_id: vendedor_id?.value || vendedor_id,
                ...args
            }))
        }))

        if (this.props.bloque_vendedor_id)
            this.update(values)
        else
            this.add(values)

    }

    /**
     * @memberof FormReglaTemplates
     * @method add
     * @description Crea una nueva regla en el sistema
     */
    add = (values) => {
        this.setState({ loading: true })

        axios.post('/bloques-vendedores', values)
            .then(({ data }) => {
                message.success('Template añadido.')
                this.props.onClose(data)
            }).catch(error => {
                message.error(getResponseError(error.response, "Error al añadir el template."))
            }).finally(() => this.setState({ loading: false }))

    }

    /**
     * @memberof FormReglaTemplates
     * @method update
     * @description Actualiza una cuenta en el sistema
     */
    update = (values) => {
        this.setState({ loading: true })

        axios.put('/bloques-vendedores', {
            ...values,
            bloque_vendedor_id: this.props.bloque_vendedor_id,
        })
            .then(response => {
                message.success('Template editado.')
                this.props.onClose(true)
            }).catch(error => {
                console.log(error)
                message.error(getResponseError(error.response, "Error al editar el template."))
            }).finally(() => this.setState({ loading: false }))

    }

    render() {

        return (
            <Form
                layout="vertical"
                ref={this.modalRef}
                onFinish={this.submit}

            >
                <Spin spinning={this.state.loading}>
                    <Row className="mt-1">
                        <Form.Item
                            name="nombre"
                            label="Nombre del Template"
                            style={{ width: "100%" }}
                        >
                            <Input />
                        </Form.Item>
                    </Row>
                    <Row align="center">
                        <BloqueVendedor formListName="bloques_vendedores" />
                    </Row>
                    <Row className="mt-1">
                        <Form.Item
                            name="activo"
                            label="¿Template Activo?"
                            valuePropName="checked"
                        >
                            <Switch checked={this.state.check_activo} onChange={(e) => this.setState({ check_activo: e })} />
                        </Form.Item>
                    </Row>

                    <Form.Item >
                        <Button htmlType="submit" type="primary" block size="large" className="mt-2" disabled={!(this.state.errores.length == 0)}>
                            Guardar
                        </Button>
                    </Form.Item>
                </Spin>
            </Form>
        )
    }
}

export default function (props) {

    const { visible, onClose, template_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        onClose={onClose}
        title="Template de la Regla"
        footer={null}
        destroyOnClose={true}
        zIndex={1000}
        width={600}
    >
        <FormReglaTemplates {...props} onClose={onClose} />
    </Modal>


}

