import React from 'react'
import { Route, Routes } from "react-router-dom";

//componentes
import Vendedores from '../../Components/Admin/Vendedores/Vendedores';

/**
 * 
 * @export
 * @function RouterVendedores
 * @description Router de Vendedores
 */
function RouterVendedores(props) {
  return (
    <Routes>
      <Route path='/' element={<Vendedores {...props}/>}/>
    </Routes>
  )
}

export default RouterVendedores