import React from 'react'
import { Layout, Col, Row, Typography, Button, Card, Space, message, Spin, List, Statistic, Tooltip, Select} from "antd";
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ReactEcharts from "echarts-for-react";
import { EyeOutlined, FilePdfOutlined, CheckOutlined, CheckCircleOutlined, WarningOutlined, CloseCircleOutlined } from '@ant-design/icons';

//componentes
import { IconMoney } from '../../../Widgets/Icons';
import CustomAvatar from '../../../Widgets/Avatar';
import { CardResumenFinanciero, CardTransaccionReciente, CardCuentaInfo } from '../../../Widgets/Cards'

//Modales
import ModalCobranza from "../ModalCobranza";


const { Text, Title } = Typography;
const { Content } = Layout;
const { Option } = Select;

class Dashboard extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			clientes: {
				data: [],
				page: 1,
				limit: 10,
				total: 0,
				fecha: undefined,
			},
			total_cobrado: 0,
			spei: 0,
			uso_cuenta: 0,
			cuenta_SPEI : "",
			cuenta_uso_cuenta :"",


			cuenta: {
				balance: 0
			},
			ingresos: 0,
			egresos: 0,
			datos: [],
			fechas: [],
			cuentas: {
				data: [],
				loading: false,
				page: 1,
				limit: 10,
			},
			loading_grafica: false,
			loading_transacciones: false,
		}
	}

	componentDidMount() {

		this.getClientes()

	}

	/**
	* @method getClientes
	* @description Obtiene las cuentas de comisiones de SPEI y de uso de cuenta
	*/
	getClientes = ({
		page = this.state.clientes.page,
		limit = this.state.clientes.limit,
		fecha = this.state.clientes.fecha,
	} = this.state.clientes) => {
		this.setState({loading: true})
		axios.get('/cobranza/clientes',{
			params:{
				page,
				limit,
				fecha,
			}
		})
		.then(({data}) => {
			
			this.setState({
				clientes: {...data, fecha}
			})
		})
		.catch(error => {
			console.log("error", error);
			message.error(error.response?.data?.message ?? "Error al obtener informacion de las cuentas")

		}).finally(()=>this.setState({loading: false}))
	}	

	renderOptions = () => {

		let options = []
		const fecha_inicial = moment('2023-07-01');
	    let fecha_actual = moment();

	    let fechaActualTemp = fecha_inicial.clone();
	    while (fechaActualTemp.isSameOrBefore(fecha_actual, 'month')) {
	        options.unshift(<Option value={fechaActualTemp.format("MM-YYYY")}> {fechaActualTemp.format("MMMM YYYY").toUpperCase()} </Option>);
	        fechaActualTemp.add(1, 'month');
	    }

	    return options
	}

	render() {

		let { cuenta, ingresos, egresos } = this.state;

		return (
			<>

				<Row>
					<Col xs={24} md={12}>
						<Title level={4}>
							Cobros por Cliente
						</Title>
					</Col>
					<Col xs={24} md={12} className="flex-right">
						<Select
		                    style={{minWidth: "200px"}}
		                    placeholder={"Mes"}
		                    size="small"
		                    onSelect={(fecha) => this.getClientes({fecha, page:1})}
		                    onClear={() => { 
		                    	this.getClientes({fecha: null, page:1})}}
		                    allowClear={true}
		                >
		                    {this.renderOptions()}
		                </Select>
					</Col>
					<Col xs={24}>
						<List
							loading={this.state.loading}
							className="component-list"
							itemLayout="horizontal"
							locale={{ emptyText: "Sin Clientes" }}
							dataSource={this.state.clientes?.data}
							pagination={{
								current: this.state.clientes.page,
								pageSize: this.state.clientes.limit,
								total: this.state.clientes.total,
								position: 'bottom',
								className: "flex-left",
								showSizeChanger: true,
								pageSizeOptions: [10,20, 50, 100, 500],
								showQuickJumper: true,
								locale: { jump_to: "Ir a la página", page: '' },
								onChange: (page, limit) => {
									this.getClientes({ page, limit })
								},
								onShowSizeChange: (page, limit) => {
									this.getClientes({ page: 1, limit })
								}
							}}
							header={<Row className="header-list width-100 ">
								<Col xs={0} sm={0} md={5} className=" center">
									<Text>Clientes</Text>
								</Col>
								<Col xs={0} sm={0} md={4} className="center">
									<Text>Transacciones con comisión</Text>
								</Col>
								<Col xs={0} sm={0} md={5} className="center">
									<Text>Comisiones Totales</Text>
								</Col>
								<Col xs={0} sm={0} md={3} className="center">
									<Text># Cuentas</Text>
								</Col>
								<Col xs={0} sm={0} md={3} className="center">
									<Text>Cobros SPEI</Text>
								</Col>
								<Col xs={0} sm={0} md={3} className="center">
									<Text>Cobros Uso Cuenta</Text>
								</Col>
								
							</Row>
							}

							renderItem={item => (

								<List.Item className="component-list-item">
									<Card className="card-list width-100" bordered={false}>
										<Row className="width-100" align={"middle"}>
											<Col xs={24} sm={12} md={5} className=" center">
												<Text ellipsis>{item.cliente_id?.nombre} {item?.cliente_id?.apellido_paterno ?? ""} {item?.cliente_id?.apellido_materno ?? ""}</Text>
											</Col>
											<Col xs={24} sm={12} md={4} className=" center">
												<Text>transacciones ( {item?.transacciones} )</Text>
											</Col>
											<Col xs={24} sm={12} md={5} className="center">
												<Statistic
													value={Math.abs(item.comisiones)}
													precision={2}
													prefix={"$"}
													suffix={"MXN"}
													valueStyle={{ fontWeight: 'bold', fontSize: 16, marginRigth: "5px" }}
												/>
											</Col>
											<Col xs={24} sm={12} md={3} className=" center">
												<Text>cuentas ( {item.cuentas?.length} )</Text>
											</Col>
											<Col xs={24} sm={12} md={3} className=" center">
												<Statistic
													value={Math.abs(item.spei_cobros)}
													precision={2}
													prefix={"$"}
													suffix={"MXN"}
													valueStyle={{ fontWeight: 'bold', fontSize: 16, marginRigth: "5px" }}
												/>
											</Col>
											<Col xs={24} sm={12} md={3} className=" center">
												<Statistic
													value={Math.abs(item.uso_cobros)}
													precision={2}
													prefix={"$"}
													suffix={"MXN"}
													valueStyle={{ fontWeight: 'bold', fontSize: 16, marginRigth: "5px" }}
												/>
											</Col>
											<Col xs={24} sm={12} md={1} className="flex-right">
												<Space>
													<Button
														type="primary"
														icon={<EyeOutlined />}
														onClick={()=>this.setState({
															modal_visible: true,
															cliente_id: item._id
														})}
													/>
												</Space>
											</Col>
										</Row>
									</Card>
								</List.Item>
							)}
						/>
					</Col>
					
				</Row>
				<ModalCobranza
					visible={this.state.modal_visible}
					onClose={(flag)=>{
						this.setState({modal_visible: false, cliente_id: undefined})
						if(flag === true)
							this.getClientes()
					}}
					cliente_id={this.state.cliente_id}
				/>
			</>
		)
	}
}

export default () => {




	return <Dashboard />
}