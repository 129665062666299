import React, { Component } from "react";
import { Layout, Menu, Row, Col } from 'antd';
import { FaSitemap, FaUserFriends } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { UserOutlined, KeyOutlined } from "@ant-design/icons";

import {
    IconDashboard,
    IconTransacciones
} from '../Widgets/Icons';
import { User } from '../../Hooks/Logged'
import usePermissions from '../../Hooks/usePermissions';

import './sidebar.css'

const { Sider } = Layout;

/**
 *
 *
 * @export
 * @class Admin_Sidebar
 * @extends {Component}
 */
class CustomerSidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            collapsed: false
        }
    }


    /**
     *
     *
     * @memberof CustomerSidebar
     */
    toogle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }
    render() {
        return (
            <Sider
                width={100}
                collapsedWidth={0}
                breakpoint="lg"
                className="sidebar"
            >
                <Row>
                    <Col span={24} className="center mb-1">
                        <img src={"/img/BXNKLogo.png"} width="64" />
                    </Col>
                </Row>
                <Menu
                    mode="inline"
                    defaultSelectedKeys={['dashboard']}
                >
                    {this.props.dashboard && <Menu.Item
                        key="dashboard"
                        icon={<IconDashboard />}
                        onClick={() => this.props.navigate("/customer/dashboard",)}
                    />}

                    {this.props.transacciones && <Menu.Item
                        key="transacciones"
                        icon={<IconTransacciones />}
                        onClick={() => this.props.navigate("/customer/transacciones",)} />}

                    {this.props.beneficiarios && <Menu.Item
                        key="beneficiarios"
                        icon={<FaUserFriends />}
                        onClick={() => this.props.navigate("/customer/beneficiarios",)} />}

                    {this.props.usuarios ? <Menu.Item
                        title="usuarios"
                        key="usuarios"
                        icon={<UserOutlined />}
                        onClick={() => this.props.navigate("/customer/usuarios",)} /> : null}

                    {this.props.roles ? <Menu.Item
                        title="roles"
                        key="roles"
                        icon={<FaSitemap />}
                        onClick={() => this.props.navigate("/customer/roles",)} /> : null}


                </Menu>

            </Sider>
        )
    }
}

export default function View(props) {

    let user = React.useContext(User)
    let permissions;

    permissions = usePermissions(user?.rol_id?.permisos, {
        dashboard: ["dashboard"],
        transacciones: ["transacciones", "access"],
        beneficiarios: ["beneficiarios", "access"],
        usuarios: ["usuarios", "access"],
        roles: ["roles", "access"],
        cuentas: ["cuentas", "access"],
    })

    return <CustomerSidebar navigate={useNavigate()} {...props} {...permissions} />
}

