import React, { Component } from 'react';
import { Row, Col, Card, Button, Popconfirm, Spin, PageHeader, Layout, message, Space, List, Typography } from 'antd'

import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import { EditOutlined, DeleteOutlined, MailOutlined } from '@ant-design/icons';

//componentes
import Logged from '../../../Hooks/Logged'
import { IconFiltro } from '../../Widgets/Icons';
import DrawerFiltros from '../../Widgets/DrawerFiltros/DrawerFiltros';
import usePermissions from '../../../Hooks/usePermissions';
import FloatingButton from '../../Widgets/FloatingButton/FloatingButton';

//Modales
import ModalRoles from './ModalRoles'
import useSearch from '../../../Hooks/Search';
import { getResponseError } from '../../Utils';


const { Content } = Layout;
const { Text, Title } = Typography;

/**
 * @export
 * @class Roles
 * @extends {Component}
 * @description Vista de Roles
 */
export class Roles extends Component {

	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			modal_visible: false,

			rol_id: undefined,
			roles: {
				data: [],
				limit: 10,
				page: 1,

				total: 0,
				pages: 0,
				filters: []
			},
			searching: true,
			filtroSearch: '',

		}
	}

	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.getRoles()
	}


	/**
	 * @methodOf  Roles
	 * @method getRoles
	 *
	 * @description Obtiene las Roles
	 * */
	getRoles = ({
		page = this.state.roles.page,
		limit = this.state.roles.limit,
		search = this.props.search,
		filters = this.state.roles.filters,
	} = this.state.roles) => {
		this.setState({ loading: true, roles: { ...this.state.roles, page, limit } })
		axios.get('/roles', {
			params: {
				page,
				limit,
				search,
				filters,
				tipos: [1, 2, 4, 5 ]
			}
		}).then(({ data }) => {

			this.setState({
				roles: { ...this.state.roles, ...data, filters },
			});

		}).catch(error => {
			console.log(error)
			message.error(getResponseError(error.response, 'Error al traer la información'))
		})
	}

	/**
	 * @methodOf  Roles
	 * @method TipoRol
	 *
	 * @description Obtiene las Roles
	 * */
	TipoRol = (tipo) => {

		let roles = {
			1 : "Rol Administrativo",
			2 : "Rol Clientes",
			3 : "Rol creado por el cliente",
		}

		return roles[tipo] ?? "N/D" 
	}

	render() {
		return (
			<>
				<Content className="p-1">
					<Row>
						<Col span={24}>
							<Row className='main'>
								<Col xs={12}>
									<Title level={5}> Roles </Title>
								</Col>
								
								<Col xs={24}>
									<List
										loading={this.state.roles.loading}
										className="component-list"
										itemLayout="horizontal"
										locale={{ emptyText: "Sin Roles " }}
										dataSource={this.state.roles.data}
										pagination={{
											current: this.state.roles.page,
											pageSize: this.state.roles.limit,
											total: this.state.roles.total,
											position: 'bottom',
											className: "flex-left",
											onChange: (page, limit) => this.getRoles({ page, limit })
										}}
										header={<Row className="header-list width-100 pr-1 pl-1" gutter={[16, 16]}>
											<Col xs={24} lg={8} className="center">
												<Text strong>Nombre</Text>
											</Col>
											<Col xs={24} lg={9} className="center">
												<Text strong>Descripción</Text>
											</Col>
											<Col xs={24} lg={3} className="center">
												<Text strong>Tipo</Text>
											</Col>
										</Row>}

										renderItem={item => (
											<List.Item className="component-list-item">
												<Card className="card-list width-100">
													<Row className="width-100 " gutter={[16, 16]}>

														<Col xs={24} lg={8} className="flex-left">
															<Text>{item.nombre}</Text>
														</Col>
														<Col xs={24} lg={9} className="center">
															<Text>{item.descripcion}</Text>
														</Col>
														<Col xs={24} lg={3} className="center">
															<Text>{this.TipoRol(item.tipo)}</Text>
														</Col>
														<Col xs={24} lg={4} align="right">
															<Space size="small" direction="horizontal">
																<Button
																	type="primary"
																	disabled={!item.editable || !this.props.editRoles}
																	icon={<EditOutlined />}
																	title="Editar"
																	onClick={() => this.setState({ modal_visible: true, rol_id: item._id, tipo_rol: item.tipo })}
																/>
																<Popconfirm
																	placement="topRight"
																	disabled={!item.editable || !this.props.deleteRoles}
																	title="¿Deseas eliminar este rol?"
																	onConfirm={() => axios.delete('/roles',
																		{ params: { rol_id: item._id } }).then(() => {
																			this.getRoles()
																			message.success('Rol eliminado')
																		}).catch(error => {
																			message.error(getResponseError(error.response, "No se pudo eliminar"))
																		})}
																	okText="Sí"
																	cancelText="No"
																>
																	<Button
																		disabled={!item.editable || !this.props.deleteRoles}
																		type="primary"
																		icon={<DeleteOutlined />}
																		title="Eliminar"
																		danger
																	/>
																</Popconfirm>
															</Space>
														</Col>
													</Row>
												</Card>
											</List.Item>
										)}
									/>
								</Col>
							</Row>
						</Col>
					</Row>


				</Content>

				{this.props.createRoles ? <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modal_visible: true, tipo_rol: 1 })} /> : null}


				<ModalRoles
					visible={this.state.modal_visible}
					onClose={() => {
						this.setState({ modal_visible: false, rol_id: undefined })
						this.getRoles()
					}}
					rol_id={this.state.rol_id}
					user_tipo={this.state.tipo_rol}
				/>

				<DrawerFiltros
					title="Filtros Roles"
					visible={this.state.drawer_visible}
					onClose={() => this.setState({ drawer_visible: false })}
					clientes={true}
					rango_fecha={false}
					fecha={false}
					cuentas={false}
					beneficiarios={false}
					tipo_operacion={false}
					monto={false}
					updateFilters={filters => this.getRoles({
						page: 1,
						filters
					})}
				/>
			</>
		)
	}
}

export default function (props) {

	let user = React.useContext(Logged)

	let permissions = usePermissions(user?.rol_id?.permisos, {
		editRoles: ["roles", "edit"],
		createRoles: ["roles", "create"],
		deleteRoles: ["roles", "delete"]
	})

	const [search, setSearch] = useSearch();

	return <Roles {...props} navigate={useNavigate()} search={search} {...permissions} />

}