import React, { Component, } from "react";
import { Modal, Form, Input, message, Spin, Tree, Typography, Tooltip, Radio } from 'antd';
import axios from "axios"
import { getResponseError } from '../../Utils';
// import  from "antd/lib/skeleton/Paragraph";


/*
    Los permisos están definidos bajo la siguiente estructura:
      * permiso padre | permiso padre | permiso hijo
    Cuando existe un padre, pero no existe ningun hijo, se da por hecho que están todos los hijos. 
    Cuando existe un hijo, se tiene el permiso parcial del padre. 
    Debemos iterar los permisos, si está un padre solo, es necesario 
*/


const { Text, Paragraph } = Typography
/**
 *
 *
 * @class ModalRoles
 * @extends {Component}
 */
class ModalRoles extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            permisos: [],

            //Arreglo de Permisos Completo
            permissionsDictionary: {},


            //Arreglo de Permisos para el Tree Sleect
            selectedPermissions: [],

            //Donde se encuentra localizado el permiso
            selectedPermissionsDictionary: {},

            //Aquellos requeridos
            selectedPermissionsRequired: {},

            //ARBOL DE PERMISOS por defecto, todos son falsos. Al Editar / Gaurdar se cambian los valores con base el arreglo de ANTD
            permissionsTree: {},

            editable: true
        }
    }

    ModalRoles = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getPermisos(this.props.user_tipo)
        if (this.props.rol_id !== undefined) {
            this.get()

        }
    }

    /**
     * @memberof ModalRoles
     * @method get
     * @description Obtiene una rol de la DB
     */
    get = () => {
        this.setState({ loading: true })
        axios.get(`/roles/${this.props.rol_id}`, {
            params: {
                id: this.props.rubro
            }
        }).then(({ data }) => {

            console.log('permisos get', data.permisos)

            // this.getPermisos(data.tipo)
            this.loadCheckedPermissions(data.editable, data.permisos)
            this.ModalRoles.current.setFieldsValue({
                nombre: data.nombre,
                descripcion: data.descripcion,
                tipo: data.tipo
            })
        }).catch(error => {
            message.error(getResponseError(error.response, 'Error al traer el Rol'))
        }).finally(() => this.setState({ loading: false }))
    }

    loadCheckedPermissions = (editable, permisos) => {

        let selectedPermissions = []

        //Metodo recursivo para formatear el objeto de permisos a un arreglo 
        //interpretable por el TREE ANTD
        let updateTreePermissions = (item) => {
            //R es el elemento a revisar, y P es el key
            //Metodo iterativo
            console.log('item', item)
            let iterateTree = (tree, key, path) => {
                //Si no hay un key, simplemente iteramos el TREE y lo llamamos recursivamente
                if (!key) {
                    for (const keyChild in tree)
                        iterateTree(tree, keyChild, keyChild);
                    return
                }

                //Si es un objeto, iterammos el objeto y vamos llenando el PATH hasta recorrer todo
                //el objeti
                if (typeof tree[key] === "object") {
                    path = (path ?? "");
                    //Iteramos los objetos y vamos recursivamente hasta obtener todo el arreglo.
                    for (const keyChild in tree[key])
                        //Ocupo evaluar al padre y al hijo
                        iterateTree(tree[key], keyChild, (path ? (path + "|") : "") + keyChild);

                } else {

                    //Si el permiso está, se agrega al arreglo de permisos
                    if (tree[key])
                        selectedPermissions.push(path)
                }
                return tree;
            }
            return iterateTree(item);
        }
        updateTreePermissions(permisos)

        this.setState({
            editable: editable,
            selectedPermissions
        })
    }

    /**
     * @memberof ModalRoles
     * @method getPermisos
     * @description Obtenemos los permisos actuales en el sistema
     */
    getPermisos = (tipo = 2) => {
        this.setState({ loading: true })
        axios.get(`/roles/permisos`, { params: { tipo } }).then(({ data }) => {


            console.log('data permisos', data)
            let permissionsDictionary = {}
            let permissionsTree = {}

            //Metodo recursivo para formatear el arreglo de permisos 
            //a algo que pueda interpretar el ANTD
            let formatPermisos = (permission, name, permissionsTree) => {
                let cPermission = {
                    title: permission.title
                }
                cPermission['name'] = (name ? (name + "|") : "") + permission.name
                cPermission['key'] = cPermission['name']
                if (permission?.description)
                    cPermission["description"] = permission?.description

                if (permission.required)
                    cPermission['required'] = permission.required

                permissionsDictionary[cPermission['name']] = { ...cPermission }

                if (Array.isArray(permission.permissions)) {
                    if (!permissionsTree[permission.name])
                        permissionsTree[permission.name] = {}
                    cPermission['children'] = permission.permissions.map(item => formatPermisos(item, cPermission['name'], permissionsTree[permission.name]))
                }
                else
                    permissionsTree[permission.name] = false

                return cPermission
            }

            let permisos = data.map(permission => formatPermisos(permission, undefined, permissionsTree))
            this.setState({
                permisos,

                permissionsTree,
                permissionsDictionary,
                permissionsArray: Object.keys(permissionsDictionary)
            })

        })
            .catch(error => {
                console.log("eror", error)
                message.error(getResponseError(error.response, 'Error al traer los permisos'))
            })
            .finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberof ModalRoles
    * @method addRol
    * @description Añade una rol a la BD
    */
    addRol = (values) => {

        if (this.props.tipo)
            values.tipo = this.props.tipo

            
        this.setState({ loading: true })
        axios.post('/roles', {
            ...values,
            cliente_id: this.props.cliente_id,

        }).then(response => {
            message.success('Rol creado')
            this.props.onClose()
        }).catch(error => {
            console.log('error', error)
            message.error(getResponseError(error.response, 'Error al crear el Rol.'))
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalRoles
     * @method updateRol
     * @description Actualiza la información de un rol
     */
    updateRol = (values) => {
        this.setState({ loading: true })

        axios.put('/roles', {
            ...values,
            rol_id: this.props.rol_id,
        }).then(response => {
            message.success('Rol actualizado')
            this.props.onClose()
        }).catch(error => {
            message.error(getResponseError(error.response, 'Error al actualizar el Rol'))
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalRoles
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {
        let { selectedPermissions, permissionsTree } = this.state

        console.log('values', values)
        permissionsTree = { ...this.updateTreePermissions(permissionsTree) }
        values.permisos = { ...permissionsTree }

        if (this.props.rol_id)
            this.updateRol(values)
        else
            this.addRol(values)
    }

    /**
     * @memberof ModalRoles
     * @method updateTreePermissions
     * @description Actualizamos el ARBOL de permisos según el arreglo de elementos selecionados en el TREE ANTD
     */
    updateTreePermissions = (item, selectedPermissionsDictionary = this.state.selectedPermissions.reduce((acc, curr) => (acc[curr] = true, acc), {})) => {
        //R es el elemento a revisar, y P es el key
        let iterateTree = (tree, key, path) => {
            if (!key)
                for (const keyChild in tree)
                    iterateTree(tree, keyChild, keyChild);

            if (typeof tree[key] === "object") {
                path = (path ?? "");
                for (const keyChild in tree[key])
                    //Ocupo evaluar al padre y al hijo
                    iterateTree(tree[key], keyChild, (path ? (path + "|") : "") + keyChild);

            } else
                tree[key] = Boolean(selectedPermissionsDictionary[path]);

            return tree;
        }
        return iterateTree(item);
    }

    /**
     * @memberof ModalRoles
     * @method actualizarArbolDePermisos
     * @description Se encarga de evaluar si alguno de los elementos dependen de otros elementos, esto para todos los permisos dependiendtes de otros permisos coincida. 
     */
    actualizarArbolDePermisos = (selectedPermissions, allConfig) => {


        const {
            permissionsArray,
            selectedPermissionsRequired,
            permissionsDictionary
        } = this.state

        const { checked, node } = allConfig
        const { name } = node

        //Transformo el arreglo de permisos a un objeto
        let selectedPermissionsDictionary = selectedPermissions.reduce((acc, curr) => (acc[curr] = '', acc), {});

        /*
        Cuando se seleccciona un permiso, se debe de revisar todos aquellso permisos que 
        dependen de ese permiso o bien, los hijos de ese permiso
        */
        if (checked) {

            let getAllPermissionsRequired = (permission) => {

                //Obtengo todos los permisos HIJOS de este permiso
                let permisosHijos = permissionsArray.filter(permissionArray => permissionArray.startsWith(permission))
                let permisosRequired = {}
                // let permisosRequired = {}
                //Iteramos cada hijo, obtenemos lo que require cada uno
                for (const permisoHijo of permisosHijos) {

                    //Obtenemos los permisos que require cada hijo
                    if (permissionsDictionary[permisoHijo].required) for (const permisoHijoRequired of permissionsDictionary[permisoHijo].required) {

                        let permisosParaAgregar = permissionsArray.filter(permissionArray => (permissionArray.startsWith(permisoHijoRequired)))

                        permisosParaAgregar.map(permisoHijoRequired => {

                            permisosRequired[permisoHijoRequired] = true
                            if (!selectedPermissionsRequired[permisoHijoRequired])
                                selectedPermissionsRequired[permisoHijoRequired] = {}

                            selectedPermissionsRequired[permisoHijoRequired][name] = true
                            selectedPermissionsRequired[permisoHijoRequired][permisoHijo] = true
                            //Obtenemos los permisos que requieren los permisos requeridos  
                            permisosRequired = { ...permisosRequired, ...getAllPermissionsRequired(permisoHijoRequired) }

                        })


                    }
                }
                return permisosRequired;
            }

            //Itero todos los permisos requeridos, si no existen se agregan al arbol de permisos
            for (const permisoRequired in getAllPermissionsRequired(name)) {
                let permisosParaAgregar = permissionsArray.filter(permissionArray => (permissionArray.startsWith(permisoRequired)))
                for (const permisoParaAgregar of permisosParaAgregar) {
                    selectedPermissionsDictionary[permisoParaAgregar] = true
                }
            }
            //Transformo a un arreglo para que el TREE lo pueda interpretar.
        }
        /*
        Cuando se deselecciona, se deben deseleccionar todos aquellos permisos que dependan de ese permiso. 
        */
        else {
            let removePermissionsRequired = selectedPermissionsRequired[name];
            for (const removePermission in removePermissionsRequired)
                for (const selectedPermissionDictionary in selectedPermissionsDictionary)
                    if (selectedPermissionDictionary.startsWith(removePermission) || !selectedPermissionDictionary.includes("|"))
                        delete selectedPermissionsDictionary[selectedPermissionDictionary]
        }
        this.setState({ selectedPermissions: Object.keys(selectedPermissionsDictionary), selectedPermissionsRequired })
    }

    render() {
        console.log('state roles', this.state)
        return (
            <Form
                layout="vertical"
                name="form-roles"
                id="form-roles"
                ref={this.ModalRoles}
                onFinish={this.onFinish}
                className="pd-1"
            >
                <Spin spinning={this.state.loading}>
                    <Form.Item
                        label="Nombre"
                        name="nombre"
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese el nombre"
                        }]}
                    >
                        <Input placeholder="Nombre" ></Input>
                    </Form.Item>
                    <Form.Item
                        label="Descripción"
                        name="descripcion"
                    >
                        <Input placeholder="Descripción" ></Input>
                    </Form.Item>
                    {this.props.tipo ? null : <Form.Item
                        label="Tipo de Rol"
                        name="tipo"
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese el tipo de Rol"
                        }]}
                    >
                        <Radio.Group size="10" onChange={(e) => this.getPermisos(e.target.value)}>
                            <Radio value={1}>
                                <Paragraph strong style={{ marginBottom: 3 }}>Administrativo</Paragraph>
                                <Text>Tiene control de todos los elementos de los cuales tenga permiso</Text>
                            </Radio>
                            <Radio value={2} style={{ marginTop: 20 }}>
                                <Paragraph strong style={{ marginBottom: 3 }}>Cliente</Paragraph>
                                <Text>Son roles creados para los clientes, los permisos se refieren a los elementos relacionados al cliente. <br></br>Estos roles son específicos para los clientes</Text>
                            </Radio>
                        </Radio.Group>
                    </Form.Item>}
                    <Form.Item
                        label="Permiso del Rol"
                        name="permisos"
                    >
                        <Tree
                            onCheck={this.actualizarArbolDePermisos}
                            checkedKeys={this.state.selectedPermissions}
                            disabled={this.state.editable === false}
                            checkable
                            treeData={this.state.permisos}
                            defaultExpandAll={true}

                            titleRender={(node) => {
                                if (node.description)
                                    return <Tooltip title={node.description} placement="right">{node.title}</Tooltip>
                                return node.title
                            }}
                        />
                    </Form.Item>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, rol_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        title={rol_id ? "Editar Rol" : "Crear Rol"}
        closable={true}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-roles', key: 'form-roles', htmlType: 'submit' }}
    >
        <ModalRoles {...props} />
    </Modal>


}